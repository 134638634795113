import React from "react";
import {
  Button,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from "@mui/material";
import useTranslation from "../../hooks/Translation";

const HomeSection4 = () => {
  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));
  const translate = useTranslation();
  return (
    <>
      <section className="cta1">
        <div className="conta_iner">
          <div className="cta_flx">
            <div className="cta_lt hd_2">
              <h3>{translate.home.getPersonalizedWithWhizzo}</h3>
              <p>
                <small>{translate.home.getPersonalizedSubtext}</small>
              </p>
              <div className="app_flx mt_40">
                <BootstrapTooltip title="Coming Soon">
                  <figure>
                    <img src="/static/images/app_store.svg" alt=""></img>
                  </figure>
                </BootstrapTooltip>
                <BootstrapTooltip title="Coming Soon">
                  <figure>
                    <img src="/static/images/play_store.svg" alt=""></img>
                  </figure>
                </BootstrapTooltip>
              </div>
            </div>
            <div className="cta_rt">
              <figure>
                <img src="/static/images/cta1_img.png" alt=""></img>
              </figure>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default HomeSection4;
