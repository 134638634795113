import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import Drag from "./drag";
import { TestAI } from "../constants/testAILoading";
import TestingModuleDrag from "./testingModuleDrag";
import useTranslation from "../hooks/Translation";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function Test(props: any) {
  const translate = useTranslation();
  const navigate = useNavigate();
  const [path, setPath] = React.useState<string>("");

  const [openDrag, setDragOpen] = React.useState<any>(false);

  const handleDragClose = () => {
    setDragOpen(false);
  };

  return (
    <>
      <React.Fragment>
        <BootstrapDialog
          className="modl"
          onClose={props.handleClose}
          aria-labelledby="customized-dialog-title"
          open={props.open}
        >
          <IconButton
            aria-label="close"
            onClick={props.handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <p>{translate.testing.selectTypeOfTest}</p>
            <ul className="tst_lst">
              <li>
                <figure>
                  <img src="/static/images/bars.png" alt=""></img>
                </figure>
                <Button
                  className="btnn btn_primary"
                  onClick={() => {
                    if (props?.testing) {
                      props?.setOpen(false);
                      setDragOpen(true);
                      setPath("/quiz/");
                    }
                  }}
                >
                  {translate.testing.questionsWithOptions}
                </Button>
              </li>
              <li>
                <figure>
                  <img src="/static/images/box.png" alt=""></img>
                </figure>
                <Button
                  className="btnn btn_primary"
                  onClick={() => {
                    if (props?.testing) {
                      props?.setOpen(false);
                      setDragOpen(true);
                      setPath("/flashcard-quiz/");
                    }
                  }}
                >
                  {translate.testing.flashcards}
                </Button>
              </li>
            </ul>
          </DialogContent>
          <DialogActions></DialogActions>
        </BootstrapDialog>
        <TestingModuleDrag
          open={openDrag}
          setOpen={setDragOpen}
          handleClose={handleDragClose}
          nextPage={path}
          type={TestAI.TESTING}
        />
      </React.Fragment>
    </>
  );
}
export default Test;
