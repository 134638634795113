import React, { useEffect, useState } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
//@ts-ignore
import { compress, decompress } from "@witzbould/utils-string-compression";

import { Button, IconButton } from "@mui/material";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { presentationTemplate } from "../../constants/presentationTemplate";
import * as fabric from "fabric";
import {
  useDetailedPresentationMutation,
  useEditPresentationByIdMutation,
  useLazyGetPresentationByIdQuery,
} from "../../services/presentation";
import { showError, showToast } from "../../constants/toast";
import { CommonBody } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import pptxgen from "pptxgenjs";
import Loader from "../../constants/Loader";
import { getFromStorage, setToStorage } from "../../constants/storage";
import { STORAGE_KEYS } from "../../constants/storageKeys";
import { UploadMedia, UploadTextData } from "../../utils/mediaUpload";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  compressData,
  decompressData,
  GetTextData,
} from "../../utils/decompressData";
import useTranslation from "../../hooks/Translation";
const CANVAS_WIDTH = 1152;
const CANVAS_HEIGHT = 648;
const PPT_WIDTH = 10;
const PPT_HEIGHT = 5.6;
let pptContent = "";
let topicName = "";
let binaryDataArray: any = [];
let template_id: any = 0;
// let selectedIndex = 0;
function PresentationEdit() {
  const translate = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [currentPresentationData, setCurrentPresentationData] = useState<any>();
  const [canvas, setCanvas] = useState<any>(null);
  const [slideList, setSlideList] = useState<any>([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedSlide, setSelectedSlide] = useState(
    "/static/images/ppt-placeholder.png"
  );
  const [currentSlideInfo, setCurrentSlideInfo] = useState({
    currentPage: 1,
    totalPages: 6,
  });
  const [searchParam, setSearchParam] = useSearchParams();
  const [displayBackButton, setDisplayBackButton] = useState(true);
  const { state } = useLocation();

  const singleLineLimitAdder = (stringData: any, wordLimitPerLine: number) => {
    stringData = stringData?.trim()?.replaceAll("\n", " ");
    let count = 0;
    let tempString = "";
    for (let key of stringData.split(" ")) {
      if (count < wordLimitPerLine) tempString += key + " ";
      else {
        tempString += key + "\n";
        count = 0;
      }
      count++;
    }
    return tempString;
  };
  const generateImagesFromPptJson = async (pptData: any[]) => {
    const canvasElement: any = document.createElement("canvas");

    try {
      const canvasInstance = new fabric.Canvas(canvasElement, {
        width: CANVAS_WIDTH,
        height: CANVAS_HEIGHT,
        backgroundColor: "#FFFFFF",
      });
      const slideList: string[] = [];
      console.log("pptData :", pptData);
      for (const data of pptData) {
        await canvasInstance
          .loadFromJSON(JSON.parse(await decompressData(data)))
          .then(() => {
            canvasInstance.renderAll();
            console.log(
              "canvasInstance.toDataURL() :",
              canvasInstance.toDataURL()
            );
            slideList.push(canvasInstance.toDataURL());
            // setSlideList((prevData:any)=>[...prevData,canvasInstance?.toDataURL()])
            console.log("slideList00000 :", slideList);

            canvasInstance.clear();
          });
      }
      setSlideList(slideList);
      setSelectedSlide(slideList[0]);
      // selectedIndex = index;
      setSelectedIndex(0);
      // window.scrollTo(0,0)

      console.log("slideList232323 :", slideList);
      setCurrentSlideInfo((prev) => ({ ...prev, currentPage: 1 }));
      setIsLoading(false);
    } catch (error: any) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const anchor: any = document.querySelector("#scrollPptPage");
    if (anchor) anchor.scrollIntoView({ behavior: "smooth", block: "center" });
  }, [currentSlideInfo]);
  const getValueWithInLimits = (
    originalRangeInitialValue: number,
    originalRangeFinalValue: number,
    targetRangeInitialValue: number,
    targetRangeFinalValue: number,
    currentValue: number
  ) => {
    return (
      targetRangeInitialValue +
      ((currentValue - originalRangeInitialValue) *
        (targetRangeFinalValue - targetRangeInitialValue)) /
      (originalRangeFinalValue - originalRangeInitialValue)
    );
  };
  const convertFabricToPptElementsAndDownload = async (
    slide: any,
    objects: any,
    presentation: any,
    totalIndex?: any,
    currentIndex?: any
  ) => {
    objects.forEach(async function (obj: any) {
      // console.log("OBJ<<<<<<<<<<<<<<<<", getValueWithInLimits(0,CANVAS_WIDTH,0,PPT_WIDTH,obj.width),obj.getScaledWidth());
      console.log("basic", obj.width);
      console.log("scaled", obj.getScaledWidth());
      console.log("bounding", obj.getBoundingRect().width);
      //   points?: Array<
      // 	| { x: Coord, y: Coord, moveTo?: boolean }
      // 	| { x: Coord, y: Coord, curve: { type: 'arc', hR: Coord, wR: Coord, stAng: number, swAng: number } }
      // 	| { x: Coord, y: Coord, curve: { type: 'cubic', x1: Coord, y1: Coord, x2: Coord, y2: Coord } }
      // 	| { x: Coord, y: Coord, curve: { type: 'quadratic', x1: Coord, y1: Coord } }
      // 	| { close: true }
      // >
      console.log("path", obj?.path);
      // let data=[]
      // for(let key of obj?.path)
      // {
      //   if(key[0]=="M")
      //   {
      //     data.push({ x: key[1], y: key[2], moveTo:true });
      //   }
      //   if(key[0]=="L")
      //   {
      //     data.push({ x: key[1], y: key[2]});
      //   }
      //   if(key[0]=="Q")
      //   {
      //     data.push({ x: key[1], y: key[2], curve: { type: 'quadratic', x1: key[3], y1 : key[4] } })
      //   }
      // }
      switch (obj.type) {
        case "rect":
          // slide.addShape({
          //   type: 'rect',
          //   x: obj.left,
          //   y: obj.top,
          //   w: obj.width,
          //   h: obj.height,
          //   fill: obj.fill
          // });
          slide.addShape(presentation.ShapeType.rect, {
            // type:presentation.ShapeType.ellipse,
            // w: getValueWithInLimits(0,CANVAS_WIDTH,0,PPT_WIDTH,obj.width),
            // h: getValueWithInLimits(0,CANVAS_HEIGHT,0,PPT_HEIGHT,obj.height),
            // fill: { type: "solid", color: "0088CC" },
            x: getValueWithInLimits(0, CANVAS_WIDTH, 0, PPT_WIDTH, obj.left),
            y: getValueWithInLimits(0, CANVAS_HEIGHT, 0, PPT_HEIGHT, obj.top),
            w: getValueWithInLimits(
              0,
              CANVAS_WIDTH,
              0,
              PPT_WIDTH,
              obj.getScaledWidth()
            ),
            h: getValueWithInLimits(
              0,
              CANVAS_HEIGHT,
              0,
              PPT_HEIGHT,
              obj.getScaledHeight()
            ),
            fill: obj.fill,
            rotate: obj.angle,
          });
          break;
        case "circle":
          slide.addShape(presentation.ShapeType.ellipse, {
            // type:presentation.ShapeType.ellipse,
            // w: getValueWithInLimits(0,CANVAS_WIDTH,0,PPT_WIDTH,obj.width),
            // h: getValueWithInLimits(0,CANVAS_HEIGHT,0,PPT_HEIGHT,obj.height),
            // fill: { type: "solid", color: "0088CC" },
            x: getValueWithInLimits(0, CANVAS_WIDTH, 0, PPT_WIDTH, obj.left),
            y: getValueWithInLimits(0, CANVAS_HEIGHT, 0, PPT_HEIGHT, obj.top),
            w: getValueWithInLimits(
              0,
              CANVAS_WIDTH,
              0,
              PPT_WIDTH,
              obj.getScaledWidth()
            ),
            h: getValueWithInLimits(
              0,
              CANVAS_HEIGHT,
              0,
              PPT_HEIGHT,
              obj.getScaledHeight()
            ),
            fill: obj.fill,
            rotate: obj.angle,
          });
          break;
        case "triangle":
          slide.addShape(presentation.ShapeType.triangle, {
            // type:presentation.ShapeType.ellipse,
            // w: getValueWithInLimits(0,CANVAS_WIDTH,0,PPT_WIDTH,obj.width),
            // h: getValueWithInLimits(0,CANVAS_HEIGHT,0,PPT_HEIGHT,obj.height),
            // fill: { type: "solid", color: "0088CC" },
            x: getValueWithInLimits(0, CANVAS_WIDTH, 0, PPT_WIDTH, obj.left),
            y: getValueWithInLimits(0, CANVAS_HEIGHT, 0, PPT_HEIGHT, obj.top),
            w: getValueWithInLimits(
              0,
              CANVAS_WIDTH,
              0,
              PPT_WIDTH,
              obj.getScaledWidth()
            ),
            h: getValueWithInLimits(
              0,
              CANVAS_HEIGHT,
              0,
              PPT_HEIGHT,
              obj.getScaledHeight()
            ),
            fill: obj.fill,
            rotate: obj.angle,
          });
          break;
        // case 'path':
        //   slide.addShape(presentation.PlaceholderType,{
        //     // type:presentation.ShapeType.ellipse,
        //     // w: getValueWithInLimits(0,CANVAS_WIDTH,0,PPT_WIDTH,obj.width),
        //     // h: getValueWithInLimits(0,CANVAS_HEIGHT,0,PPT_HEIGHT,obj.height),
        //     // fill: { type: "solid", color: "0088CC" },
        //     x: getValueWithInLimits(0,CANVAS_WIDTH,0,PPT_WIDTH,obj.left),
        //     y: getValueWithInLimits(0,CANVAS_HEIGHT,0,PPT_HEIGHT,obj.top),
        //     w: getValueWithInLimits(0,CANVAS_WIDTH,0,PPT_WIDTH,obj.getScaledWidth()),
        //     h: getValueWithInLimits(0,CANVAS_HEIGHT,0,PPT_HEIGHT,obj.getScaledHeight()),
        //     fill: obj.fill,
        //     rotate:obj.angle,
        //   });
        //   break;
        case "image":
          slide.addImage({
            data: obj?.getSrc(),
            x: getValueWithInLimits(0, CANVAS_WIDTH, 0, PPT_WIDTH, obj.left),
            y: getValueWithInLimits(0, CANVAS_HEIGHT, 0, PPT_HEIGHT, obj.top),
            w: getValueWithInLimits(
              0,
              CANVAS_WIDTH,
              0,
              PPT_WIDTH,
              obj.getScaledWidth()
            ),
            h: getValueWithInLimits(
              0,
              CANVAS_HEIGHT,
              0,
              PPT_HEIGHT,
              obj.getScaledHeight()
            ),
            // fill: obj.fill,
            rotate: obj.angle,
          });
          break;
        case "textbox":
          slide.addText(obj.text, {
            // x: obj.left,
            // y: obj.top,
            x: getValueWithInLimits(
              0,
              CANVAS_WIDTH,
              0,
              PPT_WIDTH,
              obj.left - 125
            ),
            y: getValueWithInLimits(
              0,
              CANVAS_HEIGHT,
              0,
              PPT_HEIGHT,
              obj.top - 15
            ),
            w: getValueWithInLimits(
              0,
              CANVAS_WIDTH,
              0,
              PPT_WIDTH,
              obj.getScaledWidth() + 300
            ),
            h: getValueWithInLimits(
              0,
              CANVAS_HEIGHT,
              0,
              PPT_HEIGHT,
              obj.getScaledHeight() + 30
            ),
            fontSize: obj.fontSize,
            fontFace: obj.fontFamily - 2,
            color: obj.fill,
            // fill: obj.fill
            rotate: obj.angle,
            inset: 1,
          });
          break;
        case "i-text":
          console.log("MOHIT ", obj);
          const data = obj.getSelectionStyles(0, obj.text.length, true);
          let pptTextData = [];
          for (let key = 0; key < obj.text.length; key += 1) {
            //   [
            //     { text: "I have a star bullet", options: { bullet: { code: "2605" }, color: "CC0000" } },
            //     { text: "I have a triangle bullet", options: { bullet: { code: "25BA" }, color: "00CD00" } },
            //     { text: "no bullets on this line", options: { fontSize: 12 } },
            //     { text: "I have a normal bullet", options: { bullet: true, color: "0000AB" } },
            // ],

            //   {
            //     "fontSize": 28,
            //     "fontWeight": "bold",
            //     "fontFamily": "Lucida Console",
            //     "fontStyle": "italic",
            //     "underline": true,
            //     "overline": false,
            //     "linethrough": false,
            //     "stroke": null,
            //     "strokeWidth": 1,
            //     "fill": "#000000",
            //     "deltaY": 0,
            //     "textBackgroundColor": ""
            // }
            pptTextData.push({
              text: obj.text[key],
              options: {
                bold: data[key]?.fontWeight == "bold",
                italic: data[key]?.fontStyle == "italic",
                underline: data[key]?.underline,
                color: data[key]?.fill,
                fontSize: data[key]?.fontSize - 7,
                fontFace: data[key]?.fontFamily,
              },
            });
          }
          console.log("MOHIT pptTextData :", pptTextData);
          console.log("MOHIT s 2", data);
          await slide.addText(
            // obj.text
            pptTextData,
            {
              // x: obj.left,
              // y: obj.top,
              x: getValueWithInLimits(0, CANVAS_WIDTH, 0, PPT_WIDTH, obj.left),
              y: getValueWithInLimits(0, CANVAS_HEIGHT, 0, PPT_HEIGHT, obj.top),
              w: getValueWithInLimits(
                0,
                CANVAS_WIDTH,
                0,
                PPT_WIDTH,
                obj.getBoundingRect().width + obj.getBoundingRect().width * 0.5
              ),
              h: getValueWithInLimits(
                0,
                CANVAS_HEIGHT,
                0,
                PPT_HEIGHT,
                obj.getBoundingRect().height
              ),
              // fontSize: obj.fontSize-7,
              // fontFace: obj.fontFamily,
              // color:obj.fill,
              rotate: obj.angle,
              // inset:1,
              margin: 0,
              // fit:"resize",
              autoFit: true,

              // fill: obj.fill
            }
          );
          break;
        case "line":
          slide.addShape(presentation.ShapeType.line, {
            x: getValueWithInLimits(0, CANVAS_WIDTH, 0, PPT_WIDTH, obj.left),
            y: getValueWithInLimits(0, CANVAS_HEIGHT, 0, PPT_HEIGHT, obj.top),
            w: getValueWithInLimits(
              0,
              CANVAS_WIDTH,
              0,
              PPT_WIDTH,
              obj.getScaledWidth()
            ),
            h: getValueWithInLimits(
              0,
              CANVAS_HEIGHT,
              0,
              PPT_HEIGHT,
              obj.getScaledHeight()
            ),
            // fill: obj.fill,
            rotate: obj.angle,
            stroke: obj.stroke,
            strokeWidth: obj.strokeWidth,
          });
          break;
        // case 'path':
        // const path = obj.path;
        // const svg = `
        //   <svg width="${obj.width}" height="${obj.height}">
        //     <path d="${getPathD(path)}" stroke="black" stroke-width="1" fill="none" />
        //   </svg>
        // `;
        // toImg(svg, 'pathDrawing', {
        //   scale: 3,
        //   format: 'png',
        //   quality: 0.01,
        //   download: false,
        //   ignore: '.ignored'
        // }).then((fileData:any) => {
        //   const imageData = fileData.toString('base64');
        //   const imageURL=`data:image/png;base64,${imageData}`
        //   console.log(imageURL);

        //   slide.addImage({
        //     data: `data:image/png;base64,${imageData}`,
        //     x: obj.left,
        //     y: obj.top,
        //     w: obj.width,
        //     h: obj.height,
        //   });
        // });

        // break;

        case "path":
          const path: any = obj.path;
          console.log("JOHN", obj.stroke);
          const boundingRect = obj.getBoundingRect();
          const canvasTemp = document.createElement("canvas");
          canvasTemp.width = boundingRect.width;
          canvasTemp.height = boundingRect.height;
          const ctx: any = canvasTemp.getContext("2d");
          ctx.strokeStyle = obj.stroke;
          ctx.translate(-boundingRect.left, -boundingRect.top); // translate to the top-left corner of the bounding box
          ctx.beginPath();
          path.forEach((segment: any) => {
            const command = segment[0];
            const args = segment.slice(1);
            switch (command) {
              case "M":
                ctx.moveTo(args[0], args[1]);
                break;
              case "Q":
                ctx.quadraticCurveTo(args[0], args[1], args[2], args[3]);
                break;
              case "L":
                ctx.lineTo(args[0], args[1]);
                break;
              default:
                break;
            }
          });
          ctx.stroke();
          const imageData = canvasTemp.toDataURL("image/png");
          console.log("_______________", imageData);
          slide.addImage({
            data: imageData,
            x: getValueWithInLimits(0, CANVAS_WIDTH, 0, PPT_WIDTH, obj.left),
            y: getValueWithInLimits(0, CANVAS_HEIGHT, 0, PPT_HEIGHT, obj.top),
            w: getValueWithInLimits(
              0,
              CANVAS_WIDTH,
              0,
              PPT_WIDTH,
              obj.getScaledWidth()
            ),
            h: getValueWithInLimits(
              0,
              CANVAS_HEIGHT,
              0,
              PPT_HEIGHT,
              obj.getScaledHeight()
            ),
            // fill: obj.fill,
            rotate: obj.angle,
          });
          break;
        //       case 'path':

        // const boundingRect = obj.getBoundingRect();
        // const tempCanvas = new fabric.StaticCanvas(undefined, {
        //   width: boundingRect.width,
        //   height: boundingRect.height,
        // });

        // // Calculate the offset of the bounding box from the original canvas
        // const offsetX = obj.left;
        // const offsetY = obj.top;

        // // Create a temporary object with the same properties as the original path
        // const tempPath = obj.path.clone();
        // tempPath.set({
        //   left: -offsetX,
        //   top: -offsetY,
        // });

        // tempCanvas.add(tempPath);
        // tempCanvas.renderAll();
        // const imageData = tempCanvas.toDataURL();
        // // const imageData = new Image();
        // // imageData.src = pngDataUrl;
        // console.log("_______________",imageData);
        // slide.addImage({
        //   data: imageData,
        //   x: getValueWithInLimits(0,CANVAS_WIDTH,0,PPT_WIDTH,obj.left),
        //   y: getValueWithInLimits(0,CANVAS_HEIGHT,0,PPT_HEIGHT,obj.top),
        //   w: getValueWithInLimits(0,CANVAS_WIDTH,0,PPT_WIDTH,obj.getScaledWidth()),
        //   h: getValueWithInLimits(0,CANVAS_HEIGHT,0,PPT_HEIGHT,obj.getScaledHeight()),
        //   // fill: obj.fill,
        //   rotate:obj.angle,
        // });
        //       break;

        // case 'path':
        //   const path:any = obj.path;
        //   console.log(path);
        // const canvasTemp = document.createElement('canvas');
        // canvasTemp.width = canvas.width;
        // canvasTemp.height = canvas.height;
        // const ctx :any= canvasTemp.getContext('2d');
        // ctx.strokeStyle = obj.fill;
        // ctx.beginPath();
        // path.forEach((segment:any) => {
        //   const command = segment[0];
        //   const args = segment.slice(1);
        //   switch (command) {
        //     case 'M':
        //       ctx.moveTo(args[0], args[1]);
        //       break;
        //     case 'Q':
        //       ctx.quadraticCurveTo(args[0], args[1], args[2], args[3]);
        //       break;
        //     case 'L':
        //       ctx.lineTo(args[0], args[1]);
        //       break;
        //     default:
        //       break;
        //   }
        // });
        // ctx.stroke();
        // const imageData = canvasTemp.toDataURL('image/png');
        // console.log("_______________",imageData);
        // slide.addImage({
        //   data: imageData,
        //   x: getValueWithInLimits(0,CANVAS_WIDTH,0,PPT_WIDTH,obj.left),
        //   y: getValueWithInLimits(0,CANVAS_HEIGHT,0,PPT_HEIGHT,obj.top),
        //   w: getValueWithInLimits(0,CANVAS_WIDTH,0,PPT_WIDTH,obj.getScaledWidth()),
        //   h: getValueWithInLimits(0,CANVAS_HEIGHT,0,PPT_HEIGHT,obj.getScaledHeight()),
        //   // fill: obj.fill,
        //   rotate:obj.angle,
        // });
        // break;
      }
    });
    if (totalIndex == currentIndex) {
      if (Boolean(searchParam.get("app"))) {
        presentation
          .write("blob")
          .then(async (data: any) => {
            console.log("write as base64: Here are 0-100 chars of `data`:\n");
            const uploadPPT = await UploadMedia(data, "Presentation.pptx");
            console.log("TEST", uploadPPT);
            // alert(uploadPPT?.data?.[0]?.media_url);
            // navigate(window.location.href);
            //@ts-ignore
            window["ReactNativeWebView"] &&
              //@ts-ignore
              window["ReactNativeWebView"].postMessage(
                uploadPPT?.data?.[0]?.media_url
              );
            setIsLoading(false);
            window.open(uploadPPT?.data?.[0]?.media_url);
            showToast(
              translate.presentation.pptDownloadedSuccessfully ||
              "PPT Downloaded Successfully"
            );
          })
          .catch((err: any) => {
            console.error(err);
            setIsLoading(false);
          });
      } else {
        presentation.writeFile();
        setIsLoading(false);

      }
    }

  };
  const handleDownloadPPT = async (
    slideListData: any,
    backgroundImage: any
  ) => {
    console.log("slideListData :", slideListData);
    setIsLoading(true);
    try {
      if (!slideListData?.length) {
        showError(
          translate.presentation.pptDoNotHaveAnySlides ||
          "PPT don't have any slides",
          Boolean(searchParam.get("app")) ||
          Boolean(getFromStorage(STORAGE_KEYS.application))
        );
        return;
      }
      const allPresentation = new pptxgen();
      for (let key in slideListData) {
        const slide: any = allPresentation.addSlide();
        if (backgroundImage) slide.background = { path: backgroundImage };

        const canvasElement: any = document.createElement("canvas");
        const canvasInstance = new fabric.Canvas(canvasElement, {
          width: CANVAS_WIDTH,
          height: CANVAS_HEIGHT,
          backgroundColor: "#FFFFFF",
        });

        canvasInstance
          .loadFromJSON(JSON.parse(await decompressData(slideListData[key])))
          .then(() => {
            canvasInstance.renderAll();
            let objects = canvasInstance.getObjects();
            console.log("MOOHIIIITT", objects);
            convertFabricToPptElementsAndDownload(
              slide,
              objects,
              allPresentation,
              slideListData?.length - 1,
              key
            );
            console.log("TTTTTTTTTT", slide);
          });
      }
    } catch (error: any) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const generateInitialScreen = async (contentData: any) => {
    const canvasElement: any = document.createElement("canvas");
    const canvasInstance = new fabric.Canvas(canvasElement, {
      width: CANVAS_WIDTH,
      height: CANVAS_HEIGHT,
      backgroundColor: "#FFFFFF",
    });

    const slideList: string[] = [];

    for (const data of contentData) {
      const img = new Image();
      img.src = state.template;
      await new Promise((resolve) => {
        img.onload = () => {
          const image = new fabric.Image(img, {
            width: CANVAS_WIDTH,
            height: CANVAS_HEIGHT,
            selectable: false, // make sure the background image is not selectable
          });
          canvasInstance.backgroundImage = image; // set the background image
          const headingText = new fabric.IText(
            singleLineLimitAdder(data.heading, 12),
            {
              left: CANVAS_WIDTH * 0.2,
              top: 100,
              fontSize: 28,
              fontFamily: "Helvetica",
              fill: "#000000",
              lockScalingX: true,
              lockScalingY: true,
              hasControls: false,
            }
          );
          const contentText = new fabric.IText(
            singleLineLimitAdder(data.content, 12),
            {
              left: CANVAS_WIDTH * 0.25,
              top: 150,
              fontSize: 18,
              fontFamily: "Helvetica",
              fill: "#000000",
              lockScalingX: true,
              lockScalingY: true,
              hasControls: false,
            }
          );
          canvasInstance.add(headingText);
          canvasInstance.add(contentText);
          canvasInstance.renderAll(); // render the canvas
          slideList.push(canvasInstance.toDataURL());
          canvasInstance.clear();
          resolve("done");
        };
      });
    }
    setSelectedSlide(slideList[0]);
    setSlideList(slideList);
    setCanvas(canvasInstance);
  };

  // useEffect(()=>{
  //   generateInitialScreen(state.content);
  //     // return () => {
  //     //   canvasInstance.dispose();
  //     // };
  // },[])
  const [getContent] = useDetailedPresentationMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [editById] = useEditPresentationByIdMutation();

  const generateInitialJSON = async (contentData: any) => {
    const canvasElement: any = document.createElement("canvas");
    const canvasInstance = new fabric.Canvas(canvasElement, {
      width: CANVAS_WIDTH,
      height: CANVAS_HEIGHT,
      backgroundColor: "#FFFFFF",
    });

    const slideList: string[] = [];

    for (const data of contentData) {
      const img = new Image();
      img.src = presentationTemplate[template_id - 1]?.path;
      await new Promise((resolve) => {
        img.onload = async () => {
          const image = new fabric.Image(img, {
            width: CANVAS_WIDTH,
            height: CANVAS_HEIGHT,
            selectable: false, // make sure the background image is not selectable
          });
          canvasInstance.backgroundImage = image; // set the background image
          const headingText = new fabric.IText(
            singleLineLimitAdder(data.heading, 12),
            {
              left: CANVAS_WIDTH * 0.2,
              top: 100,
              fontSize: 28,
              fontFamily: "Helvetica",
              fill: "#000000",
              lockScalingX: true,
              lockScalingY: true,
              hasControls: false,
            }
          );
          const contentText = new fabric.IText(
            singleLineLimitAdder(data.content, 12),
            {
              left: CANVAS_WIDTH * 0.25,
              top: 150,
              fontSize: 18,
              fontFamily: "Helvetica",
              fill: "#000000",
              lockScalingX: true,
              lockScalingY: true,
              hasControls: false,
            }
          );
          canvasInstance.add(headingText);
          canvasInstance.add(contentText);
          canvasInstance.renderAll(); // render the canvas
          slideList.push(
            await compressData(JSON.stringify(await canvasInstance.toJSON()))
          );
          canvasInstance.clear();
          resolve("done");
        };
      });
    }
    // setSlideJSONList(slideList);
    const binaryAWS = await UploadTextData(JSON.stringify(slideList));
    editPresentationBinaryData(binaryAWS, slideList);
    console.log("slideList :", slideList);
  };

  const editPresentationBinaryData = async (data: any, binaryData: any) => {
    try {
      const body = {
        template_id: template_id,
        binary_data: data || [],
      };
      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await editById({
        id: id as string,
        body: encryptedBody,
      }).unwrap();
      if (response?.status == 200) {
        showToast(
          translate.toastMessages.regenerationSuccessful ||
          "Regenerated Successfully",
          Boolean(searchParam.get("app")) ||
          Boolean(getFromStorage(STORAGE_KEYS.application))
        );
        generateImagesFromPptJson(binaryData);
        console.log(response);
      }
    } catch (error: any) {
      showError(
        error?.data?.message || translate.errors.somethingWentWrong,
        Boolean(searchParam.get("app")) ||
        Boolean(getFromStorage(STORAGE_KEYS.application))
      );
      console.log(error);
    }
  };

  const GetTopicData = async () => {
    setIsLoading(true);
    try {
      const body = {
        topic: topicName || state?.topic || "",
        slides: 6,
      };
      console.log("body :", body);
      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await getContent(encryptedBody).unwrap();
      if (response?.status == 200) {
        console.log(response);
        generateInitialJSON(response?.data);
        pptContent = response?.data;
      }
    } catch (error: any) {
      console.log(error);

      showError(
        error?.data?.message || translate.errors.somethingWentWrong,
        Boolean(searchParam.get("app")) ||
        Boolean(getFromStorage(STORAGE_KEYS.application))
      );
      setIsLoading(false);
    }
  };

  // const [getDataById] = useLazyGetPresentationByIdQuery();
  // const getDataByIdList = async () => {
  //   setIsLoading(true);
  //   try {
  //     const response = await getDataById({ id }).unwrap();
  //     if (response.status == 200) {
  //       setCurrentPresentationData(response?.data);
  //       generateImagesFromPptJson(response?.data?.binary_data);
  //       topicName = response?.data?.text;
  //       binaryDataArray = response?.data?.binary_data;
  //       template_id = response?.data?.template_id;
  //       setCurrentSlideInfo({
  //         currentPage: 1,
  //         totalPages: response?.data?.binary_data?.length,
  //       });
  //       console.log(response);
  //     }
  //   } catch (error: any) {
  //     showError(error?.data?.message||error?.data?.message,Boolean(searchParam.get('app')));
  //     console.log(error);
  //     setIsLoading(false);
  //   }

  const [getDataById] = useLazyGetPresentationByIdQuery();
  const getDataByIdList = async () => {
    setIsLoading(true);
    try {
      const response = await getDataById({ id }).unwrap();
      if (response.status == 200) {
        setCurrentPresentationData(response?.data);
        const finalBinaryData = JSON.parse(
          await GetTextData(response?.data?.binary_data?.data?.[0]?.media_url)
        );
        generateImagesFromPptJson(finalBinaryData);
        topicName = response?.data?.text;
        binaryDataArray = finalBinaryData || response?.data?.binary_data;
        template_id = response?.data?.template_id;
        setCurrentSlideInfo({
          currentPage: 1,
          totalPages: finalBinaryData?.length,
        });
        console.log(response);
      }
    } catch (error: any) {
      showError(
        error?.data?.message || translate.errors.somethingWentWrong,
        Boolean(searchParam.get("app")) ||
        Boolean(getFromStorage(STORAGE_KEYS.application))
      );
      console.log(error);
      setIsLoading(false);
    }
    // setIsLoading(false);
  };

  useEffect(() => {
    if (searchParam?.get("app") || getFromStorage(STORAGE_KEYS.application)) {
      setDisplayBackButton(false);
      setToStorage(STORAGE_KEYS?.application, "1");
    }
    // else
    // {
    //   removeFromStorage(STORAGE_KEYS?.application);
    // }
    // if(!state)
    // navigate('/creatpresentations');
    // GetTopicData();
    // selectedIndex = 0;
    setSelectedIndex(0);
    getDataByIdList();
  }, []);
  return (
    <>
      <main>
        <Loader isLoad={isLoading} />
        <section className="prev_ed presEdit_preview">
          <div className="prev_ed_lt">
            <div className="pd_lt">
              <h3>{translate.presentation.presentation || "Presentation"}</h3>
            </div>
            <div className="sde_br_mn">
              <div className="sde_hdr initial">
                {
                  // displayBackButton ?
                  <div
                    onClick={() => {
                      if (displayBackButton) {
                        navigate("/presentations", { replace: true });
                      }
                      //@ts-ignore
                      else {
                        //@ts-ignore
                        window["ReactNativeWebView"] &&
                          //@ts-ignore
                          window["ReactNativeWebView"].postMessage("back");
                        console.log("kkkkkkkkkkkkk");
                      }
                    }}
                    style={{ cursor: "pointer", marginLeft: 0 }}
                  >
                    <ArrowBackIosNewIcon />
                  </div>
                  // : (
                  //   ""
                  // )
                }
              </div>
            </div>

            <div className="but_gp">
              <Button
                onClick={() =>
                  navigate(`/presentation-edit2/${id}`, {
                    state: selectedIndex,
                  })
                }
              >
                {translate.global.edit || "Edit"}
              </Button>
              <Button
                // onClick={() => navigate("/presentation-down")}
                onClick={() => {
                  handleDownloadPPT(
                    binaryDataArray,
                    presentationTemplate?.[template_id - 1]?.path
                  );
                }}
              >
                {translate.global.download || "Download"}
              </Button>
            </div>
            <div className="main-int_img">
              <figure>
                <img src={selectedSlide} alt={"Slide"} />
              </figure>
              {/* <PresentationSlider4 /> */}
            </div>
            <div className="but_gp">
              <Button onClick={GetTopicData}>
                {translate.global.regenerate || "Regenerate"}
              </Button>
              {
                //Arrow keys
              }
              <IconButton
                className="arrowBtn"
                onClick={() => {
                  if (selectedIndex > 0) {
                    setCurrentSlideInfo((prev) => ({
                      ...prev,
                      currentPage: selectedIndex,
                    }));
                    setSelectedSlide(slideList[selectedIndex - 1]);
                    setSelectedIndex(selectedIndex - 1);
                    // const anchor:any = document.querySelector('#scrollPptPage')
                    // anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
                  }
                }}
              >
                <ArrowBackIosNewIcon />
              </IconButton>
              <span className="slide_count">{`${currentSlideInfo?.currentPage}/${slideList.length}`}</span>
              <IconButton
                className="arrowBtn"
                onClick={() => {
                  if (
                    currentSlideInfo?.currentPage < currentSlideInfo?.totalPages
                  ) {
                    setCurrentSlideInfo((prev) => ({
                      ...prev,
                      currentPage: selectedIndex + 2,
                    }));
                    setSelectedSlide(slideList[selectedIndex + 1]);
                    setSelectedIndex(selectedIndex + 1);
                  }
                  // const anchor:any = document.querySelector('#scrollPptPage')
                  // anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
                }}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </div>
          </div>
          <div
            className="prev_ed_rt right_scroll"
            style={{
              marginTop: "20px",
              paddingBottom: "15px !important",
              minBlockSize: "-webkit-fill-available",
            }}
          >
            <ul style={{ paddingBlock: "20px" }}>
              {/* <li>
                <figure>
                  <img src="/static/images/AI1.jpg" alt="image" />
                </figure>
              </li> */}
              {slideList?.map((data: any, index: number) => {
                return (
                  <li
                    key={index}
                    id={
                      currentSlideInfo?.currentPage - 1 == index
                        ? "scrollPptPage"
                        : ""
                    }
                  >
                    <figure>
                      <img
                        onClick={() => {
                          setCurrentSlideInfo((prev) => ({
                            ...prev,
                            currentPage: index + 1,
                          }));
                          setSelectedSlide(data);
                          // selectedIndex = index;
                          setSelectedIndex(index);
                        }}
                        style={{
                          border:
                            currentSlideInfo?.currentPage - 1 == index
                              ? "5px solid #7548f1"
                              : "",
                        }}
                        src={data ? data : "/static/images/AI1.jpg"}
                        alt="image"
                      />
                    </figure>
                  </li>
                );
              })}
            </ul>
          </div>
        </section>
      </main>
    </>
  );
}
export default PresentationEdit;
