import Layout from "../../layout";
import "react-tabs/style/react-tabs.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Test from "../../components/test";
import Notification from "../../components/notification";
import React, { useEffect, useState } from "react";
import Header from "../../layout/header/Header";
import {
  useDeleteTestingMutation,
  useEditTestingMutation,
  useGetAllPastRecordsMutation,
} from "../../services/testingModule";
import { showError, showToast } from "../../constants/toast";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import Loader from "../../constants/Loader";
import Pagination from "../../components/pagination";
import moment from "moment";
import TestingResultModal from "../../components/testingResultModal";
import SubjectsModal from "../../components/subjectsModal";
import TestingAbilities from "./abilities";
import AchievementsTesting from "./achievments";
import { FolderListComponent } from "../../components/folderList";
import { DeleteModal } from "../../components/deleteModal";
import { RenameModal } from "../../components/renameModal";
import useTranslation from "../../hooks/Translation";

function TestingPage() {
  const translate = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);
  const [getHistory] = useGetAllPastRecordsMutation();
  const [history, setHistory] = useState<any>([]);
  const [openAch, setOpenAch] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const totalPages = Math.ceil(totalCount / 20);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [open2, setOpen2] = useState<boolean>(false);
  const [rescordId, setRecordId] = useState<any>();
  const [open, setOpen] = React.useState<any>(false);
  const [open1, setOpen1] = React.useState(false);
  const handleClose1 = () => setOpen1(false);
  const [sidebar, setSidebar] = useState(true);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAch = () => {
    setOpenAch(false);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const fetchHistory1 = async () => {
    setIsLoading(true);
    const body = {
      length: 20,
      start: page,
      search: "",
      sub_category: [1, 2],
    };
    try {
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const res = await getHistory(encryptedBody).unwrap();
      setIsLoading(false);
      if (res?.status === 200) {
        setHistory(res?.data?.response_object);
        setTotalCount(res?.data?.total_records);
      }
    } catch (error: any) {
      setIsLoading(false);
      showError(
        error?.data?.message ||
        translate.errors.somethingWentWrong ||
        "Something went wrong"
      );
    }
  };

  useEffect(() => {
    fetchHistory1();
    if (localStorage.getItem("isTestLoading") == "1") {
      localStorage.removeItem("isTestLoading");
    }
  }, [page]);
  const [renameValue, setRenameValue] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [deleteData] = useDeleteTestingMutation();
  const [editData] = useEditTestingMutation();
  const [selectedId, setSelectedId] = useState(0);
  const toggleDelete = (id: number) => {
    setOpenDelete(true);
    setSelectedId(id);
  };
  const handleDelete = async (e: any) => {
    e.stopPropagation();
    try {
      const response = await deleteData({ id: selectedId }).unwrap();
      if (response.status == 200) {
        showToast(
          translate.toastMessages.testDeletedSuccessfully ||
          "Test Deleted Successfully"
        );
        setOpenDelete(false);
      }
    } catch (error: any) {
      console.log(error);
      showError(
        error?.data?.message ||
        translate.errors.somethingWentWrong ||
        "Something went wrong"
      );
    } finally {
      await fetchHistory1();
    }
  };
  const toggleEdit = (id: number, text: any) => {
    setOpenEdit(true);
    setRenameValue(text);
    setSelectedId(id);
  };
  const handleEdit = async (e: any) => {
    e.stopPropagation();
    try {
      const response = await editData({
        id: selectedId,
        body: { file_name: renameValue },
      }).unwrap();
      if (response.status == 200) {
        showToast(
          translate.toastMessages.testNameUpdatedSuccessfully ||
          "Test name updated Successfully"
        );
        setOpenEdit(false);
        setRenameValue("");
      }
    } catch (error: any) {
      console.log(error);
      showError(
        error?.data?.message ||
        translate.errors.somethingWentWrong ||
        "Something went wrong"
      );
    } finally {
      await fetchHistory1();
    }
  };
  return (
    <div className="sdbr_rltv">
      <Loader isLoad={isLoading} />
      {sidebar ? <Layout.SidePanel /> : null}
      <Header setSidebar={setSidebar} />

      <div className="sde_br_mn test_mn">
        <div className="sde_mn_cnt">
          <Tabs
            selectedIndex={tabIndex}
            onSelect={(index) => setTabIndex(index)}
          >
            <TabList>
              <Tab>{translate.testing.myTests || "My Tests"}</Tab>
              <Tab>{translate.testing.Abilities || "Abilities"}</Tab>
              <Tab>{translate.testing.Achievements || "Achievements"}</Tab>
            </TabList>
            <TabPanel>
              <button className="btnn btn_border" onClick={() => setOpen(true)}>
                <i className="fa-regular fa-plus"></i>
                {translate.testing.createATestForYourself ||
                  "Create a Test For Yourself"}
              </button>
              <ul className="tst_lst">
                {history?.length ? (
                  history.map((item: any, index: any) => (
                    // <li
                    //   style={{ cursor: "pointer" }}
                    //   key={item?.id}
                    //   onClick={() => {
                    //     setRecordId(item?.id);
                    //     setOpen2(true);
                    //   }}
                    // >
                    //   <h3>{item?.sub_category}</h3>
                    //   <p className="mt_20">{moment(item?.created_at).format('DD/MM/YYYY')}</p>
                    // </li>
                    <FolderListComponent
                      index={item?.index}
                      dataCreatedAt={item?.sub_category}
                      dataName={item?.file_name}
                      newClick={() => {
                        setRecordId(item?.id);
                        setOpen2(true);
                      }}
                      isEdit={true}
                      key={item?.id}
                      handleDelete={(e: any) => {
                        e.stopPropagation();
                        toggleDelete(item?.id);
                      }}
                      handleEdit={(e: any) => {
                        e.stopPropagation();
                        toggleEdit(item?.id, item?.file_name || "");
                      }}
                    />
                  ))
                ) : (
                  <div
                    style={{
                      height: "85px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      marginTop: "15%",
                    }}
                  >
                    <h3 style={{ fontSize: "xxx-large", color: "darkgrey" }}>
                      {isLoading
                        ? ""
                        : translate.global.noRecordsFound || "No Records Found"}
                    </h3>
                  </div>
                )}
              </ul>
              <Pagination
                module={history}
                page={page}
                onPageChange={onPageChange}
                totalPages={totalPages}
              />
            </TabPanel>

            <TabPanel>
              <TestingAbilities />
            </TabPanel>

            <TabPanel>
              <AchievementsTesting />
            </TabPanel>
          </Tabs>
        </div>
      </div>
      <Test
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        testing={true}
      />
      <TestingResultModal
        open={open2}
        setOpen={setOpen2}
        handleClose={handleClose2}
        id={rescordId}
      />
      <Notification
        open={open1}
        setOpen={setOpen1}
        handleClose={handleClose1}
      />
      <SubjectsModal
        open={openAch}
        setOpen={setOpenAch}
        handleClose={handleCloseAch}
      />
      <DeleteModal
        handleDelete={handleDelete}
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        textData={translate.testing.test || "test"}
      />
      <RenameModal
        handleEdit={handleEdit}
        openEdit={openEdit}
        renameValue={renameValue}
        setOpenEdit={setOpenEdit}
        setRenameValue={setRenameValue}
        textData={translate.testing.test || "test"}
      />
    </div>
  );
}

export default TestingPage;
