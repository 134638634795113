import React, { useEffect, useRef, useState } from "react";
import Layout from "../../layout/index";
import "react-tabs/style/react-tabs.css";
import Test from "../../components/test";
import Notification from "../../components/notification";
import Header from "../../layout/header/Header";
import { Button } from "@mui/material";
import Download from "../../components/download";
import parser from "html-react-parser";
import {
  useDownloadResearchMutation,
  useLazyGetResearchByIdQuery,
} from "../../services/research";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Research } from "../../types/research";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import Loader from "../../constants/Loader";
import { showError, showToast } from "../../constants/toast";
import { handleDownload } from "../../utils/handleDownload";
import { getFromStorage } from "../../constants/storage";
import { STORAGE_KEYS } from "../../constants/storageKeys";
import { fileToBase64 } from "../../utils/fileToB64";
import TestAi from "../testAi";
import useTranslation from "../../hooks/Translation";
import { API_URL } from "../../constants/url";
let isBack = false;

const ResearchEdit = () => {
  const { id } = useParams();
  const [open, setOpen] = React.useState<any>(false);
  const translate = useTranslation();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const { state } = useLocation();
  const navigate = useNavigate();
  const [getDataById] = useLazyGetResearchByIdQuery();
  const [downloadData] = useDownloadResearchMutation();
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [sidebar, setSidebar] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [researchData, setResearchData] = useState<Research | any>("");
  //   {
  //   created_at: "",
  //   id: 0,
  //   media: "",
  //   result: "",
  //   topic: "",
  //   updated_at: "",
  // }
  // const getDataByIdList=async()=>{
  //   setIsLoading(true);
  //   try{const response=await getDataById({id}).unwrap();
  //   if(response.status==200)
  //     {
  //       setResearchData(response?.data);
  //       console.log(response);
  //     }}
  //     catch(error){
  //       console.log(error);
  //     }
  //   setIsLoading(false);

  // }

  const generateHTML = (htmlInput: string) => {
    let html =
      '<html lang="ar"><meta charset="UTF-8"><style> body{font-family:"Arial", sans-serif;}</style><body style="background-color: white;" dir="auto">';
    html +=
      '<pre style="white-space: pre-wrap; font-family: Arial, sans-serif; background-color: #fff; border: 0px">';
    html += htmlInput;
    html += "</pre>";
    html += "</body></html>";
    return html;
  };
  const [downloadResearch] = useDownloadResearchMutation();
  const downloadPdf = async (type: number) => {
    setIsLoading(true);
    try {
      let html = generateHTML(researchData);
      const body = {
        html_text: html || researchData,
        type: type,
        new: true,
      };
      console.log(body, "body for download ");

      if (html) {
        let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response = await downloadResearch({
          body: encryptedBody,
          id: id,
        }).unwrap();
        setIsLoading(false);
        if (response?.status === 200) {
          setOpen(false);
          if (type !== 3) {
            // window.open(response?.data || '');
            handleDownload(
              response?.data,
              response?.data?.split("/")?.[
              response?.data?.split("/")?.length - 1
              ]
            );
          } else {
            showToast(
              translate.toastMessages.pdfSharedOverEmail ||
              "Pdf shared over email"
            );
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  // useEffect(() => {
  //   if (id) {
  //     setResearchData((prevData: any) => {
  //       return { ...prevData, id: state?.id, result: state?.data };
  //     });

  //     //  getDataByIdList();
  //   }
  // }, [id]);
  const [summary, setSummary] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [messages, setMessages] = useState<any | String>("");
  const socketRef: any = useRef();
  const [ws, setWs] = useState<any>(null);
  const generateNewResearch = () => {
    // const ws=new WebSocket('https://d164-122-160-196-233.ngrok-free.app/file-summarization/');
    const ws = new WebSocket(
      `wss:${API_URL.replaceAll("https://", "")}detailed-research/`
    );
    socketRef.current = ws;
    ws.onmessage = (event) => {
      console.log("event.data :", event.data);
      const data = JSON.parse(event.data);
      if (data.signal == -1) {
        ws.close();
        showError(translate.errors.pleaseTryAgain || "Please try again");
        navigate(`/reserch-edit2/${id}`, { replace: true });
        return;
      }
      if (data.signal == 0) {
        ws.close();
        showToast(
          translate.toastMessages.generationSuccessfully ||
          "Generation successfully"
        );
        setDisableButton(false);

        return;

        //   if (!isBack) {
        //     if (data?.record_id)
        //       navigate(`/edit-file/${data?.record_id}`, {
        //         state: {
        //           summaryData: researchData,
        //         },
        //         replace: true,
        //       });
        //   }
        //   // window.location.replace(`/edit-file/${data?.record_id}`)
      }
      if (data.signal == 401) {
        ws.close();
        showError(translate.errors.pleaseLogin || "Please login");
        navigate("/");
        return;
      }
      if (data.signal == 400) {
        ws.close();
        showError(data?.data || data?.message);
        navigate(`/reserch-edit2/${id}`, { replace: true });
        // return;
      } else {
        setMessages(data.data);
        setSummary(data.data);
        setResearchData(data.data);
      }
      const teset = document.getElementById("mohit");
      if (teset) {
        setTimeout(() => {
          teset.scrollIntoView({ behavior: "smooth", block: "center" });
        }, 0);
      }

      console.log(event);
    };
    ws.onopen = async () => {
      // setIsBlink(true);
      setDisableButton(true);
      const PAYLOAD = {
        token: JSON?.parse(getFromStorage(STORAGE_KEYS.token) as string),
        html_text: state?.data ? state?.data : "",
        record_id: id,
      };

      console.log("PAYLOAD :", PAYLOAD);
      ws?.send(JSON.stringify(PAYLOAD));
      console.log("Connected to websocket successfully");
    };
    ws.onerror = (error) => {
      // setIsBlink(false);
      ws.close();
      setIsLoading(false);
      console.log("There is some error", error);
    };
    ws.onclose = () => {
      // setIsBlink(false);
      setIsLoading(false);
      console.log("Socket is closed");
    };
    setWs(ws);
  };
  useEffect(() => {
    if (state) {
      generateNewResearch();
    }
  }, []);
  useEffect(() => {
    isBack = false;
    return () => {
      isBack = true;
      console.log("Cleaning up: Closing WebSocket");
      const PAYLOAD = {
        signal: -1
      }

      if (socketRef?.current) {
        socketRef?.current.close(); // Close WebSocket on cleanup
      }

      ws?.send(JSON.stringify(PAYLOAD));
      if (ws) {
        ws.close();
      }
    };
  }, []);
  return researchData ? (
    <div className="sdbr_rltv rsrch_edt ">
      <Loader isLoad={isLoading} />
      {sidebar ? <Layout.SidePanel /> : null}
      <Header setSidebar={setSidebar} />

      <div className="sde_br_mn sde_br_mn_fixed adt_artcl ">
        <div className="sde_mn_cnt">
          {/* <Button className="h_46" onClick={()=>navigate('/reserch-edit2/'+id)}>Edit</Button> */}
          <div className="edt_artcl" dir="auto">
            {researchData &&
              parser(
                researchData
                // ?.replaceAll(`\n`, "<br>")
              )}
            {/* <p className="adt_mn">How to learn trigonomentery table easily?</p>
            <p className="adt_qsn"><small>1) lorem ipsum dolor set amet</small></p>
            <p className="adt_ansr"><small>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</small></p>
            <p className="adt_qsn"><small>i) lorem ipsum dolor set amet</small></p>
            <p className="adt_ansr"><small>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</small></p>
            <p className="adt_qsn"><small>ii) lorem ipsum dolor set amet</small></p>
            <p  className="adt_ansr"><small>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</small></p> */}
          </div>
          <div id="mohit" style={{ paddingTop: "40px" }}></div>

          <div className="prfl_btn fixed_bottom">
            {disableButton && <figure style={{ width: "48px" }}>
              <img
                src="/static/images/test-load.gif"
              />
            </figure>}
            <Button
              className="h_46"
              disabled={disableButton}
              onClick={() => setOpen(true)}
            >
              {translate.global.download || "Download"}
            </Button>
          </div>
        </div>
      </div>
      {/* <Test open={open} setOpen={setOpen} handleClose={handleClose} /> */}
      <Notification
        open={open1}
        setOpen={setOpen1}
        handleClose={handleClose1}
      />
      <Download
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        downloadPdf={downloadPdf}
        note={translate.research.researchPaper || "Research Paper"}
      />
    </div>
  ) : (
    <TestAi />
  );
};

export default ResearchEdit;
