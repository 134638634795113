import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { generateFileSummarization } from "../services/fileSummarisation";
import { showError, showWarning } from "../constants/toast";
import { Switch, Typography } from "@mui/material";
import "./testDrag.css";
import useTranslation from "../hooks/Translation";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function TestingModuleDrag(props: any) {
  const navigate = useNavigate();
  const translate = useTranslation();
  const [selectedFile, setSelectedFile] = React.useState<any>(null);
  const { pathname } = useLocation();
  const [repeatingQuestion, setRepeatingQuestion] = React.useState(false);
  console.log("repeatingQuestion :", repeatingQuestion);
  const imagesType = ["png", "jpeg", "jpg"];

  let type = 2;
  console.log(selectedFile, "selectedFile---->");

  const handleFileChange = (event: any) => {
    if (!event.length) {
      showWarning(
        translate.errors.selectValidFormat || `Please select a valid format.`
      );
      return;
    }
    const file = event.length === 1 ? event[0] : event;
    if (event.length == 1) {
      const extension =
        file?.name?.split(".")?.[file?.name?.split(".")?.length - 1];
      if (extension === "pdf") {
        navigate("/pdfPreview", {
          state: {
            type: props?.type,
            prevPage: pathname,
            nextPage: props?.nextPage,
            selectedFile: file,
            fileType: type,
            isGenerating: true,
            repeatingQuestion,
          },
        });
        type = 2;
      } else {
        type = 1;
        navigate(props?.nextPage || "/test-ai", {
          state: {
            type: props?.type,
            prevPage: pathname,
            nextPage: props?.nextPage,
            selectedFile: file,
            fileType: type,
            isGenerating: true,
            repeatingQuestion,
          },
        });
      }
    } else {
      let flag = 1;
      for (let key = 0; key < event.length; key++) {
        const extension =
          event[key]?.name?.split(".")?.[
            event[key]?.name?.split(".")?.length - 1
          ];
        if (!imagesType.includes(extension)) {
          flag = 0;
        }
      }
      if (flag == 0) {
        showError(
          translate.errors.onlyMultipleImages ||
            "You can only select multiple images"
        );
        return;
      }
      type = 1;
      navigate(props?.nextPage || "/test-ai", {
        state: {
          type: props?.type,
          prevPage: pathname,
          nextPage: props?.nextPage,
          selectedFile: file,
          fileType: type,
          isGenerating: true,
          repeatingQuestion,
        },
      });
    }

    setSelectedFile(file);

    // navigate("/test-ai", {
    //   state: {
    //     type: props?.type,
    //     prevPage: pathname,
    //     nextPage: props?.nextPage,
    //     selectedFile: file,
    //     fileType: type,
    //   },
    // });
    // navigate(props?.nextPage, {
    //   state: {
    //     type: props?.type,
    //     prevPage: pathname,
    //     nextPage: props?.nextPage,
    //     selectedFile: file,
    //     fileType: type,
    //   },
    // });
  };

  const dropFile = (e: any) => {
    console.log(e);
    handleFileChange(e);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "application/pdf": [".pdf"],
      "image/*": [".png", ".jpeg", ".jpg"],
    },
    onDrop: dropFile,
    multiple: true,
  });
  return (
    <>
      <React.Fragment>
        <BootstrapDialog
          className="modl_drg modl text_center"
          onClose={props.handleClose}
          aria-labelledby="customized-dialog-title"
          open={props.open}
        >
          <IconButton
            aria-label="close"
            onClick={props.handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers {...getRootProps()}>
            <div className="upld_img_modal">
              <figure>
                <img
                  width={"100px"}
                  height={"95px"}
                  src="/static/images/pdf-upload.png"
                  alt=""
                ></img>
              </figure>
              <p className="drg">
                {" "}
                {translate.global.dragAndDropImage}{" "}
                <span className="d_block">{translate.global.or}</span>
              </p>
              <Button className="btnn btn_primary">
                {translate.global.browsePDF}
              </Button>
              {
                <input
                  type="file"
                  {...getInputProps()}
                  // accept={selectedAccepted}
                  // onChange={handleFileChange}
                />
              }
            </div>
          </DialogContent>
          {props?.nextPage == "/quiz/" && (
            <div
              style={{
                position: "absolute",
                bottom: 8,
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <Typography style={{ paddingRight: "4px" }}>
                {translate.testing.repeatingQuestions}
              </Typography>
              <input
                type="checkbox"
                id="checkboxInput"
                checked={repeatingQuestion}
                onClick={(e) => {
                  setRepeatingQuestion(!repeatingQuestion);
                  e.stopPropagation();
                }}
              />
              <label htmlFor="checkboxInput" className="toggleSwitch"></label>
              {/* <Switch
                value={repeatingQuestion}
                onClick={(e) => {
                  setRepeatingQuestion(!repeatingQuestion);
                  e.stopPropagation();
                }}
              /> */}
            </div>
          )}
          <DialogActions></DialogActions>
        </BootstrapDialog>
      </React.Fragment>
    </>
  );
}
export default TestingModuleDrag;
