import React, { useEffect, useLayoutEffect, useState } from "react";
import Layout from "../../layout";
import PresentationSlider from "./presentationSlider";
import PresentationSlider1 from "./presentationSlider1";
import PresentationSlider2 from "./presentationSlider2";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import Header from "../../layout/header/Header";
import { presentationTemplate } from "../../constants/presentationTemplate";
import { replace } from "formik";
import {
  useAddPresentationMutation,
  useDetailedPresentationMutation,
} from "../../services/presentation";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { showError } from "../../constants/toast";
import Loader from "../../constants/Loader";
import * as fabric from "fabric";
import { getFromStorage } from "../../constants/storage";
import { STORAGE_KEYS } from "../../constants/storageKeys";
import { UploadTextData } from "../../utils/mediaUpload";
import { compressData } from "../../utils/decompressData";
import useTranslation from "../../hooks/Translation";
const CANVAS_WIDTH = 1152;
const CANVAS_HEIGHT = 648;
const SLIDE_COUNT = 6;
let template_id = 1;
function PresentationPreview() {
  const navigate = useNavigate();
  const translate = useTranslation();
  const { state } = useLocation();
  console.log("state :", state);
  const user = useAuth();
  const [selectedTemplate, setSelectedTemplate] = useState(
    presentationTemplate?.[0]?.path
  );
  const [isLoading, setIsLoading] = useState(false);
  const [slideJSONList, setSlideJSONList] = useState<any>([]);
  const [getContent] = useDetailedPresentationMutation();
  const [addPPT] = useAddPresentationMutation();
  console.log("slideJSONList :", slideJSONList);
  const GetTopicData = async () => {
    setIsLoading(true);
    try {
      const body = {
        topic: state?.topic || "",
        slides: SLIDE_COUNT || 1,
      };
      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await getContent(encryptedBody).unwrap();
      if (response?.status == 200) {
        console.log(response);
        // pptContent=response?.data;
        generateInitialJSON(response?.data);
      }
    } catch (error: any) {
      console.log(error);

      showError(
        error?.data?.message || translate.errors.somethingWentWrong,
        Boolean(getFromStorage(STORAGE_KEYS.application))
      );
    }
    setIsLoading(false);
  };
  const singleLineLimitAdder = (stringData: any, wordLimitPerLine: number) => {
    stringData = stringData?.trim()?.replaceAll("\n", " ");
    let count = 0;
    let tempString = "";
    for (let key of stringData.split(" ")) {
      if (count < wordLimitPerLine) tempString += key + " ";
      else {
        tempString += key + "\n";
        count = 0;
      }
      count++;
    }
    return tempString;
  };

  const generateInitialJSON = async (contentData: any) => {
    const canvasElement: any = document.createElement("canvas");
    const canvasInstance = new fabric.Canvas(canvasElement, {
      width: CANVAS_WIDTH,
      height: CANVAS_HEIGHT,
      backgroundColor: "#FFFFFF",
    });

    const slideList: string[] = [];

    for (const data of contentData) {
      const img = new Image();
      img.src = selectedTemplate;
      await new Promise((resolve) => {
        img.onload = async () => {
          const image = new fabric.Image(img, {
            width: CANVAS_WIDTH,
            height: CANVAS_HEIGHT,
            selectable: false, // make sure the background image is not selectable
          });
          canvasInstance.backgroundImage = image; // set the background image
          const headingText = new fabric.IText(
            singleLineLimitAdder(data.heading, 12),
            {
              left: CANVAS_WIDTH * 0.2,
              top: 100,
              fontSize: 28,
              fontFamily: "Helvetica",
              fill: "#000000",
              lockScalingX: true,
              lockScalingY: true,
              hasControls: false,
            }
          );
          const contentText = new fabric.IText(
            singleLineLimitAdder(data.content, 12),
            {
              left: CANVAS_WIDTH * 0.25,
              top: 150,
              fontSize: 18,
              fontFamily: "Helvetica",
              fill: "#000000",
              lockScalingX: true,
              lockScalingY: true,
              hasControls: false,
            }
          );
          canvasInstance.add(headingText);
          canvasInstance.add(contentText);
          canvasInstance.renderAll(); // render the canvas
          // slideList.push(JSON.stringify(await canvasInstance.toJSON()));
          slideList.push(
            await compressData(JSON.stringify(await canvasInstance.toJSON()))
          );
          canvasInstance.clear();
          resolve("done");
        };
      });
    }
    setSlideJSONList(slideList);
    const binaryAWS = await UploadTextData(JSON.stringify(slideList));
    addNewPPT(binaryAWS);
    console.log("slideList :", slideList);
  };

  const addNewPPT = async (slideList: any) => {
    try {
      const body = {
        topic: state?.topic || "",
        slides: SLIDE_COUNT,
        binary_data: slideList || slideJSONList,
        template_id: template_id,
        file_name: state?.topic
      };
      console.log("ADD NEW PPT BODY", body);
      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await addPPT(encryptedBody).unwrap();
      if (response?.status == 200) {
        navigate(`/presentation-edit/${response?.data?.record_id}`, {
          replace: true,
        });
        template_id = 1;
        console.log(response);
      }
    } catch (error: any) {
      console.log(error);
      showError(
        error?.data?.message,
        Boolean(getFromStorage(STORAGE_KEYS.application))
      );
    }
  };
  useEffect(() => {
    if (!state) navigate("/creatpresentations");
    // GetTopicData();
    // console.log("APPLICTION FROM STORAGE",getFromStorage(STORAGE_KEYS.application));
    return () => {
      template_id = 1;
    };
  }, []);
  useLayoutEffect(() => {
    template_id = 1;
  }, []);
  return (
    <>
      {getFromStorage(STORAGE_KEYS.application) ? "" : <Layout.SidePanel />}
      {getFromStorage(STORAGE_KEYS.application) ? "" : <Header />}
      <Loader isLoad={isLoading} />
      <main className="prst_prv sdbr_rltv presTemplate_view">
        {/* <div className="sde_hdr">
            <div className="sde_hdr_lt">
              <button
                className="back_btn"
                onClick={() => navigate("/creatpresentations")}
              >
                <ArrowBackIosNewIcon />
              </button>
              <figure>
                <img src="/static/images/presentations.png" alt=""></img>
              </figure>
              <div className="">
                <h3>Presentations</h3>
                <p> Lorem Ipsum dolor et amet</p>
              </div>
            </div>
            <div className="sde_hdr_rt">
              <figure>
                <img src="/static/images/notification.png" alt=""></img>
              </figure>
              <h3>Hi,  {user?.first_name ? user?.first_name : "there"}</h3>
              <figure >
                <img src="/static/images/ahmed.png" alt=""></img>
              </figure>
            </div>
          </div> */}
        <div className="sde_br_mn">
          <section className="present_prv_sec">
            <div className="present_prv">
              <div className="present_prv_lt">
                {getFromStorage(STORAGE_KEYS.application) && (
                  <div
                    onClick={() => {
                      navigate("/creatpresentations", { replace: true });
                    }}
                    style={{ cursor: "pointer", marginLeft: 0 }}
                  >
                    <ArrowBackIosNewIcon />
                  </div>
                )}
                <h3>
                  {translate.presentation.selectTemplates || "Select Templates"}
                </h3>
                {/* <PresentationSlider /> */}
                <figure>
                  <img
                    src={selectedTemplate}
                    style={{ border: "5px solid #008000" }}
                    alt="img"
                  />
                </figure>
              </div>
              <div className="present_prv_rt">
                <ul>
                  {presentationTemplate?.map((data: any, index: number) => {
                    return (
                      <li key={data?.id}>
                        {index + 1}){" " + data?.name}
                        <figure
                          onClick={() => {
                            template_id = data?.id;
                            setSelectedTemplate(data?.path);
                          }}
                        >
                          <img
                            style={{
                              border:
                                template_id - 1 == index
                                  ? "5px solid #7548f1"
                                  : "",
                            }}
                            src={data?.path}
                            alt={`template-${index + 1}`}
                          />
                        </figure>
                      </li>
                    );
                  })}
                </ul>
                <div className="but_bt">
                  <Button
                    onClick={() => {
                      GetTopicData();
                    }}
                  >
                    {translate.global.next || "Next"}
                  </Button>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
}

export default PresentationPreview;
