import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import {
  useGetALlSubjectsApiMutation,
  useLazyGetAbilitiesQuery,
  useLazyGetAchivementsQuery,
} from "../services/testingModule";
import { showError } from "../constants/toast";
import { generateEncryptedKeyBody } from "../utils/crypto";
import { CommonBody } from "../types/General";
import Achievements from "./achivements";
import useTranslation from "../hooks/Translation";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function SubjectsModal(props: any) {
  const navigate = useNavigate();
  const translate = useTranslation();
  const [getSubjects] = useGetALlSubjectsApiMutation();
  const [subjects, setSubjects] = React.useState<any>([]);
  const [open, setOpen] = React.useState<boolean>(false);
  const [subjectId, setSubjectId] = React.useState<number>();
  const handleClose = () => {
    setOpen(false);
  };
  const fetchResponse = async () => {
    const body = {
      search: "",
      start: 1,
      length: 50,
    };
    console.log(body, "subjects");

    try {
      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await getSubjects(encryptedBody).unwrap();
      if (response?.status === 200) {
        console.log(response, "response of get subjects");
        setSubjects(response?.data?.response_object);
      }
    } catch (error: any) {
      showError(error?.data?.message || error?.data?.message || translate.errors.somethingWentWrong);
    }
  };
  console.log(subjects, "subjects");

  React.useEffect(() => {
    fetchResponse();
  }, []);

  return (
    <>
      <React.Fragment>
        <BootstrapDialog
          className="modl"
          onClose={props.handleClose}
          aria-labelledby="customized-dialog-title"
          open={props.open}
        >
          <IconButton
            aria-label="close"
            onClick={props.handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <p style={{ marginBottom: "20px", color: "#d0dcff" }}>
              {translate.testing.selectSubject}
            </p>
            <ul
              className="prgrs_list"
              style={{ listStyleType: "circle", paddingLeft: "15px" }}
            >
              {subjects?.length
                ? subjects.map((subject: any, index: number) => (
                  <li
                    onClick={() => {
                      setSubjectId(subject?.id);
                      props.handleClose();
                      setOpen(true);
                    }}
                    className="hoverOnLi"
                    key={index}
                  >
                    <h6 style={{ padding: "6px", cursor: "pointer" }}>
                      {" "}
                      <i>{subject?.subject_name || "-"}</i>
                    </h6>
                  </li>
                ))
                : ""}
            </ul>
          </DialogContent>
        </BootstrapDialog>
      </React.Fragment>
      <Achievements
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        id={subjectId}
      />
    </>
  );
}
export default SubjectsModal;
