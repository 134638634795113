import React, { useState } from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import "react-tabs/style/react-tabs.css";
import { usePostForgotPassMutation } from "../../services/auth";
import * as Yup from "yup";
import { useFormik } from "formik";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { showError, showToast } from "../../constants/toast";
import Loader from "../../constants/Loader";
import useTranslation from "../../hooks/Translation";

const Forgot = () => {
  const translate = useTranslation();
  const navigate = useNavigate();
  const [ForgotPasswordMutation] = usePostForgotPassMutation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required(translate.validations.requiredEmail || "Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          translate.validations.enterValidEmail || "Enter a valid email address"
        ),
    }),

    onSubmit: async () => {
      formik.setSubmitting(true);
      let data = {
        email: formik.values.email,
        // role: 2,
      };
      setIsLoading(true);
      try {
        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
        const response = await ForgotPasswordMutation(encryptedBody).unwrap();
        if (response?.status === 200) {
          navigate("/verify", {
            state: { mode: "forgot", email: formik.values.email },
          });
          showToast(response?.message);
        } else if (response?.status === 401) {
          showError(translate.errors.userNotExist || "User does not exist");
          navigate("/");
        }
        setIsLoading(false);
      } catch (error: any) {
        showError(
          error?.data?.message || translate.errors.somethingWentWrong || ""
        );
        setIsLoading(false);
        console.log(error);
      }
    },
  });

  return (
    <div>
      <Loader isLoad={isLoading} />
      <section className="bnr_sec c_white sign_in">
        <div className="conta_iner">
          <div className="bnr_mn">
            <div className="bnr_lt">
              <figure className="logo">
                <img
                  src="/static/images/logo.svg"
                  alt=""
                  onClick={() => navigate("/")}
                ></img>
              </figure>
              <h1>
                {translate.home.let}
                <span className="ai_bdr">
                  {translate.home.ai} <span></span>
                </span>{" "}
                <span className="d_block">{translate.home.doYourWork}</span>
              </h1>
              <p>
                {translate.auth.forgetPasswordDetailList1}
                <span className="d_block">
                  {translate.auth.forgetPasswordDetailList2}
                </span>{" "}
                {translate.auth.forgetPasswordDetailList3}
              </p>
            </div>
            <div className="logn_rt hd_3">
              <h3> {translate.auth.forgotPassword}</h3>
              <form className="frmn " onSubmit={formik.handleSubmit}>
                <div className="frmn_mn">
                  <label> {translate.auth.emailId}</label>
                  <TextField
                    className="control_group"
                    placeholder={
                      translate.auth.enterYourEmail || "Enter your email"
                    }
                    hiddenLabel
                    fullWidth
                    variant="outlined"
                    name="email"
                    type="email"
                    id="email"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    helperText={formik.touched.email && formik.errors.email}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                  />
                </div>
                <Button type="submit" className="btn btn_primay btnn lrg">
                  {translate.global.next || "Next"}
                </Button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Forgot;
