export const noteTemplate = [
  {
    id: 0,
    path: "/static/images/note/0.jpg",
    name: "Default",
  },
  {
    id: 1,
    path: "/static/images/note/1.jpg",
    name: "Tree",
  },
  {
    id: 2,
    path: "/static/images/note/2.jpg",
    name: "Ladder",
  },
  {
    id: 3,
    path: "/static/images/note/3.jpg",
    name: "Multi to single ",
  },
  {
    id: 4,
    path: "/static/images/note/4.jpg",
    name: "Basic",
  },
  {
    id: 5,
    path: "/static/images/note/5.png",
    name: "High to Low",
  },
];
