/* eslint-disable import/no-anonymous-default-export */
import AboutSection1 from "./aboutpage/aboutSection1";
import AboutSection2 from "./aboutpage/aboutSection2";
import AboutSection3 from "./aboutpage/aboutSection3";
import AboutSection4 from "./aboutpage/aboutSection4";
import AboutSection5 from "./aboutpage/aboutSection5";
import HomeSection1 from "./homepage/homeSection1";
import HomeSection2 from "./homepage/homeSection2";
import HomeSection3 from "./homepage/homeSection3";
import HomeSection4 from "./homepage/homeSection4";
import HomeSection5 from "./homepage/homeSection5";
import ContactSection1 from "./contactpage/contactsection1";
import ContactSection2 from "./contactpage/contactSection2";
import SubscriptionSection1 from "./subscriptionpage/subscriptionSection1";
import SubscriptionSection2 from "./subscriptionpage/subscriptionSection2";
import SubscriptionSection3 from "./subscriptionpage/subscriptionSection3";
import SubscriptionPlanSection1 from "./subscriptionplanpage/subscriptionplansection1";
import SubscriptionPlanSection2 from "./subscriptionplanpage/subscriptionplansection2";



export default {
    AboutSection1,
    AboutSection2,
    AboutSection3,
    AboutSection4,
    AboutSection5,
    HomeSection1,
    HomeSection2,
    HomeSection3,
    HomeSection4,
    HomeSection5,
    ContactSection1,
    ContactSection2,
    SubscriptionSection1,
    SubscriptionSection2,
    SubscriptionSection3,
    SubscriptionPlanSection1,
    SubscriptionPlanSection2
}