import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import useTranslation from "../hooks/Translation";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

type props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleClose: () => void;
};

function Notification({ open, setOpen, handleClose }: props) {
  const translate = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <React.Fragment>
        <BootstrapDialog
          className="nt_pop_mn "
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <div className="nt_pop hd_6">
              <button className="btnn btn_primary sm s_al">
                {translate.global.seeAll}
              </button>
              <h3>{translate.global.notifications} </h3>
              <p>{translate.global.notificationDescription}</p>
              <ul className="nt_pop_lst">
                <li>
                  <figure>
                    <img src="/static/images/presentations.png" alt=""></img>
                  </figure>
                  <div>
                    <p>
                      <small>
                        Learn by relating answers with daily life examples
                      </small>
                    </p>
                    <p>March 21, 2024</p>
                  </div>
                  <i className="fa-solid fa-xmark"></i>
                </li>
                <li>
                  <figure>
                    <img src="/static/images/presentations.png" alt=""></img>
                  </figure>
                  <div>
                    <p>
                      <small>
                        Learn by relating answers with daily life examples
                      </small>
                    </p>
                    <p>March 21, 2024</p>
                  </div>
                  <i className="fa-solid fa-xmark"></i>
                </li>
                <li>
                  <figure>
                    <img src="/static/images/presentations.png" alt=""></img>
                  </figure>
                  <div>
                    <p>
                      <small>
                        Learn by relating answers with daily life examples
                      </small>
                    </p>
                    <p>March 21, 2024</p>
                  </div>
                  <i className="fa-solid fa-xmark"></i>
                </li>
                <li>
                  <figure>
                    <img src="/static/images/presentations.png" alt=""></img>
                  </figure>
                  <div>
                    <p>
                      <small>
                        Learn by relating answers with daily life examples
                      </small>
                    </p>
                    <p>March 21, 2024</p>
                  </div>
                  <i className="fa-solid fa-xmark"></i>
                </li>
                <li>
                  <figure>
                    <img src="/static/images/presentations.png" alt=""></img>
                  </figure>
                  <div>
                    <p>
                      <small>
                        Learn by relating answers with daily life examples
                      </small>
                    </p>
                    <p>March 21, 2024</p>
                  </div>
                  <i className="fa-solid fa-xmark"></i>
                </li>
                <li>
                  <figure>
                    <img src="/static/images/presentations.png" alt=""></img>
                  </figure>
                  <div>
                    <p>
                      <small>
                        Learn by relating answers with daily life examples
                      </small>
                    </p>
                    <p>March 21, 2024</p>
                  </div>
                  <i className="fa-solid fa-xmark"></i>
                </li>
              </ul>
            </div>
          </DialogContent>
          <DialogActions></DialogActions>
        </BootstrapDialog>
      </React.Fragment>
    </>
  );
}
export default Notification;
