// import { useLocation, useNavigate, useParams } from "react-router-dom";
// import Layout from "../../layout/index";
// import { Box, Button, Modal, Typography } from "@mui/material";
// import Download2 from "../../components/download2";
// import React, { useEffect, useLayoutEffect, useState } from "react";
// import Header from "../../layout/header/Header";
// import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
// import useAuth from "../../hooks/useAuth";
// import HTMLReactParser from "html-react-parser";
// import {
//   useChangeLanguageFileSummarizationMutation,
//   useDownloadFileSummarizationMutation,
//   useLazyGetFileSummarizationByIdQuery,
// } from "../../services/fileSummarisation";
// import { showError, showToast, showWarning } from "../../constants/toast";
// import { CommonBody } from "../../types/General";
// import { generateEncryptedKeyBody } from "../../utils/crypto";
// import AiWork from "../../components/aiwork";
// import { Close, FamilyRestroomOutlined } from "@mui/icons-material";
// import {
//   useChangeLanguageMutation,
//   useGenerateAiExplanationMutation,
//   useIdentifyVocabMutation,
// } from "../../services/utils";
// import Loader from "../../constants/Loader";
// import { handleDownload } from "../../utils/handleDownload";
// import { fileToBase64 } from "../../utils/fileToB64";
// import TestAi from "../testAi";
// import { getFromStorage } from "../../constants/storage";
// import { STORAGE_KEYS } from "../../constants/storageKeys";
// import { isatty } from "tty";
// let isBack = false;
// let isLanguageChanged = false;
// let originalSummary = "";
// let translatedSummary = "";
// let summaryState = "";
// let interval: any = null;
// const summaryData: any = [];
// const EditFile = () => {
//   const navigate = useNavigate();
//   const [isStreaming, setIsStreaming] = useState(false);
//   const [isBlink, setIsBlink] = useState(false);
//   const { id } = useParams();
//   console.log("id :", id);
//   const user = useAuth();
//   const { state } = useLocation();
//   console.log("state  ", state);
//   const [getDataById] = useLazyGetFileSummarizationByIdQuery();
//   const [downloadPdfFile] = useDownloadFileSummarizationMutation();
//   const [aiExplain] = useGenerateAiExplanationMutation();
//   const [changeLanguage] = useChangeLanguageFileSummarizationMutation();
//   const [identifyVocab] = useIdentifyVocabMutation();
//   const [aiDetail, setAiDetail] = useState("");
//   const goProfile = () => {
//     navigate("/setting");
//   };
//   const [open, setOpen] = React.useState<any>(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const handleClickOpen = () => {
//     setOpen(true);
//   };
//   const handleClose = () => {
//     setOpen(false);
//   };
//   const [openAI, setOpenAI] = React.useState<any>(false);

//   const handleClickOpenAI = () => {
//     setOpenAI(true);
//   };
//   const handleCloseAI = () => {
//     setOpenAI(false);
//   };
//   const [sidebar, setSidebar] = useState(true);
//   const [summary, setSummary] = useState("");
//   const [summary2, setSummary2] = useState<any>("");
//   const [dataCompleted, setDataCompleted] = useState(false);
//   console.log("summary2 :", summary2);
//   useEffect(() => {
//     translatedSummary = "";
//     interval = isStreaming
//       ? setInterval(() => {
//           console.log("MMM", summaryData);

//           if (summaryData?.length)
//             setSummary2((prev: any) => prev + " " + summaryData.shift());
//           const teset = document.getElementById("mohit");
//           if (teset) {
//             setTimeout(() => {
//               teset.scrollIntoView({ behavior: "smooth", block: "center" });
//             }, 0);
//           }
//         }, 100)
//       : null;

//     if (!isStreaming) if (interval) clearInterval(interval);
//     return () => {
//       if (interval) clearInterval(interval);
//       translatedSummary = "";
//       summaryData.length = 0;
//     };
//   }, [isStreaming]);
//   console.log("isStreaming :", isStreaming);
//   useEffect(() => {
//     console.log("summary.length :", summary.length);
//     console.log("summary2.length  :", summary2.length);
//     if (dataCompleted && summaryData.length == 0) {
//       setDisableButton(false);
//       setIsStreaming(false);
//       showToast("Generation successfully");
//       // if (interval) clearInterval(interval);
//     }
//   }, [summary2]);
//   useEffect(() => {
//     if (summary) {
//       summaryData.push(...summary.replaceAll(translatedSummary, "").split(" "));
//     }
//     translatedSummary = summary;
//   }, [summary]);
//   const [messages, setMessages] = useState<any | String>("");
//   const [ws, setWs] = useState<any>(null);
//   const createNewSummarization = (isChangeLanguage?: any) => {
//     // const ws=new WebSocket('https://d164-122-160-196-233.ngrok-free.app/file-summarization/');
//     // if (isChangeLanguage && originalSummary) {
//     //   setSummary(originalSummary)
//     // }
//     if (isChangeLanguage) {
//       setDisableButton(true);
//       setIsLoading(true);
//     }
//     const ws = new WebSocket(
//       "wss://whizzodevapi.appgrowthcompany.com/file-summarization/"
//     );

//     ws.onmessage = (event) => {
//       console.log("event.data :", event.data);
//       const data = JSON.parse(event.data);
//       if (data.signal == -1) {
//         ws.close();
//         showError("Please try again");
//         navigate("/file-summarization", { replace: true });
//       }
//       if (data.signal == 0) {
//         setDataCompleted(true);
//         ws.close();
//         // showToast("Generation successfully");
//         if (!isBack) {
//           if (data?.record_id)
//             navigate(`/edit-file/${data?.record_id}`, {
//               state: {
//                 summaryData: summaryState,
//               },
//               replace: true,
//             });
//         }
//         // window.location.replace(`/edit-file/${data?.record_id}`)
//       }
//       if (data.signal == 401) {
//         ws.close();
//         showError("Please login");
//         navigate("/");
//         // return;
//       }
//       if (data.signal == 400) {
//         ws.close();
//         showError(data?.data||data?.message);
//         navigate("/file-summarization", { replace: true });
//         // return;
//       }
//       if (data.signal == 1) {
//         if (!isStreaming) {
//           setIsStreaming(true);
//           // summaryData.length = 0;
//         }
//         if (isChangeLanguage) setIsLoading(false);

//         setMessages(data.data);
//         setSummary(data.data);
//         summaryState = data.data;
//       }
//       const teset = document.getElementById("mohit");
//       if (teset) {
//         setTimeout(() => {
//           teset.scrollIntoView({ behavior: "smooth", block: "center" });
//         }, 0);
//       }

//       console.log(event);
//     };
//     ws.onopen = async () => {
//       setIsStreaming(true);
//       setDataCompleted(false);
//       setSummary2("");
//       setDisableButton(true);
//       setIsBlink(true);
//       const PAYLOAD: any = {
//         token: JSON?.parse(getFromStorage(STORAGE_KEYS.token) as string),
//       };
//       if (isChangeLanguage) {
//         // setDisableButton(true);
//         // setIsLoading(true);
//         PAYLOAD["text"] = summary;
//         PAYLOAD["change_language"] = true;
//       } else {
//         PAYLOAD["type"] = 1;
//         PAYLOAD["binary_data"] = await fileToBase64(state?.selectedFile);
//         PAYLOAD["file_name"] = Array.isArray(state?.selectedFile)
//           ? `${Date.now()}`
//           : state?.fileName;
//       }
//       console.log("PAYLOAD :", PAYLOAD);
//       ws?.send(JSON.stringify(PAYLOAD));
//       console.log("Connected to websocket successfully");
//     };
//     ws.onerror = (error) => {
//       setIsBlink(false);
//       setIsLoading(false);
//       ws.close();
//       console.log("There is some error", error);
//     };
//     ws.onclose = () => {
//       setIsBlink(false);
//       setIsLoading(false);
//       console.log("Socket is closed");
//     };
//     setWs(ws);
//   };
//   useEffect(() => {
//     if (id) {
//       if (state?.summaryData) {
//         setSummary(state?.summaryData);
//         if (!isStreaming) setSummary2(state?.summaryData);
//       } else {
//         getDataByIdList();
//       }
//     } else {
//       createNewSummarization();
//     }
//     // return () => {
//     //   console.log('Cleaning up: Closing WebSocket');
//     //   // ws?.send(-1);
//     //   originalSummary=""
//     //   isBack=true;
//     //   if(ws) {
//     //     ws.close();
//     //   }
//     // }
//   }, [id]);
//   useEffect(() => {
//     isBack = false;
//     return () => {
//       isBack = true;
//       console.log("Cleaning up: Closing WebSocket");
//       originalSummary = "";

//       if (ws) {
//         ws.close();
//       }
//     };
//   }, []);
//   // useEffect(()=>{
//   // window.scrollTo(0,0)
//   // },[summary])

//   const getDataByIdList = async () => {
//     setIsLoading(true);
//     try {
//       const response = await getDataById({ id }).unwrap();
//       if (response.status == 200) {
//         originalSummary = response?.data?.result;
//         setSummary(response?.data?.result);
//         setSummary2(response?.data?.result);
//         console.log(response);
//       }
//     } catch (error) {
//       console.log(error);
//     }
//     setIsLoading(false);
//   };
//   const generateHTMLcontent = (text: string) => {
//     return text.replaceAll("\n", "<br>");
//   };

//   const getAiDetail = async () => {
//     if (!document.getSelection()?.toString()) {
//       showError("Please select something to get detail");
//       return;
//     }
//     setIsLoading(true);
//     try {
//       const body = {
//         text: document.getSelection()?.toString() || summary,
//       };
//       const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
//       const response = await aiExplain(encryptedBody).unwrap();
//       if (response.status == 200) {
//         setAiDetail(response?.data);
//         setOpenAI(true);
//         console.log(response);
//       }
//     } catch (error) {
//       console.log(error);
//     }
//     setIsLoading(false);
//   };
//   const [disableButton, setDisableButton] = useState(false);
//   const getChangeLanguage = async (isPdf: boolean) => {
//     setIsLoading(true);
//     try {
//       if (!isPdf)
//         originalSummary = summary2.replaceAll(
//           `style='background-color:rgb(185 154 36)'`,
//           ""
//         );
//       const body = {
//         text: summary2.replaceAll(
//           `style='background-color:rgb(185 154 36)'`,
//           ""
//         ),
//       };
//       const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
//       const response = await changeLanguage(encryptedBody).unwrap();
//       if (response.status == 200) {
//         if (isPdf) {
//           // if(isLanguageChanged)
//           // downloadPdfData(originalSummary,2)
//           // else
//           downloadPdfData(response?.data, 9);
//         } else setSummary(response?.data);
//         console.log(response);
//       }
//     } catch (error) {
//       console.log(error);
//     }
//     setIsLoading(false);
//   };

//   const getVocab = async (isPdf: boolean) => {
//     setIsLoading(true);
//     try {
//       const body = {
//         text_data: summary2,
//       };
//       const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
//       const response = await identifyVocab(encryptedBody).unwrap();
//       if (response.status == 200) {
//         let temp = summary2;
//         response?.data?.map((element: any) => {
//           temp = temp?.replaceAll(
//             element + " ",
//             `&nbsp<span style='background-color:rgb(185 154 36)'>${element + " "}</span>`
//           );
//         });
//         console.log(temp);
//         if (isPdf) downloadPdfData(temp, 3);
//         else setSummary2(temp);
//         console.log(response);
//       }
//     } catch (error) {
//       console.log(error);
//     }
//     setIsLoading(false);
//   };

//   const downloadPdfData = async (data: any, type: number) => {
//     setIsLoading(true);
//     try {
//       let html = "";

//       if (type == 9) {
//         html =
//           '<html lang="ar"><meta charset="UTF-8"><style> body{font-family:"Arial", sans-serif;}</style></head><body style="background-color: white;" dir="auto">';
//         html +=
//           '<pre style="white-space: pre-wrap; font-family: Arial, sans-serif; background-color: #fff; border: 0px">';
//         html += generateHTMLcontent(data);
//         html += "</pre>";
//         html += "</body></html>";
//       } else {
//         html =
//           '<html lang="ar"><meta charset="UTF-8"><body style="background-color: white;"  dir="auto">';
//         html +=
//           '<pre style="white-space: pre-wrap; font-family: Arial, sans-serif; background-color: #fff; border: 0px">';
//         html += generateHTMLcontent(data);
//         html += "</pre>";
//         html += "</body></html>";
//       }

//       const body = {
//         html_text: html,
//         type: type == 9 ? 2 : type,
//         new: true,
//       };
//       console.log(body, "body for download ");

//       if (html) {
//         // setLoading(true);
//         let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
//         const response = await downloadPdfFile({
//           body: encryptedBody,
//           id: id,
//         }).unwrap();
//         // setLoading(false);
//         if (response?.status === 200) {
//           setOpen(false);
//           handleDownload(
//             response?.data,
//             response?.data?.split("/")?.[response?.data?.split("/")?.length - 1]
//           );

//           // window.open(response?.data || '');
//         }
//       }
//     } catch (error) {
//       // setLoading(false);
//       console.log(error);
//     }
//     setIsLoading(false);
//   };
//   const generateHTML = (parts: string[], wordsToHighlight: string[]) => {
//     let html =
//       '<html lang="ar"><meta charset="UTF-8"><style> body{font-family:"Arial", sans-serif;}</style></head><body style="background-color: white;" dir="auto">';
//     // let html =isLanguageChanged?'<html lang="ar"><meta charset="UTF-8"><style> body{font-family:"Arial", sans-serif;}</style></head><body style="background-color: white;" dir="rtl">':'<html><body style="background-color: white;" dir="auto">';

//     html +=
//       '<pre style="white-space: pre-wrap; font-family: Arial, sans-serif; background-color: #fff; border: 0px">';
//     html += parts
//       ?.map((part, index) => {
//         const isHighlighted = wordsToHighlight
//           .map((word) => word?.toLowerCase())
//           .includes(part?.toLowerCase());

//         const style = isHighlighted
//           ? "font-size: 14px; color: black; background-color: yellow; letter-spacing: 1px;"
//           : "font-size: 14px; color: black; letter-spacing: 1px;";

//         return part?.length
//           ? `<span style="${style}" key="${index}">${part}</span>`
//           : "";
//       })
//       .join("");

//     html += "</pre>";
//     html += "</body></html>";
//     return html;
//   };
//   const downloadPdf = async (type: number) => {
//     if (type == 1) {
//       getChangeLanguage(true);
//     } else if (type == 2) {
//       if (isLanguageChanged) {
//         downloadPdfData(summary2, 9);
//       } else {
//         downloadPdfData(summary2, 2);
//       }
//     } else if (type == 3) {
//       setIsLoading(true);
//       let temp = "";
//       try {
//         const body = {
//           text_data: summary2,
//         };
//         const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
//         const response = await identifyVocab(encryptedBody).unwrap();
//         if (response.status == 200) {
//           const regex = new RegExp(`(${response?.data?.join("|")})`, "gi");
//           temp = generateHTML(summary?.split(regex), response?.data);
//         }
//       } catch (error) {
//         console.log(error);
//       }

//       try {
//         const body = {
//           html_text: temp,
//           type: 3,
//           new: true,
//         };
//         console.log(body, "body for download ");

//         if (temp) {
//           // setLoading(true);
//           let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
//           const response = await downloadPdfFile({
//             body: encryptedBody,
//             id: id,
//           }).unwrap();
//           // setLoading(false);
//           if (response?.status === 200) {
//             setOpen(false);
//             // window.open(response?.data || '');
//             handleDownload(
//               response?.data,
//               response?.data?.split("/")?.[
//                 response?.data?.split("/")?.length - 1
//               ]
//             );
//           }
//         }
//       } catch (error) {
//         // setLoading(false);
//         console.log(error);
//       }
//       setIsLoading(false);

//       // getVocab(true);
//     }
//   };
//   const style = {
//     position: "absolute" as "absolute",
//     top: "50%",
//     left: "50%",
//     transform: "translate(-50%, -50%)",
//     width: "100%",
//     maxWidth: 587,
//     bgcolor: "#353e3d",
//     border: "1px solid #FFFFFF8A",
//     boxShadow: 24,
//     borderRadius: "10px",
//     p: 3,
//     maxHeight: "95vh",
//     overflow: "auto",
//   };

//   return (
//     <>
//       <Loader isLoad={isLoading} />
//       {summary || id ? (
//         <div className="sdbr_rltv">
//           <Layout.SidePanel />
//           <Header />

//           <div className="sde_br_mn sde_br_mn_fixed">
//             {/* <div className='sde_hdr'>
//           <div className='sde_hdr_lt'>
//           <button
//                 className="back_btn"
//                 onClick={() => navigate("/file-summarization")}
//               >
//                 <ArrowBackIosNewIcon />
//                 </button>
//               <figure>
//                 <img src='/static/images/summarization.png' alt=''></img>
//               </figure>
//               <div className=''>
//                 <h3>File Summarization</h3>
//                 <p> Lorem Ipsum dolor et amet</p>
//               </div>
//           </div>
//           <div className='sde_hdr_rt'>
//             <figure>
//               <img src='/static/images/notification.png' alt=''></img>
//             </figure>
//             <h3>Hi, Ahmed</h3>
//             <figure onClick={goProfile}>
//               <img src='/static/images/ahmed.png' alt=''></img>
//             </figure>
//           </div>
//       </div> */}
//             <div className="sde_mn_cnt edt_fle">
//               {/* <Button className='btnn btn-primary h_46'>
//         Upload a new File
//         </Button> */}
//               <div className="edt_nte">
//                 <div>
//                   <div
//                     dir="auto"
//                     style={{ background: "transparent" }}
//                     className="list_enable"
//                   >
//                     {
//                       // !isStreaming ? (
//                       //   summary ? (
//                       //     <>
//                       //       {HTMLReactParser(summary)}
//                       //       {isBlink ? <span className="blinker">|</span> : null}
//                       //       {/* {isBlink?<span className="dots"></span>:null} */}
//                       //     </>
//                       //   ) : (
//                       //     <p>Loading...</p>
//                       //   )
//                       // ) : (
//                       summary2 && (
//                         <>
//                           {HTMLReactParser(
//                             summary2
//                               ?.replaceAll("< ", "<")
//                               ?.replaceAll(" <", "<")
//                               ?.replaceAll("> ", ">")
//                               ?.replaceAll(" >", ">")
//                           )}
//                           {isBlink ? <span className="blinker">|</span> : null}
//                         </>
//                       )
//                       // )
//                     }
//                   </div>
//                   <div id="mohit" style={{ paddingTop: "80px" }}></div>
//                 </div>

//                 <div className="prfl_btn fixed_bottom">
//                   <div className="prfl_btn_lft">
//                     <Button
//                       disabled={disableButton}
//                       className="btnn h_46"
//                       onClick={() => {
//                         window.scrollTo(0, 0);
//                         createNewSummarization(true);
//                         // if (!isLanguageChanged) {
//                         //   getChangeLanguage(false);
//                         //   isLanguageChanged = true;
//                         // } else {
//                         //   setSummary(originalSummary);
//                         //   // originalSummary="";
//                         //   isLanguageChanged = false;
//                         // }
//                       }}
//                     >
//                       Change Language
//                     </Button>
//                     <Button
//                       className="btnn h_46"
//                       disabled={disableButton}
//                       onClick={() => {
//                         // if(!isLanguageChanged)
//                         getVocab(false);
//                         // else
//                         // showWarning("Please change language to identify important vocabulary")
//                       }}
//                     >
//                       Identifying Important Vocabulary
//                     </Button>

//                     <Button
//                       disabled={disableButton}
//                       className="btnn h_46"
//                       onClick={() => {
//                         getAiDetail();
//                       }}
//                     >
//                       AI Explanation
//                     </Button>
//                   </div>

//                   <Button
//                     disabled={!id || disableButton}
//                     className="btnn h_46"
//                     onClick={() => setOpen(true)}
//                   >
//                     Download
//                   </Button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       ) : (
//         <TestAi />
//       )}
//       <Download2
//         open={open}
//         setOpen={setOpen}
//         handleClose={handleClose}
//         downloadPdf={downloadPdf}
//         label={"Summarization"}
//       />
//       <Modal
//         open={openAI}
//         onClose={handleCloseAI}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//         className="modall"
//       >
//         <Box
//           sx={style}
//           component={"div"}
//           className="text_center how_to_modal"
//           dir="auto"
//         >
//           <p className="close_icon" onClick={handleCloseAI}>
//             <Close />
//           </p>
//           <figure>
//             <img src="/static/images/bulb.svg" alt="icon"></img>
//           </figure>
//           <Typography variant="h6" component="h2" dir="auto">
//             {aiDetail
//               ? aiDetail?.slice(0, aiDetail?.indexOf("\n"))
//               : "How to learn trignomentery table easily?"}
//           </Typography>
//           <Typography sx={{ mt: 2, textAlign: "start", direction: "auto" }}>
//             {aiDetail
//               ? HTMLReactParser(
//                   generateHTMLcontent(
//                     aiDetail.slice(aiDetail?.indexOf("\n"), aiDetail?.length)
//                   )
//                 )
//               : `It is a long established fact that a reader will be distracted by
//             the readable content of a page when looking at its layout. The point
//             of using Lorem Ipsum is that it has a more-or-less normal
//             distribution of letters, as opposed to using 'Content here, content
//             here', making it look like readable English. Many desktop publishing
//             packages and web page editors now use Lorem Ipsum as their default
//             model text, and a search for 'lorem ipsum' will uncover many web
//             sites still in their infancy. Various versions have evolved over the
//             years, sometimes by accident, sometimes on purpose (injected humour
//             and the like).`}
//           </Typography>
//         </Box>
//       </Modal>
//       {/* <Drag
//         open={open}
//         setOpen={setOpen}
//         handleClose={handleClose}
//         nextPage={'/edit-file'}
//       /> */}
//     </>
//   );
// };
// export default EditFile;
//typewriter working with streaming
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Layout from "../../layout/index";
import { Box, Button, Modal, Typography } from "@mui/material";
import Download2 from "../../components/download2";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Header from "../../layout/header/Header";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import useAuth from "../../hooks/useAuth";
import HTMLReactParser from "html-react-parser";
import {
  useChangeLanguageFileSummarizationMutation,
  useDownloadFileSummarizationMutation,
  useLazyGetFileSummarizationByIdQuery,
} from "../../services/fileSummarisation";
import { showError, showToast, showWarning } from "../../constants/toast";
import { CommonBody } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import AiWork from "../../components/aiwork";
import { Close, FamilyRestroomOutlined } from "@mui/icons-material";
import {
  useChangeLanguageMutation,
  useGenerateAiExplanationMutation,
  useIdentifyVocabMutation,
} from "../../services/utils";
import Loader from "../../constants/Loader";
import { handleDownload } from "../../utils/handleDownload";
import { fileToBase64 } from "../../utils/fileToB64";
import TestAi from "../testAi";
import { getFromStorage } from "../../constants/storage";
import { STORAGE_KEYS } from "../../constants/storageKeys";
import { isatty } from "tty";
import useTranslation from "../../hooks/Translation";
import { API_URL } from "../../constants/url";
let isBack = false;
let isLanguageChanged = false;
let originalSummary = "";
let translatedSummary = "";
let summaryState = "";
const EditFile = () => {
  const navigate = useNavigate();
  const translate = useTranslation();
  const [isBlink, setIsBlink] = useState(false);
  const { id } = useParams();
  console.log("id :", id);
  const user = useAuth();
  const { state } = useLocation();
  console.log("state  ", state);
  const [getDataById] = useLazyGetFileSummarizationByIdQuery();
  const [downloadPdfFile] = useDownloadFileSummarizationMutation();
  const [aiExplain] = useGenerateAiExplanationMutation();
  const [changeLanguage] = useChangeLanguageFileSummarizationMutation();
  const [identifyVocab] = useIdentifyVocabMutation();
  const [aiDetail, setAiDetail] = useState("");
  const goProfile = () => {
    navigate("/setting");
  };
  const [open, setOpen] = React.useState<any>(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [openAI, setOpenAI] = React.useState<any>(false);

  const handleClickOpenAI = () => {
    setOpenAI(true);
  };
  const handleCloseAI = () => {
    setOpenAI(false);
  };
  const [sidebar, setSidebar] = useState(true);
  const [summary, setSummary] = useState("");
  const [messages, setMessages] = useState<any | String>("");
  const [ws, setWs] = useState<any>(null);
  const socketRef: any = useRef();
  const createNewSummarization = (isChangeLanguage?: any) => {
    // const ws=new WebSocket('https://d164-122-160-196-233.ngrok-free.app/file-summarization/');
    // if (isChangeLanguage && originalSummary) {
    //   setSummary(originalSummary)
    // }
    if (isChangeLanguage) {
      setDisableButton(true);
      setIsLoading(true);
    }
    const ws = new WebSocket(
      `wss:${API_URL.replaceAll("https://", "")}file-summarization/`
    );
    socketRef.current = ws
    ws.onmessage = (event) => {
      console.log("event.data :", event.data);
      const data = JSON.parse(event.data);
      if (data.signal == -1) {
        ws.close();
        showError(data?.data || data?.message || translate.errors.pleaseTryAgain || "Please try again");
        navigate("/file-summarization", { replace: true });
      }
      if (data.signal == 0) {
        setDisableButton(false);
        ws.close();
        showToast(
          translate.toastMessages.generationSuccessfully ||
          "Generation successfully"
        );
        if (!isBack) {
          if (data?.record_id)
            navigate(`/edit-file/${data?.record_id}`, {
              state: {
                summaryData: summaryState,
              },
              replace: true,
            });
        }
        // window.location.replace(`/edit-file/${data?.record_id}`)
      }
      if (data.signal == 401) {
        ws.close();
        showError(data?.data || data?.message || translate.errors.pleaseLogin || "Please login");
        navigate("/");
        // return;
      }
      if (data.signal == 400) {
        ws.close();
        showError(data?.data || data?.message);
        navigate("/file-summarization", { replace: true });
        // return;
      }
      if (data.signal == 1) {
        if (isChangeLanguage) setIsLoading(false);

        setMessages(data.data);
        setSummary(data.data);
        summaryState = data.data;
      }
      const teset = document.getElementById("mohit");
      if (teset) {
        setTimeout(() => {
          teset.scrollIntoView({ behavior: "smooth", block: "center" });
        }, 0);
      }

      console.log(event);
    };
    ws.onopen = async () => {
      setIsBlink(true);

      const PAYLOAD: any = {
        token: JSON?.parse(getFromStorage(STORAGE_KEYS.token) as string),
      };
      if (isChangeLanguage) {
        // setDisableButton(true);
        // setIsLoading(true);
        PAYLOAD["text"] = summary;
        PAYLOAD["change_language"] = true;
      } else {
        PAYLOAD["type"] = 1;
        PAYLOAD["binary_data"] = await fileToBase64(state?.selectedFile);
        PAYLOAD["file_name"] = Array.isArray(state?.selectedFile)
          ? new Date()
          : state?.fileName;
      }
      console.log("PAYLOAD :", PAYLOAD);
      ws?.send(JSON.stringify(PAYLOAD));
      console.log("Connected to websocket successfully");
    };
    ws.onerror = (error) => {
      setIsBlink(false);
      setIsLoading(false);
      ws.close();
      console.log("There is some error", error);
    };
    ws.onclose = () => {
      setIsBlink(false);
      setIsLoading(false);
      console.log("Socket is closed");
    };
    setWs(ws);
  };
  useEffect(() => {
    if (id) {
      if (state?.summaryData) {
        setSummary(state?.summaryData);
      } else {
        getDataByIdList();
      }
    } else {
      createNewSummarization();
    }
    // return () => {
    //   console.log('Cleaning up: Closing WebSocket');
    //   // ws?.send(-1);
    //   originalSummary=""
    //   isBack=true;
    //   if(ws) {
    //     ws.close();
    //   }
    // }
  }, [id]);
  useEffect(() => {
    isBack = false;
    return () => {
      isBack = true;
      console.log("Cleaning up: Closing WebSocket");
      originalSummary = "";

      if (ws) {
        const PAYLOAD = {
          signal: -1
        }

        if (socketRef?.current) {
          socketRef?.current.close(); // Close WebSocket on cleanup
        }

        ws?.send(JSON.stringify(PAYLOAD));

        ws.close();
      }
    };
  }, []);
  // useEffect(()=>{
  // window.scrollTo(0,0)
  // },[summary])

  const getDataByIdList = async () => {
    setIsLoading(true);
    try {
      const response = await getDataById({ id }).unwrap();
      if (response.status == 200) {
        originalSummary = response?.data?.result;
        setSummary(response?.data?.result);
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };
  const generateHTMLcontent = (text: string) => {
    return text.replaceAll("\n", "<br>");
  };

  const getAiDetail = async () => {
    if (!document.getSelection()?.toString()) {
      showError(
        translate.noteTaking.selectSomethingToGetDetail ||
        "Please select something to get detail"
      );
      return;
    }
    setIsLoading(true);
    try {
      const body = {
        text: document.getSelection()?.toString() || summary,
      };
      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await aiExplain(encryptedBody).unwrap();
      if (response.status == 200) {
        setAiDetail(response?.data);
        setOpenAI(true);
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };
  const [disableButton, setDisableButton] = useState(false);
  const getChangeLanguage = async (isPdf: boolean) => {
    setIsLoading(true);
    try {
      if (!isPdf)
        originalSummary = summary.replaceAll(
          `style='background-color:rgb(185 154 36)'`,
          ""
        );
      const body = {
        text: summary.replaceAll(
          `style='background-color:rgb(185 154 36)'`,
          ""
        ),
      };
      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await changeLanguage(encryptedBody).unwrap();
      if (response.status == 200) {
        if (isPdf) {
          // if(isLanguageChanged)
          // downloadPdfData(originalSummary,2)
          // else
          downloadPdfData(response?.data, 9);
        } else setSummary(response?.data);
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const getVocab = async (isPdf: boolean) => {
    setIsLoading(true);
    try {
      const body = {
        text_data: summary,
      };
      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await identifyVocab(encryptedBody).unwrap();
      if (response.status == 200) {
        let temp = summary;
        response?.data?.map((element: any) => {
          temp = temp?.replaceAll(
            element + " ",
            `<span style='background-color:rgb(185 154 36)'>${element + " "}</span>`
          );
        });
        console.log(temp);
        if (isPdf) downloadPdfData(temp, 3);
        else setSummary(temp);
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const downloadPdfData = async (data: any, type: number) => {
    setIsLoading(true);
    try {
      let html = "";

      if (type == 9) {
        html =
          '<html lang="ar"><meta charset="UTF-8"><style> body{font-family:"Arial", sans-serif;}</style></head><body style="background-color: white;" dir="auto">';
        html +=
          '<pre style="white-space: pre-wrap; font-family: Arial, sans-serif; background-color: #fff; border: 0px">';
        html += generateHTMLcontent(data);
        html += "</pre>";
        html += "</body></html>";
      } else {
        html =
          '<html lang="ar"><meta charset="UTF-8"><body style="background-color: white;"  dir="auto">';
        html +=
          '<pre style="white-space: pre-wrap; font-family: Arial, sans-serif; background-color: #fff; border: 0px">';
        html += generateHTMLcontent(data);
        html += "</pre>";
        html += "</body></html>";
      }

      const body = {
        html_text: html,
        type: type == 9 ? 2 : type,
        new: true,
      };
      console.log(body, "body for download ");

      if (html) {
        // setLoading(true);
        let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response = await downloadPdfFile({
          body: encryptedBody,
          id: id,
        }).unwrap();
        // setLoading(false);
        if (response?.status === 200) {
          setOpen(false);
          handleDownload(
            response?.data,
            response?.data?.split("/")?.[response?.data?.split("/")?.length - 1]
          );

          // window.open(response?.data || '');
        }
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
    }
    setIsLoading(false);
  };
  const generateHTML = (parts: string[], wordsToHighlight: string[]) => {
    let html =
      '<html lang="ar"><meta charset="UTF-8"><style> body{font-family:"Arial", sans-serif;}</style></head><body style="background-color: white;" dir="auto">';
    // let html =isLanguageChanged?'<html lang="ar"><meta charset="UTF-8"><style> body{font-family:"Arial", sans-serif;}</style></head><body style="background-color: white;" dir="rtl">':'<html><body style="background-color: white;" dir="auto">';

    html +=
      '<pre style="white-space: pre-wrap; font-family: Arial, sans-serif; background-color: #fff; border: 0px">';
    html += parts
      ?.map((part, index) => {
        const isHighlighted = wordsToHighlight
          .map((word) => word?.toLowerCase())
          .includes(part?.toLowerCase());

        const style = isHighlighted
          ? "font-size: 14px; color: black; background-color: yellow; letter-spacing: 1px;"
          : "font-size: 14px; color: black; letter-spacing: 1px;";

        return part?.length
          ? `<span style="${style}" key="${index}">${part}</span>`
          : "";
      })
      .join("");

    html += "</pre>";
    html += "</body></html>";
    return html;
  };
  const downloadPdf = async (type: number) => {
    if (type == 1) {
      getChangeLanguage(true);
    } else if (type == 2) {
      if (isLanguageChanged) {
        downloadPdfData(summary, 9);
      } else {
        downloadPdfData(summary, 2);
      }
    } else if (type == 3) {
      setIsLoading(true);
      let temp = "";
      try {
        const body = {
          text_data: summary,
        };
        const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response = await identifyVocab(encryptedBody).unwrap();
        if (response.status == 200) {
          const regex = new RegExp(`(${response?.data?.join("|")})`, "gi");
          temp = generateHTML(summary?.split(regex), response?.data);
        }
      } catch (error) {
        console.log(error);
      }

      try {
        const body = {
          html_text: temp,
          type: 3,
          new: true,
        };
        console.log(body, "body for download ");

        if (temp) {
          // setLoading(true);
          let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
          const response = await downloadPdfFile({
            body: encryptedBody,
            id: id,
          }).unwrap();
          // setLoading(false);
          if (response?.status === 200) {
            setOpen(false);
            // window.open(response?.data || '');
            handleDownload(
              response?.data,
              response?.data?.split("/")?.[
              response?.data?.split("/")?.length - 1
              ]
            );
          }
        }
      } catch (error) {
        // setLoading(false);
        console.log(error);
      }
      setIsLoading(false);

      // getVocab(true);
    }
  };
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: 587,
    bgcolor: "#353e3d",
    border: "1px solid #FFFFFF8A",
    boxShadow: 24,
    borderRadius: "10px",
    p: 3,
    maxHeight: "95vh",
    overflow: "auto",
  };

  return (
    <>
      <Loader isLoad={isLoading} />
      {summary || id ? (
        <div className="sdbr_rltv">
          <Layout.SidePanel />
          <Header />

          <div className="sde_br_mn sde_br_mn_fixed">
            {/* <div className='sde_hdr'>
          <div className='sde_hdr_lt'>
          <button
                className="back_btn"
                onClick={() => navigate("/file-summarization")}
              >
                <ArrowBackIosNewIcon />
                </button>
              <figure>
                <img src='/static/images/summarization.png' alt=''></img>
              </figure>
              <div className=''>
                <h3>File Summarization</h3>
                <p> Lorem Ipsum dolor et amet</p>
              </div>
          </div>
          <div className='sde_hdr_rt'>
            <figure>
              <img src='/static/images/notification.png' alt=''></img>
            </figure>
            <h3>Hi, Ahmed</h3>
            <figure onClick={goProfile}>
              <img src='/static/images/ahmed.png' alt=''></img>
            </figure>
          </div>
      </div> */}
            <div className="sde_mn_cnt edt_fle">
              {/* <Button className='btnn btn-primary h_46'>
        Upload a new File
        </Button> */}
              <div className="edt_nte">
                <div>
                  <div
                    dir="auto"
                    style={{ background: "transparent" }}
                    className="list_enable"
                  >
                    {summary ? (
                      <>
                        {HTMLReactParser(summary)}
                        {isBlink ? <span className="blinker">|</span> : null}
                        {/* {isBlink?<span className="dots"></span>:null} */}
                      </>
                    ) : (
                      <p>Loading...</p>
                    )}
                  </div>
                  <div id="mohit" style={{ paddingTop: "80px" }}></div>
                </div>
                {id && (
                  <div className="prfl_btn fixed_bottom">
                    <div className="prfl_btn_lft">
                      <Button
                        disabled={!id || disableButton}
                        className="btnn h_46"
                        onClick={() => {
                          window.scrollTo(0, 0);
                          createNewSummarization(true);
                          // if (!isLanguageChanged) {
                          //   getChangeLanguage(false);
                          //   isLanguageChanged = true;
                          // } else {
                          //   setSummary(originalSummary);
                          //   // originalSummary="";
                          //   isLanguageChanged = false;
                          // }
                        }}
                      >
                        {translate.global.changeLanguage || "Change Language"}
                      </Button>
                      <Button
                        className="btnn h_46"
                        disabled={!id || disableButton}
                        onClick={() => {
                          // if(!isLanguageChanged)
                          getVocab(false);
                          // else
                          // showWarning("Please change language to identify important vocabulary")
                        }}
                      >
                        {translate.global.identifyingImportantVocabulary ||
                          "Identifying Important Vocabulary"}
                      </Button>

                      <Button
                        disabled={!id || disableButton}
                        className="btnn h_46"
                        onClick={() => {
                          getAiDetail();
                        }}
                      >
                        {translate.global.aIExplanation || "AI Explanation"}
                      </Button>
                    </div>

                    <Button
                      disabled={!id || disableButton}
                      className="btnn h_46"
                      onClick={() => setOpen(true)}
                    >
                      {translate.global.download || "Download"}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <TestAi />
      )}
      <Download2
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        downloadPdf={downloadPdf}
        label={translate.fileSummarization.summarization || "Summarization"}
      />
      <Modal
        open={openAI}
        onClose={handleCloseAI}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modall"
      >
        <Box
          sx={style}
          component={"div"}
          className="text_center how_to_modal"
          dir="auto"
        >
          <p className="close_icon" onClick={handleCloseAI}>
            <Close />
          </p>
          <figure>
            <img src="/static/images/bulb.svg" alt="icon"></img>
          </figure>
          <Typography variant="h6" component="h2" dir="auto">
            {aiDetail
              ? aiDetail?.slice(0, aiDetail?.indexOf("\n"))
              : "How to learn trignomentery table easily?"}
          </Typography>
          <Typography sx={{ mt: 2, textAlign: "start", direction: "auto" }}>
            {aiDetail
              ? HTMLReactParser(
                generateHTMLcontent(
                  aiDetail.slice(aiDetail?.indexOf("\n"), aiDetail?.length)
                )
              )
              : `It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout. The point
            of using Lorem Ipsum is that it has a more-or-less normal
            distribution of letters, as opposed to using 'Content here, content
            here', making it look like readable English. Many desktop publishing
            packages and web page editors now use Lorem Ipsum as their default
            model text, and a search for 'lorem ipsum' will uncover many web
            sites still in their infancy. Various versions have evolved over the
            years, sometimes by accident, sometimes on purpose (injected humour
            and the like).`}
          </Typography>
        </Box>
      </Modal>
      {/* <Drag
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        nextPage={'/edit-file'}
      /> */}
    </>
  );
};
export default EditFile;
