import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import AiWork from "../../components/aiwork";
import PaymentCards from "../../components/paymentCards";
import { useNavigate } from "react-router-dom";
import useTranslation from "../../hooks/Translation";

function PaymentOption() {
  const navigate = useNavigate();
  const translate = useTranslation();
  return (
    <div>
      <section className="bnr_sec pymt_optn sign_in sn_in">
        <div className="conta_iner">
          <div className="bnr_mn">
            <AiWork />
            <div className="pymt_optn_rt">
              <PaymentCards />
              <div className="ptm_dtl">
                <div className="ptm_cnt hd_2_1">
                  <h2>
                    {translate.subscriptions.paymentDetails ||
                      "Payment Details"}
                  </h2>
                  <ul className="ptm_flx hd_5">
                    <li>
                      <h3>
                        {translate.subscriptions.totalAmount || "Total Amount"}
                      </h3>
                      <h4>$29.99</h4>
                    </li>
                    <li>
                      <h3>{translate.subscriptions.tax || "Tax"}</h3>
                      <h4>$10.00</h4>
                    </li>
                    <li>
                      <h3>
                        {translate.subscriptions.grandTotal || "Grand Total"}
                      </h3>
                      <h4 className="totl">$30.99</h4>
                    </li>
                  </ul>
                </div>
              </div>
              <Button
                className="btnn btn_primary lrg"
                onClick={() => navigate("/congratulation")}
              >
                {translate.subscriptions.payNow || "Pay Now"}
              </Button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default PaymentOption;
