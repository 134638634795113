import { API_URL, END_POINTS } from "../constants/url";
import { UploadDocument } from "../utils/documentsUpload";
import emptySplitApi from "../utils/rtk";
type ResearchResponse = any;
type CommonResponseType = {
  status: number;
  message: string;
  record_id: number;
};

type GetAllResearchsResponse = {
  // Research: ResearchResponse[];
  response_object: ResearchResponse[];
  total_records: number;
};

// type encryptedBody = {
//     image: string;
//     email: string;
//     fullName: string;
//     countryCode: string;
//     phone: string;
//     address:string;
//   };
type encryptedBody = {
  hash: string;
  sek: string;
} | null;

type getDataBody = {
  length: number;
  start: number;
  search: string;
};
type ChangeStatusBody = {
  is_active: boolean;
};
export const ResearchApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    addResearch: builder.mutation<
      CommonResponseType & { data: ResearchResponse },
      encryptedBody
    >({
      query: (body) => {
        let url = END_POINTS.getResearchAnswer;
        return {
          url: url,
          method: "POST",
          body,
        };
      },
    }),

    getResearch: builder.mutation<
      CommonResponseType & { data: GetAllResearchsResponse },
      encryptedBody
    >({
      query: (body) => {
        let url = END_POINTS.getAllResearch;
        return {
          url: url,
          method: "POST",
          body,
        };
      },
    }),
    downloadResearch: builder.mutation<
      CommonResponseType & { data: any },
      { id: string | undefined; body: encryptedBody }
    >({
      query: ({ id, body }) => {
        let url = END_POINTS.downloadResearch + id + "/";
        return {
          url: url,
          method: "POST",
          body,
        };
      },
    }),

    getResearchById: builder.query<
      CommonResponseType & { data: ResearchResponse },
      { id: string | undefined }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.getResearchById}${id}/`,
        method: "GET",
      }),
    }),
    detailedResearch: builder.mutation<
      CommonResponseType & { data: any },
      { body: any; id: string }
    >({
      query: ({ body, id }) => ({
        url: `${END_POINTS.detailedResearch}${id}/`,
        method: "POST",
        body,
      }),
    }),
    saveResearch: builder.mutation<
      CommonResponseType & { data: any },
      { body: any; id: string }
    >({
      query: ({ body, id }) => ({
        url: `${END_POINTS.saveResearch}${id}/`,
        method: "POST",
        body,
      }),
    }),
    regenerateResearchAnswer: builder.query<
      CommonResponseType & { data: any },
      { id: string; page: string }
    >({
      query: ({ id, page }) => ({
        url: `${END_POINTS.regenerateResearchAnswer}${id}/?page=${page}/`,
        method: "GET",
      }),
    }),
    deleteResearch: builder.mutation<CommonResponseType, { id: number }>({
      query: ({ id }) => ({
        url: `${END_POINTS.deleteResearch}${id}/`,
        method: "DELETE",
      }),
    }),
    editResearch: builder.mutation<
      CommonResponseType,
      {
        id: number;
        body: {
          file_name: string;
        };
      }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.editResearchName}${id}/`,
        method: "PUT",
        body,
      }),
    }),
    // changeResearchStatus: builder.mutation<
    // CommonResponseType & { data: ResearchResponse },
    // { id: string , body: encryptedBody}
    // >({
    // query: ({ id }) => ({
    //   url: `${END_POINTS.changeResearchStatus}${id}/`,
    //   method: "PUT",
    // }),
    // }),

    // editResearchById: builder.mutation<
    //       CommonResponseType & { data: ResearchResponse },
    //       { id: string; body: encryptedBody }
    //     >({
    //       query: ({ id, body }) => ({
    //         url: `${END_POINTS.updateResearchs}${id}/`,
    //         method: "PUT",
    //         body,
    //       }),
    //     }),

    // deleteResearchById:builder.mutation<
    // CommonResponseType & {data:ResearchResponse},
    // {id:string}
    // >({
    //     query:({id})=>({
    //         url:`${END_POINTS.deleteResearch}${id}/`,
    //         method:'DELETE'
    //     })
    // }),
    // getResearchCSV: builder.query<CommonResponseType & { data: string }, {
    //     search:string;
    //   }>({
    //     query: ({search}) => {
    //       let url = END_POINTS.exportResearchs;
    //       const queryParams = [];
    //       if (search) {
    //         queryParams.push(`search=${search}`);
    //       }
    //       if (queryParams.length > 0) {
    //         url += `?${queryParams.join('&')}`;
    //       }
    //      return{
    //       url:url,
    //       method: "GET",}
    //     },
    //   }),
  }),
});
export const researchByReference = async (
  selectedFile: any,
  key: string,
  description: string,
  reduce_citation: boolean,
  endpoint: string
) => {
  const formData = new FormData();
  formData.append(key, selectedFile);
  console.log("selectedFile :", selectedFile);
  formData.append("description", description);
  formData.append("reduce_citation", reduce_citation.toString());
  console.log(formData.get("reduce_citation"));

  let url = `${API_URL}${endpoint}`;
  try {
    const response = await UploadDocument(url, formData);
    return response;
  } catch (error) {
    console.log(error);
  }
};
export const {
  useAddResearchMutation,
  useGetResearchMutation,
  useLazyGetResearchByIdQuery,
  useDownloadResearchMutation,
  useDetailedResearchMutation,
  useLazyRegenerateResearchAnswerQuery,
  useSaveResearchMutation,
  useDeleteResearchMutation,
  useEditResearchMutation,
  // useChangeResearchStatusMutation,
  // useEditResearchByIdMutation,
  // useDeleteResearchByIdMutation,
  // useLazyGetResearchCSVQuery,
} = ResearchApi;
