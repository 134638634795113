import { translate } from "pdf-lib";
import React from "react";
import useTranslation from "../../hooks/Translation";

const AboutSection3 = () => {
  const translate = useTranslation();
  return (
    <>
      <section className="srve_sec u_spc">
        <div className="conta_iner">
          <div className="srve_flx">
            <div className="srve_lt hd_1_1">
              <h2>
                {translate.about.services}{" "}
                <span className="d_block">{translate.about.weProvide}</span>
              </h2>
              <p>{translate.about.servicesWeProvideLine}</p>
            </div>
            <ul className="srve_rt">
              <li>{translate.testing.testing}</li>
              <li>{translate.research.research}</li>
              <li>{translate.article.articles}</li>
              <li>{translate.noteTaking.notes}</li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};
export default AboutSection3;
