import { useNavigate } from "react-router-dom";
import { getFromStorage } from "../constants/storage";
import { STORAGE_KEYS } from "../constants/storageKeys";
import { showError, showToast } from "../constants/toast";
import { API_URL, END_POINTS, purposeUrl } from "../constants/url";
import { CommonBody } from "../types/General";
import { UploadDocument } from "../utils/documentsUpload";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  status: number;
  message: string;
};

export type Question = {
  correct_answer: string;
  options: string[];
  question: string;
  question_no: number;
  question_type: number;
};

export const AssignmentApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    GetAllAssignments: builder.mutation<
      CommonResponseType & { data: any },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.getAllAsignments,
        method: "POST",
        body,
      }),
    }),

    GetAssignmentById: builder.query<
      CommonResponseType & { data: any },
      { id: string | number | undefined }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.getAssignmentById}${id}/`,
        method: "GET",
      }),
    }),

    downloadPdf: builder.mutation<
      CommonResponseType & { data: any },
      { body: CommonBody } & { id: string | number | undefined }
    >({
      query: ({ body, id }) => ({
        url: `${END_POINTS.downloadPdfFile}${id}/`,
        method: "POST",
        body,
      }),
    }),

    generateAssignment: builder.mutation<
      CommonResponseType & { data: any },
      { body: CommonBody; param: string | number | undefined }
    >({
      query: ({ body, param }) => ({
        url: `${END_POINTS.generateSolution}${param}/`,
        method: "POST",
        body,
      }),
    }),

    translateAssignment: builder.mutation<
      CommonResponseType & { data: any },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.translateText,
        method: "POST",
        body,
      }),
    }),

    deleteAssignment: builder.mutation<CommonResponseType, { id: number }>({
      query: ({ id }) => ({
        url: `${END_POINTS.deleteAssignment}${id}/`,
        method: "DELETE",
      }),
    }),
    editAssignment: builder.mutation<
      CommonResponseType,
      {
        id: number;
        body: {
          file_name: string;
        };
      }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.editAssignmentName}${id}/`,
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  useGetAllAssignmentsMutation,
  useLazyGetAssignmentByIdQuery,
  useDownloadPdfMutation,
  useGenerateAssignmentMutation,
  useTranslateAssignmentMutation,
  useDeleteAssignmentMutation,
  useEditAssignmentMutation,
} = AssignmentApi;

export const getAssignmentSolution = async (
  selectedFile: any[],
  type: number,
  language: string
) => {
  console.log("YYYYYYYYYY>>>>>>>", selectedFile, type);

  const formData = new FormData();
  console.log(selectedFile, "selectedFile in form");
  console.log(type, "type in form ");

  if (Array.isArray(selectedFile)) {
    for (let file = 0; file < selectedFile?.length; file++) {
      let fileToAppend = selectedFile[file];
      formData.append("file_link", fileToAppend as any);
    }
  } else {
    formData.append("file_link", selectedFile);
  }
  // formData.append('file_link', selectedFile);

  formData.append("type", String(type));
  formData.append("language", String(language));

  const getToken = (await getFromStorage(STORAGE_KEYS.token)) as string;
  const token = JSON.parse(getToken);

  let headers = {
    // 'Content-Type': 'multipart/form-data',
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const url = `${API_URL}${END_POINTS.getAnswer}`;
  console.log(formData, "body of uploading");
  try {
    const res = await fetch(url, {
      method: "POST",
      headers,
      body: formData,
    });
    let response = await res.json();
    if (response?.status === 200) {
    }
    if (response?.status !== 200) {
      showError(response?.message || "Please check your network connection");
    }
    return response;
  } catch (error: any) {
    showError(
      error?.data?.message ||
        error?.data?.detail ||
        "Please check your network connection"
    );
    console.log(error, ">>>>>>>>>");
    return error;
  }
};
