// export const API_URL = "https://whizzodevapi.appgrowthcompany.com/"; //development

export const API_URL = "https://whizzostageapi.appgrowthcompany.com/";
export const uploadUrl = API_URL + "media/upload/";
export const purposeUrl = API_URL + "admin/get-all-purpose-listing/";
export const ContactUsUrl = API_URL + "admin/get-cms-detail/";
export const contactSupport = API_URL + "admin/add-contact-suport/";
export const getAllSubjects = API_URL + "admin/get-all-subject/";

export const END_POINTS = {
  //auth
  testimonial: "user/user-testimonial/",
  registration: "user/registration/",
  login: "user/login/",
  verifyOtp: "user/verify-otp/",
  resendOtp: "user/resend-otp/",
  changePassword: "user/change-password/",
  forgotPassword: "user/forgot-password/",
  resetPassword: "user/reset-password/",
  userDetails: "user/user-details/",
  updateProfile: "user/update-profile/",
  sendQuery: "user/send-query/",
  upload: "user/media/upload/",
  logout: "user/logout/",
  sendOtpToNewEmail: "user/send-otp-to-new-mail/",
  verifyNewMail: "user/verify-new-mail/",

  deleteAccount: "category/delete-user-app/",
  termsConditions: "category/get-terms-condition-app/",
  faq: "category/get-faq-app-list/",

  //file type conversion
  pdfToWord: "category/conversion/pdf-to-word/",
  pdfToExcel: "category/conversion/pdf-to-excel/",
  excelToPdf: "category/conversion/excel-to-pdf/",
  pdfToImage: "category/conversion/pdf-to-image/",
  pdfToPpt: "category/conversion/pdf-to-ppt/",
  imageToPdf: "category/conversion/image-to-pdf/",
  wordToPdf: "category/conversion/word-to-pdf/",
  pptToPdf: "category/conversion/ppt-to-pdf/",
  conversionHistory: "category/conversion/history/",

  //assignment module
  getAnswer: "category/get-assignmnet-answer/",
  getAllAsignments: "category/fetch-all-assignment/",
  getAssignmentById: "category/get-assignment/",
  downloadPdfFile: "category/update-assignment/",
  generateSolution: "category/regenerate-solution/",
  translateText: "category/change-language/",
  deleteAssignment: "category/assignment/delete/",
  editAssignmentName: "category/assignment/edit/",

  //research
  getAllResearch: "category/get-all-listing-research/",
  getResearchById: "category/get-research-by-id/",
  getResearchAnswer: "category/get-research-answer/",
  downloadResearch: "category/download-research-file/",
  uploadReferenceForResearch: "category/research-answer-by-upload-reference/",

  detailedResearch: "category/detailed-research-answer/",
  regenerateResearchAnswer: "category/regenerate-research-answer/",
  saveResearch: "category/save-research-topics/",
  deleteResearch: "category/research-file/delete/",
  editResearchName: "category/research-file/edit/",

  //FileSummarization
  getAllFileSummarization: "category/file/history/",
  getFileSummarizationById: "category/file/",
  generateFileSummarizationAnswer: "category/file/summarization/",
  downloadFileSummarization: "category/download-file-summary/",
  uploadReferenceForFileSummarization:
    "category/FileSummarization-answer-by-upload-reference/",
  changeLanguageFileSummarization: "category/change-language-file-summary/",
  deleteFile: "category/file/delete/",
  editFileName: "category/file/edit/",
  //common
  generateAIExplanation: "category/get-ai-explanation/",
  changeLanguage: "category/change-language/",
  identifyVocab: "category/file/summarization-vocab/",

  //article
  getAllArticle: "category/articles/",
  getArticleById: "category/article/",
  downloadArticle: "category/download-article/",
  detailedArticle: "category/detailed-article/",
  regenerateArticleAnswer: "category/regenerate-article/",
  deleteArticle: "category/article/delete/",
  editArticleName: "category/article/edit/",

  //testing module
  mcqAndFlash: "category/testing/generate-response/",
  getAbilities: "category/testing/ablities/",
  getAchivements: "category/testing/achievements/",
  updateResult: "category/testing/result/",
  getPastTests: "category/testing/past-tests/",
  getRecordById: "category/testing/",
  downloadFile: "category/download-file/",
  deleteTesting: "category/testing-delete/",
  editTesting: "category/testing-rename/",
  //presentation
  generatePPTContent: "category/generate-slide-text/",
  addPPTContent: "category/presentation-binary-data/",
  getAllPPT: "category/presentation-history/",
  getPresentationById: "category/get-presentation/",
  updatePresentationById: "category/update-presentation/",
  deletePresentation: "category/presentation-delete/",
  editPresentationName: "category/presentation-rename/",

  //note taking
  createNote: "category/save-notes/",
  getAllNotes: "category/notes-history/",
  getNoteById: "category/notes/",
  noteActions: "category/notes-actions/",
  deleteNoteTaking: "category/notes-delete/",
  editNoteTakingName: "category/notes-rename/",
};
