import React, { useState } from "react";
import Layout from "../../layout";
import { Button, IconButton, InputAdornment } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import * as Yup from "yup";
import { useFormik } from "formik";
import "react-tabs/style/react-tabs.css";
import { usePostSignUpMutation } from "../../services/auth";
import { setToStorage } from "../../constants/storage";
import { STORAGE_KEYS } from "../../constants/storageKeys";
import { showError, showWarning } from "../../constants/toast";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useAppDispatch } from "../../hooks/store";
import {
  FamilyRestroomOutlined,
  SafetyCheckRounded,
} from "@mui/icons-material";
import Loader from "../../constants/Loader";
import useTranslation from "../../hooks/Translation";

const SignUp = () => {
  const navigate = useNavigate();
  const translate = useTranslation();
  const [isEmail, setIsEmail] = useState(false);
  const [signUpMutation] = usePostSignUpMutation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [checked, SetChecked] = useState<boolean>(false);
  const handleClickShowPassword1 = () => {
    setShowPassword1(!showPassword1);
  };
  const handleMouseDownPassword1 = (event: any) => {
    event.preventDefault();
  };

  const [showPassword2, setShowPassword2] = useState(false);

  const handleClickShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };
  const handleMouseDownPassword2 = (event: any) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
      accept_terms_and_conditions: false,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required(translate.validations.requiredEmail || "Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          translate.validations.enterValidEmail || "Please enter a valid email"
        )
        .max(
          80,
          translate.auth.emailMustLessThan80Characters ||
            "Email must be 80 characters or fewer"
        ),
      password: Yup.string()
        .label(translate.auth.newPassword || "new Password")
        .required(
          translate.validations.requirePassword || "Password is required"
        )
        .min(
          5,
          translate.validations.passwordAtleast5CharacterLong ||
            "Password must be at least 5 characters long"
        ),
      // .matches(
      //   /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.*[0-9])[^\s]{8,}$/,
      //   "Password must be at least 8 characters long and include 1 uppercase, 1 lowercase, and 1 special character."
      // ),

      confirmPassword: Yup.string()
        .oneOf(
          [Yup.ref("password")],
          translate.validations.passwordMustMatch || "Passwords must match"
        )
        .required(
          translate.validations.requiredConfirmPassword ||
            "Confirm password is required"
        ),
    }),

    onSubmit: async (values) => {
      formik.setSubmitting(true);

      if (!checked) {
        showWarning(
          translate.errors.acceptTermsConditions ||
            "Please accept terms & conditions"
        );
        return;
      }

      let body = {
        email: formik.values.email,
        password: formik.values.password,
        accept_terms_and_conditions: checked,
      };
      setIsLoading(true);
      console.log("body for sign up", body);
      try {
        let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response = await signUpMutation(encryptedBody).unwrap();
        setIsLoading(false);
        if (response?.status === 400) {
          showError(response?.message || translate.errors.somethingWentWrong);
          return;
        }
        navigate("/verify", {
          state: { mode: "signup", email: formik.values.email },
        });
      } catch (error: any) {
        setIsLoading(false);
        showError(
          error?.data?.message || translate.errors.somethingWentWrong || ""
        );
      }
    },
  });

  return (
    <div>
      <Loader isLoad={isLoading} />
      <section className="bnr_sec c_white  sign_in">
        <div className="conta_iner">
          <div className="bnr_mn">
            <div className="bnr_lt">
              <figure className="logo">
                <img
                  src="/static/images/logo.svg"
                  alt=""
                  onClick={() => navigate("/")}
                ></img>
              </figure>
              <h1>
                {translate.home.let}
                <span className="ai_bdr">
                  {translate.home.ai} <span></span>
                </span>{" "}
                <span className="d_block">{translate.home.doYourWork}</span>
              </h1>
              <p>
                {translate.home.homeMessageLine1}
                <span className="d_block">
                  {translate.home.homeMessageLine2}
                </span>{" "}
                {translate.home.homeMessageLine3}
              </p>
            </div>
            <div className="logn_rt hd_3">
              <h3>{translate.global.getStarted || "Get Started"}</h3>
              <form className="frmn frm_sn" onSubmit={formik.handleSubmit}>
                <div className="frmn_mn">
                  <label>{translate.auth.emailId || "Email Id"}</label>
                  <TextField
                    className="control_group"
                    hiddenLabel
                    placeholder={
                      translate.auth.enterEmailAddress || "Enter Email address"
                    }
                    fullWidth
                    variant="outlined"
                    inputProps={{ maxLength: 50 }}
                    name="email"
                    value={formik.values.email}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    onBlur={formik.handleBlur}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </div>
                <div className="frmn_mn">
                  <label>{translate.auth.password || "Password"}</label>
                  <TextField
                    className="control_group"
                    hiddenLabel
                    fullWidth
                    variant="outlined"
                    placeholder={
                      translate.auth.enterPassword || "Enter Password"
                    }
                    name="password"
                    type={showPassword1 ? "text" : "password"}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment className="eye_btn" position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleClickShowPassword1()}
                            onMouseDown={(e) => handleMouseDownPassword1(e)}
                            edge="end"
                          >
                            {showPassword1 ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <i className="fa-regular fa-eye"></i>
                </div>
                <div className="frmn_mn">
                  <label>{translate.auth.confirmPassword}</label>
                  <TextField
                    className="control_group"
                    hiddenLabel
                    fullWidth
                    variant="outlined"
                    placeholder={translate.auth.confirmPassword}
                    name="confirmPassword"
                    type={showPassword2 ? "text" : "password"}
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.confirmPassword &&
                      Boolean(formik.errors.confirmPassword)
                    }
                    helperText={
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment className="eye_btn" position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleClickShowPassword2()}
                            onMouseDown={(e) => handleMouseDownPassword2(e)}
                            edge="end"
                          >
                            {showPassword2 ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <i className="fa-regular fa-eye"></i>
                </div>
                <div style={{ display: "flex", marginBottom: "10px" }}>
                  <FormGroup className="checkbox_label">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={() => SetChecked(!checked)}
                        />
                      }
                      label={
                        <span style={{ marginLeft: "10px" }}>
                          {translate.auth.iAcceptAll}{" "}
                          <a
                            onClick={() => window.open("/terms-conditions")}
                            style={{ textDecorationLine: "underline" }}
                          >
                            {translate.auth.termsConditions}
                          </a>
                        </span>
                      }
                    />
                  </FormGroup>
                </div>
                <Button
                  type="submit"
                  className="btn btn_primay btnn lrg"
                  // onClick={() => navigate("/verify", { state: "signup" })}
                >
                  {translate.global.signup}
                </Button>
              </form>
              {/* <p className="sng_nmbr">Sign in with Number</p> */}
              <p className="lne">
                <small>{translate.global.or}</small>
              </p>
              <ul className="lgn_icn">
                <li>
                  <figure>
                    <img src="/static/images/facebook.png" alt=""></img>
                  </figure>
                </li>
                <li>
                  <figure>
                    <img src="/static/images/gogle.png" alt=""></img>
                  </figure>
                </li>
                <li>
                  <figure>
                    <img src="/static/images/apple.png" alt=""></img>
                  </figure>
                </li>
                <li>
                  <figure>
                    <img src="/static/images/x-social.png" alt=""></img>
                  </figure>
                </li>
              </ul>
              <p className="sgn_cnt" onClick={() => navigate("/signin")}>
                {translate.auth.alreadyHaveAccount ||
                  "Already have an account?"}{" "}
                <span>{translate.auth.signIn || "Sign in"}</span>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default SignUp;
