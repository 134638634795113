import { useNavigate } from "react-router-dom";
import { getFromStorage } from "../constants/storage";
import { STORAGE_KEYS } from "../constants/storageKeys";
import { showError, showToast } from "../constants/toast";
import {
  API_URL,
  END_POINTS,
  getAllSubjects,
  purposeUrl,
} from "../constants/url";
import { CommonBody } from "../types/General";
import { UploadDocument } from "../utils/documentsUpload";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  status: number;
  message: string;
};

export const TestingApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllPastRecords: builder.mutation<
      CommonResponseType & { data: any },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.getPastTests,
        method: "POST",
        body,
      }),
    }),

    updateResult: builder.mutation<
      CommonResponseType & { data: any },
      { body: CommonBody } & { id: string | number | undefined }
    >({
      query: ({ body, id }) => ({
        url: `${END_POINTS.updateResult}${id}/`,
        method: "POST",
        body,
      }),
    }),

    getRecordById: builder.query<
      CommonResponseType & { data: any },
      { id: any }
    >({
      query: (id) => ({
        url: `${END_POINTS.getRecordById}${id}/`,
        method: "GET",
      }),
    }),

    downloadPdfTesting: builder.mutation<
      CommonResponseType & { data: any },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.downloadFile,
        method: "POST",
        body,
      }),
    }),

    postMcqAndFlash: builder.mutation<
      CommonResponseType & { data: any },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.mcqAndFlash,
        method: "POST",
        body,
      }),
    }),

    getAbilities: builder.query<
      CommonResponseType & { data: any },
      { type: number }
    >({
      query: ({ type }) => ({
        url: `${END_POINTS.getAbilities}?type=${type}`,
        method: "GET",
      }),
    }),

    getAchivements: builder.query<
      CommonResponseType & { data: any },
      { id: number; type: number }
    >({
      query: ({ id, type }) => ({
        url: `${END_POINTS.getAchivements}${id}/?type=${type}`,
        method: "GET",
      }),
    }),

    getALlSubjectsApi: builder.mutation<
      CommonResponseType & { data: any },
      CommonBody
    >({
      query: (body) => ({
        url: getAllSubjects,
        method: "POST",
        body,
      }),
    }),

    deleteTesting: builder.mutation<CommonResponseType, { id: number }>({
      query: ({ id }) => ({
        url: `${END_POINTS.deleteTesting}${id}/`,
        method: "DELETE",
      }),
    }),
    editTesting: builder.mutation<
      CommonResponseType,
      {
        id: number;
        body: {
          file_name: string;
        };
      }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.editTesting}${id}/`,
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  useGetAllPastRecordsMutation,
  useUpdateResultMutation,
  useLazyGetRecordByIdQuery,
  useDownloadPdfTestingMutation,
  usePostMcqAndFlashMutation,
  useLazyGetAbilitiesQuery,
  useLazyGetAchivementsQuery,
  useGetALlSubjectsApiMutation,
  useDeleteTestingMutation,
  useEditTestingMutation,
} = TestingApi;

export const getTestingSolution = async (
  selectedFile: any[],
  type: number,
  sub_category: number
) => {
  console.log("YYYYYYYYYY>>>>>>>", selectedFile, type);

  const formData = new FormData();
  console.log(selectedFile, "selectedFile in form");
  console.log(type, "type in form ");

  if (Array.isArray(selectedFile)) {
    for (let file = 0; file < selectedFile?.length; file++) {
      let fileToAppend = selectedFile[file];
      formData.append("file", fileToAppend as any);
    }
  } else {
    formData.append("file", selectedFile);
  }
  // formData.append('file_link', selectedFile);

  formData.append("type", String(type));
  formData.append("sub_category", String(sub_category));

  const getToken = (await getFromStorage(STORAGE_KEYS.token)) as string;
  const token = JSON.parse(getToken);

  let headers = {
    // 'Content-Type': 'multipart/form-data',
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const url = `${API_URL}${END_POINTS.mcqAndFlash}`;
  console.log(formData, "body of uploading");
  try {
    const res = await fetch(url, {
      method: "POST",
      headers,
      body: formData,
    });
    let response = await res.json();
    if (response?.status === 200) {
    }
    if (response?.status !== 200) {
      showError(response?.message || "Please check your network connection");
    }
    return response;
  } catch (error: any) {
    showError(
      error?.data?.message ||
        error?.data?.detail ||
        "Please check your network connection"
    );
    console.log(error, ">>>>>>>>>");
    return error;
  }
};
