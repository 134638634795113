import { translate } from "pdf-lib";
import React from "react";
import useTranslation from "../../hooks/Translation";

const SubscriptionPlanSection1 = () => {
  const translate = useTranslation();
  return (
    <>
      <section className="abut_us bg_color ">
        <div className="conta_iner">
          <div className="text_center hd_1">
            <h2>{translate.subscriptions.subscriptionPlans}</h2>
            <p>{translate.subscriptions.subscriptionPlansDescription}</p>
          </div>
        </div>
      </section>
    </>
  );
};
export default SubscriptionPlanSection1;
