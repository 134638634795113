import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import Features from "../../features";
import { showError } from "../../constants/toast";
import { useLazyGetTermsQuery } from "../../services/cms";
import Loader from "../../constants/Loader";
import HTMLReactParser from "html-react-parser";
import useTranslation from "../../hooks/Translation";

const TermsConditions = () => {
  const [getTerms] = useLazyGetTermsQuery();
  const [termsData, setTermsData] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchTerms = async () => {
    setIsLoading(true);
    try {
      const res = await getTerms({}).unwrap();
      if (res?.status === 200) {
        setTermsData(res?.data);
      }
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      showError(
        error?.data?.message || error?.message || "something went wrong"
      );
    }
  };

  useEffect(() => {
    fetchTerms();
  }, []);
  const translate = useTranslation();
  return (
    <div>
      <Loader isLoad={isLoading} />
      <Layout.Header />
      <section className="abut_us bg_color" id="about">
        <div className="conta_iner">
          <div className="text_center hd_1">
            <h2>{translate.global.termsConditions || "Terms & conditions"}</h2>
            <div
              style={{ textAlign: "left", fontSize: "22px" }}
              className="Bullet_added"
              // dangerouslySetInnerHTML={{
              //     __html: termsData?.[0]?.terms_condition,
              // }}
            >
              {termsData?.[0]?.terms_condition
                .trim()
                .replace("\n", "")
                .replace("<p></p>", "") ? (
                HTMLReactParser(termsData?.[0]?.terms_condition)
              ) : (
                <div style={{ width: "100%", textAlign: "center" }}>
                  {translate.settings.noTermsConditionsFound ||
                    " No Terms and Conditions Found"}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <Features.AboutSection4 />
      <Layout.Footer />
    </div>
  );
};
export default TermsConditions;
