import { toast } from "react-toastify";

export const showToast = (message: string,app?:boolean) => {
  toast.dismiss();
  if(app)
    toast.success(message ,{
      position:"bottom-right"
    });
    else
    toast.success(message)
};

export const showWarning = (message: string,app?:boolean) => {
  toast.dismiss();
  if(app)
    toast.warn(message ,{
      position:"bottom-right"
    });
    else
    toast.warn(message)
};

export const showError = (message: string,app?:boolean) => {
  toast.dismiss();
  if(app)
  toast.error(message ,{
    position:"bottom-right"
  });
  else
  toast.error(message)
};
