import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import useTranslation from "../hooks/Translation";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function AboutMe(props: any) {
  const navigate = useNavigate();
  const translate = useTranslation();

  return (
    <>
      <React.Fragment>
        <BootstrapDialog
          className="modl"
          onClose={props.handleClose}
          aria-labelledby="customized-dialog-title"
          open={props.open}
        >
          <IconButton
            aria-label="close"
            onClick={props.handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <p>{translate.noteTaking.selectNoteTakingType}</p>
            <ul className="tst_lst">
              <li>
                <figure>
                  <img src="/static/images/bars.png" alt=""></img>
                </figure>
                <Button
                  className="btnn btn_primary"
                  onClick={() => {
                    navigate("/note-taking-edit");
                  }}
                >
                  {translate.noteTaking.createNewNote}
                </Button>
              </li>
              <li>
                <figure>
                  <img src="/static/images/voice.png" alt=""></img>
                </figure>
                <Button
                  className="btnn btn_primary"
                  onClick={() => {
                    if (props?.testing) {
                      props?.setOpen(false);
                      props?.setDragOpen(true);
                    } else navigate("/edit-note");
                  }}
                >
                  {translate.noteTaking.writeAboutMe}
                </Button>
              </li>
            </ul>
          </DialogContent>
          <DialogActions></DialogActions>
        </BootstrapDialog>
      </React.Fragment>
    </>
  );
}
export default AboutMe;
