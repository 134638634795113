import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import { usePostContactSupportMutation } from "../../services/cms";
import * as Yup from "yup";
import { useFormik } from "formik";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { showError, showToast } from "../../constants/toast";
import Loader from "../../constants/Loader";
import useTranslation from "../../hooks/Translation";

const HomeSection5 = () => {
  const [contactUs] = usePostContactSupportMutation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const translate = useTranslation();

  const formik = useFormik({
    initialValues: {
      email: "",
      fullname: "",
      query: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required(translate.validations.requiredEmail || "Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          translate.validations.enterValidEmail || "Please enter a valid email"
        )
        .max(
          80,
          translate.validations.emailLessThan80 ||
          "Email must be 80 characters or fewer"
        ),
      fullname: Yup.string()
        .required(translate.validations.requiredName || "Name is required")
        .max(
          50,
          translate.validations.nameLessThan50 ||
          "Name must be 50 characters or fewer"
        ),

      query: Yup.string()
        .max(
          200,
          translate.validations.messageLessThan200 ||
          "Message must be 200 characters or fewer"
        )
        .required(
          translate.validations.requiredMessage || "Message is required"
        ),
    }),

    onSubmit: async (values, { resetForm }) => {
      formik.setSubmitting(true);

      let body = {
        fullname: formik.values.fullname,
        query: formik.values.query,
        email: formik.values.email,
      };
      setIsLoading(true);
      console.log("body for contact us", body);
      try {
        let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response = await contactUs(encryptedBody).unwrap();
        setIsLoading(false);
        if (response?.status === 200) {
          showToast("Response Submitted");
        }
        resetForm();
      } catch (error: any) {
        setIsLoading(false);
        showError(error?.data?.message || error?.data?.message || "");
      }
    },
  });

  return (
    <>
      <Loader isLoad={isLoading} />
      <section className="cunt_us u_spc">
        <div className="conta_iner">
          <div className="cunt_flx">
            <div className="cunt_lt hd_1">
              <h2>{translate.contact.contactUs}</h2>
              <p>
                {translate.contact.contactUsHomepageLine1}
                <span className="d_block">
                  {translate.contact.contactUsHomepageLine2}
                </span>{" "}
                {translate.contact.contactUsHomepageLine3}
              </p>
              <figure>
                <img src="/static/images/cntc_us.png" alt=""></img>
              </figure>
            </div>
            <div className="cunt_rt">
              <p>
                <small>{translate.contact.feelFreeToContactUs}</small>
              </p>
              <form className="frmn mt_40" onSubmit={formik.handleSubmit}>
                <div className="frmn_mn">
                  <TextField
                    className="control_group"
                    hiddenLabel
                    placeholder={translate.global.name || "Name"}
                    fullWidth
                    variant="outlined"
                    inputProps={{ maxLength: 50 }}
                    name="fullname"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.fullname}
                    helperText={
                      formik.touched.fullname && formik.errors.fullname
                    }
                    error={
                      formik.touched.fullname && Boolean(formik.errors.fullname)
                    }
                  />
                </div>
                <div className="frmn_mn">
                  <TextField
                    className="control_group"
                    hiddenLabel
                    placeholder={translate.global.email || "Email"}
                    fullWidth
                    variant="outlined"
                    inputProps={{ maxLength: 50 }}
                    name="email"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    helperText={formik.touched.email && formik.errors.email}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                  />
                </div>
                <div className="frmn_mn">
                  <TextField
                    className="control_group"
                    hiddenLabel
                    placeholder={translate.global.message || "Message"}
                    fullWidth
                    variant="outlined"
                    inputProps={{ maxLength: 30 }}
                    name="query"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.query}
                    helperText={formik.touched.query && formik.errors.query}
                    error={formik.touched.query && Boolean(formik.errors.query)}
                  />
                </div>
                <Button type="submit" className="smbt_btn">
                  {translate.global.submit || "Submit"}
                </Button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default HomeSection5;
