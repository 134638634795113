import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import { useState } from "react";
import {
  usePostSendOtpToNewMailMutation,
  usePostVerifyEmailMutation,
} from "../services/auth";
import { showError, showToast } from "../constants/toast";
import { generateEncryptedKeyBody } from "../utils/crypto";
import { CommonBody } from "../types/General";
import useTranslation from "../hooks/Translation";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

type props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  email: string;
  setVerified: React.Dispatch<React.SetStateAction<any>>;
};

function VerifyEmail({ open, setOpen, email, setVerified }: props) {
  const translate = useTranslation();
  const [countDown, setCountDown] = useState<number>(28);
  const [otp, setOtp] = useState("");
  const [sendOtp] = usePostSendOtpToNewMailMutation();
  const [getVerified] = usePostVerifyEmailMutation();

  const getOtp = async () => {
    const body = {
      email: email,
    };
    try {
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const res = await sendOtp(encryptedBody).unwrap();
    } catch (error: any) {
      showError(error?.data?.message || error?.message || translate.errors.somethingWentWrong);
    }
  };

  const getVerifyEmail = async () => {
    if (otp?.length < 4) {
      showError(translate.auth.enterValidOTP || "Please enter a valid otp");
      return;
    }
    const body = {
      otp: otp,
    };
    try {
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const res = await getVerified(encryptedBody).unwrap();
      if (res?.status === 200) {
        setOpen(false);
        setVerified(true);
        showToast(translate.auth.emailVerified || "Email Verified");
      }
    } catch (error: any) {
      showError(error?.data?.message || error?.data?.message || translate.errors.somethingWentWrong);
    }
  };

  React.useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => {
        setCountDown(countDown - 1);
      }, 1000);
    } else {
      setCountDown(0);
    }
  }, [countDown]);

  React.useEffect(() => {
    if (open) {
      setCountDown(28);
      setOtp("");
    }
  }, [open]);

  return (
    <>
      <React.Fragment>
        <BootstrapDialog
          className="modl log_mdl"
          onClose={() => setOpen(false)}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>

          <DialogContent dividers>
            <div className="logn_rt hd_3 vrfy">
              <h4>{translate.auth.otpVerification}</h4>
              <p>
                {" "}
                <small>
                  {" "}
                  <span className="d_block">
                    {translate.auth.codeSentToEmail}
                  </span>
                  <span className="verify_email"> {email} </span>
                </small>
              </p>
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                shouldAutoFocus
                renderInput={(props) => <input {...props} />}
                inputType="tel"
              />
              <br />

              <div className="tmr">
                <p>
                  {" "}
                  {countDown === 0 ? (
                    ""
                  ) : (
                    <p style={{ cursor: "pointer" }}>
                      <span
                        style={{
                          margin: 0,
                          color: "#D63853",
                          paddingLeft: 3,
                          cursor: "pointer",
                        }}
                      >
                        00 : {countDown < 10 ? `0${countDown}` : countDown}
                      </span>
                    </p>
                  )}
                </p>
                <p>
                  {translate.auth.codeNotReceived}
                  <span
                    className="txt_clr d_block"
                    onClick={() => {
                      getOtp();
                      setCountDown(28);
                    }}
                  >
                    {countDown === 0 ? translate.auth.resend : ""}
                  </span>
                </p>
              </div>
              <Button
                type="button"
                className="btn btn_primay btnn lrg"
                onClick={getVerifyEmail}
              >
                {translate.global.submit}
              </Button>
            </div>
          </DialogContent>
        </BootstrapDialog>
      </React.Fragment>
    </>
  );
}
export default VerifyEmail;
