import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import { Button, IconButton, InputAdornment } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import "react-tabs/style/react-tabs.css";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useAppDispatch } from "../../hooks/store";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  getFromStorage,
  removeFromStorage,
  setToStorage,
} from "../../constants/storage";
import { STORAGE_KEYS } from "../../constants/storageKeys";
import { showError, showToast } from "../../constants/toast";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { usePostLoginMutation } from "../../services/auth";
import { setCredentials } from "../../reducers/authSlice";
import useAuth from "../../hooks/useAuth";
import Loader from "../../constants/Loader";
import useTranslation from "../../hooks/Translation";

const SignIn = () => {
  const translate = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loginApi] = usePostLoginMutation();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: false,
    },
    validationSchema: Yup.object({
      remember: Yup.boolean(),
      email: Yup.string()
        .required(translate.validations.requiredEmail || "Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          translate.validations.enterValidEmail ||
            "Enter a valid email address!"
        ),
      password: Yup.string().required(
        translate.validations.requirePassword || "Password is required"
      ),
      // .min(6, "Password should be min 6 letters"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      // const fcmtoken = getFromStorage(STORAGE_KEYS.fcmToken);
      let body = {
        email: formik.values.email,
        password: formik.values.password,
        // role: 2,
      };
      setIsLoading(true);
      console.log(body, "body for login");
      try {
        let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response = await loginApi(encryptedBody).unwrap();
        setIsLoading(false);
        if (response?.status === 200) {
          setToStorage(
            STORAGE_KEYS.token,
            JSON.stringify(response?.data?.token) || null
          );
          dispatch(
            setCredentials({
              user: response?.data || null,
              token: response?.data?.token || null,
            })
          );
          if (formik.values.remember) {
            setToStorage(STORAGE_KEYS.credentials, JSON.stringify(body));
          } else {
            removeFromStorage(STORAGE_KEYS.credentials);
          }
          showToast(
            translate.toastMessages.LoginSuccessfully ||
              "Logged in successfully"
          );
          response?.data?.profile_status === 3
            ? navigate("/testing")
            : navigate("/profile");
        }
      } catch (error) {
        showError(translate.errors.invalidCredentials || "Invalid credentials");
        setIsLoading(false);
      } finally {
        formik.setSubmitting(false);
        setIsLoading(false);
      }
    },
  });

  function testJSON(text: string) {
    if (typeof text !== "string") {
      return false;
    }
    try {
      JSON.parse(text);
      return true;
    } catch (error) {
      return false;
    }
  }

  useEffect(() => {
    const data = getFromStorage(STORAGE_KEYS.credentials);
    if (data) {
      const rememberData =
        testJSON(data as string) && JSON.parse(data as string);
      formik.setFieldValue("password", rememberData?.password);
      formik.setFieldValue("email", rememberData?.email);
      formik.setFieldValue("remember", true);
    }
  }, []);

  return (
    <div>
      <Loader isLoad={isLoading} />
      <section className="bnr_sec c_white sign_in">
        <div className="conta_iner">
          <div className="bnr_mn">
            <div className="bnr_lt">
              <figure className="logo">
                <img
                  src="/static/images/logo.svg"
                  alt=""
                  onClick={() => navigate("/")}
                ></img>
              </figure>
              <h1>
                {translate.home.let}
                <span className="ai_bdr">
                  {translate.home.ai}
                  <span></span>
                </span>{" "}
                <span className="d_block"> {translate.home.doYourWork}</span>
              </h1>
              <p>
                {translate.home.homeMessageLine1}
                <span className="d_block">
                  {translate.home.homeMessageLine2}
                </span>{" "}
                {translate.home.homeMessageLine3}
              </p>
            </div>
            <div className="logn_rt hd_3">
              <h3> {translate.auth.signIn}</h3>
              <form className="frmn frm_sn" onSubmit={formik.handleSubmit}>
                <div className="frmn_mn">
                  <label>{translate.auth.emailId}</label>
                  <TextField
                    className="control_group"
                    hiddenLabel
                    fullWidth
                    placeholder={
                      translate.auth.enterEmailAddress || "Enter Email Address"
                    }
                    variant="outlined"
                    name="email"
                    type="email"
                    id="email"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </div>
                <div className="frmn_mn ">
                  <label>{translate.auth.password}</label>
                  <TextField
                    className="control_group"
                    hiddenLabel
                    fullWidth
                    variant="outlined"
                    placeholder={
                      translate.auth.enterPassword || "Enter Password"
                    }
                    name="password"
                    id="password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                    type={showPassword ? "text" : "password"}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment className="eye_btn" position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={(event) => event.preventDefault()}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <i className="fa-regular fa-eye"></i>
                </div>
                <div className="frgt">
                  <FormGroup className="checkbox_label">
                    <FormControlLabel
                      control={<Checkbox />}
                      label={translate.auth.rememberMe || "Remember me"}
                      checked={formik.values.remember}
                      name="remember"
                      onChange={formik.handleChange}
                    />
                  </FormGroup>
                  <p onClick={() => navigate("/forgot")}>
                    {" "}
                    <small>
                      {" "}
                      {translate.auth.forgotPassword || "Forgot Password"}
                    </small>
                  </p>
                </div>
                <Button type="submit" className="btn btn_primay btnn lrg">
                  {translate.auth.signIn}
                </Button>
              </form>
              {/* <p className="sng_nmbr">Sign in with Number</p> */}
              <p className="lne">
                <small>{translate.global.or}</small>
              </p>
              <ul className="lgn_icn">
                <li>
                  <figure>
                    <img src="/static/images/facebook.png" alt=""></img>
                  </figure>
                </li>
                <li>
                  <figure>
                    <img src="/static/images/gogle.png" alt=""></img>
                  </figure>
                </li>
                <li>
                  <figure>
                    <img src="/static/images/apple.png" alt=""></img>
                  </figure>
                </li>
                <li>
                  <figure>
                    <img src="/static/images/x-social.png" alt=""></img>
                  </figure>
                </li>
              </ul>
              <p className="sgn_cnt" onClick={() => navigate("/signup")}>
                {translate.auth.DoNotHaveAnAccount || "Don’t have an account?"}{" "}
                <span>{translate.auth.signUp}</span>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default SignIn;
