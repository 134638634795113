/* eslint-disable react/jsx-pascal-case */
import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Pages from "./pages";
import { getFromStorage, setToStorage } from "./constants/storage";
import { STORAGE_KEYS } from "./constants/storageKeys";
import { setCredentials } from "./reducers/authSlice";
import { useAppDispatch } from "./hooks/store";

const Routing = () => {
  const dispatch = useAppDispatch();

  const params = new URLSearchParams(window.location.search);
  const paramToken = params.get("token");

  useEffect(() => {
    if (paramToken) {
      setToStorage(STORAGE_KEYS.token, JSON.stringify(paramToken));
      const user = getFromStorage(STORAGE_KEYS.user) as any;
      dispatch(
        setCredentials({
          user: user ? JSON.parse(user) : null,
          token: paramToken ? paramToken : null,
        })
      );
    }
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Pages.Homepage />} />
      <Route path="/about" element={<Pages.Aboutpage />} />
      <Route path="/privacy-policy" element={<Pages.PrivacyPolicy />} />
      <Route path="/terms-conditions" element={<Pages.TermsConditions />} />
      <Route path="/subscription" element={<Pages.Subscriptionpage />} />
      <Route path="/contact" element={<Pages.Contactpage />} />
      <Route path="/testing" element={<Pages.TestingPage />} />
      <Route path="/faq" element={<Pages.Faqpage />} />
      <Route path="/work" element={<Pages.Workpage />} />
      <Route path="/login" element={<Pages.Login />} />
      <Route path="/signin" element={<Pages.SignIn />} />
      <Route path="/signup" element={<Pages.SignUp />} />
      <Route path="/profile" element={<Pages.Profile />} />
      <Route path="/forgot" element={<Pages.Forgot />} />
      <Route path="/verify" element={<Pages.Verify />} />
      <Route path="/reset" element={<Pages.Reset />} />
      <Route path="/paymentoption" element={<Pages.PaymentOption />} />
      <Route path="/congratulation" element={<Pages.Congratulation />} />
      <Route
        path="/whizzosubscription"
        element={<Pages.WhizzoSubscription />}
      />
      <Route
        path="/subscription-plan"
        element={<Pages.SubscriptionPlanPage />}
      />
      <Route
        path="/file-summarization"
        element={<Pages.FileSummarizationPage />}
      />
      <Route path="/note-taking" element={<Pages.NoteTakingPage />} />
      <Route path="/note-create" element={<Pages.NoteCreate />} />
      <Route path="/research" element={<Pages.Research />} />
      <Route
        path="/assignment-solution"
        element={<Pages.AssignmentSolutions />}
      />
      <Route path="/file-type" element={<Pages.FileType />} />
      <Route path="/quiz/" element={<Pages.Quiz />} />
      <Route path="/quiz/:id" element={<Pages.Quiz />} />
      <Route path="/flashcard-quiz/" element={<Pages.FlashCardQuiz />} />`
      <Route path="/flashcard-quiz/:id" element={<Pages.FlashCardQuiz />} />
      ``
      <Route path="/presentations" element={<Pages.Presentations />} />
      <Route
        path="/creatpresentations"
        element={<Pages.Creatpresentations />}
      />
      <Route path="/artical" element={<Pages.Artical />} />
      <Route path="/createartical" element={<Pages.CreateArtical />} />
      <Route path="/language" element={<Pages.SelectLanguage />} />
      <Route path="/editartical" element={<Pages.EditArtical />} />
      <Route path="/selecttons" element={<Pages.SelectTons />} />
      <Route path="/pronouns" element={<Pages.Pronouns />} />
      <Route path="/subscriptions" element={<Pages.Subscriptions />} />
      <Route
        path="/presentation-preview"
        element={<Pages.PresentationPreview />}
      />
      <Route
        path="/presentation-edit/:id"
        element={<Pages.PresentationEdit />}
      />
      <Route path="/presentation-down" element={<Pages.PresentationDown />} />
      <Route path="/setting" element={<Pages.Setting />} />
      <Route path="/assign-solution" element={<Pages.Assignsolution />} />
      <Route path="/topic-research" element={<Pages.TopicResearch />} />
      <Route path="/research-page" element={<Pages.ResearchPages />} />
      <Route path="/convert" element={<Pages.Convert />} />
      <Route path="/test-ai" element={<Pages.TestAi />} />
      <Route path="/edit-assign/" element={<Pages.EditAssignment />} />
      <Route path="/edit-assign/:id" element={<Pages.EditAssignment />} />
      <Route path="/reserch-tone" element={<Pages.ReserchTons />} />
      <Route path="/refernce" element={<Pages.SpecifyReference />} />
      <Route path="/edit-note" element={<Pages.EditNote />} />
      <Route path="/edit-note/:id" element={<Pages.EditNote />} />
      <Route path="/edit-file" element={<Pages.EditFile />} />
      <Route path="/edit-file/:id" element={<Pages.EditFile />} />
      <Route path="/edit-article/:id" element={<Pages.EditArticle />} />
      <Route path="/edit-article" element={<Pages.EditArticle />} />
      <Route path="/file-convert" element={<Pages.FileCovert />} />
      <Route path="/assign-video" element={<Pages.AssignmentVideo />} />
      <Route path="/artical-edit" element={<Pages.ArticalEdit />} />
      <Route path="/reserch-edit/" element={<Pages.ResearchEdit />} />
      <Route path="/reserch-edit/:id" element={<Pages.ResearchEdit />} />
      <Route path="/reserch-edit2" element={<Pages.EditResearch2 />} />
      <Route path="/reserch-edit2/:id" element={<Pages.EditResearch2 />} />
      <Route path="/note-edit" element={<Pages.NoteEdit />} />
      <Route path="/note-create" element={<Pages.NoteCreate />} />
      <Route path="/note-taking-edit" element={<Pages.NoteTakingEditFirst />} />
      <Route
        path="/note-taking-edit/:id"
        element={<Pages.NoteTakingEditFirst />}
      />
      <Route path="/pdfPreview" element={<Pages.PdfPreview />} />
      <Route
        path="/presentation-edit2/:id"
        element={<Pages.PresentationEditor />}
      />
    </Routes>
  );
};

export default Routing;
