import { MoreVert } from "@mui/icons-material";
import { Tooltip, IconButton, Menu, MenuItem } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslateAssignmentMutation } from "../services/assignmentModule";
import useTranslation from "../hooks/Translation";

export const FolderListComponent = ({
  dataName,
  dataCreatedAt,
  index,
  handleDelete,
  handleEdit,
  nextPage,
  isEdit,
  newClick,
}: {
  dataName?: any;
  dataCreatedAt?: any;
  index: number;
  handleDelete?: any;
  handleEdit?: any;
  nextPage?: any;
  isEdit?: boolean;
  newClick?: any;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open0 = Boolean(anchorEl);
  const translate = useTranslation();
  const handleClick0 = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose0 = (event: any) => {
    event.stopPropagation();

    setAnchorEl(null);
  };
  const navigate = useNavigate();
  return (
    <li
      onClick={() => {
        nextPage ? navigate(nextPage) : newClick();
      }}
      style={{ cursor: "pointer" }}
    >
      <Tooltip title={dataName ? dataName : "File"}>
        <h3>
          {dataName
            ? dataName.length > 10
              ? dataName?.slice(0, 10) + "..."
              : dataName
            : translate.global.file}
        </h3>
      </Tooltip>
      {/* <h3>My File {(page - 1) * 20 + index + 1}</h3> */}
      <p className="mt_40">{dataCreatedAt?.slice(0, 10)}</p>
      {/* <div> */}
      <IconButton
        aria-label="more"
        id="demo-positioned-button"
        aria-controls={open0 ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open0 ? "true" : undefined}
        onClick={handleClick0}
        style={{
          position: "absolute",
          top: "25px",
          right: 0,
        }}
      >
        <MoreVert htmlColor="white" />
      </IconButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open0}
        onClose={handleClose0}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {isEdit ? (
          <MenuItem
            onClick={(e) => {
              setAnchorEl(null);
              handleEdit(e);
            }}
          >
            {translate.global.edit}
          </MenuItem>
        ) : null}
        <MenuItem
          onClick={(e) => {
            setAnchorEl(null);
            handleDelete(e);
          }}
        >
          {translate.global.delete}
        </MenuItem>
      </Menu>
      {/* </div> */}
    </li>
  );
};
