import React, { useCallback, useEffect, useRef, useState } from "react";
import Layout from "../../layout/index";
import "react-tabs/style/react-tabs.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Button, Checkbox, FormControlLabel, FormGroup, IconButton, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AboutMe from "../../components/aboutMe";
import Notification from "../../components/notification";
import useAuth from "../../hooks/useAuth";
import Header from "../../layout/header/Header";
import {
  useEditNoteTakingMutation,
  useGetNoteTakingMutation,
  useNoteTakingActionsMutation,
} from "../../services/noteTaking";
import { showError, showToast } from "../../constants/toast";
import { CommonBody } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import Pagination from "../../components/pagination";
import Dictaphone from "../../components/recording";
import SpeechToText from "../../components/speechText";
import Loader from "../../constants/Loader";
import { Edit, Star } from "@mui/icons-material";
import useTranslation from "../../hooks/Translation";
import { RenameModal } from "../../components/renameModal";

// const SelectedId:any=[];

const NoteTakingPage = () => {
  const translate = useTranslation();
  const navigate = useNavigate();
  const user = useAuth();
  const goProfile = () => {
    navigate("/setting");
  };
  const [open, setOpen] = React.useState<any>(false);
  const [recentNotes, setRecentNotes] = useState<any>([]);
  console.log("recentNotes :", recentNotes);
  const [selectedRecentNote, setSelectedRecentNote] = useState<string | number>(
    ""
  );
  console.log("selectedRecentNote :", selectedRecentNote);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [sidebar, setSidebar] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [recentCount, setRecentCount] = useState(0);
  const [FavoriteCount, setFavoriteCount] = useState(0);
  const [page, setPage] = useState(1);
  const [NoteTaking, setNoteTaking] = useState<any[]>([]);
  const [selectedId, setSelectedId] = useState<any[]>([]);
  const totalPages = Math.ceil(totalCount / 20);
  const hasMore = page <= totalPages;
  const pageRef = useRef(page);
  const [editMode, setEditMode] = useState(false);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // };

  const [getNoteTaking] = useGetNoteTakingMutation();
  const getNoteTakingList = async (page: number) => {
    setIsLoading(true);
    try {
      let body = {};
      if (selectedRecentNote) {
        body = {
          start: page,
          length: 20,
          search: "",
          filter: selectedRecentNote,
        };
      } else {
        body = {
          start: page,
          length: 20,
          search: "",
        };
      }
      console.log("body :", body);
      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await getNoteTaking(encryptedBody).unwrap();
      if (response?.status === 200) {
        setNoteTaking(response?.data?.response_object);
        if (!selectedRecentNote) {
          setRecentNotes(response?.recent_notes);
          setFavoriteCount(response?.favourite_notes_count);
          setRecentCount(response?.recent_notes_count);
          setTotalCount(response?.data?.total_records);
        }
      } else {
        setNoteTaking([]);
      }
    } catch (error: any) {
      showError(
        error?.data?.message || translate.errors.somethingWentWrong || ""
      );
    }
    setIsLoading(false);
  };
  const [getNoteTakingAction] = useNoteTakingActionsMutation();
  const getNoteTakingActions = async (type: number, records: any[]) => {
    if (!records.length) {
      showError(
        translate.errors.selectAtleastOneNote || "Select atleast one note"
      );
      return;
    }
    setIsLoading(true);

    try {
      const body = {
        type: type,
        record_ids: records,
      };
      console.log("body :", body);
      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await getNoteTakingAction(encryptedBody).unwrap();
      if (response?.status === 200) {
        if (type == 1) {
          showToast(
            translate.toastMessages.recordsDuplicatedSuccessfully ||
            "Records duplicated successfully"
          );
        } else if (type == 2) {
          showToast(
            translate.toastMessages.recordsUpdatedSuccessfully ||
            "Records updated successfully"
          );
        } else if (type == 3) {
          showToast(
            translate.toastMessages.recordsDeletedSuccessfully ||
            "Records deleted successfully"
          );
        } else {
          showError(
            translate.errors.somethingWentWrong || "Something went wrong"
          );
        }
        setSelectedId([]);
        setEditMode(false);
      }
    } catch (error: any) {
      showError(
        error?.data?.message || translate.errors.somethingWentWrong || ""
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getNoteTakingList(page);
  }, [page, selectedRecentNote]);

  console.log("selectedId :", selectedId);

  const handleCheckBoxChange = (id: string) => {
    if (selectedId?.some((data: any) => data == id)) {
      setSelectedId((prev: any) => {
        return prev.filter((data: any) => data != id);
      });
    } else {
      setSelectedId([...selectedId, id]);
    }
  };
  useEffect(() => {
    if (!editMode) {
      setSelectedId([]);
    }
  }, [editMode]);
  const [renameValue, setRenameValue] = useState("");
  const [openEdit, setOpenEdit] = useState(false);
  const [editData] = useEditNoteTakingMutation();
  const [selectedEditId, setSelectedEditId] = useState(0);
  const toggleEdit = (id: number, text: any) => {
    setOpenEdit(true);
    setRenameValue(text);
    setSelectedEditId(id);
  };
  const handleEdit = async (e: any) => {
    e.stopPropagation();
    try {
      const response = await editData({
        id: selectedEditId,
        body: { file_name: renameValue },
      }).unwrap();
      if (response.status == 200) {
        showToast(
          translate.toastMessages.noteNameUpdatedSuccessfully ||
          "Note name updated Successfully"
        );
        setOpenEdit(false);
        setRenameValue("");
      }
    } catch (error: any) {
      console.log(error);
      showError(
        error?.data?.message ||
        translate.errors.somethingWentWrong ||
        "Something went wrong"
      );
    } finally {
      await getNoteTakingList(page)
    }
  };
  return (
    <>
      <div className="sdbr_rltv">
        <Layout.SidePanel />
        <Header setSidebar={setSidebar} />
        <Loader isLoad={isLoading} />
        <div className="sde_br_mn nt_tk">
          {/* <div className="sde_hdr">
          <div className="sde_hdr_lt ">
            <figure>
              <img src="/static/images/note-taking.png" alt=""></img>
            </figure>
            <div className="">
              <h3>Note Taking</h3>
              <p> Lorem Ipsum dolor et amet</p>
            </div>
          </div>
          <div className="sde_hdr_rt">
            <figure onClick={() => setOpen1(true)}>
              <img src="/static/images/notification.png" alt=""></img>
            </figure>
            <h3>Hi, Ahmed</h3>
            <figure onClick={goProfile}>
              <img src="/static/images/ahmed.png" alt=""></img>
            </figure>
          </div>
        </div> */}
          <div className="sde_mn_cnt nt_sde_mn">
            <div className="edt_nt">
              <button className="btnn btn_border" onClick={() => setOpen(true)}>
                <i className="fa-regular fa-plus"></i>{" "}
                {translate.noteTaking.createNewNote || "Create a new Note"}
              </button>
              <Button
                className="btnn btn_primary sm "
                disabled={!NoteTaking.length}
                onClick={() => {
                  // if(editMode)
                  // setSelectedId([]);
                  setEditMode(!editMode);
                }}
              >
                {editMode
                  ? translate.global.done || "Done"
                  : translate.noteTaking.editNotes || "Edit Notes"}
              </Button>
            </div>
            <Tabs>
              <TabList>
                <Tab style={{ cursor: "context-menu" }}>
                  {translate.noteTaking.myPastNotes || "My Past Notes"}
                </Tab>
              </TabList>

              <TabPanel>
                <div className="nt_lst mt_40">
                  <ul className="nt_lt">
                    <li
                      className={
                        selectedRecentNote == "" ? "active nt_img" : "nt_img"
                      }
                      onClick={() => {
                        setEditMode(false);
                        setSelectedRecentNote("");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <figure>
                        <img src="/static/images/doc.png" alt=""></img>
                      </figure>
                      <p>{translate.noteTaking.allNotes || "All Notes"}</p>
                      <span>{totalCount}</span>
                    </li>
                    <li
                      className={
                        selectedRecentNote == "2" ? "active nt_img" : "nt_img"
                      }
                      onClick={() => {
                        setEditMode(false);
                        setSelectedRecentNote(2);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <figure>
                        <img src="/static/images/doc.png" alt=""></img>
                      </figure>
                      <p>
                        {translate.noteTaking.recentNotes || "Recent notes"}
                      </p>
                      <span>{recentCount}</span>
                    </li>
                    <li
                      className={
                        selectedRecentNote == "1" ? "active nt_img" : "nt_img"
                      }
                      onClick={() => {
                        setEditMode(false);
                        setSelectedRecentNote(1);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <figure>
                        <img src="/static/images/doc.png" alt=""></img>
                      </figure>
                      <p>{translate.noteTaking.favorite || "Favorite"}</p>
                      <span>{FavoriteCount}</span>
                    </li>
                    {/* <li className="nt_img">
                      <figure>
                        <img src="/static/images/clock.png" alt=""></img>
                      </figure>
                      <p>clock</p>
                      <span>50</span>
                    </li> */}
                    {/* <li className="nt_img">
                      <figure>
                        <img src="/static/images/unif.png" alt=""></img>
                      </figure>
                      <p>Unified</p>
                      <span>30</span>
                    </li> */}
                    {recentNotes?.length ? (
                      recentNotes?.map((data: any) => {
                        return (
                          <li
                            // className="active nt_dot"
                            className={
                              selectedRecentNote == data?.title
                                ? "active nt_dot"
                                : "nt_dot"
                            }
                            onClick={() => {
                              setEditMode(false);
                              setSelectedRecentNote(data?.title);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <p>{data?.title}</p>
                            <span>{data?.count}</span>
                          </li>
                        );
                      })
                    ) : (
                      <li className="nt_dot">
                        <p>
                          {translate.noteTaking.noRecentNotes ||
                            "No Recent Notes"}
                        </p>
                        {/* <span>{data?.count}</span> */}
                      </li>
                    )}
                    {/* <li className="nt_dot">
                      <p>Note 22 April</p>
                      <span>20</span>
                    </li>
                    <li className="nt_dot">
                      <p>Note 22 April</p>
                      <span>20</span>
                    </li>
                    <li className="nt_dot">
                      <p>Note 22 April</p>
                      <span>20</span>
                    </li>
                    <li className="nt_dot">
                      <p>Note 22 April</p>
                      <span>20</span>
                    </li>
                    <li className="nt_dot">
                      <p>Note 22 April</p>
                      <span>20</span>
                    </li>
                    <li className="nt_dot">
                      <p>Note 22 April</p>
                      <span>20</span>
                    </li>
                    <li className="nt_dot">
                      <p>Note 22 April</p>
                      <span>20</span>
                    </li> */}
                  </ul>
                  <ul className="nt_rt hd_6 text_center">
                    {NoteTaking?.length ? (
                      NoteTaking?.map((data: any, index: number) => {
                        return (
                          <li
                            style={{
                              position: "relative",
                              paddingBottom: "45px",
                              cursor: "pointer",
                            }}
                          >
                            {editMode ? (
                              <FormGroup className="checkbox_label">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      onChange={() =>
                                        handleCheckBoxChange(data?.id)
                                      }
                                      checked={selectedId?.some(
                                        (data2: any) => data2 == data?.id
                                      )}
                                    />
                                  }
                                  label=""
                                />
                              </FormGroup>
                            ) : null}
                            <div
                              style={{
                                position: "absolute",
                                bottom: 5,
                                left: "10%",
                              }}
                            >
                              {data?.is_favourite && <Star color="warning" />}
                            </div>
                            <figure
                              onClick={() =>
                                editMode
                                  ? handleCheckBoxChange(data?.id)
                                  : navigate(
                                    (data?.type == 1
                                      ? "/note-taking-edit/"
                                      : "/edit-note/") + data?.id
                                  )
                              }
                            >
                              <img
                                src={
                                  data?.note_screenshot
                                    ? JSON.parse(data?.note_screenshot)
                                    : "/static/images/agrement.png"
                                }
                                alt=""
                              ></img>
                            </figure>
                            <span style={{ display: "flex", width: "100%" }}>

                              <Tooltip title={data?.file_name ? data?.file_name : translate.noteTaking.notes || "Note"}>
                                <h3
                                  style={{
                                    position: "absolute",
                                    bottom: 10,
                                    margin: 0,
                                    padding: 0
                                  }}
                                >
                                  {data?.file_name
                                    ? data?.file_name.length > 10
                                      ? data?.file_name?.slice(0, 10) + "..."
                                      : data?.file_name
                                    : translate.noteTaking.notes}
                                </h3>
                              </Tooltip>
                              <IconButton onClick={(e) => {
                                toggleEdit(data?.id, data?.file_name)
                              }}
                                style={{
                                  position: "absolute", margin: 0, background: "#696868",
                                  padding: 3, right: 10, bottom: 10, borderRadius: "50%"
                                }}> <Edit htmlColor="white" /></IconButton>
                            </span>
                          </li>
                        );
                      })
                    ) : (
                      <div
                        style={{
                          height: "85px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                          marginTop: "15%",
                        }}
                      >
                        {" "}
                        <h3
                          style={{ fontSize: "xxx-large", color: "darkgrey" }}
                        >
                          {translate.global.noRecordsFound ||
                            "No Records Found"}
                        </h3>
                      </div>
                    )}
                    {/* <li>
                      <figure>
                        <img src="/static/images/agrement.png" alt=""></img>
                      </figure>
                      <h3>Note 24 April ...</h3>
                    </li>
                    <li>
                      <figure>
                        <img src="/static/images/agrement.png" alt=""></img>
                      </figure>
                      <h3>Note 24 April ...</h3>
                    </li> */}
                  </ul>
                </div>
                <Pagination
                  module={NoteTaking}
                  onPageChange={onPageChange}
                  page={page}
                  totalPages={totalPages}
                />
              </TabPanel>
            </Tabs>
            {editMode ? (
              <div className="prfl_btn">
                <Button
                  className="btnn h_46"
                  onClick={async () => {
                    await getNoteTakingActions(1, selectedId);
                    await getNoteTakingList(page);
                  }}
                >
                  <img src="/static/images/dublicate.png" alt=""></img>Duplicate
                </Button>
                <Button
                  className="btnn h_46"
                  onClick={async () => {
                    await getNoteTakingActions(2, selectedId);
                    await getNoteTakingList(page);
                  }}
                >
                  <i className="fa-regular fa-star"></i> Favourite{" "}
                </Button>
                <Button
                  className="btnn h_46"
                  onClick={async () => {
                    await getNoteTakingActions(3, selectedId);
                    await getNoteTakingList(page);
                  }}
                >
                  <i className="fa-solid fa-trash-can"></i>Delete
                </Button>
                {/* <Button className="btnn h_46">Done</Button> */}
              </div>
            ) : null}
            {/* <Dictaphone/>
            <SpeechToText/> */}
          </div>
        </div>
      </div >
      <AboutMe open={open} setOpen={setOpen} handleClose={handleClose} />
      <Notification
        open={open1}
        setOpen={setOpen1}
        handleClose={handleClose1}
      />
      <RenameModal
        handleEdit={handleEdit}
        openEdit={openEdit}
        renameValue={renameValue}
        setOpenEdit={setOpenEdit}
        setRenameValue={setRenameValue}
        textData={
          translate.fileSummarization.fileSummarizationLowerCase ||
          "note"
        }
      />
    </>
  );
};
export default NoteTakingPage;
