import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useDownloadPdfMutation } from "../services/assignmentModule";
import { generateEncryptedKeyBody } from "../utils/crypto";
import { CommonBody } from "../types/General";
import { showToast } from "../constants/toast";
import useTranslation from "../hooks/Translation";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function Download2(props: any) {
  const translate = useTranslation();

  const handleDownload = (type: number) => {
    props.downloadPdf(type);
  };

  return (
    <>
      <React.Fragment>
        <BootstrapDialog
          className=" down_pop modl text_center"
          onClose={props.handleClose}
          aria-labelledby="customized-dialog-title"
          open={props.open}
        >
          <IconButton
            aria-label="close"
            onClick={props.handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers className="test_cmplt">
            <div className="modal_header">
              <button className="back_btn" onClick={() => props.setOpen(false)}>
                <figure>
                  <img src="/static/images/back_arrow.svg" alt="icon"></img>
                </figure>
                {translate.global.back}
              </button>

              <h2>
                {translate.global.download} {props?.label ? props?.label : ""}
              </h2>
            </div>
            <figure className="dwn_img">
              <img src="/static/images/smily_face.png" alt="icon"></img>
            </figure>
            <div className="btn_group">
              <Button onClick={() => handleDownload(1)}>
                {translate.global.changeLanguageDownload}
              </Button>
              <Button onClick={() => handleDownload(2)}>
                {translate.global.downloadPDF}
              </Button>
              <Button onClick={() => handleDownload(3)}>
                {translate.global.downloadFileWithSpecifyingImportantVocabulary}
              </Button>
            </div>
          </DialogContent>
          <DialogActions></DialogActions>
        </BootstrapDialog>
      </React.Fragment>
    </>
  );
}
export default Download2;
