import React from "react";
import Layout from "../../layout";

import Features from "../../features";


function Subscriptionpage() {

  return (
    <div>
      <Layout.Header />
      <Features.SubscriptionSection1 />

      <Features.SubscriptionSection2 />
      <Features.SubscriptionSection3 />

    
      <Layout.Footer />
    </div>
  );
};
export default Subscriptionpage;