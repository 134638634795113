import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import "react-tabs/style/react-tabs.css";
import {
  useLazyGetPurposeQuery,
  usePostProfileSetupMutation,
} from "../../services/auth";
import { useFormik } from "formik";
import * as Yup from "yup";
import CancelIcon from "@mui/icons-material/Cancel";
import "react-phone-input-2/lib/bootstrap.css";
import PhoneInput from "react-phone-input-2";
import useAuth from "../../hooks/useAuth";
import AddIcon from "@mui/icons-material/Add";
import { showError, showToast, showWarning } from "../../constants/toast";
import { getFromStorage, setToStorage } from "../../constants/storage";
import { STORAGE_KEYS } from "../../constants/storageKeys";
import { useDispatch } from "react-redux";
import { setCredentials, setToken } from "../../reducers/authSlice";
import { CommonBody } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { UploadMedia } from "../../utils/mediaUpload";
import Loader from "../../constants/Loader";
import useTranslation from "../../hooks/Translation";

const Profile = () => {
  const translate = useTranslation();

  const navigate = useNavigate();
  const userData = useAuth();
  const dispatch = useDispatch();
  const [imageId, setImageId] = useState<string>("");
  const [image, setImage] = useState<string>("");
  const [phoneCode, setPhoneCode] = useState("+1");
  const [purposeData, setPurposeData] = useState<any>();
  const [selectedPurpose, setSelectedPurpose] = useState<string>("");
  const [getPurpose] = useLazyGetPurposeQuery();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [updateProfile] = usePostProfileSetupMutation();
  const token = getFromStorage(STORAGE_KEYS?.token);
  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone_no", phone?.replace(country.dialCode, ""));
    setPhoneCode(country?.dialCode);
  };
  console.log(token, "token in profile set up ");
  console.log(selectedPurpose, "selectedPurpose");

  useLayoutEffect(() => {
    const token = getFromStorage(STORAGE_KEYS.token) as string;
    dispatch(setToken(JSON.parse(token)));
  }, []);

  const fetchPurpose = async () => {
    setIsLoading(true);
    try {
      const res = await getPurpose({}).unwrap();
      if (res?.status === 200) {
        setPurposeData(res?.data);
      }
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      showError(
        error?.message ||
        translate.errors.somethingWentWrong ||
        "Something went wrong"
      );
    }
  };
  console.log("purpose", purposeData);

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsLoading(true);
    const files = event.target.files;
    const file = files?.length ? files[0] : null;

    try {
      if (file) {
        const allowedExtensions = ["png", "jpg", "jpeg"];
        const fileExtension = file?.name.split(".").pop()?.toLowerCase();
        if (!allowedExtensions.includes(fileExtension || "")) {
          setIsLoading(false);
          showError(
            translate.errors.onlyJpgPngAllowed ||
            "Invalid file format: only png, jpg images are allowed"
          );
          return;
        }
      }

      const res = await UploadMedia(file);
      if (res?.status === 200) {
        console.log(res?.data[0]);
        setImage(res?.data[0]?.media_url);
        setImageId(res?.data[0]?.id);
        showToast(
          translate.toastMessages.ProfilePictureUploadedSuccessfully ||
          "Profile Picture uploaded successfully"
        );
      } else {
        showError(res?.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error uploading image:", error);
      showError(
        translate.errors.imageUploadError ||
        "Error uploading image. Please try again."
      );
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: userData?.first_name || "",
      last_name: userData?.last_name || "",
      email: userData?.email || "",
      purpose: userData?.purpose || "",
      profile_picture: image || "",
      phone_no: userData?.phone_no || "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required(translate.validations.requiredEmail || "Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          translate.validations.enterValidEmail || "Please enter a valid email"
        ),
      first_name: Yup.string()
        .required(translate.validations.requiredName || "Name is required")
        .min(
          2,
          translate.validations.minimumTwoCharacterRequired ||
          "Minimum 2 characters are required"
        )
        .max(
          100,
          translate.validations.maximum100CharactersRequired ||
          "Maximum 100 characters is required"
        )
        .matches(
          /^[A-Za-z\s'-]+$/,
          translate.validations.nameContainOnlyAlphabets ||
          "Name must contain only alphabets"
        ),
      last_name: Yup.string()
        .min(
          2,
          translate.validations.minimumTwoCharacterRequired ||
          "Minimum 2 characters are required"
        )
        .max(
          50,
          translate.validations.maximum50CharactersRequired ||
          "Maximum 50 characters is required"
        )
        .matches(
          /^[A-Za-z\s'-]+$/,
          translate.validations.nameContainOnlyAlphabets ||
          "Name must contain only alphabets"
        ),
      phone_no: Yup.string()
        .required(
          translate.validations.requiredPhone || "Phone number is required"
        )
        .min(
          6,
          translate.validations.phoneAtLeastMoreThan6Characters ||
          "Phone number must be at least 6 characters"
        )
        .max(
          16,
          translate.validations.phoneMustLessThan16Characters ||
          "Phone number must be less than 16 characters"
        ),
    }),

    onSubmit: async (values) => {
      formik.setSubmitting(true);
      let body = {
        profile_picture: imageId,
        email: values.email,
        first_name: values.first_name,
        last_name: values.last_name,
        purpose: selectedPurpose,
        phone_no: values.phone_no,
        country_code: (phoneCode.includes("+") ? "" : "+") + phoneCode,
      };
      console.log("body for setup", body);

      if (!selectedPurpose) {
        showWarning(
          translate.errors.selectPurpose || "Please select a purpose"
        );
        return;
      }

      try {
        let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response = await updateProfile(encryptedBody).unwrap();
        if (response?.status === 200) {
          showToast(
            translate.toastMessages.profileSetupSuccessfully ||
            "Profile setup successfully"
          );
          dispatch(
            setCredentials({
              user: response?.data || null,
              token: token || null,
            })
          );
          navigate("/whizzosubscription");
        }
      } catch (errors: any) {
        console.log(errors, "errrr");

        showError(errors?.data?.message || errors?.data?.errors || translate.errors.somethingWentWrong);
      }
    },
  });

  useEffect(() => {
    fetchPurpose();
  }, []);

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      profile_picture: image,
    });
  }, [image]);

  return (
    <div>
      <Loader isLoad={isLoading} />
      <section className="bnr_sec c_white  sign_in">
        <div className="conta_iner">
          <div className="bnr_mn">
            <div className="bnr_lt">
              <figure className="logo">
                <img
                  src="/static/images/logo.svg"
                  alt=""
                  onClick={() => navigate("/")}
                ></img>
              </figure>
              <h1>
                {translate.home.let}
                <span className="ai_bdr">
                  {translate.home.ai} <span></span>
                </span>{" "}
                <span className="d_block"> {translate.home.doYourWork}</span>
              </h1>
              <p>
                {translate.auth.profileSetupDetailLine1}
                <span className="d_block">
                  {translate.auth.profileSetupDetailLine2}
                </span>{" "}
                {translate.auth.profileSetupDetailLine3}
              </p>
            </div>
            <div className="logn_rt hd_3">
              <h3>{translate.auth.profileSetup}</h3>
              <form className="frmn frm_sn " onSubmit={formik.handleSubmit}>
                <div className="prfl_stup">
                  {/* <figure>
                    <img src="/static/images/profile.svg" alt=""></img>
                  </figure>
                  <input type="file" title="image" id="myFile" name="filename"
                  >
                  </input> */}

                  {image ? (
                    <div className="upload_image">
                      <figure>
                        <img
                          src={image ? image : "/static/images/profile.svg"}
                          alt="icon"
                        />
                      </figure>
                      <div className="action">
                        <CancelIcon
                          onClick={() => {
                            setImage("");
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="upload_image">
                      <figure>
                        <img src="/static/images/profile.svg" alt="icon" />
                      </figure>
                      <div className="action">
                        <AddIcon />
                        <input
                          placeholder="img"
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          onChange={handleImageUpload}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="frmn_mn">
                  <label>{translate.auth.firstName}</label>
                  <TextField
                    className="control_group"
                    hiddenLabel
                    fullWidth
                    placeholder={
                      translate.auth.enterFirstName || "Enter first name"
                    }
                    variant="outlined"
                    inputProps={{ maxLength: 30 }}
                    name="first_name"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.first_name}
                    helperText={
                      formik.touched.first_name && formik.errors.first_name
                    }
                    error={
                      formik.touched.first_name &&
                      Boolean(formik.errors.first_name)
                    }
                  />
                </div>
                <div className="frmn_mn">
                  <label>{translate.auth.lastName}</label>
                  <TextField
                    className="control_group"
                    hiddenLabel
                    fullWidth
                    placeholder={
                      translate.auth.enterLastName || "Enter last name"
                    }
                    variant="outlined"
                    inputProps={{ maxLength: 30 }}
                    name="last_name"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.last_name}
                    helperText={
                      formik.touched.last_name && formik.errors.last_name
                    }
                    error={
                      formik.touched.last_name &&
                      Boolean(formik.errors.last_name)
                    }
                  />
                </div>
                <div className="frmn_mn">
                  <label>{translate.auth.emailId}</label>
                  <TextField
                    className="control_group"
                    hiddenLabel
                    placeholder={translate.auth.emailId}
                    fullWidth
                    variant="outlined"
                    inputProps={{ maxLength: 50 }}
                    name="email"
                    onChange={(val) => {
                      if (val.target.value === " ") {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    helperText={formik.touched.email && formik.errors.email}
                    disabled
                    error={formik.touched.email && Boolean(formik.errors.email)}
                  />
                </div>
                <div className="frmn_mn">
                  <label>{translate.auth.phoneNumber}</label>
                  <PhoneInput
                    enableSearch={true}
                    value={phoneCode + formik.values.phone_no}
                    country={"us"}
                    inputClass="phoneInput"
                    placeholder={translate.auth.phoneNumber}
                    inputStyle={{ width: "100%" }}
                    onChange={(phone, country) =>
                      handleChangePhone(phone, country)
                    }
                    onBlur={formik.handleBlur}
                  />
                </div>
                <div className="frmn_mn">
                  <label>{translate.auth.purpose}</label>
                  <FormControl fullWidth className="control_group">
                    <Select
                      labelId="category-label"
                      id="category-select"
                      value={selectedPurpose}
                      displayEmpty
                      onChange={(e) => {
                        setSelectedPurpose(e.target.value);
                      }}
                    >
                      <MenuItem value="" disabled>
                        {translate.auth.purpose}
                      </MenuItem>
                      {purposeData?.map((row: any, index: number) => {
                        return <MenuItem value={row?.id}>{row?.name}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </div>
                <Button
                  type="submit"
                  className="btn btn_primay btnn lrg"
                // onClick={() => navigate("/whizzosubscription")}
                >
                  {translate.global.submit}
                </Button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Profile;
