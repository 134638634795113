
import { API_URL, END_POINTS } from "../constants/url";
import { UploadDocument } from "../utils/documentsUpload";
import emptySplitApi from "../utils/rtk";
type PresentationResponse = any;
type CommonResponseType = {
  status: number;
  message: string;
  record_id: number;
};

type GetAllPresentationsResponse = {
  // Presentation: PresentationResponse[];
  response_object: PresentationResponse[];
  total_records: number;
};


// type encryptedBody = {
//     image: string;
//     email: string;
//     fullName: string;
//     countryCode: string;
//     phone: string;
//     address:string;
//   };
type encryptedBody = {
  hash: string;
  sek: string;
} | null

type getDataBody = {
  length: number,
  start: number,
  search: string
}
type ChangeStatusBody = {
  is_active: boolean
}
export const PresentationApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({

    addPresentation: builder.mutation<
      CommonResponseType & { data: PresentationResponse },
      encryptedBody>({
        query: (body) => {
          let url = END_POINTS.addPPTContent;
          return {
            url: url,
            method: 'POST',
            body
          }
        }
      }),

    getPresentation: builder.mutation<
      CommonResponseType & { data: GetAllPresentationsResponse },
      encryptedBody>({
        query: (body) => {
          let url = END_POINTS.getAllPPT;
          return {
            url: url,
            method: 'POST',
            body
          }
        }
      }),


    getPresentationById: builder.query<CommonResponseType & { data: PresentationResponse },
      { id: string | undefined }>({
        query: ({ id }) => ({
          url: `${END_POINTS.getPresentationById}${id}/`,
          method: "GET",
        })
      }),

    detailedPresentation: builder.mutation<
      CommonResponseType & { data: any },
      any
    >({
      query: (body) => ({
        url: END_POINTS.generatePPTContent,
        method: 'POST',
        body,
      }),
    }),
    editPresentationById: builder.mutation<
      CommonResponseType & { data: PresentationResponse },
      { id: string; body: encryptedBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.updatePresentationById}${id}/`,
        method: "PUT",
        body,
      }),
    }),
    deletePresentation: builder.mutation<CommonResponseType, { id: number }>({
      query: ({ id }) => ({
        url: `${END_POINTS.deletePresentation}${id}/`,
        method: "DELETE",
      }),
    }),
    editPresentation: builder.mutation<
      CommonResponseType,
      {
        id: number;
        body: {
          file_name: string;
        };
      }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.editPresentationName}${id}/`,
        method: "PUT",
        body,
      }),
    }),


    // regeneratePresentation: builder.query<
    //   CommonResponseType & { data: any },
    //   { id: string; page: string }
    // >({
    //   query: ({ id, page }) => ({
    //     url: `${END_POINTS.regeneratePresentationAnswer}${id}/?page=${page}/`,
    //     method: 'GET',
    //   }),
    // }),

  })
})
export const {
  useAddPresentationMutation,
  useGetPresentationMutation,
  useDetailedPresentationMutation,
  useLazyGetPresentationByIdQuery,
  useEditPresentationByIdMutation,
  useDeletePresentationMutation,
  useEditPresentationMutation
  //   useDownloadPresentationMutation,
  //   useLazyRegeneratePresentationAnswerQuery,
  // useChangePresentationStatusMutation,
  // useEditPresentationByIdMutation,
  // useDeletePresentationByIdMutation,
  // useLazyGetPresentationCSVQuery,
} = PresentationApi;
