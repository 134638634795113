import { END_POINTS, purposeUrl } from "../constants/url";
import { CommonBody } from "../types/General";
import { User } from "../types/User";
import emptySplitApi from "../utils/rtk";


type CommonResponseType = {
    status: number;
    mesaage: string;
    message: string;
    token: string;
    user: User;
};



export const authApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        postSignUp: builder.mutation<
            CommonResponseType & { data: any },
            CommonBody
        >({
            query: (body) => ({
                url: END_POINTS.registration,
                method: "POST",
                body,
            }),
        }),

        postLogin: builder.mutation<
            CommonResponseType & { data: User },
            CommonBody
        >({
            query: (body) => ({
                url: END_POINTS.login,
                method: "POST",
                body,
            }),
        }),

        postVerifyOtp: builder.mutation<
            CommonResponseType & { data: User },
            CommonBody
        >({
            query: (body) => ({
                url: `${END_POINTS.verifyOtp}`,
                method: "POST",
                body,
            }),
        }),

        postResendOtp: builder.mutation<
            CommonResponseType & { data: User },
            CommonBody
        >({
            query: (body) => ({
                url: `${END_POINTS.resendOtp}`,
                method: "POST",
                body,
            }),
        }),

        postProfileSetup: builder.mutation<
            CommonResponseType & { EMAIL_CHANGED: boolean } & { data: any },
            CommonBody
        >({
            query: (body) => ({
                url: END_POINTS.updateProfile,
                method: "PUT",
                body,
            }),
        }),

        postForgotPass: builder.mutation<
            CommonResponseType & { data: User },
            CommonBody
        >({
            query: (body) => ({
                url: `${END_POINTS.forgotPassword}`,
                method: "POST",
                body,
            }),
        }),

        postResetPassword: builder.mutation<
            CommonResponseType & { data: User },
            CommonBody
        >({
            query: (body) => ({
                url: `${END_POINTS.resetPassword}`,
                method: "POST",
                body,
            }),
        }),

        GetProfile: builder.query<
            CommonResponseType & { data: User },
            {}
        >({
            query: () => ({
                url: END_POINTS.userDetails,
                method: "GET",

            }),
        }),

        changePassword: builder.mutation<
            CommonResponseType & { data: User },
            CommonBody
        >({
            query: (body) => ({
                url: END_POINTS.changePassword,
                method: "POST",
                body,
            }),
        }),

        postQuery: builder.mutation<
            CommonResponseType & { data: any },
            CommonBody
        >({
            query: (body) => ({
                url: END_POINTS.sendQuery,
                method: "POST",
                body,
            }),
        }),
        getPurpose: builder.query<
            CommonResponseType & { data: any },
            {}
        >({
            query: () => ({
                url: purposeUrl,
                method: "GET",
            }),
        }),
        logout: builder.query<CommonResponseType & { data: any }, {}>({
            query: () => ({
                url: END_POINTS.logout,
                method: "POST",
            }),
        }),
        deleteAccount: builder.query<CommonResponseType & { data: any }, {}>({
            query: () => ({
                url: END_POINTS.deleteAccount,
                method: "DELETE",
            })
        }),

        postSendOtpToNewMail: builder.mutation<
            CommonResponseType & { data: User },
            CommonBody
        >({
            query: (body) => ({
                url: `${END_POINTS.sendOtpToNewEmail}`,
                method: "POST",
                body,
            }),
        }),


        postVerifyEmail: builder.mutation<
            CommonResponseType & { data: User },
            CommonBody
        >({
            query: (body) => ({
                url: `${END_POINTS.verifyNewMail}`,
                method: "POST",
                body,
            }),
        }),


    }),
});

export const {
    usePostSignUpMutation,
    usePostLoginMutation,
    usePostVerifyOtpMutation,
    usePostResendOtpMutation,
    usePostProfileSetupMutation,
    usePostForgotPassMutation,
    usePostResetPasswordMutation,
    useLazyGetProfileQuery,
    useChangePasswordMutation,
    usePostQueryMutation,
    useLazyGetPurposeQuery,
    useLazyLogoutQuery,
    useLazyDeleteAccountQuery,
    usePostSendOtpToNewMailMutation,
    usePostVerifyEmailMutation,

} = authApi;