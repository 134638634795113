import { API_URL, END_POINTS } from "../constants/url";
import { UploadDocument } from "../utils/documentsUpload";
import emptySplitApi from "../utils/rtk";
type FileSummarizationResponse = any;
type CommonResponseType = {
  status: number;
  message: string;
};

type GetAllFileSummarizationsResponse = {
  // FileSummarization: FileSummarizationResponse[];
  response_object: FileSummarizationResponse[];
  total_records: number;
};

// type encryptedBody = {
//     image: string;
//     email: string;
//     fullName: string;
//     countryCode: string;
//     phone: string;
//     address:string;
//   };
type encryptedBody = {
  hash: string;
  sek: string;
} | null;

type getDataBody = {
  length: number;
  start: number;
  search: string;
};
type ChangeStatusBody = {
  is_active: boolean;
};
export const FileSummarizationApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    // addFileSummarization:builder.mutation<
    // CommonResponseType & {data:GetAllFileSummarizationsResponse},
    //    encryptedBody>({
    //       query:(body)=>{
    //           let url = END_POINTS.generateFileSummarizationAnswer;
    //           return{
    //             url:url,
    //             method:'POST',
    //             body
    //           }
    //         }
    // }),

    getFileSummarization: builder.mutation<
      CommonResponseType & { data: GetAllFileSummarizationsResponse },
      encryptedBody
    >({
      query: (body) => {
        let url = END_POINTS.getAllFileSummarization;
        return {
          url: url,
          method: "POST",
          body,
        };
      },
    }),
    downloadFileSummarization: builder.mutation<
      CommonResponseType & { data: any },
      { id: string | undefined; body: encryptedBody }
    >({
      query: ({ id, body }) => {
        let url = END_POINTS.downloadFileSummarization + id + "/";
        return {
          url: url,
          method: "POST",
          body,
        };
      },
    }),
    changeLanguageFileSummarization: builder.mutation<
      CommonResponseType & { data: any },
      any
    >({
      query: (body) => {
        let url = END_POINTS.changeLanguageFileSummarization;
        return {
          url: url,
          method: "POST",
          body,
        };
      },
    }),

    getFileSummarizationById: builder.query<
      CommonResponseType & { data: FileSummarizationResponse },
      { id: string | undefined }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.getFileSummarizationById}${id}/`,
        method: "GET",
      }),
    }),
    deleteFile: builder.mutation<CommonResponseType, { id: number }>({
      query: ({ id }) => ({
        url: `${END_POINTS.deleteFile}${id}/`,
        method: "DELETE",
      }),
    }),
    editFile: builder.mutation<
      CommonResponseType,
      {
        id: number;
        body: {
          file_name: string;
        };
      }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.editFileName}${id}/`,
        method: "PUT",
        body,
      }),
    }),

    // changeFileSummarizationStatus: builder.mutation<
    // CommonResponseType & { data: FileSummarizationResponse },
    // { id: string , body: encryptedBody}
    // >({
    // query: ({ id }) => ({
    //   url: `${END_POINTS.changeFileSummarizationStatus}${id}/`,
    //   method: "PUT",
    // }),
    // }),

    // editFileSummarizationById: builder.mutation<
    //       CommonResponseType & { data: FileSummarizationResponse },
    //       { id: string; body: encryptedBody }
    //     >({
    //       query: ({ id, body }) => ({
    //         url: `${END_POINTS.updateFileSummarizations}${id}/`,
    //         method: "PUT",
    //         body,
    //       }),
    //     }),

    // deleteFileSummarizationById:builder.mutation<
    // CommonResponseType & {data:FileSummarizationResponse},
    // {id:string}
    // >({
    //     query:({id})=>({
    //         url:`${END_POINTS.deleteFileSummarization}${id}/`,
    //         method:'DELETE'
    //     })
    // }),
    // getFileSummarizationCSV: builder.query<CommonResponseType & { data: string }, {
    //     search:string;
    //   }>({
    //     query: ({search}) => {
    //       let url = END_POINTS.exportFileSummarizations;
    //       const queryParams = [];
    //       if (search) {
    //         queryParams.push(`search=${search}`);
    //       }
    //       if (queryParams.length > 0) {
    //         url += `?${queryParams.join('&')}`;
    //       }
    //      return{
    //       url:url,
    //       method: "GET",}
    //     },
    //   }),
  }),
});

export const {
  // useAddFileSummarizationMutation,
  useGetFileSummarizationMutation,
  useChangeLanguageFileSummarizationMutation,
  useLazyGetFileSummarizationByIdQuery,
  useDownloadFileSummarizationMutation,
  useDeleteFileMutation,
  useEditFileMutation,
  // useChangeFileSummarizationStatusMutation,
  // useEditFileSummarizationByIdMutation,
  // useDeleteFileSummarizationByIdMutation,
  // useLazyGetFileSummarizationCSVQuery,
} = FileSummarizationApi;
export const generateFileSummarization = async (file: any) => {
  let url = API_URL + END_POINTS.generateFileSummarizationAnswer;
  const formData = new FormData();
  formData.append("file_link", file);
  formData.append("type", "1");
  try {
    const response = await UploadDocument(url, formData);
    return response;
  } catch (error) {
    console.log(error);
  }
};
