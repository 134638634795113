import { API_URL, END_POINTS } from "../constants/url";
import { UploadDocument } from "../utils/documentsUpload";
import emptySplitApi from "../utils/rtk";
type noteTakingResponse = any;
type CommonResponseType = {
  status: number;
  message: string;
  record_id: number;
  recent_notes: any;
  recent_notes_count: any;
  favourite_notes_count: any;
};

type GetAllnoteTakingsResponse = {
  // noteTaking: noteTakingResponse[];
  response_object: noteTakingResponse[];
  total_records: number;
};

// type encryptedBody = {
//     image: string;
//     email: string;
//     fullName: string;
//     countryCode: string;
//     phone: string;
//     address:string;
//   };
type encryptedBody = {
  hash: string;
  sek: string;
} | null;

type getDataBody = {
  length: number;
  start: number;
  search: string;
};
type ChangeStatusBody = {
  is_active: boolean;
};
export const noteTakingApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    addNoteTaking: builder.mutation<
      CommonResponseType & { data: noteTakingResponse },
      encryptedBody
    >({
      query: (body) => {
        let url = END_POINTS.createNote;
        return {
          url: url,
          method: "POST",
          body,
        };
      },
    }),
    noteTakingActions: builder.mutation<
      CommonResponseType & { data: noteTakingResponse },
      encryptedBody
    >({
      query: (body) => {
        let url = END_POINTS.noteActions;
        return {
          url: url,
          method: "POST",
          body,
        };
      },
    }),

    getNoteTaking: builder.mutation<
      CommonResponseType & { data: any },
      encryptedBody
    >({
      query: (body) => {
        let url = END_POINTS.getAllNotes;
        return {
          url: url,
          method: "POST",
          body,
        };
      },
    }),

    getNoteTakingById: builder.query<
      CommonResponseType & { data: noteTakingResponse },
      { id: string | undefined }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.getNoteById}${id}/`,
        method: "GET",
      }),
    }),

    // detailedNoteTaking: builder.mutation<
    //   CommonResponseType & { data: any },
    //   any
    // >({
    //   query: (body) => ({
    //     url:END_POINTS.generatePPTContent,
    //     method: 'POST',
    //     body,
    //   }),
    // }),
    editNoteTakingById: builder.mutation<
      CommonResponseType & { data: noteTakingResponse },
      { id: string; body: encryptedBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.getNoteById}${id}/`,
        method: "PUT",
        body,
      }),
    }),
    deleteNoteTaking: builder.mutation<CommonResponseType, { id: number }>({
      query: ({ id }) => ({
        url: `${END_POINTS.deleteNoteTaking}${id}/`,
        method: "DELETE",
      }),
    }),
    editNoteTaking: builder.mutation<
      CommonResponseType,
      {
        id: number;
        body: {
          file_name: string;
        };
      }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.editNoteTakingName}${id}/`,
        method: "PUT",
        body,
      }),
    }),
    // regeneratenoteTaking: builder.query<
    //   CommonResponseType & { data: any },
    //   { id: string; page: string }
    // >({
    //   query: ({ id, page }) => ({
    //     url: `${END_POINTS.regeneratenoteTakingAnswer}${id}/?page=${page}/`,
    //     method: 'GET',
    //   }),
    // }),
  }),
});
export const {
  useAddNoteTakingMutation,
  useGetNoteTakingMutation,
  useLazyGetNoteTakingByIdQuery,
  useEditNoteTakingByIdMutation,
  useNoteTakingActionsMutation,
  useDeleteNoteTakingMutation,
  useEditNoteTakingMutation
} = noteTakingApi;
