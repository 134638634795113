import { useNavigate } from "react-router-dom";
import useTranslation from "../hooks/Translation";

const AiWork = () => {
  const translation = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <div className="bnr_lt">
        <figure className="logo">
          <img
            src="/static/images/logo.svg"
            alt=""
            onClick={() => navigate("/")}
          ></img>
        </figure>
        <h1>
          {translation.home.let}{" "}
          <span className="ai_bdr">
            {translation.home.ai} <span></span>
          </span>{" "}
          <span className="d_block">{translation.home.doYourWork}</span>
        </h1>
        <p>
          {translation.home.homeMessageLine1}
          <span className="d_block">
            {translation.home.homeMessageLine2}
          </span>{" "}
          {translation.home.homeMessageLine3}
        </p>
      </div>
    </>
  );
};
export default AiWork;
