import React, { useEffect, useState } from "react";
import { showError } from "../../constants/toast";
import { useLazyGetContactQuery } from "../../services/cms";
import Loader from "../../constants/Loader";
import useTranslation from "../../hooks/Translation";

const AboutSection1 = () => {
  const [aboutUs] = useLazyGetContactQuery();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [about, setAbout] = useState<string>("");

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const res = await aboutUs({}).unwrap();
      if (res?.status === 200) {
        setAbout(res?.data?.about_us?.about_us);
      }
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      showError(
        error?.data?.message || error?.message || "something went wrong"
      );
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const translate = useTranslation();
  return (
    <>
      <Loader isLoad={isLoading} />
      <section className="abut_us bg_color" id="about">
        <div className="conta_iner">
          <div className="text_center hd_1">
            <h2>{translate.about.aboutUs}</h2>
            <div
              style={{ textAlign: "center", fontSize: "22px" }}
              dangerouslySetInnerHTML={{
                __html: about.trim().replace("\n", "").replace("<p></p>", "")
                  ? about
                  : translate.about.noInformationProvided,
              }}
            ></div>
          </div>
        </div>
      </section>
    </>
  );
};
export default AboutSection1;
