import React, { useEffect, useState, useRef, useCallback } from "react";
import Layout from "../../layout/index";
import "react-tabs/style/react-tabs.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Test from "../../components/test";
import Notification from "../../components/notification";
import useAuth from "../../hooks/useAuth";
import Header from "../../layout/header/Header";
import { useNavigate } from "react-router-dom";
import { showError, showToast } from "../../constants/toast";
import { CommonBody } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import {
  useDeleteResearchMutation,
  useEditResearchMutation,
  useGetResearchMutation,
} from "../../services/research";
import { Research } from "../../types/research";
import { Tooltip, Typography } from "@mui/material";
import Pagination from "../../components/pagination";
import NewResearch from "../../components/research";
import Loader from "../../constants/Loader";
import { DeleteModal } from "../../components/deleteModal";
import { RenameModal } from "../../components/renameModal";
import {
  useDeleteAssignmentMutation,
  useEditAssignmentMutation,
} from "../../services/assignmentModule";
import { FolderListComponent } from "../../components/folderList";
import useTranslation from "../../hooks/Translation";

const Researchpage = () => {
  const navigate = useNavigate();
  const translate = useTranslation();
  const [open, setOpen] = useState(false);
  const user = useAuth();
  const [open1, setOpen1] = useState(false);
  const [sidebar, setSidebar] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [research, setResearch] = useState<Research[]>([]);
  const totalPages = Math.ceil(totalCount / 20);
  const hasMore = page <= totalPages;
  const pageRef = useRef(page);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  const [getResearch] = useGetResearchMutation();

  const getResearchList = async (page: number) => {
    setIsLoading(true);
    try {
      const body = {
        start: page,
        length: 20,
        search: "",
      };
      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await getResearch(encryptedBody).unwrap();
      if (response?.status === 200) {
        setResearch(response?.data?.response_object);
        setTotalCount(response?.data?.total_records);
      } else {
        setResearch([]);
      }
    } catch (error: any) {
      showError(
        error?.data?.message || translate.errors.somethingWentWrong || ""
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getResearchList(page);
  }, [page]);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (
  //       window.innerHeight + document.documentElement.scrollTop >=
  //       document.documentElement.offsetHeight - 2
  //     ) {
  //       if (hasMore && !isLoading) {
  //         setPage((prevPage) => prevPage + 1);
  //       }
  //     }
  //   };

  //   window.addEventListener('scroll', handleScroll);
  //   return () => window.removeEventListener('scroll', handleScroll);
  // }, [hasMore, isLoading]);
  const [renameValue, setRenameValue] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [deleteData] = useDeleteResearchMutation();
  const [editData] = useEditResearchMutation();
  const [selectedId, setSelectedId] = useState(0);
  const toggleDelete = (id: number) => {
    setOpenDelete(true);
    setSelectedId(id);
  };
  const handleDelete = async (e: any) => {
    e.stopPropagation();
    try {
      const response = await deleteData({ id: selectedId }).unwrap();
      if (response.status == 200) {
        showToast(
          translate.toastMessages.researchDeletedSuccessfully ||
            "Research Deleted Successfully"
        );
        setOpenDelete(false);
      }
    } catch (error: any) {
      console.log(error);
      showError(
        error?.data?.message ||
          translate.errors.somethingWentWrong ||
          "Something went wrong"
      );
    } finally {
      await getResearchList(page);
    }
  };
  const toggleEdit = (id: number, text: any) => {
    setOpenEdit(true);
    setRenameValue(text);
    setSelectedId(id);
  };
  const handleEdit = async (e: any) => {
    e.stopPropagation();
    try {
      const response = await editData({
        id: selectedId,
        body: { file_name: renameValue },
      }).unwrap();
      if (response.status == 200) {
        showToast(
          translate.toastMessages.researchNameUpdatedSuccessfully ||
            "Research name updated Successfully"
        );
        setOpenEdit(false);
        setRenameValue("");
      }
    } catch (error: any) {
      console.log(error);
      showError(
        error?.data?.message ||
          translate.errors.somethingWentWrong ||
          "Something went wrong"
      );
    } finally {
      await getResearchList(page);
    }
  };
  return (
    <div className="sdbr_rltv">
      <Loader isLoad={isLoading} />
      {sidebar ? <Layout.SidePanel /> : null}
      <Header setSidebar={setSidebar} />
      <div className="sde_br_mn">
        <div className="sde_mn_cnt">
          <button className="btnn btn_border" onClick={handleClickOpen}>
            <i className="fa-regular fa-plus"></i>{" "}
            {translate.research.createNewResearch || "Create a new Research"}
          </button>
          <Tabs>
            <TabList>
              <Tab>{translate.research.myResearches || "My Researches"}</Tab>
            </TabList>
            <TabPanel>
              <ul className="tst_lst">
                {research.length ? (
                  research.map((data: Research, index: number) => (
                    <FolderListComponent
                      dataName={data?.file_name}
                      dataCreatedAt={data?.created_at}
                      index={index}
                      key={index}
                      nextPage={`/reserch-edit2/${data?.id}`}
                      handleDelete={(e: any) => {
                        e.stopPropagation();
                        toggleDelete(data?.id);
                      }}
                      handleEdit={(e: any) => {
                        e.stopPropagation();
                        toggleEdit(data?.id, data?.file_name || "");
                      }}
                      isEdit={true}
                    />
                    // <li
                    //   style={{ cursor: "pointer" }}
                    //   key={index}
                    //   onClick={() => navigate(`/reserch-edit2/${data?.id}`)}
                    // >
                    //   <Tooltip title={data?.topic ? data?.topic : "Research"}>
                    //     <h3>
                    //       {data?.topic
                    //         ? data?.topic.length > 10
                    //           ? data?.topic?.slice(0, 10) + "..."
                    //           : data?.topic
                    //         : "Research"}
                    //     </h3>
                    //   </Tooltip>
                    //   <p className="mt_40">{data?.created_at?.slice(0, 10)}</p>
                    // </li>
                  ))
                ) : (
                  <div
                    style={{
                      height: "85px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      marginTop: "15%",
                    }}
                  >
                    {" "}
                    <h3 style={{ fontSize: "xxx-large", color: "darkgrey" }}>
                      {translate.global.noRecordsFound || "No Records Found"}
                    </h3>
                  </div>
                )}
              </ul>
            </TabPanel>
          </Tabs>
        </div>
      </div>
      <NewResearch open={open} setOpen={setOpen} handleClose={handleClose} />
      <Notification
        open={open1}
        setOpen={setOpen1}
        handleClose={handleClose1}
      />
      <Pagination
        module={research}
        onPageChange={onPageChange}
        page={page}
        totalPages={totalPages}
      />
      <DeleteModal
        handleDelete={handleDelete}
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        textData={translate.research.researchLowerCase || "research"}
      />
      <RenameModal
        handleEdit={handleEdit}
        openEdit={openEdit}
        renameValue={renameValue}
        setOpenEdit={setOpenEdit}
        setRenameValue={setRenameValue}
        textData={translate.research.researchLowerCase || "research"}
      />
    </div>
  );
};

export default Researchpage;
