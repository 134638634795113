import { useLocation, useNavigate, useParams } from "react-router-dom";
import Layout from "../../layout/index";
import { Button } from "@mui/material";
import Download from "../../components/download";
import useAuth from "../../hooks/useAuth";
import React, { useEffect, useRef, useState } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  useDownloadPdfMutation,
  useGenerateAssignmentMutation,
  useLazyGetAssignmentByIdQuery,
  useTranslateAssignmentMutation,
} from "../../services/assignmentModule";
import { showError, showToast } from "../../constants/toast";
import Header from "../../layout/header/Header";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import Loader from "../../constants/Loader";
import { handleDownload } from "../../utils/handleDownload";
import HTMLReactParser from "html-react-parser";
import { getFromStorage } from "../../constants/storage";
import { STORAGE_KEYS } from "../../constants/storageKeys";
import { fileToBase64 } from "../../utils/fileToB64";
import AssignmentVideo from "../assignmentvideo";
import useTranslation from "../../hooks/Translation";
import { API_URL } from "../../constants/url";
import { renderContent } from "../../components/latexTest";
let isBack = false;
const EditAssignment = () => {
  const translation = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  console.log(id, "id");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { state } = useLocation();
  const [updateAssignment] = useDownloadPdfMutation();
  const [getAssignmentById] = useLazyGetAssignmentByIdQuery();
  const [assignmentData, setAssignmentData] = useState<any>();
  const [regenerateResponse] = useGenerateAssignmentMutation();
  const [eng, setEng] = useState<boolean>(true);
  const [translate] = useTranslateAssignmentMutation();
  const [translatedData, setTranslatedData] = useState<any>();

  const translateText = async () => {
    setIsLoading(true);
    const body = {
      text: assignmentData,
    };
    console.log(body, "Body for translation");

    try {
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await translate(encryptedBody).unwrap();
      setIsLoading(false);
      if (response?.status === 200) {
        console.log(response?.data);
        setTranslatedData(response?.data);
      }
    } catch (error: any) {
      setIsLoading(false);
      showError(
        error?.message ||
        translation.errors.somethingWentWrong ||
        "Something went wrong"
      );
    }
  };

  const fetchRevision = async () => {
    setIsLoading(true);
    const body = {
      html_text: generateHTML(assignmentData),
    };
    console.log(body, "body for revision");
    try {
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const res = await regenerateResponse({
        body: encryptedBody,
        param: id,
      }).unwrap();
      setIsLoading(false);
      if (res?.status === 200) {
        if (res?.data.length) {
          setAssignmentData(res?.data);
          console.log(assignmentData, "assignment data while regenerate");
        }
        showToast(res?.message);
      }
    } catch (error: any) {
      setIsLoading(false);
      showError(error?.data?.message || error.message);
    }
  };

  const generateHTML = (questions: any) => {
    let html = `<html lang=${eng ? "en" : "ar"}><meta charset="UTF-8"><style> body{font-family:"Arial", sans-serif;}</style></head><body>`;
    questions.forEach((item: any, index: any) => {
      html += `<div style="margin-bottom: 20px;" key=${index}>`;
      html += `<p style="font-family: 'MEDIUM'; color: black;">Question ${item.question_no || 0
        }:</p>`;
      html += `<p style="font-family: 'MEDIUM'; color: black; margin-vertical: 10px;">${item.question || ""
        }</p>`;
      if (item.question_type === 1) {
        html += `<div>`;
        html += `<p style="font-family: 'MEDIUM'; color: black; margin-vertical: 20px;">Solution:</p>`;
        html += `<p style="color: black;">${item?.correct_answer || ""}</p>`;
        html += `</div>`;
      } else {
        html += `<div>`;
        html += `<p style="font-family: 'MEDIUM'; color: black; margin-vertical: 20px;">Options:</p>`;
        item.options.forEach((option: any, innerIndex: any) => {
          html += `<p style="color: black;">${option || ""}</p>`;
        });
        html += `<p style="font-family: 'MEDIUM'; color: black; margin-vertical: 20px;">Correct Answer:</p>`;
        html += `<p style="color: black;">${item.correct_answer || ""}</p>`;
        html += `</div>`;
      }
      html += `</div>`;
    });
    html += "</body></html>";
    return html;
  };

  const downloadPdf = async (type: number) => {
    setIsLoading(true);
    try {
      let html = "";
      html =
        '<html lang="ar"><meta charset="UTF-8"><style> body{font-family:"Arial", sans-serif;}</style></head><body style="background-color: white;" dir="auto">';
      html +=
        '<pre style="white-space: pre-wrap; font-family: Arial, sans-serif; background-color: #fff; border: 0px">';
      html += summary;
      html += "</pre>";
      html += "</body></html>";
      const body = {
        html_text: html,
        type: type,
        new: true,
      };
      console.log(body, "body for download ");

      if (html) {
        let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response = await updateAssignment({
          body: encryptedBody,
          id: id,
        }).unwrap();
        setIsLoading(false);
        if (response?.status === 200) {
          setOpen(false);
          if (type !== 3) {
            // window.open(response?.data || '');
            handleDownload(
              response?.data,
              response?.data?.split("/")?.[
              response?.data?.split("/")?.length - 1
              ]
            );
          } else {
            showToast(
              translation.toastMessages.pdfSharedOverEmail ||
              "Pdf shared over email"
            );
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const fetchDetails = async () => {
    setIsLoading(true);
    try {
      const response = await getAssignmentById({ id: id }).unwrap();
      setIsLoading(false);
      if (response?.status === 200) {
        // setAssignmentData(response?.data?.result);
        setSummary(response?.data?.result);
      }
    } catch (error: any) {
      setIsLoading(false);
      showError(
        error?.message ||
        translation.errors.somethingWentWrong ||
        "Something went wrong"
      );
    }
  };

  const [disableButton, setDisableButton] = useState(false);
  const [ws, setWs] = useState<any>(null);
  const [isBlink, setIsBlink] = useState(false);
  const [summary, setSummary] = useState("");
  console.log("summary :", summary);
  const socketRef: any = useRef();
  const createNewSummarization = (isChangeLanguage?: any) => {
    // const ws=new WebSocket('https://d164-122-160-196-233.ngrok-free.app/file-summarization/');
    // if (isChangeLanguage && originalSummary) {
    //   setSummary(originalSummary)
    // }
    if (isChangeLanguage || id) {
      setDisableButton(true);
      setIsLoading(true);
    }
    const ws = new WebSocket(
      `wss:${API_URL.replaceAll("https://", "")}assignment-solutions/`
    );
    socketRef.current = ws;
    ws.onmessage = (event) => {
      console.log("event.data :", event.data);
      const data = JSON.parse(event.data);
      if (data.signal == -1) {
        ws.close();
        showError(translation.errors.pleaseTryAgain || "Please try again");
        navigate("/assignment-solution", { replace: true });
      }
      if (data.signal == 0) {
        setDisableButton(false);
        ws.close();
        showToast(
          translation.toastMessages.generationSuccessfully ||
          "Generation successfully"
        );
        if (!id) {
          if (!isBack) {
            if (data?.record_id)
              navigate(`/edit-assign/${data?.record_id}`, {
                state: {
                  summaryData: summary,
                },
                replace: true,
              });
          }
        }
        // window.location.replace(`/edit-file/${data?.record_id}`)
      }
      if (data.signal == 401) {
        ws.close();
        showError(translation.errors.pleaseLogin || "Please login");
        navigate("/");
        // return;
      }
      if (data.signal == 400) {
        ws.close();
        showError(data?.data || data?.message);
        if (isChangeLanguage) setIsLoading(false);
        navigate("/assignment-solution", { replace: true });
        // return;
      }
      if (data.signal == 1) {
        if (isChangeLanguage || id) setIsLoading(false);

        setSummary(data.data);
      }
      const teset = document.getElementById("mohit");
      if (teset) {
        setTimeout(() => {
          teset.scrollIntoView({ behavior: "smooth", block: "center" });
        }, 0);
      }

      console.log(event);
    };
    ws.onopen = async () => {
      setIsBlink(true);
      let binaryData: any = [];
      if (state?.selectedFile) {
        if (Array.isArray(state?.selectedFile)) {
          for (let key = 0; key < state?.selectedFile?.length; key++) {
            binaryData.push(await fileToBase64(state?.selectedFile[key]));
          }
        } else {
          binaryData = await fileToBase64(state?.selectedFile);
        }
      }
      const PAYLOAD: any = {
        token: JSON?.parse(getFromStorage(STORAGE_KEYS.token) as string),
      };
      if (isChangeLanguage) {
        // setDisableButton(true);
        // setIsLoading(true);
        PAYLOAD["text"] = summary;
        PAYLOAD["change_language"] = true;
      } else if (id) {
        PAYLOAD["record_id"] = id;
      } else {
        PAYLOAD["type"] = state?.type;
        PAYLOAD["binary_data"] = state?.type == 1 ? binaryData : Array.isArray(binaryData) ? binaryData : [binaryData];
        PAYLOAD["file_name"] = Array.isArray(binaryData)
          ? Date.now()
          : state?.fileName;
        PAYLOAD["language"] = state?.language;
      }
      console.log("PAYLOAD :", PAYLOAD);
      ws?.send(JSON.stringify(PAYLOAD));
      console.log("Connected to websocket successfully");
    };
    ws.onerror = (error) => {
      setIsBlink(false);
      setIsLoading(false);
      ws.close();
      console.log("There is some error", error);
    };
    ws.onclose = () => {
      setIsBlink(false);
      setIsLoading(false);
      console.log("Socket is closed");
    };
    setWs(ws);
  };
  useEffect(() => {
    if (id) {
      if (state?.summaryData) {
        setSummary(state?.summaryData);
      } else {
        fetchDetails();
      }
    } else {
      createNewSummarization();
    }
    // return () => {
    //   console.log('Cleaning up: Closing WebSocket');
    //   // ws?.send(-1);
    //   originalSummary=""
    //   isBack=true;
    //   if(ws) {
    //     ws.close();
    //   }
    // }
  }, [id]);
  useEffect(() => {
    isBack = false;
    return () => {
      isBack = true;
      const PAYLOAD = {
        signal: -1
      }

      if (socketRef?.current) {
        console.log("Cleaning up: Closing WebSocket");
        socketRef?.current.close(); // Close WebSocket on cleanup
      }

      ws?.send(JSON.stringify(PAYLOAD));
      if (ws) {
        ws.close();
      }
    };
  }, []);
  // useEffect(() => {
  //   if (id) {
  //     fetchDetails();
  //   }
  // }, []);

  const goProfile = () => {
    navigate("/setting");
  };
  const [open, setOpen] = React.useState<any>(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [sidebar, setSidebar] = useState(true);
  return (
    <>
      {summary || id ? (
        <div className="sdbr_rltv">
          <Loader isLoad={isLoading} />
          {sidebar ? <Layout.SidePanel /> : null}
          <Header setSidebar={setSidebar} />
          <div className="sde_br_mn sde_br_mn_fixed">
            <div className="sde_mn_cnt">
              {/* <Button className='btnn btn-primary h_46'>
              Edit
            </Button> */}
              <div
                className="edt_nte"
                dir="auto"
                style={{ fontSize: "revert" }}
              >
                {summary ? (
                  <>
                    {/* {HTMLReactParser(summary)} */}
                    {renderContent(summary)}
                    {isBlink ? <span className="blinker">|</span> : null}
                    {/* {isBlink?<span className="dots"></span>:null} */}
                  </>
                ) : (
                  <p>Loading...</p>
                )}
                {/*!eng ? (
                translatedData?.length ? (
                  translatedData?.map((item: any, i: number) => {
                    return (
                      <>
                        <div style={{ display: "flex", justifyContent: "start", textAlign: "center" }}>
                          <p><b>Question {item?.question_no}:</b></p>
                          <p style={{ margin: "0px 32px", marginLeft: "5px" }}>{item?.question || ""}</p>
                        </div>
                        <br />
                        {item?.question_type === 1 ? ("") : (
                          <p><b>Options :</b></p>
                        )}
                        {
                          item?.options?.length ? (
                            item?.options.map((flag: any) => {
                              return (
                                <p>{flag}</p>
                              )
                            })
                          ) : ("")
                        }
                        <br />
                        <div style={{ display: "flex", justifyContent: "start", textAlign: "center" }}>
                          <p><b>Correct Answer :</b></p>
                          <p style={{ margin: "0px 32px", marginLeft: "10px" }}>{item?.correct_answer}</p>
                        </div>
                        <br />
                        <br />
                      </>
                    )
                  })
                ) : (isLoading ? "" : "No data found")
              ) : (
                assignmentData?.length ? (
                  assignmentData?.map((item: any, i: number) => {
                    return (
                      <>
                        <div style={{ display: "flex", justifyContent: "start", textAlign: "center" }}>
                          <p><b>Question {item?.question_no}:</b></p>
                          <p style={{ margin: "0px 32px", marginLeft: "5px" }}>{item?.question || ""}</p>
                        </div>
                        <br />
                        {item?.question_type === 1 ? ("") : (
                          <p><b>Options :</b></p>
                        )}
                        {
                          item?.options?.length ? (
                            item?.options.map((flag: any) => {
                              return (
                                <p>{flag}</p>
                              )
                            })
                          ) : ("")
                        }
                        <br />
                        <div style={{ display: "flex", justifyContent: "start", textAlign: "center" }}>
                          <p><b>Correct Answer :</b></p>
                          <p style={{ margin: "0px 32px", marginLeft: "10px" }}>{item?.correct_answer}</p>
                        </div>

                        <br />
                        <br />
                      </>
                    )
                  })
                ) : (isLoading ? "" : "No data found")
              )*/}
                <div id="mohit" style={{ paddingTop: "90px" }}></div>
              </div>

              <div className="prfl_btn fixed_bottom">
                <div className="prfl_btn_lft">
                  <Button
                    disabled={!id || disableButton}
                    className="btnn h_46"
                    onClick={() => {
                      // if (eng) {
                      //   translateText();
                      //   setEng(!eng);
                      // } else {
                      //   setEng(!eng);
                      // }
                      window.scroll(0, 0);
                      createNewSummarization(true);
                    }}
                  >
                    {translation.global.changeLanguage || "Change Language"}
                  </Button>
                  <Button
                    disabled={!id || disableButton}
                    className="btnn h_46"
                    onClick={() => {
                      createNewSummarization();
                      // fetchRevision()
                    }}
                  >
                    {translation.assignmentSolution.requestRevision ||
                      "Request Revision"}
                  </Button>
                </div>
                <Button
                  disabled={!id || disableButton}
                  className="btnn h_46"
                  onClick={() => setOpen(true)}
                >
                  {translation.global.download || "Download"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <AssignmentVideo />
      )}
      <Download
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        downloadPdf={downloadPdf}
        note={
          translation.assignmentSolution.assignmentsolutions ||
          "Assignment solutions"
        }
      />
    </>
  );
};
export default EditAssignment;
