import React, { useState } from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { showWarning } from "../../constants/toast";
import useTranslation from "../../hooks/Translation";

const WhizzoSubscription = () => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const translate = useTranslation();
  const handleRadioChange = (event: any) => {
    setSelected(event.target.value);
  };

  const handleButtonClick = () => {
    if (selected === "card1") {
      navigate("/testing");
    } else if (!selected) {
      showWarning(translate.errors.selectPlan || "Please select a plan");
    } else {
      navigate("/paymentoption");
    }
  };

  return (
    <div>
      <section className="bnr_sec c_white sign_in sn_in">
        <div className="conta_iner">
          <div className="bnr_mn">
            <div className="bnr_lt">
              <figure className="logo">
                <img
                  src="/static/images/logo.svg"
                  alt=""
                  onClick={() => navigate("/")}
                ></img>
              </figure>
              <h1>
                {translate.home.let}
                <span className="ai_bdr">
                  {translate.home.let}
                  <span></span>
                </span>{" "}
                <span className="d_block"> {translate.home.doYourWork}</span>
              </h1>
              <p>
                {translate.home.homeMessageLine1}
                <span className="d_block">
                  {translate.home.homeMessageLine2}
                </span>{" "}
                {translate.home.homeMessageLine3}
              </p>
            </div>
            <div className="logn_rt hd_3">
              <h3 className="mb_30">
                {translate.subscriptions.whizzoSubscription ||
                  "Whizzo Subscription"}
              </h3>
              <div className="wz_subLst">
                <RadioGroup
                  className="checkbox_label"
                  aria-labelledby="demo-radio-buttons-group-label"
                  // defaultValue="card1"
                  name="radio-buttons-group"
                  value={selected}
                  onChange={handleRadioChange}
                >
                  <FormControlLabel
                    value="card1"
                    control={<Radio />}
                    label={
                      <>
                        <p>
                          <small>
                            {translate.subscriptions.freeTrial || "Free Trial"}
                          </small>
                        </p>
                      </>
                    }
                  />
                  <FormControlLabel
                    value="card2"
                    control={<Radio />}
                    label={
                      <>
                        <div className="hd_4">
                          <p>
                            <small>
                              {translate.subscriptions.monthlySubscription}
                            </small>
                          </p>
                          <h3>$29.99/yr</h3>
                        </div>
                      </>
                    }
                  />
                  <FormControlLabel
                    value="card3"
                    control={<Radio />}
                    label={
                      <>
                        <div className="hd_4">
                          <p>
                            <small>
                              {translate.subscriptions.quarterlySubscription}
                            </small>
                          </p>
                          <h3>$100.99/yr</h3>
                        </div>
                      </>
                    }
                  />
                  <FormControlLabel
                    value="card4"
                    control={<Radio />}
                    label={
                      <>
                        <div className="hd_4">
                          <p>
                            <small>
                              {translate.subscriptions.yearlySubscription}
                            </small>
                          </p>
                          <h3>$100.99/yr</h3>
                        </div>
                      </>
                    }
                  />
                </RadioGroup>
              </div>
              <Button
                type="button"
                className="btn btn_primay btnn lrg"
                onClick={handleButtonClick}
              >
                {translate.global.next || " Next"}
              </Button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default WhizzoSubscription;
