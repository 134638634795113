/* eslint-disable @typescript-eslint/no-unused-vars */
import Layout from "../../layout/index";
import "react-tabs/style/react-tabs.css";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import Header from "../../layout/header/Header";
import { Button } from "@mui/material";
import Download from "../../components/download";
import useTranslation from "../../hooks/Translation";

function ArticalEdit() {
  const translate = useTranslation();
  const navigate = useNavigate();
  const goProfile = () => {
    navigate("/setting");
  };
  const [open, setOpen] = React.useState<any>(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [sidebar, setSidebar] = useState(true);

  return (
    <div className="sdbr_rltv">
      {<Layout.SidePanel />}
      <Header setSidebar={setSidebar} />

      <div className="sde_br_mn adt_artcl">
        <div className="sde_mn_cnt">
          <Button className="h_46">{translate.global.regenerate}</Button>
          <Button className="h_46 adt_btn">
            {translate.article.addPicturesWithAi}
          </Button>
          <div className="edt_artcl">
            <p className="adt_mn">How to learn trigonomentery table easily?</p>
            <p className="adt_qsn">
              <small>1) lorem ipsum dolor set amet</small>
            </p>
            <p className="adt_ansr">
              <small>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters, as opposed to using 'Content
                here, content here', making it look like readable English. Many
                desktop publishing packages and web page editors now use Lorem
                Ipsum as their default model text, and a search for 'lorem
                ipsum' will uncover many web sites still in their infancy.
                Various versions have evolved over the years, sometimes by
                accident, sometimes on purpose (injected humour and the like).
              </small>
            </p>
            <p className="adt_qsn">
              <small>i) lorem ipsum dolor set amet</small>
            </p>
            <p className="adt_ansr">
              <small>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters, as opposed to using 'Content
                here, content here', making it look like readable English. Many
                desktop publishing packages and web page editors now use Lorem
                Ipsum as their default model text, and a search for 'lorem
                ipsum' will uncover many web sites still in their infancy.
                Various versions have evolved over the years, sometimes by
                accident, sometimes on purpose (injected humour and the like).
              </small>
            </p>
            <p className="adt_qsn">
              <small>ii) lorem ipsum dolor set amet</small>
            </p>
            <p className="adt_ansr">
              <small>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters, as opposed to using 'Content
                here, content here', making it look like readable English. Many
                desktop publishing packages and web page editors now use Lorem
                Ipsum as their default model text, and a search for 'lorem
                ipsum' will uncover many web sites still in their infancy.
                Various versions have evolved over the years, sometimes by
                accident, sometimes on purpose (injected humour and the like).
              </small>
            </p>
            <p className="adt_qsn">
              <small>ii) lorem ipsum dolor set amet</small>
            </p>
            <p className="adt_ansr">
              <small>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters, as opposed to using 'Content
                here, content here', making it look like readable English. Many
                desktop publishing packages and web page editors now use Lorem
                Ipsum as their default model text, and a search for 'lorem
                ipsum' will uncover many web sites still in their infancy.
                Various versions have evolved over the years, sometimes by
                accident, sometimes on purpose (injected humour and the like).
              </small>
            </p>
          </div>

          <div className="adt_btm">
            <Button className="h_46">
              {translate.global.identifyingImportantVocabulary}
            </Button>
            <Button className="h_46">{translate.global.aIExplanation}</Button>
            <Button className="h_46" onClick={() => setOpen(true)}>
              {translate.global.download}
            </Button>
          </div>
        </div>
        <Download open={open} setOpen={setOpen} handleClose={handleClose} />
      </div>
    </div>
  );
}

export default ArticalEdit;
