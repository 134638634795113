import { Backdrop, Box, Modal } from "@mui/material";
import "./loader.css";
const Loader = (props: any) => {
  const style = {
    outline: "none",
  };
  return (
    <Box>
      {props.isLoad ? (
        <Modal
          open
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              sx: {
                backgroundColor: "rgba(0, 0, 0, 0.65)",
              },
            },
          }}
        >
          <Box className="loader_loading" sx={style}>
            <svg className="loaderSVG" viewBox="25 25 50 50">
              <circle className="loaderCircle" r="20" cy="50" cx="50"></circle>
            </svg>
          </Box>
        </Modal>
      ) : (
        ""
      )}
    </Box>
  );
};

export default Loader;