import React, { useState } from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useTranslation from "../../hooks/Translation";

const HomeSection3 = () => {
  const [activeState, setActiveState] = useState(2);
  //1
  //2
  //3
  const navigate = useNavigate();
  const translate = useTranslation();
  return (
    <>
      <section className="sbscrptn_sec ub_spc">
        <div className="conta_iner">
          <div className="text_center hd_1">
            <h2>{translate.subscriptions.subscriptionPlan}</h2>
            <p>
              {translate.subscriptions.subscriptionHomepageLine1}
              <span className="d_block">
                {translate.subscriptions.subscriptionHomepageLine2}
              </span>
            </p>
          </div>
          <div className="tbs_mn">
            <ul className="tbs_btn">
              <li
                className={activeState == 1 ? "active" : ""}
                onClick={() => setActiveState(1)}
              >
                {translate.subscriptions.monthly}
              </li>
              <li
                className={activeState == 2 ? "active" : ""}
                onClick={() => setActiveState(2)}
              >
                {translate.subscriptions.quarterly}
              </li>
              <li
                className={activeState == 3 ? "active" : ""}
                onClick={() => setActiveState(3)}
              >
                {translate.subscriptions.yearly}
              </li>
            </ul>
            <div className="tbs_cnt">
              <div
                className={
                  activeState == 1
                    ? "active tbs_inr hd_1 text_center"
                    : "tbs_inr hd_1 text_center"
                }
              >
                <figure>
                  <img src="/static/images/quartely.png" alt=""></img>
                </figure>
                <h3> {translate.subscriptions.monthly}</h3>
                <h4>$90</h4>
                <ul className="tbs_inr_lst">
                  <li> {translate.subscriptions.monthlyLine1}</li>
                  <li> {translate.subscriptions.monthlyLine2}</li>
                  <li> {translate.subscriptions.monthlyLine3}</li>
                  <li> {translate.subscriptions.monthlyLine4}</li>
                </ul>
                <Button
                  type="button"
                  className="btn btn_primary"
                  onClick={() => navigate("/subscription-plan")}
                >
                  {translate.subscriptions.subscribe}
                </Button>
              </div>
              <div
                className={
                  activeState == 2
                    ? "active tbs_inr hd_1 text_center"
                    : "tbs_inr hd_1 text_center"
                }
              >
                <figure>
                  <img src="/static/images/quartely.png" alt=""></img>
                </figure>
                <h3>{translate.subscriptions.quarterly}</h3>
                <h4>$90</h4>
                <ul className="tbs_inr_lst">
                  <li>{translate.subscriptions.quarterlyLine1} </li>
                  <li>{translate.subscriptions.quarterlyLine2} </li>
                  <li>{translate.subscriptions.quarterlyLine3} </li>
                  <li>{translate.subscriptions.quarterlyLine4} </li>
                </ul>
                <Button
                  type="button"
                  className="btn btn_primary"
                  onClick={() => navigate("/subscription-plan")}
                >
                  {translate.subscriptions.subscribe}
                </Button>
              </div>
              <div
                className={
                  activeState == 3
                    ? "active tbs_inr hd_1 text_center"
                    : "tbs_inr hd_1 text_center"
                }
              >
                <figure>
                  <img src="/static/images/quartely.png" alt=""></img>
                </figure>
                <h3>{translate.subscriptions.yearly}</h3>

                <h4>$90</h4>
                <ul className="tbs_inr_lst">
                  <li>{translate.subscriptions.yearlyLine1} </li>
                  <li>{translate.subscriptions.yearlyLine2} </li>
                  <li>{translate.subscriptions.yearlyLine3} </li>
                  <li>{translate.subscriptions.yearlyLine4} </li>
                </ul>
                <Button
                  type="button"
                  className="btn btn_primary"
                  onClick={() => navigate("/subscription-plan")}
                >
                  {translate.subscriptions.subscribe}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default HomeSection3;
