import React from "react";
import Layout from "../../layout";
import Features from "../../features";


import "react-tabs/style/react-tabs.css";
const SubscriptionPlanPage = () => {
 
  return (
    <div>
      <Layout.Header />
      <Features.SubscriptionPlanSection1 />
      <Features.SubscriptionPlanSection2 />

    
      <Layout.Footer />
    </div>
  );
};
export default SubscriptionPlanPage;

function useState(arg0: boolean): [any, any] {
  throw new Error("Function not implemented.");
}
