/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { covertFile } from "../../services/conversion";
import { showError, showToast } from "../../constants/toast";
import { END_POINTS } from "../../constants/url";
import { Check, SkipNext, SkipPrevious } from "@mui/icons-material";
import { Button, IconButton, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import { PDFDocument } from "pdf-lib";
import * as pdfjsLib from "pdfjs-dist";
import Loader from "../../constants/Loader";
import useTranslation from "../../hooks/Translation";
//@ts-ignore
// import pdfjsWorker from "pdfjs-dist/build/pdf.worker.mjs";

// Set the workerSrc to the correct path to the PDF worker
pdfjsLib.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjsLib.version}/build/pdf.worker.mjs`;

// let selectedImages:any=[]
const PdfPreview = () => {
  const translate = useTranslation();
  const { state } = useLocation();
  console.log("state :", state.selectedFile.name);
  const navigate = useNavigate();
  const pageLimit = 20;
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(pageLimit);
  const nextPage = () => {
    if (endPage > allImages.length) return;
    setStartPage(startPage + pageLimit);
    setEndPage(endPage + pageLimit);
  };
  const prevPage = () => {
    if (startPage < pageLimit) return;
    setStartPage(startPage - pageLimit);
    setEndPage(endPage - pageLimit);
  };
  const [file, setFile] = useState<any>();
  const [selectedImages, setSelectedImages] = useState<any>([]);
  // const handleUpload=(e:any)=>{
  //     setFile(e.target.files[0])
  //     uploadDoc(e.target.files[0])
  // }
  const jspdf = new jsPDF();

  const convertPdfToImages = async () => {
    setIsLoading(true);
    const fileReader = new FileReader();
    fileReader.onload = async () => {
      const pdfBytes = fileReader.result;
      const loadingTask = pdfjsLib.getDocument(pdfBytes as ArrayBuffer);
      const pdf = await loadingTask.promise;
      const imageArray = [];

      for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
        const page = await pdf.getPage(pageNumber);

        const viewport = page.getViewport({ scale: 1 });
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        const renderContext: any = {
          canvasContext: context,
          viewport: viewport,
        };

        await page.render(renderContext).promise;

        const imgSrc = canvas.toDataURL(); // Convert canvas to image
        imageArray.push({ image: imgSrc, index: imageArray.length });
      }
      setImages(imageArray);
      setIsLoading(false);

      console.log("imageArray :", imageArray);
    };
    fileReader.readAsArrayBuffer(state.selectedFile);
  };

  const loadFileData = async () => {
    if (!selectedImages.length) {
      showError(
        translate.errors.selectAtleastPage || "Select atleast one page"
      );
      return;
    }
    // jspdf.loadFile(state.selectedFile)
    const fileReader = new FileReader();
    fileReader.onload = async () => {
      const pdfBytes = fileReader.result;
      const pdfDoc = await PDFDocument.load(pdfBytes as ArrayBuffer);
      const pages = pdfDoc.getPages();
      const newPdfDoc = await PDFDocument.create();
      if (selectedImages.length !== allImages.length) {
        for (let key = 0; key < pages.length; key++) {
          const exist = selectedImages.some((data: any) => data.index == key);
          // const exist=true;
          if (exist) {
            const [firstDonorPage] = await newPdfDoc.copyPages(pdfDoc, [key]);
            newPdfDoc.addPage(firstDonorPage);
          }
        }
      }
      console.log("pdfDoc :", pdfDoc);
      console.log(newPdfDoc);
      const fileToDownload = await newPdfDoc.save();
      const blob = new Blob([fileToDownload]);
      if (state.nextPage)
        navigate(state.nextPage, {
          replace: true,
          state: {
            ...state,
            fileName: state?.selectedFile?.name
              ? state.selectedFile.name
              : new Date(),
            selectedFile:
              selectedImages.length == allImages.length
                ? state.selectedFile
                : blobToFile(blob, "mohit.pdf"),
          },
        });
      //   const blobUrl = window.URL.createObjectURL(blob);

      //     const link = document.createElement('a');
      //     link.href = blobUrl;
      //     link.download = "mohit.pdf";
      //     document.body.appendChild(link);
      //     link.click();
      //     setTimeout(()=>{
      //         document.body.removeChild(link);
      //         window.URL.revokeObjectURL(blobUrl);
      //     },100)
    };
    fileReader.readAsArrayBuffer(state.selectedFile);
  };
  useEffect(() => {
    if (state?.selectedFile) {
      convertPdfToImages();
    } else showError(translate.errors.corruptedPdfFile || "Corrupted PDF file");
  }, []);
  const [isLoading, setIsLoading] = useState(false);
  const [allImages, setImages] = useState<any>([]);
  function blobToFile(theBlob: any, fileName: any) {
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date().getTime();
    theBlob.name = fileName;
    return theBlob as File;
  }
  // const uploadDoc = async (file:any) => {
  //     const uploadDocument = await covertFile(file,'pdf_file', END_POINTS.pdfToImage)
  //   if (uploadDocument.status == 200) {
  //       showToast(uploadDocument?.message || "Conversion successful")
  //       setImages(uploadDocument.data)
  //     }
  //     console.log(uploadDocument);
  //   }
  //       const nextButton=()=>{
  //     const temp:any=[];
  //     selectedImages.map((element:string) => {
  //         fetch(element)
  //         .then((data)=>data.blob())
  //         .then((data:any)=>{
  //             temp.push(blobToFile(data,new Date().toString()))
  //             if(temp.length==selectedImages.length)
  //             {
  //                 showToast("Files Sent Successfully")
  //                 console.log('data :', temp);
  //             }
  //         })
  //     })
  // // console.log(temp);
  // }
  return (
    <>
      <Loader isLoad={isLoading} />
      {/* <input type='file' onChange={handleUpload}/> */}
      <section className="pdf_preview">
        <div className="conta_iner">
          <div className="pdf_inner">
            {/* <div className="lt_s">
              <h1>Uploaded <br />PDF Preview</h1>
              <p>Please click on PDF pages preview to select.</p>
               <ul>
                <li>
                  <span>Selected Images:</span>
                  <strong>{selectedImages.length}</strong>
                </li>
                <li>
                  <span>Total Images:</span>
                  <strong>{allImages.length}</strong>
                </li>
                <li>
                  <span>Current Pages:</span>
                  <strong>{Math.floor(endPage / pageLimit)}</strong>
                </li>
                <li>
                  <span>Total Pages:</span>
                  <strong>{Math.ceil(allImages.length / pageLimit)}</strong>
                </li>
              </ul>
            </div> */}
            <div className="rt_s">
              <div className="pdf_select">
                <p
                  onClick={() => {
                    if (selectedImages.length == allImages.length) {
                      setSelectedImages([]);
                    } else {
                      setSelectedImages(allImages);
                    }
                  }}
                >
                  {selectedImages.length == allImages.length
                    ? "Deselect all"
                    : "Select all"}
                </p>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(state?.prevPage || "/");
                  }}
                >
                  Close
                </span>
              </div>
              <ul className="figure_list">
                {allImages.map((data: any, index: any) => {
                  // allImages.slice(startPage, endPage).map((data: any, index: any) => {
                  return (
                    <li
                      key={index}
                      onClick={() => {
                        const imageExist = selectedImages.some(
                          (data2: any) => data2.index == data.index
                        );
                        if (imageExist) {
                          setSelectedImages((prev: any) =>
                            prev.filter(
                              (data2: any) => data2.index !== data.index
                            )
                          );
                        } else {
                          // selectedImages.push(data);
                          setSelectedImages((prev: any) => [...prev, data]);
                        }
                      }}
                    >
                      <figure>
                        <span>
                          {selectedImages.some(
                            (data2: any) => data2.index == data.index
                          ) && <Check color="error" />}
                        </span>
                        <img loading="lazy" src={data.image} />
                      </figure>
                    </li>
                  );
                })}
              </ul>
              <div className="pdf_pagination">
                <Button onClick={loadFileData}>
                  {translate.global.submit || "Submit"}
                </Button>
                {/* <IconButton onClick={prevPage}><SkipPrevious /></IconButton>
                  <IconButton onClick={nextPage}><SkipNext /></IconButton> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Button onClick={loadFileData}>Submit</Button> */}
    </>
  );
};

export default PdfPreview;
