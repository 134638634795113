import { Close, Search } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  Card,
  TextField,
  IconButton,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { StickerPickerType } from "../types/stickerPicker";
import useTranslation from "../hooks/Translation";
let headers: any = {
  Accept: "application/json",
  "Content-Type": "application/json",
  //   "x-api-key": `VaMesZr3M7gJRLIosSoreA`,
};
let timer: any = null;
const StickerPicker = ({
  open,
  setOpen,
  loadImageAndAdd,
}: {
  open: any;
  setOpen: any;
  loadImageAndAdd: any;
}) => {
  const translate = useTranslation();
  const [stickerData, seStickerData] = useState<StickerPickerType[]>([]);
  const [displayLoader, setDisplayLoader] = useState(false);
  const debounceSearch = (search: string) => {
    if (timer) clearTimeout(timer);
    seStickerData([]);
    setDisplayLoader(true);
    timer = setTimeout(() => {
      fetchStickers(search);
    }, 1500);
  };
  const fetchStickers = async (search?: string) => {
    const response = await fetch(
      search
        ? `https://api.giphy.com/v1/stickers/search?api_key=fM5Q9myL4G64QTbBcSdF2yj32fG55d2B&q=${search}`
        : `https://api.giphy.com/v1/stickers/trending?api_key=fM5Q9myL4G64QTbBcSdF2yj32fG55d2B`,
      {
        headers,
      }
      //   search
      //     ? `https://api.stickerapi.io/v2/stickers/search?q=${search}`
      //     : `https://api.stickerapi.io/v2/stickers/trending`,
      //   {
      //     headers,
      //   }
    );
    const data = await response.json();
    seStickerData(data.data);
    setDisplayLoader(false);
    console.log(data);
  };

  useEffect(() => {
    if (open) {
      fetchStickers();
    } else {
      seStickerData([]);
    }
  }, [open]);
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      className="sticker_dialog"
    >
      <DialogContent>
        <div className="sticky_head">
          <div className="control_group">
            <TextField
              className="mohitRename"
              fullWidth
              hiddenLabel
              placeholder={translate.global.searchStickers || "Search stickers"}
              onChange={(e) => debounceSearch(e.target.value)}
            />
          </div>
          <IconButton onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
        </div>
        <Card
          className="total_sticker"
          style={{
            display: "flex",
            alignItems: "center",
            padding: "5px",

            flexWrap: "wrap",
            minHeight: "calc(100vh - 170px)",
          }}
        >
          {stickerData?.length
            ? stickerData?.map((data: StickerPickerType, index: number) => (
                <figure
                  onClick={() => {
                    //   fetchStickerFile(data.images.fixed_height.url);
                    loadImageAndAdd(data.images["original_still"].url);
                    setOpen(false);
                  }}
                  style={{
                    border: "solid black 1px",
                    width: "100px",
                    height: "100px",
                    maxHeight: "100px",
                    maxWidth: "100px",
                  }}
                >
                  <img
                    src={data.images["original_still"].url}
                    loading="lazy"
                    style={{ width: "100%", height: "100%", cursor: "pointer" }}
                  />
                </figure>
              ))
            : null}
          {displayLoader ? (
            <div
              style={{ width: "100%", textAlign: "center", marginTop: "10px" }}
            >
              {" "}
              <CircularProgress />
            </div>
          ) : stickerData?.length ? null : (
            translate.global.noStickersFound || "No Stickers found"
          )}
        </Card>
      </DialogContent>
    </Dialog>
  );
};

export default StickerPicker;
