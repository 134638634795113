export const presentationTemplate=[
    {
    id:1,
    path:'/static/images/ppt/green.jpg',
    name:"Blue"
    },
    {
    id:2,
    path:'/static/images/ppt/blue.jpg',
    name:"Light Blue"
    },
    {
    id:3,
    path:'/static/images/ppt/green2.jpg',
    name:"Green"
    },
    {
    id:4,
    path:'/static/images/ppt/blue2.jpg',
    name:"Blue 2"
    },
    {
    id:5,
    path:'/static/images/ppt/b15.jpg',
    name:"Abstract"
    },
    {
    id:6,
    path:'/static/images/ppt/pink.jpg',
    name:"Pink"
    },
    {
    id:7,
    path:'/static/images/ppt/objects.jpg',
    name:"Objects"
    },
    {
    id:8,
    path:'/static/images/ppt/minimal.jpg',
    name:"Minimal"
    },
    {
    id:9,
    path:'/static/images/ppt/alice.jpg',
    name:"Alice"
    },

]