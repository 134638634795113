import { Dialog, DialogContent, Card, Typography, Button } from "@mui/material";
import useTranslation from "../hooks/Translation";
export const DeleteModal = ({
  openDelete,
  setOpenDelete,
  handleDelete,
  textData,
}: {
  openDelete: any;
  setOpenDelete: any;
  handleDelete: any;
  textData: any;
}) => {
  const translate = useTranslation();
  return (
    <Dialog
      open={openDelete}
      onClose={() => {
        setOpenDelete(false);
      }}
    >
      <DialogContent
        style={{
          padding: 0,
          width: "400px",
          height: "200px",
        }}
      >
        <Card
          style={{
            color: "black",
            background: "#373737",
            width: "400px",
            height: "200px",
            padding: "40px",
          }}
        >
          <Typography style={{ marginBottom: "30px", marginLeft: "10px" }}>
            {`${translate.testing.deleteWarning} ${textData}?`}
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <Button onClick={handleDelete}>{translate.global.yes}</Button>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                setOpenDelete(false);
              }}
            >
              {translate.global.no}
            </Button>
          </div>
        </Card>
      </DialogContent>
    </Dialog>
  );
};
