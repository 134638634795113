import React, { useState } from "react";
import Layout from "../../layout";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

import "react-tabs/style/react-tabs.css";
import useTranslation from "../../hooks/Translation";

const Login = () => {
  const translate = useTranslation();
  const navigate = useNavigate();

  return (
    <div>
      <section className="bnr_sec c_white test_sec jn_sec">
        <div className="conta_iner">
          <div className="bnr_mn">
            <div className="bnr_lt">
              <figure className="logo">
                <img
                  src="/static/images/logo.svg"
                  alt=""
                  onClick={() => navigate("/")}
                ></img>
              </figure>
              <h1>
                <span className="d_block">{translate.auth.joinMillions}</span>{" "}
                {translate.global.of}
                <span className="ai_bdr usr">
                  {translate.global.users} <span></span> <span></span>{" "}
                </span>{" "}
              </h1>
              <p>
                {translate.auth.loginDetailLine1}
                <span className="d_block">
                  {translate.auth.loginDetailLine2}
                </span>{" "}
                {translate.auth.loginDetailLine3}
              </p>
            </div>
            <div className="logn_rt text_center">
              <video
                autoPlay
                muted
                loop
                src="/static/images/sign_up.mp4"
              ></video>
              <button
                type="button"
                className="btn sng_up btnn lrg"
                onClick={() => navigate("/signin")}
              >
                {translate.auth.signIn}
              </button>
              <Button
                type="button"
                className="btn btn_primary btnn lrg"
                onClick={() => navigate("/signup")}
              >
                {translate.auth.signUp}
              </Button>
              <p className="lne">
                <small> {translate.global.or}</small>
              </p>
              <ul className="lgn_icn">
                <li>
                  <figure>
                    <img src="/static/images/facebook.png" alt=""></img>
                  </figure>
                </li>
                <li>
                  <figure>
                    <img src="/static/images/gogle.png" alt=""></img>
                  </figure>
                </li>
                <li>
                  <figure>
                    <img src="/static/images/apple.png" alt=""></img>
                  </figure>
                </li>
                <li>
                  <figure>
                    <img src="/static/images/x-social.png" alt=""></img>
                  </figure>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Login;
