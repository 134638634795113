import React, { useEffect, useState } from "react";
import * as fabric from "fabric";
import { HexColorPicker } from "react-colorful";
import useTranslation from "../../hooks/Translation";

const NoteCreate = () => {
  const translate = useTranslation();
  const [canvas, setCanvas] = useState<any>(null);
  const [penMode, setPenMode] = useState(false);
  const [highlightMode, setHighlightMode] = useState(false);
  const [penSize, setPenSize] = useState(3);
  const [color, setColor] = useState("#000000");

  const CANVAS_WIDTH = 1152;
  const CANVAS_HEIGHT = 648;
  const [canvasHeight, setCanvasHeight] = useState(CANVAS_HEIGHT);
  useEffect(() => {
    const canvasElement: any = document.getElementById("canvas");
    const canvasInstance = new fabric.Canvas(canvasElement, {
      width: CANVAS_WIDTH,
      height: CANVAS_HEIGHT,
      backgroundColor: "#FFFFFF",
      preserveObjectStacking: true,
    });

    canvasInstance.renderAll();
    setCanvas(canvasInstance);
    handleInitialText(canvasInstance);
    return () => {
      canvasInstance.dispose();
    };
  }, []);

  const handleDelete = () => {
    const active = canvas.getActiveObject();
    if (active && !active?.name) {
      canvas.remove(active);
    }
  };

  const handlePenMode = () => {
    setHighlightMode(false);
    if (penMode) {
      setPenMode(false);
      canvas.isDrawingMode = false;
      return;
    }
    setPenMode(true);
    canvas.isDrawingMode = true;
    canvas.freeDrawingBrush = new fabric.PencilBrush(canvas);
    canvas.freeDrawingBrush.width = penSize;
    canvas.freeDrawingBrush.color = color;
    canvas.on("path:created", (options: any) => {
      if (penMode) {
        const path = options.path;
        path.set({
          stroke: color,
          strokeWidth: 5,
          selectable: true,
        });
      }
    });
  };

  const handleHighlightMode = () => {
    setPenMode(false);
    if (highlightMode) {
      setHighlightMode(false);
      canvas.isDrawingMode = false;
      return;
    }
    setHighlightMode(true);
    canvas.isDrawingMode = true;
    canvas.freeDrawingBrush = new fabric.PencilBrush(canvas);
    canvas.freeDrawingBrush.width = 8;
    canvas.freeDrawingBrush.color = "#FFFF0040";
    canvas.on("path:created", (options: any) => {
      if (highlightMode) {
        const path = options.path;
        path.set({
          stroke: color,
          strokeWidth: 5,
          selectable: true,
        });
      }
    });
  };

  const handleAddText = () => {
    const text = new fabric.Textbox("\n\n\n", {
      fontSize: 18,
      fontFamily: "Arial",
      fill: "#000000",
      editable: true,
      height: 200,
      width: 500,
    });
    canvas.add(text);
    canvas.setActiveObject(text);
  };

  const handleInitialText = (canvasInstance: any) => {
    const text = new fabric.Textbox(
      "\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n",
      {
        fontSize: 18,
        fontFamily: "Arial",
        fill: "#000000",
        editable: true,
        height: CANVAS_HEIGHT,
        width: CANVAS_WIDTH,
        name: "MainText",
        lockMovementX: true,
        lockMovementY: true,
      }
    );
    canvasInstance.add(text);
    canvasInstance.setActiveObject(text);
  };

  const handleUploadImage = (event: any) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const imageData = reader.result;
      const img: any = new Image();
      img.src = imageData;
      img.onload = () => {
        const image = new fabric.Image(img, {
          scaleX: 200 / img.width,
          scaleY: 200 / img.width,
        });
        canvas.add(image);
        canvas.setActiveObject(image);
      };
    };
    reader.readAsDataURL(file);
  };

  const handleAlignLeft = () => {
    const activeObject = canvas.getActiveObject();
    if (activeObject && activeObject.type === "textbox") {
      activeObject.set({ textAlign: "left" });
      canvas.renderAll();
    }
  };

  const handleAlignCenter = () => {
    const activeObject = canvas.getActiveObject();
    if (activeObject && activeObject.type === "textbox") {
      activeObject.set({ textAlign: "center" });
      canvas.renderAll();
    }
  };

  const handleAlignRight = () => {
    const activeObject = canvas.getActiveObject();
    if (activeObject && activeObject.type === "textbox") {
      activeObject.set({ textAlign: "right" });
      canvas.renderAll();
    }
  };

  const handleUnderline = () => {
    const activeObject = canvas.getActiveObject();
    if (activeObject && activeObject.type === "textbox") {
      activeObject.set({ underline: !activeObject.underline });
      canvas.renderAll();
    }
  };

  const handleItalic = () => {
    const activeObject = canvas.getActiveObject();
    if (activeObject && activeObject.type === "textbox") {
      activeObject.set({
        fontStyle: activeObject.fontStyle === "italic" ? "normal" : "italic",
      });
      canvas.renderAll();
    }
  };

  const handleBold = () => {
    const activeObject = canvas.getActiveObject();
    if (activeObject && activeObject.type === "textbox") {
      const selectedText = activeObject.getSelectedText();
      const startIndex = activeObject.getSelectionStart();
      const endIndex = activeObject.getSelectionEnd();

      if (selectedText) {
        const currentStyle = activeObject.getSelectionStyles(
          startIndex,
          endIndex
        )[0];
        const newStyle = {
          ...currentStyle,
          fontWeight: currentStyle.fontWeight === "bold" ? "normal" : "bold",
        };

        activeObject.setText(selectedText, newStyle, startIndex, endIndex);
        canvas.renderAll();
      }
    }
  };

  const handleIncreaseFontSize = () => {
    const activeObject = canvas.getActiveObject();
    if (activeObject && activeObject.type === "textbox") {
      activeObject.set({ fontSize: activeObject.fontSize + 1 });
      canvas.renderAll();
    }
  };

  const handleDecreaseFontSize = () => {
    const activeObject = canvas.getActiveObject();
    if (activeObject && activeObject.type === "textbox") {
      activeObject.set({ fontSize: activeObject.fontSize - 1 });
      canvas.renderAll();
    }
  };

  return (
    <div className="sdbr_rltv">
      {penMode || highlightMode ? (
        <div>
          <HexColorPicker
            color={color}
            onChange={(value) => {
              if (value) {
                setColor(value);
                if (canvas && canvas.freeDrawingBrush) {
                  canvas.freeDrawingBrush.color = `${value}${highlightMode ? "80" : ""}`;
                  console.log(`${value}${highlightMode ? "40" : ""}`);
                }
              }
            }}
          />

          <input
            type="range"
            min="1"
            max="20"
            value={`${penSize}`}
            onChange={(value) => {
              console.log(value.target.value);
              if (value.target.value) {
                setPenSize(Number(value.target.value));
                if (canvas && canvas.freeDrawingBrush) {
                  canvas.freeDrawingBrush.width = value.target.value;
                }
              }
            }}
          />
        </div>
      ) : null}
      <div style={{ margin: 30 }}>
        <canvas id="canvas" style={{ border: "1px solid #ccc" }} />
      </div>
      <button onClick={handleAddText}>Add Text </button>
      <button onClick={handleDelete}>Delete</button>
      <button onClick={handlePenMode}>Pen</button>
      <button onClick={handleHighlightMode}>Highlight</button>
      <input accept="image/*" type="file" onChange={handleUploadImage} />

      {/* Text formatting buttons */}
      <button onClick={handleAlignLeft}>Align Left</button>
      <button onClick={handleAlignCenter}>Align Center</button>
      <button onClick={handleAlignRight}>Align Right</button>
      <button onClick={handleUnderline}>Underline</button>
      <button onClick={handleItalic}>Italic</button>
      <button onClick={handleBold}>Bold</button>
      <button onClick={handleIncreaseFontSize}>Increase Font Size</button>
      <button onClick={handleDecreaseFontSize}>Decrease Font Size</button>
      <button
        onClick={() => {
          canvas.setHeight(canvasHeight + 100);
          setCanvasHeight((prev) => prev + 100);
        }}
      >
        INCREASE Size
      </button>
    </div>
  );
};

export default NoteCreate;
