import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import { Accordion, AccordionItem } from "@szhsin/react-accordion";

import "react-tabs/style/react-tabs.css";
import { useLazyGetFaqQuery } from "../../services/cms";
import { showError } from "../../constants/toast";
import Loader from "../../constants/Loader";
import useTranslation from "../../hooks/Translation";

const Faqpage = () => {
  const [getfaq] = useLazyGetFaqQuery();
  const [faqData, setFaqData] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const translate = useTranslation();

  const fetchFaq = async () => {
    setIsLoading(true);
    try {
      const res = await getfaq({}).unwrap();
      if (res?.status === 200) {
        setFaqData(res?.data);
      }
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      showError(error?.data?.message || error?.message || translate.errors.somethingWentWrong || "");
    }
  };

  useEffect(() => {
    fetchFaq();
  }, []);

  return (
    <div className="">
      <Loader isLoad={isLoading} />
      <Layout.Header />
      <section className="abut_us bg_color ">
        <div className="conta_iner">
          <div className="text_center hd_1">
            <h2>FAQ’S</h2>
            <p>
              It is a long established fact that a reader will be distracted by
              the readable content{" "}
              <span className="d_block">
                {" "}
                of a page when looking at its layout. The point of using Lorem
                Ipsum is.
              </span>
            </p>
          </div>
        </div>
      </section>
      <section className="faq_sec u_spc">
        <div className="conta_iner">
          {/* <Accordion>
            <AccordionItem header="How to learn trignomentery table easily?">
              It is a long established fact that a reader will be distracted by the readable content of a page <span className="d_block">when looking at its layout. The point of using Lorem Ipsum is.</span>
            </AccordionItem>
            <AccordionItem header="How to learn trignomentery table easily?">
              It is a long established fact that a reader will be distracted by the readable content of a page <span className="d_block">when looking at its layout. The point of using Lorem Ipsum is.</span>
            </AccordionItem>
            <AccordionItem header="How to learn trignomentery table easily?">
              It is a long established fact that a reader will be distracted by the readable content of a page <span className="d_block">when looking at its layout. The point of using Lorem Ipsum is.</span>

            </AccordionItem>
            <AccordionItem header="How to learn trignomentery table easily?">
              It is a long established fact that a reader will be distracted by the readable content of a page <span className="d_block">when looking at its layout. The point of using Lorem Ipsum is.</span>

            </AccordionItem>
            <AccordionItem header="How to learn trignomentery table easily?">
              It is a long established fact that a reader will be distracted by the readable content of a page <span className="d_block">when looking at its layout. The point of using Lorem Ipsum is.</span>

            </AccordionItem>
            <AccordionItem header="How to learn trignomentery table easily?">
              It is a long established fact that a reader will be distracted by the readable content of a page <span className="d_block">when looking at its layout. The point of using Lorem Ipsum is.</span>

            </AccordionItem>
          </Accordion> */}
          {faqData?.length
            ? faqData.map((item: any) => {
              return (
                <Accordion>
                  <AccordionItem header={"Question :  " + item?.question}>
                    {"Answer :  " + item?.answer}
                  </AccordionItem>
                </Accordion>
              );
            })
            : ""}
        </div>
      </section>
      <Layout.Footer />
    </div>
  );
};
export default Faqpage;
