import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { generateFileSummarization } from "../services/fileSummarisation";
import { showError, showWarning } from "../constants/toast";
import useTranslation from "../hooks/Translation";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function Drag(props: any) {
  const translate = useTranslation();

  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = React.useState<any>(null);
  const { pathname } = useLocation();

  console.log(selectedFile, "selectedFile---->");

  const handleFileDrop = (event: any) => {
    if (!event.length) {
      showError(
        translate.errors.onlyPDFFormat ||
          "Please upload the file in PDF format only"
      );
      return;
    }
    // console.log(event);
    setSelectedFile(event[0]);
    // processPdf(event[0]);
    navigate("/pdfPreview", {
      state: {
        type: props?.type,
        prevPage: pathname,
        nextPage: props?.nextPage,
        selectedFile: event[0],
      },
    });
    // navigate('/test-ai', {
    //   state: {
    //     type: props?.type,
    //     prevPage: pathname,
    //     nextPage: props?.nextPage,
    //     selectedFile: event[0]
    //   }
    // })
  };
  const { getRootProps, getInputProps } = useDropzone({
    accept: { "application/pdf": [".pdf"] },
    onDrop: handleFileDrop,
    multiple: false,
  });
  return (
    <>
      <React.Fragment>
        <BootstrapDialog
          className="modl_drg modl text_center"
          onClose={props.handleClose}
          aria-labelledby="customized-dialog-title"
          open={props.open}
        >
          <IconButton
            aria-label="close"
            onClick={props.handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers {...getRootProps()}>
            <div className="upld_img_modal">
              <figure>
                <img
                  width={"100px"}
                  height={"95px"}
                  src="/static/images/pdf-upload.png"
                  alt=""
                ></img>
              </figure>
              <p className="drg">
                {translate.global.dragAndDropPdf}{" "}
                <span className="d_block">{translate.global.or}</span>
              </p>
              <Button className="btnn btn_primary">
                {translate.global.browseFile}
              </Button>
              {
                <input
                  type="file"
                  {...getInputProps()}
                  // accept={selectedAccepted}
                  // onChange={handleFileChange}
                />
              }
            </div>
          </DialogContent>
          <DialogActions></DialogActions>
        </BootstrapDialog>
      </React.Fragment>
    </>
  );
}
export default Drag;
