import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import Features from "../../features";
import { showError } from "../../constants/toast";
import { useLazyGetContactQuery } from "../../services/cms";
import Loader from "../../constants/Loader";
import useTranslation from "../../hooks/Translation";

const PrivacyPolicy = () => {
  const translate = useTranslation();
  const [PrivacyPolicy] = useLazyGetContactQuery();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [privacy, setPrivacy] = useState<string>("");

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const res = await PrivacyPolicy({}).unwrap();
      if (res?.status === 200) {
        setPrivacy(res?.data?.privacy_policy?.privacy_policy);
      }
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      showError(
        error?.message ||
          translate.errors.somethingWentWrong ||
          "Something went wrong"
      );
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div>
      <Loader isLoad={isLoading} />
      <Layout.Header />
      <section className="abut_us bg_color" id="about">
        <div className="conta_iner">
          <div className="text_center hd_1">
            <h2>{translate.global.privacyPolicy || "Privacy Policy"}</h2>
            <div
              style={{ textAlign: "center", fontSize: "22px" }}
              dangerouslySetInnerHTML={{
                __html: privacy
                  ? privacy
                  : translate.about.noInformationProvided ||
                    "No information Provided",
              }}
            ></div>
          </div>
        </div>
      </section>
      <Features.AboutSection4 />
      <Layout.Footer />
    </div>
  );
};
export default PrivacyPolicy;
