import Layout from "../../layout/index";
import "react-tabs/style/react-tabs.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Button, Tooltip, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import Header from "../../layout/header/Header";
import { showToast } from "../../constants/toast";
import { handleDownload } from "../../utils/handleDownload";
import useTranslation from "../../hooks/Translation";
const FileCovert = () => {
  const navigate = useNavigate();
  const translate = useTranslation();
  const [sidebar, setSidebar] = useState(true);
  const { state } = useLocation();
  const [selectedImage, setSelectedImage] = useState("");
  const selectedFormat: any = {
    pdf: "/static/images/format/pdf.png",
    doc: "/static/images/format/doc.png",
    docx: "/static/images/format/doc.png",
    png: "/static/images/format/img.png",
    jpeg: "/static/images/format/jpg.png",
    jpg: "/static/images/format/jpg.png",
    xls: "/static/images/format/xlsx.png",
    xlsx: "/static/images/format/xlsx.png",
    ppt: "/static/images/format/ppt.png",
    pptx: "/static/images/format/ppt.png",
  };

  const [selectedExtension, setSelectedExtension] = useState("");
  //   const handleDownload = (url:string, name:string) => {
  //     console.log(url,"url");

  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.setAttribute('download', name);
  //     link.target = '_blank';
  //     link.style.display = 'none';
  //     document.body.appendChild(link);

  //     link.click();
  //     setTimeout(() => {
  //         document.body.removeChild(link);
  //     }, 100);
  // }

  useEffect(() => {
    if (state) {
      // const extension="";
      const extension = Array.isArray(state?.data)
        ? state?.data?.[0]?.media_url.split(".")[
            state?.data?.[0]?.media_url.split(".").length - 1
          ]
        : state?.data?.media_url.split(".")[
            state?.data?.media_url.split(".").length - 1
          ];
      console.log(extension);
      setSelectedExtension(extension);
      setSelectedImage(selectedFormat[extension] || "");
    }
    console.log(state);
  }, []);
  return (
    <div className="sdbr_rltv">
      {sidebar ? <Layout.SidePanel /> : null}
      <Header setSidebar={setSidebar} />
      <div className="sde_br_mn ">
        {/* <div className="sde_hdr">
          <div className="sde_hdr_lt">
            <figure>
              <img src="/static/images/file_type.png" alt=""></img>
            </figure>
            <div className="">
              <h3>File Type Conversion</h3>
              <p> Lorem Ipsum dolor et amet</p>
            </div>
          </div>
          <div className="sde_hdr_rt">
            <figure>
              <img src="/static/images/notification.png" alt=""></img>
            </figure>
            <h3>Hi, Ahmed</h3>
            <figure>
              <img src="/static/images/ahmed.png" alt=""></img>
            </figure>
          </div>
        </div> */}
        <div className="sde_mn_cnt hd_6">
          <h3>
            {" "}
            {translate.fileTypeConversion.convertedFileReady ||
              "Your Converted file is ready"}
          </h3>

          <Tabs>
            <TabList>
              <Tab style={{ cursor: "context-menu" }}>
                {translate.fileTypeConversion.fileTypeConversion ||
                  "File Type Conversion"}
              </Tab>
            </TabList>

            <TabPanel className="  text_center fle_type_mn">
              <figure>
                <img
                  src={
                    selectedImage ? selectedImage : "/static/images/filetpe.png"
                  }
                  alt=""
                ></img>
              </figure>
              <Typography>
                {Array.isArray(state?.data)
                  ? translate.fileTypeConversion.totalFiles || "Total files"
                  : translate.fileTypeConversion.fileName || "File Name"}{" "}
                :{" "}
                {Array.isArray(state?.data) ? (
                  state?.data?.length
                ) : state?.data?.media_name?.length < 15 ? (
                  state?.data?.media_name
                ) : (
                  <Tooltip title={state?.data?.media_name}>
                    <div>{state?.data?.media_name.slice(0, 15) + "..."}</div>
                  </Tooltip>
                )}
              </Typography>
              <Typography>
                Type : {selectedExtension}
                {/* {Array.isArray(state?.data)?state?.data?.[0]?.media_type:state?.data?.media_type} */}
              </Typography>
              <div className="py_nw">
                <Button
                  className="btnn btn_primary"
                  onClick={() => {
                    if (Array.isArray(state?.data)) {
                      state?.data?.forEach((data: any, index: number) => {
                        setTimeout(() => {
                          handleDownload(data.media_url, data.media_name);
                        }, index * 200); // Adjust delay between downloads if needed
                      });
                    } else {
                      handleDownload(
                        state?.data?.media_url,
                        state?.data?.media_name
                      );
                    }
                    // if(Array.isArray(state?.data))
                    //   {
                    //     state?.data?.map((data:any,index:number)=>{
                    //     //  window.open(data?.media_url)
                    //     setTimeout(()=>{

                    //       handleDownload(data?.media_url,data?.media_name)
                    //     },index*200)
                    //       // fileDownloader(data?.media_url,data?.media_name)
                    //     })
                    //   }
                    //   else
                    //   {
                    //     handleDownload(state?.data?.media_url,state?.data?.media_name)
                    //   }
                  }}
                >
                  {translate.global.download || "Download"}
                </Button>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default FileCovert;
