import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { useState } from "react";
import { useChangePasswordMutation } from "../services/auth";
import { useFormik } from "formik";
import * as Yup from "yup";
import { showError, showToast } from "../constants/toast";
import { generateEncryptedKeyBody } from "../utils/crypto";
import { CommonBody } from "../types/General";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import useTranslation from "../hooks/Translation";

const ChangePassword = () => {
  const translate = useTranslation();
  const navigate = useNavigate();
  const userData = useAuth();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [oldPassword, setOldPassword] = useState<boolean>(false);
  const [cnfmPassword, setCnfmPassword] = useState<boolean>(false);
  const [ChangePasswordMutation] = useChangePasswordMutation();

  const formik = useFormik({
    initialValues: {
      new_password: "",
      passwordConfirmation: "",
      old_password: "",
    },
    validationSchema: Yup.object({
      old_password: Yup.string()
        .label(translate.auth.oldPassword || "Old Password")
        .required(
          translate.validations.requiredOldPassword ||
          "Old password is required."
        ),

      new_password: Yup.string()
        .label(translate.auth.newPassword || "new Password")
        .min(
          5,
          translate.validations.passwordAtleast5CharacterLong ||
          "Password must be at least 5 characters long"
        )
        .required(
          translate.validations.requireNewPassword ||
          "New password is required."
        )
        .notOneOf(
          [Yup.ref("old_password"), null],
          translate.validations.newOldPasswordSameNotAllowed ||
          "New password cannot be same as old password."
        ),

      passwordConfirmation: Yup.string()
        .oneOf(
          [Yup.ref("new_password")],
          translate.validations.passwordMustMatch || "Passwords must match."
        )
        .required(
          translate.validations.requiredConfirmPassword ||
          "Confirm password is required."
        ),
    }),
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      formik.setSubmitting(true);

      let body = {
        old_password: formik.values.old_password,
        new_password: formik.values.new_password,
      };
      console.log(body, "body");

      try {
        let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response = await ChangePasswordMutation(encryptedBody).unwrap();
        if (response?.status === 200) {
          showToast(
            translate.auth.passwordChanged || "Password changed successfully"
          );
          resetForm();
        }
      } catch (error: any) {
        showError(error?.data?.message || error?.data?.message || "");
        console.log(error, "errorrr");
      }
      formik.setSubmitting(false);
    },
  });

  return (
    <>
      <div className="chn_pwrd">
        <div className="chn_pswd_lt">
          <figure>
            <img
              src={
                userData?.profile_picture?.media_url
                  ? userData?.profile_picture?.media_url
                  : "/static/images/mbr_sldr1.png"
              }
              alt=""
            ></img>
          </figure>
          {/* <figure className="cmra">
                    <input type="file" id="myFile" name="filename"></input>
                    <img src="/static/images/camera.png" alt="" ></img>
                  </figure> */}
          <h5>{userData?.first_name ? userData?.first_name : "-"}</h5>
        </div>
        <div className="chn_pswd_rt sign_in">
          <form
            className="frmn frm_sn logn_rt chn_pwrd_icn"
            onSubmit={formik.handleSubmit}
          >
            <div className="frmn_mn">
              {/* <div className="plc_hldr"> */}
              <label>{translate.auth.currentPassword}</label>
              <TextField
                className="control_group"
                hiddenLabel
                fullWidth
                placeholder={
                  translate.auth.enterCurrentPassword ||
                  "Enter current password"
                }
                // multiline={true}
                variant="outlined"
                id="old_password"
                name="old_password"
                type={oldPassword ? "text" : "password"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.old_password}
                helperText={
                  formik.touched.old_password && formik.errors.old_password
                }
                error={
                  formik.touched.old_password &&
                  Boolean(formik.errors.old_password)
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" className="eye_btn">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setOldPassword(!oldPassword)}
                        onMouseDown={(event) => event.preventDefault()}
                        edge="end"
                      >
                        {oldPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {/* </div> */}

              <i className="fa-regular "></i>
            </div>
            <div className="frmn_mn">
              {/* <div className="plc_hldr"> */}
              <label>{translate.auth.newPassword}</label>
              <TextField
                className="control_group"
                hiddenLabel
                fullWidth
                placeholder={translate.auth.enterNewPassword}
                // multiline={true}
                variant="outlined"
                id="new_password"
                name="new_password"
                type={cnfmPassword ? "text" : "password"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.new_password}
                helperText={
                  formik.touched.new_password && formik.errors.new_password
                }
                error={
                  formik.touched.new_password &&
                  Boolean(formik.errors.new_password)
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" className="eye_btn">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setCnfmPassword(!cnfmPassword)}
                        onMouseDown={(event) => event.preventDefault()}
                        edge="end"
                      >
                        {cnfmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {/* </div> */}
              <i className="fa-regular "></i>
            </div>
            <div className="frmn_mn">
              <label>{translate.auth.reEnterPassword}</label>
              <TextField
                className="control_group "
                hiddenLabel
                fullWidth
                placeholder={translate.auth.confirmNewPassword}
                // multiline={true}
                variant="outlined"
                id="passwordConfirmation"
                name="passwordConfirmation"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.passwordConfirmation}
                helperText={
                  formik.touched.passwordConfirmation &&
                  formik.errors.passwordConfirmation
                }
                error={
                  formik.touched.passwordConfirmation &&
                  Boolean(formik.errors.passwordConfirmation)
                }
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" className="eye_btn">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={(event) => event.preventDefault()}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <i className="fa-regular "></i>
            </div>
            <Button type="submit" className="btnn h_46">
              {translate.global.update || "Update"}
            </Button>
          </form>
        </div>
      </div>
    </>
  );
};
export default ChangePassword;
