import Compressor from "compressorjs";
import { getFromStorage } from "../constants/storage";
import { API_URL, END_POINTS, uploadUrl } from "../constants/url";
import { STORAGE_KEYS } from "../constants/storageKeys";

interface UploadResponse {
  code: number;
  status: number;
  url: string;
  data: any;
  message: string;
}
const accessToken = getFromStorage(STORAGE_KEYS.token);

// export const UploadMedia = (imageObject: any): Promise<UploadResponse> => {
//   return new Promise(async (resolve, reject) => {
//     new Compressor(imageObject, {
//       quality: 0.6,
//       success: async (compressedResult) => {
//         const formData = new FormData();
//         console.log(formData, "Sfdsfdfffdf");
//         formData.append("file", compressedResult as any);
//         // const encryptedFormData = generateEncryptedKeyBody(formData);
//         let headers = {
//           Accept: "application/json",
//           // Authorization: `Bearer ${accessToken}`,
//         };

//         try {
//           const res = await fetch(API_URL + END_POINTS?.upload, {
//             method: "POST",
//             headers,
//             body: formData,
//           });
//           let response = await res.json();
//           resolve(response); // Resolve the Promise with the response
//         } catch (error) {
//           console.log(error, ">>>>>>>>>");
//           reject(error); // Reject the Promise with the error
//         }
//       },
//     });
//   });
// };

// export const UploadMedia = (imageObject: any): Promise<UploadResponse> => {
//   return new Promise(async (resolve, reject) => {
//     const formData = new FormData();

//     formData.append("media", imageObject as any);
//     let headers = {
//       Accept: "application/json",
//       Authorization: `Bearer ${accessToken}`,
//     };

//     try {
//       const res = await fetch(uploadUrl, {
//         method: "POST",
//         headers,
//         body: formData,
//       });
//       let response = await res.json();
//       resolve(response); // Resolve the Promise with the response
//       console.log(response, "resssssss");
//     } catch (error) {
//       console.log(error, ">>>>>>>>>");
//       reject(error); // Reject the Promise with the error
//     }
//   });
// };

export const UploadMedia = async (
  imageObject: any,
  type?: string
): Promise<UploadResponse> => {
  const formData = new FormData();

  if (type) formData.append("media", imageObject, type);
  else formData.append("media", imageObject);

  const headers = {
    Accept: "application/json",
  };

  try {
    const res = await fetch(uploadUrl, {
      method: "POST",
      headers,
      body: formData,
    });

    if (!res.ok) {
      throw new Error(`Failed to upload image: ${res.statusText}`);
    }

    const response = await res.json();
    return response;
  } catch (error) {
    console.error("Error uploading image:", error);
    throw error; // Rethrow the error to be caught by the caller
  }
};
export const UploadTextData = async (textData: string): Promise<UploadResponse> => {
  const blob = new Blob([textData], { type: 'text/plain' });
  const formData = new FormData();

  formData.append('media', blob, "textData.txt");

  const headers = {
    Accept: 'application/json',
  };

  try {
    const res = await fetch(uploadUrl, {
      method: 'POST',
      headers,
      body: formData,
    });

    if (!res.ok) {
      throw new Error(`Failed to upload text file: ${res.statusText}`);
    }

    const response = await res.json();
    return response;
  } catch (error) {
    console.error('Error uploading text file:', error);
    throw error; // Rethrow the error to be caught by the caller
  }
};
export const Uploadpdf = async (imageObject: any) => {
  console.log("imageObject, ", imageObject);

  const formData = new FormData();

  formData.append("media", imageObject as any);

  const getToken = await getFromStorage(STORAGE_KEYS.token);
  const token = getToken ? JSON.parse(`${getToken}`) : null;
  let headers = {
    Accept: "application/json",
    Authorization: "Bearer " + token,
  };
  try {
    const res = await fetch(API_URL + END_POINTS.upload, {
      method: "POST",
      headers,
      body: formData,
    });
    let response = await res.json();
    return response;
  } catch (error) {
    console.log(error, ">>>>>>>>>");
    return error;
  }
};
