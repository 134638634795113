/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getAssignmentSolution } from "../../services/assignmentModule";
import { showError } from "../../constants/toast";
import useTranslation from "../../hooks/Translation";
import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { TestAI } from "../../constants/testAILoading";

function AssignmentVideo() {
  const navigate = useNavigate();
  const { state } = useLocation();

  console.log(state, "state in video page");

  const fun = async () => {
    try {
      const res = await getAssignmentSolution(
        state.file,
        state.type,
        state.language
      );
      console.log("res", res);
      if (res?.status === 200) {
        navigate(`/edit-assign/${res?.record_id}`, {
          state: res?.data,
          replace: true,
        });
      } else {
        showError("Please upload file again");
        navigate("/assign-solution");
      }
    } catch (error: any) {
      showError(error?.data?.message || error?.message);
      navigate("/assign-solution");
    }
  };

  useEffect(() => {
    // fun();
  }, []);
  const translate = useTranslation();

  return (
    <section className="bnr_sec cnvrt">
      <div className="conta_iner">
        <IconButton
          style={{ position: "absolute", right: "100px", color: "white" }}
          onClick={() => {
            navigate("/assignment-solution");
          }}
        >
          <Close />
        </IconButton>
        <div className="bnr_mn" onClick={() => navigate("/edit-assign")}>
          <div className="bnr_lt text_center">
            <video
              autoPlay
              loop
              muted
              src="/static/images/solution.mp4"
              className="tst_vdo"
            ></video>
            <h1 className="mt_40">
              {translate.home.let}
              <span className="ai_bdr">
                {translate.home.ai} <span></span>
              </span>
              <span className="d_block">{translate.home.doItMagic}</span>
            </h1>
          </div>
        </div>
      </div>
    </section>
  );
}
export default AssignmentVideo;
