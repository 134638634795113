/* eslint-disable @typescript-eslint/no-unused-vars */
import Layout from "../../layout/index";
import "react-tabs/style/react-tabs.css";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import Header from "../../layout/header/Header";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import useAuth from "../../hooks/useAuth";

function EditArtical() {
  const navigate = useNavigate();

  return (
    <div className="sdbr_rltv">
      <Layout.SidePanel />
      <div className="sde_hdr">
        <div className="sde_hdr_lt">
          <button className="back_btn" onClick={() => navigate("/language")}>
            <ArrowBackIosNewIcon />
          </button>
          <figure>
            <img src="/static/images/articles.png" alt=""></img>
          </figure>
          <div className="">
            <h3>Articales</h3>
            <p> Lorem Ipsum dolor et amet</p>
          </div>
        </div>
        <div className="sde_hdr_rt">
          <figure>
            <img src="/static/images/notification.png" alt=""></img>
          </figure>
          <h3>Hi, Ahmed</h3>
          <figure>
            <img src="/static/images/ahmed.png" alt=""></img>
          </figure>
        </div>
      </div>
      <div className="sde_br_mn">
        <div className="sde_mn_cnt tpc_rsrch">
          <Button className="btn btn_primary h_46">Edit</Button>
          <div className="edt_artcl">
            <p>How to learn trigonomentery table easily?</p>
            <ul>
              <li>1) lorem ipsum dolor set amet</li>
              <li>i) lorem ipsum dolor set amet</li>
              <li>ii) lorem ipsum dolor set amet</li>
            </ul>
            <ul>
              <li>2) lorem ipsum dolor set amet</li>
              <li>i) lorem ipsum dolor set amet</li>
              <li>ii) lorem ipsum dolor set amet</li>
            </ul>
            <ul>
              <li>1) lorem ipsum dolor set amet</li>
              <li>i) lorem ipsum dolor set amet</li>
              <li>ii) lorem ipsum dolor set amet</li>
            </ul>
            <ul>
              <li>2) lorem ipsum dolor set amet</li>
              <li>i) lorem ipsum dolor set amet</li>
              <li>ii) lorem ipsum dolor set amet</li>
            </ul>
          </div>
          <div className="rght_btn edt_art_btn">
            <ul className="edt_art_lst">
              <li className="active">
                <figure>
                  <img src="/static/images/genrte.png" alt="img"></img>
                </figure>
                <p>Regenerate</p>
              </li>
              <li>
                <figure>
                  <img src="/static/images/singlet.png" alt="img"></img>
                </figure>
                <p>Text Style</p>
              </li>
              <li>
                <figure>
                  <img src="/static/images/gallery.png" alt="img"></img>
                </figure>
                <p> Photo</p>
              </li>
              <li>
                <figure>
                  <img src="/static/images/text.png" alt="img"></img>
                </figure>
                <p> Pronoun</p>
              </li>
              <li>
                <figure>
                  <img src="/static/images/delete.png" alt="img"></img>
                </figure>
                <p> Delete</p>
              </li>
            </ul>
            <Button
              type="button"
              className="btnn btn_primary"
              onClick={() => navigate("/selecttons")}
            >
              Generate
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditArtical;
