export const  fileToBase64=(file:File)=> {
    return new Promise((resolve, reject) => {
      const reader:any = new FileReader();
      reader.onload = () => {
        const base64Data = reader?.result?.split(',')[1];
        resolve(base64Data);
      };
      reader.onerror = () => {
        reject(reader.error);
      };
      reader.readAsDataURL(file);
    });
  }