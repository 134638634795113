import React, { useState } from "react";
import Layout from "../../layout/index";
import "react-tabs/style/react-tabs.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AboutMe from "../../components/aboutMe";
import Notification from "../../components/notification";
import Header from "../../layout/header/Header";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import useTranslation from "../../hooks/Translation";

const NoteEdit = () => {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const translate = useTranslation();
  const navigate = useNavigate();
  const goProfile = () => {
    navigate("/setting");
  };
  const [open, setOpen] = React.useState<any>(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [sidebar, setSidebar] = useState(true);

  return (
    <>
      <div className="sdbr_rltv">
        {sidebar ? <Layout.SidePanel /> : null}
        <Header setSidebar={setSidebar} />

        <div className="sde_br_mn nt_tk">
          <div className="sde_mn_cnt">
            <div className="nt_btn">
              <button className="btnn btn_border" onClick={() => setOpen(true)}>
                <i className="fa-regular fa-plus"></i>{" "}
                {translate.noteTaking.createNewNote || "Create a new Note"}
              </button>
            </div>
            <Tabs>
              <TabList>
                <Tab>{translate.noteTaking.myPastNotes || "My Past Notes"}</Tab>
              </TabList>

              <TabPanel>
                <div className="nt_lst mt_40">
                  <ul className="nt_lt">
                    <li className="active nt_img">
                      <figure>
                        <img src="/static/images/doc.png" alt=""></img>
                      </figure>
                      <p>All Notes</p>
                      <span>100</span>
                    </li>
                    <li className="nt_img">
                      <figure>
                        <img src="/static/images/clock.png" alt=""></img>
                      </figure>
                      <p>clock</p>
                      <span>50</span>
                    </li>
                    <li className="nt_img">
                      <figure>
                        <img src="/static/images/unif.png" alt=""></img>
                      </figure>
                      <p>Unified</p>
                      <span>30</span>
                    </li>
                    <li className="nt_dot">
                      <p>Note 22 April</p>
                      <span>20</span>
                    </li>
                    <li className="nt_dot">
                      <p>Note 22 April</p>
                      <span>20</span>
                    </li>
                    <li className="nt_dot">
                      <p>Note 22 April</p>
                      <span>20</span>
                    </li>
                    <li className="nt_dot">
                      <p>Note 22 April</p>
                      <span>20</span>
                    </li>
                    <li className="nt_dot">
                      <p>Note 22 April</p>
                      <span>20</span>
                    </li>
                    <li className="nt_dot">
                      <p>Note 22 April</p>
                      <span>20</span>
                    </li>
                    <li className="nt_dot">
                      <p>Note 22 April</p>
                      <span>20</span>
                    </li>
                    <li className="nt_dot">
                      <p>Note 22 April</p>
                      <span>20</span>
                    </li>
                    <li className="nt_dot">
                      <p>Note 22 April</p>
                      <span>20</span>
                    </li>
                  </ul>
                  <ul className="nt_rt hd_6 text_center">
                    <li>
                      <FormGroup className="checkbox_label">
                        <Checkbox {...label} defaultChecked />
                      </FormGroup>
                      <figure>
                        <img src="/static/images/agrement.svg" alt=""></img>
                      </figure>
                      <h3>Note 24 April ...</h3>
                    </li>
                    <li>
                      <FormGroup className="checkbox_label">
                        <FormControlLabel control={<Checkbox />} label="" />
                      </FormGroup>
                      <figure>
                        <img src="/static/images/agrement.svg" alt=""></img>
                      </figure>
                      <h3>Note 24 April ...</h3>
                    </li>
                    <li>
                      <FormGroup className="checkbox_label">
                        <FormControlLabel control={<Checkbox />} label="" />
                      </FormGroup>
                      <figure>
                        <img src="/static/images/agrement.svg" alt=""></img>
                      </figure>
                      <h3>Note 24 April ...</h3>
                    </li>
                  </ul>
                </div>
              </TabPanel>
            </Tabs>
            <div className="prfl_btn">
              <Button className="btnn h_46">
                <img src="/static/images/dublicate.png" alt=""></img>Dublicate
              </Button>
              <Button className="btnn h_46">
                <i className="fa-regular fa-star"></i> Favourite{" "}
              </Button>
              <Button className="btnn h_46">
                <i className="fa-solid fa-trash-can"></i>Delete
              </Button>
              <Button
                className="btnn h_46"
                onClick={() => navigate("/note-taking")}
              >
                Done
              </Button>
            </div>
          </div>
        </div>
      </div>

      <AboutMe open={open} setOpen={setOpen} handleClose={handleClose} />
      <Notification
        open={open1}
        setOpen={setOpen1}
        handleClose={handleClose1}
      />
    </>
  );
};

export default NoteEdit;
