/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-anonymous-default-export */
import FileSummarizationPage from "./fileSummarization";
import Homepage from "./homepage";
import TestingPage from "./testing";
import Aboutpage from "./aboutpage";
import Subscriptionpage from "./subscriptionpage";
import Contactpage from "./contactpage";
import SubscriptionPlanPage from "./subscriptionplanpage";
import Faqpage from "./faqpage";
import Workpage from "./workpage";
import Login from "./auth/login";
import SignIn from "./auth/signin";
import SignUp from "./auth/signup";
import Forgot from "./auth/forget";
import Verify from "./auth/verify";
import Reset from "./auth/reset";
import WhizzoSubscription from "./auth/whizzosubscription";
import PaymentOption from "./paymentOption";
import Congratulation from "./congratulations";
import NoteTakingPage from "./notetaking";
import Research from "./research";
import AssignmentSolutions from "./assignmentsolution";
import FileType from "./filetype";
import Quiz from "./quiz";
import FlashCardQuiz from "./flashcard-quiz";
import PresentationPreview from "./presentationPreview";
import PresentationEdit from "./presentationedit";
import PresentationDown from "./presentationdown";
import Setting from "./settings";
import Presentations from "./presentations";
import Creatpresentations from "./creatpresention";
import Artical from "./artical";
import CreateArtical from "./createartical";
import SelectLanguage from "./selectlaguage";
import EditArtical from "./editartical";
import SelectTons from "./selectton";
import Pronouns from "./pronouns";
import Subscriptions from "./subscriptions";
import Assignsolution from "./assignsolution";
import TopicResearch from "./topicresearch";
import ResearchPages from "./reserchpages";
import Convert from "./convert";
import TestAi from "./testAi";
import EditAssignment from "./editassignment";
import ReserchTons from "./reserchTones";
import SpecifyReference from "./specifyReference";
import EditNote from "./editnote";
import EditFile from "./editfile";
import FileCovert from "./fileconvert";
import AssignmentVideo from "./assignmentvideo";
import Profile from "./auth/profile";
import ArticalEdit from "./articaledit";
import ResearchEdit from "./reserchedit";
import NoteEdit from "./noteedit";
import PrivacyPolicy from "./privacyPolicy";
import EditResearch2 from "./researchEdit2";
import EditArticle from "./editArticleMohit";
import TermsConditions from "./privacyPolicy/terms";
import TestingAbilities from "./testing/abilities";
import AchievementsTesting from "./testing/achievments";
import PresentationEditor from "./edit-ppt";
import NoteCreate from "./notecreate";
import NoteTakingEditFirst from "./edit-note-taking";
import PdfPreview from "./pdfPreview";

export default {
  FileSummarizationPage,
  TestingPage,
  Homepage,
  Aboutpage,
  Subscriptionpage,
  Contactpage,
  SubscriptionPlanPage,
  Faqpage,
  Workpage,
  Login,
  SignIn,
  SignUp,
  Forgot,
  Verify,
  Reset,
  WhizzoSubscription,
  PaymentOption,
  Congratulation,
  NoteTakingPage,
  Research,
  AssignmentSolutions,
  FileType,
  Quiz,
  FlashCardQuiz,
  PresentationPreview,
  PresentationEdit,
  PresentationDown,
  Setting,
  Presentations,
  Creatpresentations,
  Artical,
  CreateArtical,
  SelectLanguage,
  EditArtical,
  SelectTons,
  Pronouns,
  Subscriptions,
  Assignsolution,
  TopicResearch,
  ResearchPages,
  Convert,
  TestAi,
  EditAssignment,
  ReserchTons,
  SpecifyReference,
  EditNote,
  EditFile,
  FileCovert,
  AssignmentVideo,
  Profile,
  ArticalEdit,
  ResearchEdit,
  NoteEdit,
  PrivacyPolicy,
  EditResearch2,
  EditArticle,
  TermsConditions,
  TestingAbilities,
  AchievementsTesting,
  PresentationEditor,
  NoteCreate,
  NoteTakingEditFirst,
  PdfPreview
};




