import { API_URL, END_POINTS, purposeUrl } from "../constants/url";
import { CommonBody } from "../types/General";
import { UploadDocument } from "../utils/documentsUpload";
import emptySplitApi from "../utils/rtk";


type CommonResponseType = {
    status: number;
    message: string;
}

export const conversionApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getHistory: builder.mutation<
            CommonResponseType & { data: any },
            CommonBody
        >({
            query: (body) => ({
                url: END_POINTS.conversionHistory,
                method: "POST",
                body,
            }),
        }),


    }),
});


export const covertFile = async (
    selectedFile: any,
    key: string,
    endpoint: string,
) => {
    const formData = new FormData();
       
    if(Array.isArray(selectedFile))
    {
            for (let file = 0; file < selectedFile?.length; file++) {
                formData.append(key, selectedFile[file]);
              }
        }
        else{
    formData?.append(key,selectedFile)}
 
    console.log(formData);
    
    let url = `${API_URL}${endpoint}`;
    try {
        const response = await UploadDocument(url, formData);
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const {
    useGetHistoryMutation
} = conversionApi;