import React from "react";
import {
  TextField,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from "@mui/material";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useTranslation from "../../hooks/Translation";
const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
function Footer() {
  const navigate = useNavigate();
  const translate = useTranslation();
  return (
    <footer className="ftr">
      <div className="conta_iner">
        <div className="footer_flx">
          <div className="footer_cnt_bx">
            <figure className="footer_logo">
              <img src="/static/images/footer_logo.png" />
            </figure>
            <p>
              <small>{translate.footer.footerDescription}</small>
            </p>
          </div>
          <div className="footer_cnt_bx">
            <h4>{translate.footer.quickLinks}</h4>
            <ul className=" mn_ftr">
              <li>
                <a
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate("/");
                  }}
                >
                  {translate.home.home}
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate("/about");
                  }}
                >
                  {translate.about.aboutUs}
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate("/faq");
                  }}
                >
                  {translate.global.faqs}
                </a>
              </li>
            </ul>
          </div>

          <div className="footer_cnt_bx">
            <ul className=" mn_ftr">
              <li>
                <a
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate("/privacy-policy");
                  }}
                >
                  {translate.global.privacyPolicy}
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate("/terms-conditions");
                  }}
                >
                  {translate.global.termsConditions}
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate("/subscription-plan");
                  }}
                >
                  {translate.subscriptions.subscriptionPlans}
                </a>
              </li>
            </ul>
          </div>
          <div className="footer_cnt_bx">
            <h4>{translate.subscriptions.subscribeUpperCase}</h4>

            <div className="subscribe_ftr sub_btn">
              <TextField
                className="control_group"
                hiddenLabel
                placeholder={translate.global.email || "Email"}
                fullWidth
                variant="outlined"
              />
              <button>{translate.subscriptions.subscribe}</button>
            </div>

            <div className="scl_icns">
              <BootstrapTooltip title={translate.footer.facebook || "Facebook"}>
                <figure>
                  <img src="/static/images/facebook.svg" />
                </figure>
              </BootstrapTooltip>
              <BootstrapTooltip
                title={translate.footer.instagram || "Instagram"}
              >
                <figure>
                  <img src="/static/images/instagram.svg" />
                </figure>
              </BootstrapTooltip>
              <BootstrapTooltip title={translate.footer.linkedIn || "LinkedIn"}>
                <figure>
                  <img src="/static/images/linkedin.svg" />
                </figure>
              </BootstrapTooltip>
              <BootstrapTooltip title={translate.footer.youtube || "Youtube"}>
                <figure>
                  <img src="/static/images/youtube.svg" />
                </figure>
              </BootstrapTooltip>
            </div>
          </div>
        </div>
        <div className="ftr_btm">
          <p>{translate.footer.rightsReserved}</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
