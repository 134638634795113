/* eslint-disable @typescript-eslint/no-unused-vars */
import Layout from "../../layout/index";
import "react-tabs/style/react-tabs.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useNavigate } from "react-router-dom";
import Notification from "../../components/notification";
import { useEffect, useState } from "react";
import Header from "../../layout/header/Header";
import {
  useDeleteArticleMutation,
  useEditArticleMutation,
  useGetArticleMutation,
} from "../../services/article";
import { showError, showToast } from "../../constants/toast";
import { CommonBody } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { Article } from "../../types/article";
import Pagination from "../../components/pagination";
import Loader from "../../constants/Loader";
import { DeleteModal } from "../../components/deleteModal";
import { RenameModal } from "../../components/renameModal";
import {
  useDeleteAssignmentMutation,
  useEditAssignmentMutation,
} from "../../services/assignmentModule";
import { FolderListComponent } from "../../components/folderList";
import useTranslation from "../../hooks/Translation";

function Artical() {
  const navigate = useNavigate();
  const translate = useTranslation();
  const goProfile = () => {
    navigate("/setting");
  };
  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [sidebar, setSidebar] = useState(true);
  const [articles, setArticles] = useState<Article[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const totalPages = Math.ceil(totalCount / 20);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  const [getArticle] = useGetArticleMutation();
  const [isLoading, setIsLoading] = useState(false);
  const getResearchList = async (page: number) => {
    setIsLoading(true);
    try {
      const body = {
        start: page,
        length: 20,
        search: "",
      };
      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await getArticle(encryptedBody).unwrap();
      if (response?.status === 200) {
        setArticles(response?.data?.response_object);
        setTotalCount(response?.data?.total_records);
      } else {
        setArticles([]);
      }
    } catch (error: any) {
      showError(
        error?.data?.message || translate.errors.somethingWentWrong || ""
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getResearchList(page);
  }, [page]);
  const [renameValue, setRenameValue] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [deleteData] = useDeleteArticleMutation();
  const [editData] = useEditArticleMutation();
  const [selectedId, setSelectedId] = useState(0);
  const toggleDelete = (id: number) => {
    setOpenDelete(true);
    setSelectedId(id);
  };
  const handleDelete = async (e: any) => {
    e.stopPropagation();
    try {
      const response = await deleteData({ id: selectedId }).unwrap();
      if (response.status == 200) {
        showToast(
          translate.toastMessages.articleDeletedSuccessfully ||
          "Article Deleted Successfully"
        );
        setOpenDelete(false);
      }
    } catch (error: any) {
      console.log(error);
      showError(error?.data?.message || error?.data?.message || "Something went wrong");
    } finally {
      await getResearchList(page);
    }
  };
  const toggleEdit = (id: number, text: any) => {
    setOpenEdit(true);
    setRenameValue(text);
    setSelectedId(id);
  };
  const handleEdit = async (e: any) => {
    e.stopPropagation();
    try {
      const response = await editData({
        id: selectedId,
        body: { file_name: renameValue },
      }).unwrap();
      if (response.status == 200) {
        showToast(
          translate.toastMessages.articleNameUpdatedSuccessfully ||
          "Article name updated Successfully"
        );
        setOpenEdit(false);
        setRenameValue("");
      }
    } catch (error: any) {
      console.log(error);
      showError(
        error?.data?.message ||
        translate.errors.somethingWentWrong ||
        "Something went wrong"
      );
    } finally {
      await getResearchList(page);
    }
  };
  return (
    <div className="sdbr_rltv">
      <Layout.SidePanel />
      <Header setSidebar={setSidebar} />
      <Loader isLoad={isLoading} />
      <div className="sde_br_mn">
        {/* <div className='sde_hdr'>
          <div className='sde_hdr_lt'>
            <figure>
              <img src='/static/images/articles.png' alt=''></img>
            </figure>
            <div className=''>
              <h3>Articles</h3>
              <p> Lorem Ipsum dolor et amet</p>
            </div>
          </div>
          <div className='sde_hdr_rt'>
            <figure onClick={() => setOpen1(true)}>
              <img src='/static/images/notification.png' alt=''></img>
            </figure>
            <h3>Hi, Ahmed</h3>
            <figure onClick={goProfile}>
              <img src='/static/images/ahmed.png' alt=''></img>
            </figure>
          </div>
        </div> */}
        <div className="sde_mn_cnt">
          <button
            className="btnn btn_border"
            onClick={() => navigate("/createartical")}
          >
            <i className="fa-regular fa-plus"></i>
            {translate.article.createNewArticle}
          </button>
          <Tabs>
            <TabList>
              <Tab style={{ cursor: "context-menu" }}>
                {translate.article.articles}
              </Tab>
            </TabList>

            <TabPanel>
              <ul className="tst_lst">
                {articles?.length ? (
                  articles?.map((data: Article, index: number) => {
                    return (
                      <FolderListComponent
                        index={index}
                        dataCreatedAt={data?.created_at}
                        dataName={data?.file_name}
                        key={data?.id}
                        nextPage={`/edit-article/${data?.id}`}
                        handleDelete={(e: any) => {
                          e.stopPropagation();
                          toggleDelete(data?.id);
                        }}
                        handleEdit={(e: any) => {
                          e.stopPropagation();
                          toggleEdit(data?.id, data?.topic || "");
                        }}
                        isEdit={true}
                      />
                      // <li
                      //   style={{ cursor: "pointer" }}
                      //   onClick={() => navigate(`/edit-article/${data?.id}`)}
                      // >
                      //   <h3>My Article {(page - 1) * 20 + index + 1}</h3>
                      //   <p className="mt_40">
                      //     {data?.created_at?.slice(0, 10)}
                      //   </p>
                      // </li>
                    );
                  })
                ) : (
                  <div
                    style={{
                      height: "85px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      marginTop: "15%",
                    }}
                  >
                    {" "}
                    <h3 style={{ fontSize: "xxx-large", color: "darkgrey" }}>
                      {translate.global.noRecordsFound}
                    </h3>
                  </div>
                )}
              </ul>
            </TabPanel>
          </Tabs>
        </div>
        <Notification
          open={open1}
          setOpen={setOpen1}
          handleClose={handleClose1}
        />
      </div>
      <Pagination
        module={articles}
        onPageChange={onPageChange}
        page={page}
        totalPages={totalPages}
      />
      <DeleteModal
        handleDelete={handleDelete}
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        textData={"article"}
      />
      <RenameModal
        handleEdit={handleEdit}
        openEdit={openEdit}
        renameValue={renameValue}
        setOpenEdit={setOpenEdit}
        setRenameValue={setRenameValue}
        textData={"article"}
      />
    </div>
  );
}

export default Artical;
