// import React, { useEffect, useState } from "react";
// import Loader from "../../constants/Loader";
// import { showError } from "../../constants/toast";
// import { useLazyGetContactQuery } from "../../services/cms";
// import { useNavigate } from "react-router-dom";

// const HomeSection2 = () => {

//   const [aboutUs] = useLazyGetContactQuery();
//   const [isLoading, setIsLoading] = useState<boolean>(false);
//   const [about, setAbout] = useState<string>("");
//   const [show, setShow] = useState<boolean>(false);
//   const navigate = useNavigate();

//   const fetchData = async () => {
//     setIsLoading(true)
//     try {
//       const res = await aboutUs({}).unwrap();
//       if (res?.status === 200) {
//         setAbout(res?.data?.about_us?.about_us);
//       }
//       setIsLoading(false)
//     } catch (error: any) {
//       setIsLoading(false)
//       showError
//         (error?.message || "something went wrong")
//     }
//   }

//   const truncateText = (text: string, maxChars: number) => {
//     if (text.length > maxChars) {
//       setShow(true);
//       return text.slice(0, maxChars) ;
//     }
//     return text;
//   };

//   useEffect(() => {
//     fetchData();
//   }, [])

//   return (
//     <>
//       <Loader isLoad={isLoading} />
//       <section className="about_sec uht_spc">
//         <div className="conta_iner">
//           <div className="abut_flx">
//             <div className="abut_lt hd_1">
//               <h2>About Us</h2>

//               <div
//                 style={{ textAlign: "center", fontSize: "22px" }}
//                 dangerouslySetInnerHTML={{
//                   __html: about ? truncateText(about, 500) : "No information Provided"
//                 }}
//               >
//               </div>
//               {show ? (
//                 <div>
//                   <a onClick={() => navigate('/about')}>...see more</a>
//                 </div>
//               ) : ("")}

//             </div>
//             <div className="abut_rt">
//               <figure>
//                 <img src="/static/images/abut_sec.png" alt=""></img>
//               </figure>
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };
// export default HomeSection2;

import React, { useEffect, useState } from "react";
import Loader from "../../constants/Loader";
import { showError } from "../../constants/toast";
import { useLazyGetContactQuery } from "../../services/cms";
import { useNavigate } from "react-router-dom";
import { translate } from "pdf-lib";
import useTranslation from "../../hooks/Translation";

const HomeSection2 = () => {
  const translate = useTranslation();
  const [aboutUs] = useLazyGetContactQuery();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [about, setAbout] = useState<string>("");
  const [show, setShow] = useState<boolean>(false);
  const navigate = useNavigate();

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const res = await aboutUs({}).unwrap();
      if (res?.status === 200) {
        const aboutText = res?.data?.about_us?.about_us || "";
        setAbout(aboutText);
        if (aboutText.length > 500) {
          setShow(true);
        }
      }
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      showError(error?.data?.message || error?.message || "something went wrong");
    }
  };

  const truncateText = (text: string, maxChars: number) => {
    return text.length > maxChars ? text.slice(0, maxChars) : text;
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Loader isLoad={isLoading} />
      <section className="about_sec uht_spc">
        <div className="conta_iner">
          <div className="abut_flx">
            <div className="abut_lt hd_1">
              <h2>{translate.about.aboutUs}</h2>
              <div
                style={{ textAlign: "center", fontSize: "22px" }}
                dangerouslySetInnerHTML={{
                  __html: about
                    ? truncateText(about, 500)
                    : translate.about.noInformationProvided,
                }}
              ></div>
              {show && (
                <div style={{ textAlign: "center" }}>
                  <span>...</span>
                  <a
                    className="seeMore"
                    style={{ textDecoration: "underline" }}
                    onClick={() => {
                      navigate("/about");
                      window.scroll(0, 0);
                    }}
                  >
                    {translate.about.seeMore}
                  </a>
                </div>
              )}
            </div>
            <div className="abut_rt">
              <figure>
                <img src="/static/images/abut_sec.png" alt=""></img>
              </figure>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeSection2;
