/* eslint-disable @typescript-eslint/no-unused-vars */
import Layout from "../../layout/index";
import "react-tabs/style/react-tabs.css";
import { Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import React, { useState } from "react";
import Header from "../../layout/header/Header";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import useAuth from "../../hooks/useAuth";
import { TestAI } from "../../constants/testAILoading";
import useTranslation from "../../hooks/Translation";
import { translate } from "pdf-lib";

function Pronouns() {
  const translation = useTranslation();
  const navigate = useNavigate();
  const [isCitation, setIsCitation] = useState(false);
  console.log(isCitation);
  const { state } = useLocation();
  console.log(state);
  const [selectedPronoun, setSelectedPronouns] = useState(1);
  const pronounObject: any = translation?.getLanguage() == "en" ? {
    1: "Absent Person",
    2: "Addressee",
    3: "Speaker",
  } : {
    1: "الشخص الغائب",
    2: "المخاطب",
    3: "المتحدث"
  }
    ;
  return (
    <div className="sdbr_rltv">
      <Layout.SidePanel />
      <Header />
      {/* <div className='sde_hdr'>
        <div className='sde_hdr_lt'>
        <button
                className="back_btn"
                onClick={() => navigate("/selecttons")}
              >
                <ArrowBackIosNewIcon />
                </button>
                <figure>
                    <img src='/static/images/articles.png' alt=''></img>
                </figure>
                <div className=''>
                    <h3>Articales</h3>
                    <p> Lorem Ipsum dolor et amet</p>
                </div>
            </div>
            <div className='sde_hdr_rt'>
                <figure>
                <img src='/static/images/notification.png' alt=''></img>
                </figure>
                <h3>Hi, Ahmed</h3>
                <figure>
                <img src='/static/images/ahmed.png' alt=''></img>
                </figure>
            </div>
        </div> */}
      <div className="sde_br_mn slct">
        <h6>{translation.article.pointOfView || "Point of View (Pronouns)"}</h6>
        <div className="sde_mn_cnt slct_mn tn_lst prn_lst">
          <ul className="slct_tn text_center">
            <li
              className={selectedPronoun == 1 ? "active" : ""}
              onClick={() => setSelectedPronouns(1)}
              style={{
                cursor: selectedPronoun == 1 ? "context-menu" : "pointer",
              }}
            >
              {pronounObject[1]}
            </li>
            <li
              className={selectedPronoun == 2 ? "active" : ""}
              onClick={() => setSelectedPronouns(2)}
              style={{
                cursor: selectedPronoun == 2 ? "context-menu" : "pointer",
              }}
            >
              {pronounObject[2]}
            </li>
            <li
              className={selectedPronoun == 3 ? "active" : ""}
              onClick={() => setSelectedPronouns(3)}
              style={{
                cursor: selectedPronoun == 3 ? "context-menu" : "pointer",
              }}
            >
              {pronounObject[3]}
            </li>
          </ul>
          <div className="rght_btn togl_mn">
            <div className="togl">
              <p>
                <small>
                  {translation.article.doYouWantToPutPictures ||
                    "Do you want to put pictures?"}
                </small>
              </p>
              <div className="container0001">
                <input
                  type="checkbox"
                  className="checkbox0001"
                  checked={isCitation}
                  onClick={() => setIsCitation(!isCitation)}
                  id="checkbox0001"
                />
                <label
                  className="switch0001"
                  // onClick={()=>setIsCitation(!isCitation)}
                  htmlFor="checkbox0001"
                >
                  <span className="slider0001 "></span>
                </label>
              </div>
            </div>
            <Button
              type="button"
              className="btnn btn_primary"
              onClick={
                () =>
                  navigate("/edit-article/", {
                    state: {
                      ...state,
                      type: TestAI.ARTICLE,
                      pronoun: pronounObject[selectedPronoun],
                      wantPicture: isCitation,
                      prevPage: "/artical",
                      nextPage: "/artical",
                    },
                    replace: true,
                  })
                // navigate("/test-ai",{state:{
                //   ...state,
                //   type:TestAI.ARTICLE,
                //   pronoun:pronounObject[selectedPronoun],
                //   wantPicture:isCitation,
                //   prevPage:"/artical",
                //   nextPage:"/artical",
                // },replace:true})
              }
            >
              {translation.global.next || "Next"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pronouns;
