import React, {
  useState,
  useRef,
  useMemo,
  useEffect,
  useCallback,
} from "react";
import JoditEditor from "jodit-react";
import "./test.css";

export const EditText2 = ({
  content,
  setContent,
  disable,
  setData,
  editor,
}: any) => {
  const [placeholder, setPlaceholder] = useState("");
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    setDisplay(disable);
  }, [disable]);

  const config = useMemo(
    () => ({
      buttons: [],
      readonly: true,
      placeholder: placeholder || "",
      toolbarAdaptive: false,
      statusbar: false,
      tabIndex: -1,
      zIndex: 0,
      addNewLine: false,
      triggerChangeEvent: true,
      theme: "light",
      height: 400,
      askBeforePasteHTML: false,
      allowResizeX: false,
      allowResizeY: false,
      toolbar: false,
      textIcons: false,
      table: {
        data: {
          classList: {
            "table table-bordered": "Bootstrap Bordered",
            "table table-striped": "Bootstrap Striped",
            "table table-dark": "Bootstrap Dark",
          },
        },
      },
      createAttributes: {
        table: {
          style:
            "border:1px solid #C5C6C8FF;border-collapse:collapse;width: 100%;",
        },
        tr: { style: "border: 1px solid #C5C6C8FF; background-color:white" },
        td: { style: "border: 1px solid #C5C6C8FF;background-color:white" },
      },
      style: {
        fontFamily: "Arial, sans-serif",
        // fontSize: "14px",
        color: "black",
      },
    }),
    [placeholder]
  );

  // const config2 = useMemo(
  //   () => ({
  //     autofocus: true,
  //     buttons: ["bold", "italic", "underline", "strikethrough"],
  //     readonly: false,
  //     placeholder: placeholder || '',
  //     toolbarAdaptive: false,
  //     statusbar: false,
  //     tabIndex: -1,
  //     zIndex: 0,
  //     addNewLine: false,
  //     triggerChangeEvent: true,
  //     theme: "light",
  //     toolbar: false,
  //     textIcons: false,
  //     style: {
  //       fontFamily: 'Arial, sans-serif',
  //       fontSize: '14px',
  //       color: 'black'
  //     },
  //     editorClassName: "jodit-black",
  //     className: "jodit-black",
  //   }),
  //   [placeholder]
  // );
  const config2 = useMemo(
    () => ({
      // autofocus: true,
      buttons: [
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "eraser",
        "ul",
        "ol",
        "font",
        "fontsize",
        "brush",
      ],
      readonly: false,
      placeholder: "",
      toolbarAdaptive: false,
      statusbar: false,
      tabIndex: -1,
      zIndex: 0,
      askBeforePasteHTML: false,
      addNewLine: false,
      // triggerChangeEvent: true,
      theme: "light",
      // toolbar: false,
      textIcons: false,
      // style: {
      //   fontFamily: 'Arial, sans-serif',
      //   fontSize: '14px',
      //   color: 'black'
      // },
      // editorClassName: "jodit-black",
      className: "jodit-black",
    }),
    [placeholder]
  );
  return (
    <>
      {display ? (
        <JoditEditor
          ref={editor}
          value={content}
          config={config}
          onBlur={(newContent) => {
            editor.current.value = newContent;

            setContent(newContent);
          }}
        />
      ) : (
        <JoditEditor
          ref={editor}
          value={content}
          config={config2}
          onBlur={(newContent) => {
            editor.current.value = newContent;

            setContent(newContent);
          }}
          // onChange={(newContent) => {

          //   console.log(newContent);
          //     setData(newContent)
          // }}
        />
      )}
    </>
  );
};
