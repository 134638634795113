import { API_URL, END_POINTS } from "../constants/url";
import { UploadDocument } from "../utils/documentsUpload";
import emptySplitApi from "../utils/rtk";
type ArticleResponse = any;
type CommonResponseType = {
  status: number;
  message: string;
  record_id: number;
};

type GetAllArticlesResponse = {
  // Article: ArticleResponse[];
  response_object: ArticleResponse[];
  total_records: number;
};

// type encryptedBody = {
//     image: string;
//     email: string;
//     fullName: string;
//     countryCode: string;
//     phone: string;
//     address:string;
//   };
type encryptedBody = {
  hash: string;
  sek: string;
} | null;

type getDataBody = {
  length: number;
  start: number;
  search: string;
};
type ChangeStatusBody = {
  is_active: boolean;
};
export const ArticleApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    addArticle: builder.mutation<
      CommonResponseType & { data: ArticleResponse },
      encryptedBody
    >({
      query: (body) => {
        let url = END_POINTS.detailedArticle;
        return {
          url: url,
          method: "POST",
          body,
        };
      },
    }),

    getArticle: builder.mutation<
      CommonResponseType & { data: GetAllArticlesResponse },
      encryptedBody
    >({
      query: (body) => {
        let url = END_POINTS.getAllArticle;
        return {
          url: url,
          method: "POST",
          body,
        };
      },
    }),
    downloadArticle: builder.mutation<
      CommonResponseType & { data: any },
      { id: string | undefined; body: encryptedBody }
    >({
      query: ({ id, body }) => {
        let url = END_POINTS.downloadArticle;
        return {
          url: url,
          method: "POST",
          body,
        };
      },
    }),

    getArticleById: builder.query<
      CommonResponseType & { data: ArticleResponse },
      { id: string | undefined }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.getArticleById}${id}/`,
        method: "GET",
      }),
    }),

    detailedArticle: builder.mutation<
      CommonResponseType & { data: any },
      { body: any; id: string }
    >({
      query: ({ body, id }) => ({
        url: `${END_POINTS.detailedArticle}${id}/`,
        method: "POST",
        body,
      }),
    }),

    regenerateArticleAnswer: builder.query<
      CommonResponseType & { data: any },
      { id: string; page: string }
    >({
      query: ({ id, page }) => ({
        url: `${END_POINTS.regenerateArticleAnswer}${id}/?page=${page}/`,
        method: "GET",
      }),
    }),

    deleteArticle: builder.mutation<CommonResponseType, { id: number }>({
      query: ({ id }) => ({
        url: `${END_POINTS.deleteArticle}${id}/`,
        method: "DELETE",
      }),
    }),
    editArticle: builder.mutation<
      CommonResponseType,
      {
        id: number;
        body: {
          file_name: string;
        };
      }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.editArticleName}${id}/`,
        method: "PUT",
        body,
      }),
    }),
    // changeArticleStatus: builder.mutation<
    // CommonResponseType & { data: ArticleResponse },
    // { id: string , body: encryptedBody}
    // >({
    // query: ({ id }) => ({
    //   url: `${END_POINTS.changeArticleStatus}${id}/`,
    //   method: "PUT",
    // }),
    // }),

    // editArticleById: builder.mutation<
    //       CommonResponseType & { data: ArticleResponse },
    //       { id: string; body: encryptedBody }
    //     >({
    //       query: ({ id, body }) => ({
    //         url: `${END_POINTS.updateArticles}${id}/`,
    //         method: "PUT",
    //         body,
    //       }),
    //     }),

    // deleteArticleById:builder.mutation<
    // CommonResponseType & {data:ArticleResponse},
    // {id:string}
    // >({
    //     query:({id})=>({
    //         url:`${END_POINTS.deleteArticle}${id}/`,
    //         method:'DELETE'
    //     })
    // }),
    // getArticleCSV: builder.query<CommonResponseType & { data: string }, {
    //     search:string;
    //   }>({
    //     query: ({search}) => {
    //       let url = END_POINTS.exportArticles;
    //       const queryParams = [];
    //       if (search) {
    //         queryParams.push(`search=${search}`);
    //       }
    //       if (queryParams.length > 0) {
    //         url += `?${queryParams.join('&')}`;
    //       }
    //      return{
    //       url:url,
    //       method: "GET",}
    //     },
    //   }),
  }),
});
export const ArticleByReference = async (
  selectedFile: any,
  key: string,
  description: string,
  reduce_citation: boolean,
  endpoint: string
) => {
  const formData = new FormData();
  formData.append(key, selectedFile);
  console.log("selectedFile :", selectedFile);
  formData.append("description", description);
  formData.append("reduce_citation", reduce_citation.toString());
  console.log(formData.get("reduce_citation"));

  let url = `${API_URL}${endpoint}`;
  try {
    const response = await UploadDocument(url, formData);
    return response;
  } catch (error) {
    console.log(error);
  }
};
export const {
  useAddArticleMutation,
  useGetArticleMutation,
  useLazyGetArticleByIdQuery,
  useDownloadArticleMutation,
  useDetailedArticleMutation,
  useLazyRegenerateArticleAnswerQuery,
  useDeleteArticleMutation,
  useEditArticleMutation,
  // useChangeArticleStatusMutation,
  // useEditArticleByIdMutation,
  // useDeleteArticleByIdMutation,
  // useLazyGetArticleCSVQuery,
} = ArticleApi;
