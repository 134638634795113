import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import useTranslation from "../hooks/Translation";

const PaymentCards = () => {
  const translate = useTranslation();
  return (
    <>
      <div className="pymt_lt hd_2_1">
        <h2>{translate.subscriptions.paymentOptions}</h2>
        <div className="pymt_lst">
          <RadioGroup
            className="checkbox_label"
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="card1"
            name="radio-buttons-group"
          >
            <FormControlLabel
              value="card1"
              control={<Radio />}
              label={
                <>
                  <figure>
                    <img src="/static/images/visa.png" alt=""></img>
                  </figure>
                  <div className="pymt_cnt hd_4">
                    <h3>•••• •••• •••• 4432</h3>
                    <h3>Personal - 06/23</h3>
                  </div>
                </>
              }
            />
            <FormControlLabel
              value="card2"
              control={<Radio />}
              label={
                <>
                  <figure>
                    <img src="/static/images/visa.png" alt=""></img>
                  </figure>
                  <div className="pymt_cnt hd_4">
                    <h3>•••• •••• •••• 4432</h3>
                    <h3>Personal - 06/23</h3>
                  </div>
                </>
              }
            />
            <FormControlLabel
              value="card3"
              control={<Radio />}
              label={
                <>
                  <figure>
                    <img src="/static/images/visa.png" alt=""></img>
                  </figure>
                  <div className="pymt_cnt hd_4">
                    <h3>•••• •••• •••• 4432</h3>
                    <h3>Personal - 06/23</h3>
                  </div>
                </>
              }
            />
          </RadioGroup>
        </div>
        <div className="upld_flx">
          <div className="upld_fle">
            {/* <input type="text" id="myFile" name="filename"></input> */}
            <figure>
              <img src="/static/images/plus.png" alt=""></img>
            </figure>
          </div>
          <div className="upld_cnt hd_5">
            <h3>{translate.subscriptions.addNewCard}</h3>
            <h4>{translate.subscriptions.saveAndPayViaCard}</h4>
            <ul className="pymt_otn">
              <li>
                <figure>
                  <img src="/static/images/visa.png" alt=""></img>
                </figure>
              </li>
              <li>
                <figure>
                  <img src="/static/images/master-card.png" alt=""></img>
                </figure>
              </li>
              <li>
                <figure>
                  <img src="/static/images/discover.png" alt=""></img>
                </figure>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
export default PaymentCards;
