import React from "react";
import "./App.scss";
import "./arpit.scss";
import "./rajeena.scss";
import Routes from "./Routes";
import PdfPreview from "./pages/pdfPreview";
import AnyComponent from "./components/recording";
import AudioRecorder from "./components/sendBase64Audio";
import AudioRecorder2 from "./components/sendBase64Audio copy";
import AudioToText from "./components/mohitAudioRecord";
import LanguageContextProvider from "./context/Language";
import LatexTest from "./components/latexTest";
import PptEditor from "./components/Fabric";

const App = () => {
  // return <AudioRecorder2/>
  // return <AudioRecorder/>
  // return <AudioToText/>
  // return <AppWriter />;
  // return <LatexTest />
  // return <PptEditor />;
  return (
    <LanguageContextProvider>
      <Routes />
    </LanguageContextProvider>
  );
  // return  <PdfPreview/>
  //  return <AnyComponent/>
};

export default App;
