import React from "react";
import useTranslation from "../../hooks/Translation";

const ContactSection1 = () => {
  const translate = useTranslation();
  return (
    <>
      <section className="abut_us bg_color ">
        <div className="conta_iner">
          <div className="text_center hd_1">
            <h2>{translate.contact.contactUs}</h2>
            <p>
              {translate.contact.contactUsPageLine1}
              <span className="d_block">
                {" "}
                {translate.contact.contactUsPageLine2}
              </span>
            </p>
          </div>
        </div>
      </section>
    </>
  );
};
export default ContactSection1;
