import React, { useEffect, useRef, useState } from "react";
import "react-tabs/style/react-tabs.css";
import "./quiz.scss";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Menu,
  MenuItem,
  Modal,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Radio, RadioGroup } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useLocation, useNavigate } from "react-router-dom";
import { useChangeLanguageMutation } from "../../services/utils";
import {
  useDownloadPdfTestingMutation,
  useUpdateResultMutation,
} from "../../services/testingModule";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { showError, showToast, showWarning } from "../../constants/toast";
import Loader from "../../constants/Loader";
import { handleDownload } from "../../utils/handleDownload";
import { getFromStorage } from "../../constants/storage";
import { STORAGE_KEYS } from "../../constants/storageKeys";
import { fileToBase64 } from "../../utils/fileToB64";
import TestAi from "../testAi";
import useTranslation from "../../hooks/Translation";
import { API_URL } from "../../constants/url";
import { isArabicText } from "../../utils/validations";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: 587,
  bgcolor: "#353e3d",
  border: "1px solid #FFFFFF8A",
  boxShadow: 24,
  borderRadius: "10px",
  p: 3,
  maxHeight: "95vh",
  overflow: "auto",
};
let isBack = false;
let allQuestionsDone = true;
let record_id = 0;
let streamingQuestionCount = 0;
const Quiz = () => {
  const [open, setOpen] = React.useState(false);
  const [repeating, setRepeating] = useState(true);
  const translate = useTranslation();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [isRadioButtonDisabled, setIsButtonDisabled] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { state } = useLocation();
  const [quesData, setQuesData] = useState<any>([]);
  console.log("quesData :", quesData);
  const totalCount = 0;
  const [currentQues, setCurrentQues] = useState<any>(null);
  const [currentNum, setCurrentNum] = useState<number>(1);
  const [result, setResult] = useState<any>();

  const [changeLanguage] = useChangeLanguageMutation();
  const [eng, setEng] = useState<boolean>(true);
  const [translatedData, setTranslatedData] = useState<any>();
  const [sendResponce] = useUpdateResultMutation();
  let [userResBody, setUserResBody] = useState<any>([]);
  const [downloadFile] = useDownloadPdfTestingMutation();
  const [selectedOption, setSelectedOption] = useState("");
  const [correct, setCorrect] = useState<boolean>();
  const [disableButton, setDisableButton] = useState(false);
  const [isBlink, setIsBlink] = useState(false);
  console.log("state in quiz page", state);
  // const [openMenu, setopenMenu] = useState(false);
  const [message, setMessage] = useState("");
  const [chatHistory, setChatHistory] = useState<any>([]);
  const [socket, setSocket] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [connecting, setConnecting] = useState(true);
  const [messages, setMessages] = useState<{ query: string; answer: string }[]>(
    []
  );
  const [prevData, setPrevData] = useState<any>([]);
  const chatHistoryRef = useRef(null);
  const [isWaiting, setIsWaiting] = useState(0);
  useEffect(() => {
    if (chatHistoryRef.current) {
      //@ts-ignore
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [chatHistory]);
  const connectToBot = () => {
    const ws = new WebSocket(
      `wss:${API_URL.replaceAll("https://", "")}chatbot/`
    );
    ws.onmessage = (event) => {
      const eventData = JSON.parse(event.data);
      console.log(eventData);
      setLoading(false);
      setMessages((prev) => [...prev, { ...eventData, question: "" }]);
      setChatHistory((prevHistory: any) => [
        ...prevHistory,
        { sender: "AI Bot", text: eventData.answer },
      ]);
    };

    ws.onopen = async () => {
      console.log("Connected to bot successfully");
      setConnecting(false);
    };
    ws.onerror = (error) => {
      ws.close();
      setLoading(false);
      console.log("There is some error", error);
    };
    ws.onclose = () => {
      setLoading(false);
      console.log("Socket is closed");
    };
    setSocket(ws);
  };

  const onSendMessage = (e: any) => {
    e.preventDefault();
    if (loading) return;
    try {
      if (!message?.trim()?.length) {
        return;
      }
      setLoading(true);
      const PAYLOAD = {
        query: message,
      };
      socket?.send(JSON.stringify(PAYLOAD));
      setChatHistory([...chatHistory, { sender: "You", text: message }]);
      // setMessages((prev) => [...prev, { ...PAYLOAD, answer: "" }]);
      setMessage("");
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openChat = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const openMenu = Boolean(anchorEl);

  useEffect(() => {
    if (openMenu) {
      setConnecting(true);
      connectToBot();
    } else {
      socket?.close();
    }
  }, [openMenu]);
  const getChangeLanguage = async (download: boolean) => {
    setIsLoading(true);
    try {
      const body = {
        // text: quesData,
        text: download ? quesData.map((data: any) => {
          return ({
            question: data.question,
            question_no: data.question_no,
            user_answer: data.user_answer,
            answer_option: data.answer_option,
            correct_answer: data.correct_answer
          })
        }) : [currentQues],
      };
      console.log("body for change language :", body);
      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await changeLanguage(encryptedBody).unwrap();
      setIsLoading(false);
      if (response.status === 200) {
        const isArabic = isArabicText(response?.data?.[0]?.question);

        setTranslatedData(response?.data);
        if (download === true) {
          const body = {
            html_text: `<!DOCTYPE html>
                <html lang="en">
                <head>
                    <meta charset="UTF-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <title>Quiz</title>
                    <style>
                        body {
                            font-family: Arial, sans-serif;
                            background-color: #ffffff;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        .quiz-container {
                            background-color: #ffffff;
                            padding: 20px;
                            width: 100%;
                        }
                        .quiz-header {
                            text-align: center;
                            margin-bottom: 20px;
                        }
                        .question {
                            font-size: 18px;
                            margin-bottom: 10px;
                            margin-top: 30px;
                        }
                        .answers {
                            list-style-type: none;
                            padding: 0;
                        }
                        .answers li {
                            background-color: #e7e7e7;
                            border-radius: 5px;
                            margin-bottom: 10px;
                            padding: 10px;
                            cursor: pointer;
                            transition: background-color 0.3s;
                        }
                        .answers li:hover {
                            background-color: #d3d3d3;
                        }
                        .quiz-footer p {
                            background-color: rgba(39, 174, 96, 0.3);
                            border-radius: 5px;
                            margin: 10px 0px;
                            padding: 10px;
                        }
                        .quiz-footer button {
                            background-color: #6200ee;
                            color: white;
                            border: none;
                            padding: 10px 20px;
                            border-radius: 5px;
                            cursor: pointer;
                            transition: background-color 0.3s;
                        }
                        .quiz-footer button:hover {
                            background-color: #3700b3;
                        }
                    </style>
                </head>
                <body dir="auto">
                
                <div class="quiz-container" dir="auto">
                    <div class="quiz-header" dir="auto">
                        <h2>${isArabic ? "اختبار" : "Testing"}</h2>
                    </div>
                    ${response?.data
                ?.map((item: any, index: number) => {
                  return `
                        <div class="question">${isArabic ? "سؤال" : "Question"} ${index + 1}: ${item?.question || ""}</div>
                        <ul class="answers">
                          ${item?.answer_option
                      ?.map((option: any, optionIndex: number) => {
                        return `<li>${optionIndex + 1}. ${option}</li>`;
                      })
                      .join("")}
                        </ul>
                        <div class="quiz-footer">
                          <p>${isArabic ? "الإجابة الصحيحة" : "Correct Answer"}: ${item?.correct_answer || ""}</p>
                        </div>`;
                })
                .join("")}
                </div>
                </body>
                </html>`,
          };

          try {
            const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
            const res = await downloadFile(encryptedBody).unwrap();
            if (res?.status === 200) {
              console.log(res, "download ka response");
              handleDownload(res?.data, "quiz");
              showToast(
                translate.toastMessages.pdfDownloadedSuccessfully ||
                "Pdf downloaded Successfully"
              );
            }
          } catch (error: any) {
            showError(
              error?.data?.message ||
              translate.errors.somethingWentWrong ||
              "Something went wrong"
            );
          }
        }
      }
    } catch (error: any) {
      console.log(error);
      showError(
        error?.data?.message ||
        translate.errors.somethingWentWrong ||
        "Something went wrong"
      );
    }
    setIsLoading(false);
  };

  const gotohome = () => {
    navigate("/testing");
    const handleClose1 = () => setOpen1(false);
  };
  const gotohome1 = () => {
    navigate("/testing");
  };
  /* menu */

  const FetchDownLoadFile = async (option: number) => {
    setIsLoading(true);
    const body = {
      html_text: `<!DOCTYPE html>
      <html lang="en">
      <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Quiz</title>
          <style>
              body {
                  font-family: Arial, sans-serif;
                  background-color: #ffffff;
                  display: flex;
                  justify-content: center;
                  align-items: center;
              }
              .quiz-container {
                  background-color: #ffffff;
                  padding: 20px;
                  width: 100%;
              }
              .quiz-header {
                  text-align: center;
                  margin-bottom: 20px;
              }
              .question {
                  font-size: 18px;
                  margin-bottom: 10px;
                  margin-top: 30px;
              }
              .answers {
                  list-style-type: none;
                  padding: 0;
              }
              .answers li {
                  background-color: #e7e7e7;
                  border-radius: 5px;
                  margin-bottom: 10px;
                  padding: 10px;
                  cursor: pointer;
                  transition: background-color 0.3s;
              }
              .answers li:hover {
                  background-color: #d3d3d3;
              }
              .quiz-footer p {
                  background-color: rgba(39, 174, 96, 0.3);
                  border-radius: 5px;
                  margin: 10px 0px;
                  padding: 10px;
              }
              .quiz-footer button {
                  background-color: #6200ee;
                  color: white;
                  border: none;
                  padding: 10px 20px;
                  border-radius: 5px;
                  cursor: pointer;
                  transition: background-color 0.3s;
              }
              .quiz-footer button:hover {
                  background-color: #3700b3;
              }
          </style>
      </head>
      <body>
      
      <div class="quiz-container">
          <div class="quiz-header">
              <h2>Testing</h2>
          </div>
          ${quesData
          ?.map((item: any, index: number) => {
            return `
  <div class="question">Question ${index + 1}: ${item?.question || ""}</div>
  <ul class="answers">
    ${item?.answer_option
                ?.map((option: string, optionIndex: number) => {
                  return `<li>${optionIndex + 1}. ${option}</li>`;
                })
                .join("")}
  </ul>
  ${option !== 2
                ? `<div class="quiz-footer">
    <p>
      Correct Answer: ${item?.correct_answer || ""}
    </p>
  </div>`
                : ""
              }`;
          })
          .join("")}
      </div>
      
      </body>
      </html>`,
    };
    try {
      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const res = await downloadFile(encryptedBody).unwrap();
      setIsLoading(false);
      if (res?.status === 200) {
        console.log(res, "download ka response");
        // window.open(res?.data);
        handleDownload(res.data, "testing_questions.pdf");
      }
    } catch (error: any) {
      setIsLoading(false);
      showError(
        error?.data?.message ||
        translate.errors.somethingWentWrong ||
        "Something went wrong"
      );
    }
  };
  useEffect(() => {
    setEng(true);
  }, [currentNum]);
  const UserResponce = async (temp: any) => {
    setIsLoading(true);
    const body = {
      user_response: temp,
    };
    console.log(body, "body for user marks");

    try {
      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await sendResponce({
        body: encryptedBody,
        id: state?.record_id || record_id,
      }).unwrap();
      setIsLoading(false);
      if (response.status === 200) {
        setResult(response?.data);
        handleOpen1();
      }
    } catch (error: any) {
      setIsLoading(false);
      showError(
        error?.data?.message ||
        translate.errors.somethingWentWrong ||
        "Something went wrong"
      );
    }
  };
  const skipQuestion = () => {
    if (isLoading) setIsLoading(false)
    if (isWaiting) setIsWaiting(0)
    setIsButtonDisabled(false);
    let body = [
      {
        question: currentQues?.question,
        question_no: currentNum,
        user_answer: "",
        answer_option: currentQues?.answer_option,
        correct_answer: currentQues?.correct_answer,
        qCount: currentQues?.qCount,
        isDone: false,
      },
    ];
    let temp = null;
    if (
      currentNum <=
      quesData?.findLastIndex((data: any) => !data.isDone) + 1
    )
      if (
        userResBody.some((data: any) => data.qCount == currentNum)
      ) {
        setUserResBody((prev: any) =>
          prev.map((data: any) =>
            data.qCount == currentNum ? body?.[0] : data
          )
        );
        temp = userResBody.map((data: any) =>
          data.qCount == currentNum ? body?.[0] : data
        );
      } else {
        setUserResBody([...userResBody, ...body]);
        temp = [...userResBody, ...body];
      }
    console.log("mmmmtemp :", temp);
    console.log(
      "mmmmtemp.every((data: any, index: number) => data?.isDone) :",
      temp.every((data: any, index: number) => data?.isDone)
    );
    if (
      temp.length == quesData.length &&
      temp.every((data: any, index: number) => data?.isDone)
    ) {
      setRepeating(false);
    }
    console.log("currentNum :", currentNum);
    // console.log("quesData?.length :", quesData?.length);
    console.log("temp?.length :", temp?.length);
    if (allQuestionsDone) {
      if (
        temp.length == quesData.length &&
        quesData?.findLastIndex((data: any) => !data.isDone) +
        1 ===
        currentNum
        // &&temp?.length === quesData?.length
      ) {
        UserResponce(temp);
      }
    }
    // let body = [
    //   {
    //     question: currentQues?.question,
    //     question_no: currentNum,
    //     user_answer: "",
    //     answer_option: currentQues?.answer_option,
    //     correct_answer: currentQues?.correct_answer,
    //     qCount: currentQues?.qCount,
    //   },
    // ];
    // if (currentNum < quesData.length) {
    //   setCurrentQues(quesData[currentNum]);
    //   setCurrentNum(currentNum + 1);
    //   setUserResBody([...userResBody, ...body]);
    //   setSelectedOption("");
    // }
    // const temp = [...userResBody, ...body];
    // console.log("currentNum :", currentNum);
    // console.log("quesData?.length :", quesData?.length);
    // console.log("temp?.length :", temp?.length);
    // if (allQuestionsDone) {
    //   if (
    //     quesData?.length === currentNum
    //     // &&temp?.length === quesData?.length
    //   ) {
    //     UserResponce(temp);
    //   }
    // }
    if (
      currentNum <
      quesData?.findLastIndex((data: any) => !data.isDone) + 1
    ) {
      setIsButtonDisabled(false);
      setCurrentQues(
        quesData[
        quesData?.findIndex(
          (data: any, index: any) =>
            index + 1 > currentNum && !data.isDone
        )
        ]
      );
      setCurrentNum(
        quesData?.findIndex(
          (data: any, index: any) =>
            index + 1 > currentNum && !data.isDone
        ) + 1
      );
      console.log(
        ` quesData?.findIndex`,
        quesData?.findIndex(
          (data: any, index: any) =>
            index + 1 > currentNum && !data.isDone
        ) + 1
      );
      console.log(
        `quesData?.findIndex:`,
        quesData?.findIndex(
          (data: any, index: any) =>
            index + 1 > currentNum && !data.isDone
        ) + 1
      );
      setSelectedOption("");
      window.scrollTo(0, 0);
    } else {
      if (!allQuestionsDone) {
        // if (
        //   !(
        //     quesData?.findLastIndex((data: any) => !data.isDone) +
        //       1 ===
        //     currentNum
        //   )
        // )
        setIsWaiting(1);
        setIsLoading(true);
        showWarning(
          translate.testing.pleaseWaitQuestionsBeingLoaded ||
          "Please wait while the questions are being loaded"
        );
      }
    }

  }
  const nextQuestion = () => {
    if (isLoading) setIsLoading(false)
    if (isWaiting) setIsWaiting(0)
    if (
      currentNum <
      quesData?.findLastIndex((data: any) => !data.isDone) +
      1
    ) {
      setIsButtonDisabled(false);
      setCurrentQues(
        quesData[
        quesData?.findIndex(
          (data: any, index: any) =>
            index + 1 > currentNum && !data.isDone
        )
        ]
      );
      setCurrentNum(
        quesData?.findIndex(
          (data: any, index: any) =>
            index + 1 > currentNum && !data.isDone
        ) + 1
      );
      console.log(
        ` quesData?.findIndex`,
        quesData?.findIndex(
          (data: any, index: any) =>
            index + 1 > currentNum && !data.isDone
        ) + 1
      );
      console.log(
        `quesData?.findIndex:`,
        quesData?.findIndex(
          (data: any, index: any) =>
            index + 1 > currentNum && !data.isDone
        ) + 1
      );
      setSelectedOption("");
      window.scrollTo(0, 0);
    } else {
      if (!allQuestionsDone) {
        // if (
        //   !(
        //     quesData?.findLastIndex((data: any) => !data.isDone) +
        //       1 ===
        //     currentNum
        //   )
        // )
        setIsWaiting(2);
        setIsLoading(true);
        showWarning(
          translate.testing
            .pleaseWaitQuestionsBeingLoaded ||
          "Please wait while the questions are being loaded"
        );
      }
    }
    // setCorrect()

  }
  const handleChange = (event: any) => {
    setIsButtonDisabled(true);
    console.log(event.target.value, "event");

    setSelectedOption(event.target.value);
    console.log(selectedOption, "selectedOption");
    console.log(currentQues?.correct_answer, "currentQues?.correct_answer");
    if (event.target.value === currentQues?.correct_answer) {
      setCorrect(true);
    } else {
      setCorrect(false);
    }
    let body = [
      {
        question: currentQues?.question,
        question_no: currentNum,
        user_answer: event.target.value,
        answer_option: currentQues?.answer_option,
        correct_answer: currentQues?.correct_answer,
        qCount: currentQues?.qCount,
        isDone:
          event.target.value === currentQues?.correct_answer ? true : false,
      },
    ];
    let temp = null;
    if (currentNum <= quesData?.findLastIndex((data: any) => !data.isDone) + 1)
      if (userResBody.some((data: any) => data.qCount == currentNum)) {
        setUserResBody((prev: any) =>
          prev.map((data: any) =>
            data.qCount == currentNum ? body?.[0] : data
          )
        );
        temp = userResBody.map((data: any) =>
          data.qCount == currentNum ? body?.[0] : data
        );
      } else {
        setUserResBody([...userResBody, ...body]);
        temp = [...userResBody, ...body];
      }
    console.log("mmmmtemp :", temp);
    console.log(
      "mmmmtemp.every((data: any, index: number) => data?.isDone) :",
      temp.every((data: any, index: number) => data?.isDone)
    );
    if (
      temp.length == quesData.length &&
      temp.every((data: any, index: number) => data?.isDone)
    ) {
      setRepeating(false);
    }
    console.log("currentNum :", currentNum);
    // console.log("quesData?.length :", quesData?.length);
    console.log("temp?.length :", temp?.length);
    if (allQuestionsDone) {
      if (
        temp.length == quesData.length &&
        quesData?.findLastIndex((data: any) => !data.isDone) + 1 === currentNum
        // &&temp?.length === quesData?.length
      ) {
        UserResponce(temp);
      }
    }
  };
  console.log(userResBody, "userResBody");

  const truncateText = (text: string, maxChars: number) => {
    console.log("lllllllllltext :", text);
    if (text?.length > maxChars) {
      return (
        <Tooltip title={text}>
          <span>{text.slice(0, maxChars) + " ..."}</span>
        </Tooltip>
      );
    }
    return text;
  };

  const closeChat = () => {
    setAnchorEl(null);
  };

  console.log(userResBody?.length, "===userResBody?.length");
  // console.log(quesData?.length, "quesdata?.length");
  const [streamedQuestions, setStreamedQuestions] = useState<any>([]);
  console.log("streamedQuestions :", streamedQuestions);
  // const [allQuestionsDone, setAllQuestionsDone] = useState(true);
  // useEffect(() => {
  //   if (quesData.length) {
  //     setQuesData((prev: any) =>
  //       prev.map((data: any) =>
  //         data.hasOwnProperty("isDone") ? prev : { ...prev, isDone: false }
  //       )
  //     );
  //   }
  // }, [quesData]);
  useEffect(() => {
    if (eng) {
      if (streamedQuestions?.length) {
        // setQuesData(
        //   streamedQuestions?.map((data: any, index: any) => ({
        //     ...data,
        //     isDone: false,
        //     qCount: index + 1,
        //   }))
        // );
        setCurrentQues(streamedQuestions?.[currentNum - 1] || currentQues);
      }
      if (state?.data) {
        setCurrentQues(state.data?.[currentNum - 1] || currentQues);
      }
    } else {
      // setQuesData(translatedData);
      setCurrentQues(translatedData?.[0] || currentQues);
    }
    console.log(translatedData);
  }, [eng, translatedData]);
  useEffect(() => {
    streamingQuestionCount = 0;
    if (state?.data) {
      setQuesData(
        state.data?.map((data: any, index: any) => ({
          ...data,
          isDone: false,
          qCount: index + 1,
        }))
      );
      setPrevData(
        state.data?.map((data: any, index: any) => ({
          ...data,
          isDone: false,
          qCount: index + 1,
        }))
      );
      allQuestionsDone = true;
      setCurrentQues(state.data?.[currentNum - 1] || currentQues);
    }
  }, []);
  useEffect(() => {
    if (streamedQuestions?.length && !currentQues)
      setCurrentQues(streamedQuestions?.[0]);
    console.log("11currentQues :", currentQues);
    if (streamedQuestions?.length) {
      streamingQuestionCount = streamedQuestions?.length;

      setQuesData(streamedQuestions);
    }
  }, [streamedQuestions]);
  useEffect(() => {
    if (isWaiting == 1) skipQuestion();
    if (isWaiting == 2) nextQuestion();
  }, [quesData])
  const [ws, setWs] = useState<any>();
  const socketRef: any = useRef();
  const [getFirstDAta, setGetFirstDAta] = useState<any>(false);
  const createNewSummarization = (isChangeLanguage?: any) => {
    // const ws=new WebSocket('https://d164-122-160-196-233.ngrok-free.app/file-summarization/');
    // if (isChangeLanguage && originalSummary) {
    //   setSummary(originalSummary)
    // }
    if (isChangeLanguage) {
      setDisableButton(true);
      setIsLoading(true);
    }
    const ws = new WebSocket(
      `wss:${API_URL.replaceAll("https://", "")}testing/generate-response/`
    );
    socketRef.current = ws;
    ws.onmessage = (event) => {
      localStorage.setItem("isTestLoading", "1")
      console.log("event.data :", event.data);
      const data = JSON.parse(event.data);
      if (data.signal == -1) {
        ws.close();
        showError(data?.data || data?.message || translate.errors.pleaseTryAgain || "Please try again");
        navigate("/testing", { replace: true });
      }
      if (data.signal == 0) {
        setDisableButton(false);
        allQuestionsDone = true;
        record_id = data?.record_id;
        ws.close();
        showToast(
          translate.testing.TestQuestionsGeneratedSuccessfully ||
          "Test questions Generated successfully"
        );
      }
      if (data.signal == 401) {
        ws.close();
        showError(data?.data || data?.message || translate.errors.pleaseLogin || "Please login");
        navigate("/");
        // return;
      }
      if (data.signal == 400) {
        ws.close();
        showError(data?.data || data?.message || translate.errors.somethingWentWrong);
        // if (isChangeLanguage) setIsLoading(false);
        navigate("/testing", { replace: true });
        // return;
      }
      if (data.signal == 1) {

        setGetFirstDAta(true);
        const data2: any = JSON.parse(event.data);
        setStreamedQuestions((prev: any) => [
          ...prev,
          ...data2.data?.map((data: any, index: any) => ({
            ...data,
            isDone: false,
            qCount: streamingQuestionCount + index + 1,
          })),
        ]);
        setPrevData((prev: any) => [
          ...prev,
          ...data2.data?.map((data: any, index: any) => ({
            ...data,
            isDone: false,
            qCount: streamingQuestionCount + index + 1,
          })),
        ]);
        // setStreamedQuestions(data2?.data);
        console.log("data2.data mmmmmm :", data2);
      }
      const teset = document.getElementById("mohit");
      if (teset) {
        setTimeout(() => {
          teset.scrollIntoView({ behavior: "smooth", block: "center" });
        }, 0);
      }

      console.log(event);
    };
    ws.onopen = async () => {
      setIsBlink(true);
      setDisableButton(true);

      allQuestionsDone = false;
      let binaryData: any = [];
      if (state?.selectedFile) {
        if (Array.isArray(state?.selectedFile)) {
          for (let key = 0; key < state?.selectedFile?.length; key++) {
            binaryData.push(await fileToBase64(state?.selectedFile[key]));
          }
        } else {
          binaryData = await fileToBase64(state?.selectedFile);
        }
      }
      const sub_cat = state?.nextPage === "/quiz/" ? 1 : 2;
      const PAYLOAD: any = {
        token: JSON?.parse(getFromStorage(STORAGE_KEYS.token) as string),
        sub_category: sub_cat,
        type: state?.fileType,
        binary_data: binaryData,
        file_name: state?.fileName ? state?.fileName : Date.now(),
        is_repeating: state?.repeatingQuestion,
      };

      console.log("PAYLOAD :", PAYLOAD);
      ws?.send(JSON.stringify(PAYLOAD));
      console.log("Connected to websocket successfully");
    };
    ws.onerror = (error) => {
      setIsBlink(false);
      // setIsLoading(false);
      ws.close();
      console.log("There is some error", error);
    };
    ws.onclose = () => {
      setIsBlink(false);
      // setIsLoading(false);
      console.log("Socket is closed");
    };
    setWs(ws);
  };
  const handleBeforeUnload = (e: any) => {
    e.preventDefault();
    const message =
      "Are you sure you want to leave?";
    e.returnValue = message;
    return message;
  };
  useEffect(() => {
    if (state?.isGenerating)
      window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);
  useEffect(() => {
    if (state?.isGenerating) createNewSummarization();
    if (localStorage.getItem("isTestLoading") == "1") {
      showError("Reloading not allowed during test")
      navigate('/testing')
    }
  }, []);
  useEffect(() => {
    isBack = false;
    setRepeating(state?.repeatingQuestion);
    return () => {
      isBack = true;
      if (socketRef?.current) {
        console.log("Cleaning up: Closing WebSocket");
        socketRef?.current.close(); // Close WebSocket on cleanup
      }
      if (ws) {
        ws.close();
      }
    };
  }, []);
  return state?.isGenerating && !getFirstDAta ? (
    <TestAi />
  ) : (
    <>
      <Loader isLoad={isLoading} />
      <section className="quiz_wrap">
        <div className="gap_p quiz_sec">
          <div className="quiz_lft">
            <button onClick={gotohome1} className="back_btn">
              <ArrowBackIosIcon />
            </button>
            <ul className="quiz_list" dir="auto">
              {quesData?.length
                ? quesData
                  ?.filter((data: any) => (data?.isDone ? false : data))
                  ?.map((item: any, index: number) => {
                    console.log("item :", item);

                    return (
                      <li key={index}>
                        <span> Ques {index + 1} </span>{" "}
                        <span
                          style={
                            currentNum == item.qCount
                              ? { color: "#8a61ff", cursor: "pointer" }
                              : { cursor: "pointer" }
                          }
                        //  onClick={() => {
                        //   setCurrentQues(item);
                        //   setCurrentNum(index + 1);
                        //   setSelectedOption('');
                        // }}
                        >
                          {truncateText(item?.question, 20)}
                        </span>
                      </li>
                    );
                  })
                : ""}
              {disableButton && (
                <li>
                  <figure>
                    <img
                      src="/static/images/test-load.gif"
                      style={{ marginInline: "100px", maxWidth: "12%" }}
                    />
                  </figure>
                </li>
              )}
            </ul>
          </div>
          <div className="quiz_rht" dir="auto">
            <div className="quiz_head">
              <p>
                {currentNum <= quesData?.length
                  ? prevData?.findIndex(
                    (data: any) => data.qCount == currentQues?.qCount
                  ) +
                  1 +
                  "/" +
                  // quesData?.filter((data: any) => !data.isDone)?.length
                  prevData?.length
                  : ""}
                {disableButton && (
                  <figure>
                    <img src="/static/images/test-load.gif" />
                  </figure>
                )}
              </p>
              <h2>{currentQues?.question || "-"}</h2>
            </div>
            <FormGroup className="quz_mn">
              <RadioGroup
                value={selectedOption}
                onChange={(e) => handleChange(e)}
              >
                {currentQues?.answer_option?.map((data: any) =>
                  data ? (
                    <FormControlLabel
                      className={
                        state?.repeatingQuestion
                          ? selectedOption === ""
                            ? ""
                            : selectedOption === data
                              ? selectedOption === currentQues?.correct_answer
                                ? "success_checkbox"
                                : "error_checkbox"
                              : ""
                          : selectedOption === ""
                            ? ""
                            : currentQues?.correct_answer === data
                              ? "success_checkbox"
                              : "error_checkbox"
                      }
                      value={data || ""}
                      control={<Radio disabled={isRadioButtonDisabled} />}
                      label={data || ""}
                    />
                  ) : (
                    ""
                  )
                )}
                {/* {currentQues?.answer_option?.[1] ? (
                  <FormControlLabel
                    className={
                      selectedOption === ""
                        ? ""
                        : currentQues?.correct_answer ===
                            currentQues?.answer_option?.[1]
                          ? "success_checkbox"
                          : "error_checkbox"
                    }
                    value={currentQues?.answer_option?.[1]}
                    control={<Radio disabled={isRadioButtonDisabled} />}
                    label={currentQues?.answer_option?.[1]}
                  />
                ) : (
                  ""
                )}

                {currentQues?.answer_option?.[2] ? (
                  <FormControlLabel
                    className={
                      selectedOption === ""
                        ? ""
                        : currentQues?.correct_answer ===
                            currentQues?.answer_option?.[2]
                          ? "success_checkbox"
                          : "error_checkbox"
                    }
                    value={currentQues?.answer_option?.[2]}
                    control={<Radio disabled={isRadioButtonDisabled} />}
                    label={currentQues?.answer_option?.[2]}
                  />
                ) : (
                  ""
                )}

                {currentQues?.answer_option?.[3] ? (
                  <FormControlLabel
                    className={
                      selectedOption === ""
                        ? ""
                        : currentQues?.correct_answer ===
                            currentQues?.answer_option?.[3]
                          ? "success_checkbox"
                          : "error_checkbox"
                    }
                    value={currentQues?.answer_option?.[3]}
                    control={<Radio disabled={isRadioButtonDisabled} />}
                    label={currentQues?.answer_option?.[3]}
                  />
                ) : (
                  ""
                )} */}
              </RadioGroup>
            </FormGroup>

            <div className="skip">
              <span
                onClick={() => skipQuestion()}
                className={
                  // userResBody?.length === quesData?.length ||
                  selectedOption !== "" ? "disabledSkip" : ""
                }
                style={{
                  color: selectedOption !== "" ? "grey" : "cornflowerblue",
                }}
              >
                {translate.testing.Skip || "Skip"}
              </span>

              <div className="quz_btns">
                <Button
                  size="small"
                  color="primary"
                  onClick={() => {
                    if (eng) {
                      getChangeLanguage(false);
                    }
                    setEng(!eng);
                  }}
                >
                  {translate.global.changeLanguage || "Change language"}
                </Button>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => FetchDownLoadFile(2)}
                >
                  {translate.testing.downloadWithoutAnswers ||
                    "Download File Without Answers"}
                </Button>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => FetchDownLoadFile(3)}
                >
                  {translate.testing.downloadWithAnswers ||
                    "Download File With Answers"}
                </Button>
              </div>
            </div>
            <div className="chat_box">
              {selectedOption !== "" ? (
                <div className="ans_status">
                  <div className="usr_dtl">
                    <figure>
                      <img
                        src={
                          correct
                            ? "/static/images/smily_face.png"
                            : "/static/images/wrong_ans.png"
                        }
                        alt="icon"
                      ></img>
                    </figure>
                    <div className="usr_cnt hd_5">
                      <h3>
                        {translate.testing.theAnswerIs || "The answer is"}{" "}
                        {correct
                          ? translate.testing.right || "Right"
                          : translate.testing.wrong || "Wrong"}
                      </h3>
                    </div>
                  </div>
                  <div className="btn_group">
                    {/* <Button
                      size="small"
                      variant="outlined"
                      onClick={handleOpen}
                      disabled
                    >
                      AI EXPLANATION
                    </Button> */}
                    <Button
                      size="small"
                      variant="outlined"
                      // onClick={handleOpen1}
                      onClick={() => nextQuestion()}
                    >
                      {translate.global.next || "NEXT"}
                    </Button>
                  </div>
                </div>
              ) : (
                ""
              )}

              <Button
                size="small"
                id="basic-button"
                aria-controls={openMenu ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openMenu ? "true" : undefined}
                onClick={openChat}
              >
                {openMenu ? (
                  <CloseIcon />
                ) : (
                  <figure>
                    <img src="/static/images/chatbot_icon.svg" alt="icon"></img>
                  </figure>
                )}
              </Button>

              <Menu
                id="basic-menu"
                className="chatBot_box"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={closeChat}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                transformOrigin={{ horizontal: "right", vertical: "bottom" }}
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
              >
                <div className="chat_wrap">
                  <p>
                    {translate.global.whizzoAiChatbot || "Whizzo AI Chatbot"}
                  </p>
                  <div className="chat_body">
                    <Box
                      sx={{
                        height: "300px",
                        overflowY: "auto",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        padding: "10px",
                        marginBottom: "10px",
                        // backgroundColor: "#f9f9f9",
                      }}
                      ref={chatHistoryRef}
                    >
                      {chatHistory.length ? (
                        chatHistory.map((msg: any, index: any) =>
                          msg.sender == "You" ? (
                            <div className="chat_right">
                              <p dir="auto">
                                {/* <strong>{msg.sender}</strong>:  */}
                                {msg.text}
                              </p>
                            </div>
                          ) : (
                            <div className="chat_right chat_left">
                              <figure>
                                <img
                                  style={{
                                    maxWidth: "70%",
                                    background: "#2d2b2b",
                                    borderRadius: "50%",
                                    padding: "7px",
                                  }}
                                  src="/static/images/chatBot.png"
                                />
                              </figure>
                              <p dir="auto">
                                {/* <strong>{msg.sender}</strong>:  */}
                                {msg.text}
                              </p>
                            </div>
                          )
                        )
                      ) : (
                        <Box sx={{ textAlign: "center", color: "#888" }}>
                          {translate.global.noMessagesYet || "No messages yet."}
                        </Box>
                      )}
                      {chatHistory?.[chatHistory.length - 1]?.sender ===
                        "You" &&
                        loading && (
                          // <CircularProgress
                          //   size={30}
                          //   sx={{
                          //     marginLeft: "10px",
                          //     color: "#666",
                          //   }}
                          // />
                          <figure
                            style={{ maxWidth: " 60px", minHeight: "25px" }}
                          >
                            <img src="/static/images/chat_load.gif" />
                          </figure>
                        )}
                    </Box>
                    {/* <div className="chat_right">
                      <p>Please answer this ques</p>
                    </div>

                 
                    <div className="chat_right">
                      <p>Ok</p>
                    </div> */}
                  </div>

                  <div className="frmn_mn send_msg ">
                    {/* <Button className="send_more">
                      {" "}
                      <AddIcon />{" "}
                    </Button> */}
                    <form
                      onSubmit={onSendMessage}
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <TextField
                        className="control_group"
                        hiddenLabel
                        fullWidth
                        onKeyDown={(e) => {
                          e.stopPropagation();
                        }}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        variant="outlined"
                        placeholder="Type a message"
                      />
                      <button
                        className="cursor_pointer"
                        onClick={onSendMessage}
                        style={{
                          background: "transparent",
                          border: "none",
                          padding: "8px",
                        }}
                      >
                        <img
                          src="/static/images/send_chat_icon.svg"
                          alt="icon"
                        ></img>
                      </button>
                    </form>
                  </div>
                </div>
              </Menu>
            </div>
          </div>
        </div>
      </section>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modall"
      >
        <Box sx={style} className="text_center how_to_modal">
          <p className="close_icon" onClick={handleClose}>
            <CloseIcon />
          </p>
          <figure>
            <img src="/static/images/bulb.svg" alt="icon"></img>
          </figure>
          <Typography variant="h6" component="h2">
            How to learn trignomentery table easily?
          </Typography>
          <Typography sx={{ mt: 2 }}>
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout. The point
            of using Lorem Ipsum is that it has a more-or-less normal
            distribution of letters, as opposed to using 'Content here, content
            here', making it look like readable English. Many desktop publishing
            packages and web page editors now use Lorem Ipsum as their default
            model text, and a search for 'lorem ipsum' will uncover many web
            sites still in their infancy. Various versions have evolved over the
            years, sometimes by accident, sometimes on purpose (injected humour
            and the like).
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modall"
        BackdropProps={{
          onClick: (event) => event.stopPropagation,
        }}
      >
        <Box sx={style} className="text_center test_cmplt">
          <div className="modal_header">
            <button className="back_btn" onClick={gotohome}>
              <figure>
                <img src="/static/images/back_arrow.svg" alt="icon"></img>
              </figure>
              {translate.global.backToHome || "Back to Home"}
            </button>

            <h2>{translate.testing.testCompleted || "Test Complete"}</h2>
            <p className="close_icon" onClick={gotohome}>
              <CloseIcon />
            </p>
          </div>
          <figure className="wron_ans">
            <img src="/static/images/wrong_ans.png" alt="icon"></img>
          </figure>
          <div className="over_score">
            <div className="over_scr_bx">
              <p>{translate.testing.overallScore || "Overall Score"}</p>
              <h2>{result?.correct_answers}</h2>
            </div>
            <ul className="prgrs_list ">
              <li>
                <h3>{result?.correct_answers_percentage}%</h3>
                <p>{translate.testing.correct || "Correct"}</p>
              </li>
              <li>
                <h3>{result?.wrong_answers_percentage}%</h3>
                <p>{translate.testing.incorrect || "Incorrect"}</p>
              </li>
              <li>
                <h3>{result?.remaining_answers_percentage}%</h3>
                <p>{translate.testing.remaining || "Remaining"}</p>
              </li>
            </ul>
          </div>
          <div className="btn_grup">
            {!repeating ? (
              <Tooltip
                title={
                  translate.testing.reAppearMessage || "Do this test again"
                }
              >
                <button
                  className="re_attempt"
                  onClick={() => {
                    setDisableButton(false);
                    setIsButtonDisabled(false);

                    if (state?.isGenerating) {
                      setSelectedOption("");
                      setCurrentNum(1);
                      setQuesData(
                        streamedQuestions?.map((data: any) => ({
                          ...data,
                          isDone: false,
                        }))
                      );
                      setPrevData(
                        streamedQuestions?.map((data: any) => ({
                          ...data,
                          isDone: false,
                        }))
                      );
                      setCurrentQues(streamedQuestions?.[0]);
                      setUserResBody([]);
                    } else {
                      setSelectedOption("");
                      setCurrentNum(1);
                      setQuesData(
                        state.data?.map((data: any) => ({
                          ...data,
                          isDone: false,
                        }))
                      );
                      setPrevData(
                        state.data?.map((data: any) => ({
                          ...data,
                          isDone: false,
                        }))
                      );
                      setCurrentQues(state.data[0]);
                      setUserResBody([]);
                    }
                    setEng(true);
                    setResult(null);
                    handleClose1();
                    window.scrollTo(0, 0);
                  }}
                >
                  {translate.testing.reAppear || "Re-appear"}
                </button>
              </Tooltip>
            ) : (
              <React.Fragment>
                <Tooltip
                  title={
                    translate.testing.continueMessage || "Continue the test"
                  }
                >
                  <button
                    className="re_attempt"
                    onClick={() => {
                      setIsButtonDisabled(false);
                      console.log("marks", userResBody);
                      console.log("marks", quesData);
                      if (state?.isGenerating) {
                        setSelectedOption("");

                        // setQuesData((data: any) =>
                        //   data?.correct_answer == data?.user_answer
                        //     ? { ...data, isDone: true }
                        //     : { ...data, isDone: false }
                        // );
                        const temp = quesData.map((data: any, index: number) =>
                          userResBody?.[index]?.correct_answer ==
                            userResBody?.[index]?.user_answer
                            ? { ...data, isDone: true }
                            : { ...data, isDone: false }
                        );
                        setCurrentNum(
                          temp?.findIndex(
                            (data: any, index: any) =>
                              // index + 1 > currentNum &&
                              !data.isDone
                          ) + 1
                        );

                        setPrevData(temp.filter((data: any) => !data.isDone));
                        setQuesData(temp);
                        setCurrentQues(
                          temp?.find((data: any) => !data.isDone)
                          // ||streamedQuestions?.[0]
                        );
                        // setUserResBody([]);
                      } else {
                        setSelectedOption("");

                        // setUserResBody([]);
                        const temp = quesData.map((data: any, index: number) =>
                          userResBody?.[index]?.correct_answer ==
                            userResBody?.[index]?.user_answer
                            ? { ...data, isDone: true }
                            : { ...data, isDone: false }
                        );
                        setPrevData(temp.filter((data: any) => !data.isDone));
                        setCurrentNum(
                          temp?.findIndex(
                            (data: any, index: any) =>
                              // index + 1 > currentNum &&
                              !data.isDone
                          ) + 1
                        );
                        setCurrentQues(
                          temp?.find((data: any) => !data.isDone)
                          // ||state.data[0]
                        );
                        setQuesData(temp);
                      }
                      setEng(true);
                      setResult(null);
                      handleClose1();
                      window.scrollTo(0, 0);
                    }}
                  >
                    {translate.testing.continue || "Continue"}
                  </button>
                </Tooltip>
                <Tooltip
                  title={translate.testing.finishMessage || "Finish the test"}
                >
                  <button
                    className="re_attempt"
                    onClick={() => navigate("/testing")}
                  >
                    {translate.testing.finish || "Finish"}
                  </button>
                </Tooltip>
              </React.Fragment>
            )}
          </div>
          <div className="btn_group">
            <Button onClick={() => getChangeLanguage(true)}>
              {" "}
              {translate.global.changeLanguageDownload ||
                "Change Language and download"}
            </Button>
            <Button onClick={() => FetchDownLoadFile(2)}>
              {translate.testing.downloadWithoutAnswers ||
                "Download File Without Answers"}
            </Button>
            <Button onClick={() => FetchDownLoadFile(3)}>
              {translate.testing.downloadWithAnswers ||
                "Download File With Answers"}
            </Button>
          </div>
        </Box>
      </Modal>
      {/* <ChatBox /> */}
    </>
  );
};

export default Quiz;
