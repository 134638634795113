import React from "react";
import Latex from "react-latex-next";
import "katex/dist/katex.min.css";
import parse from "html-react-parser"; // Import KaTeX CSS
//@ts-ignore
import { BlockMath, InlineMath } from "react-katex";
export const renderContent = (htmlContent: string) => {
  // Regular expression to identify LaTeX expressions within []
  const latexRegex = /\[(.*?)\]/g;

  // Parse HTML and handle LaTeX within brackets
  const modifiedHtmlContent = htmlContent.replace(
    latexRegex,
    (match, latexExpression) => {
      // Render LaTeX using KaTeX
      return `<span class="latex-expression">${latexExpression}</span>`;
    }
  );

  return parse(modifiedHtmlContent, {
    replace: (domNode: any) => {
      if (domNode.attribs && domNode.attribs.class === "latex-expression") {
        return <InlineMath math={domNode.children[0].data} />;
      }
    },
  });
};
const LatexTest = () => {

  const latex = `Here are 10 more calculus questions for practice:\n\n---\n\n### **Q11: Find the derivative of \\( f(x) = \\ln(3x^2 + 5) \\).**\n\n### **Q12: Evaluate the integral \\( \\int (x^2 + 2x + 1)^2 \\, dx \\).**\n\n### **Q13: Solve the limit \\( \\lim_{x \\to 0} \\frac{\\sin(3x)}{x} \\).**\n\n### **Q14: Determine the inflection points of \\( f(x) = x^4 - 4x^3 + 6x^2 \\).**\n\n### **Q15: What is the derivative of \\( f(x) = \\tan^{-1}(x) \\)?**\n\n### **Q16: Find the local minima or maxima for \\( f(x) = 2x^4 - 4x^2 + 3 \\).**\n\n### **Q17: Evaluate the integral \\( \\int \\frac{1}{x} \\, dx \\).**\n\n### **Q18: Find the derivative of \\( f(x) = \\frac{3x^2 - 2}{x^3 + 1} \\) using the quotient rule.**\n\n### **Q19: Evaluate the limit \\( \\lim_{x \\to \\infty} \\frac{e^x}{x^2} \\).**\n\n### **Q20: What is the integral of \\( \\int \\cos^2(x) \\, dx \\)?**\n\n---\n\nThese questions include more advanced concepts like inverse trigonometric derivatives, inflection points, and limits involving exponential functions."`;
  const test = `<body> <p><strong>Question 1:</strong> Differentiate [ f(x) = \\sin(x) \\cdot e^x ].</p> <p><strong>Answer 1:</strong> [ f'(x) = \\cos(x) \\cdot e^x + \\sin(x) \\cdot e^x ].</p> <p><strong>Question 2:</strong> Evaluate the definite integral [ \\int_1^3 \\frac{1}{x} \\, dx].</p> <p><strong>Answer 2:</strong> [ \\int_1^3 \\frac{1}{x} \\, dx = \\ln(3) - \\ln(1) = \\ln(3) ].</p> <p><strong>Question 3:</strong> Find the limit [ \\lim_{{x \\to 2}} \\frac{x^2 - 4}{x - 2}].</p> <p><strong>Answer 3:</strong> [ \\lim_{{x \\to 2}} \\frac{x^2 - 4}{x - 2} = \\lim_{{x \\to 2}} \\frac{(x - 2)(x + 2)}{x - 2} = \\lim_{{x \\to 2}} (x + 2) = 4 ].</p> <p><strong>Question 4:</strong> Determine the convergence or divergence of the series [ \\sum_{{n=1}}^\\infty \\frac{1}{n^3} ].</p> <p><strong>Answer 4:</strong> The series [ \\sum_{{n=1}}^\\infty \\frac{1}{n^3}] converges because it is a p-series with [ p = 3 > 1 ].</p> <p><strong>Question 5:</strong> Solve the differential equation [ \\frac{dy}{dx} = 2x - 5 ].</p> <p><strong>Answer 5:</strong> The general solution is [ y = x^2 - 5x + C ], where [ C ] is the constant of integration.</p> </body>`
  const test1 = `<body> <p><strong>Question 1:</strong> Differentiate [( f(x) = \\sin(x) \\cdot e^x )].</p> <p><strong>Answer 1:</strong> [( f'(x) = \\cos(x) \\cdot e^x + \\sin(x) \\cdot e^x )].</p> <p><strong>Question 2:</strong> Evaluate the definite integral [( \\int_1^3 \\frac{1}{x} \\, dx )].</p> <p><strong>Answer 2:</strong> [( \\int_1^3 \\frac{1}{x} \\, dx = \\ln(3) - \\ln(1) = \\ln(3) )].</p> <p><strong>Question 3:</strong> Find the limit [( \\lim_{{x \\to 2}} \\frac{x^2 - 4}{x - 2} )].</p> <p><strong>Answer 3:</strong> [( \\lim_{{x \\to 2}} \\frac{x^2 - 4}{x - 2} = \\lim_{{x \\to 2}} \\frac{(x - 2)(x + 2)}{x - 2} = \\lim_{{x \\to 2}} (x + 2) = 4 )].</p> <p><strong>Question 4:</strong> Determine the convergence or divergence of the series [( \\sum_{{n=1}}^\\infty \\frac{1}{n^3} )].</p> <p><strong>Answer 4:</strong> The series [( \\sum_{{n=1}}^\\infty \\frac{1}{n^3} )] converges because it is a p-series with [( p = 3 > 1 )].</p> <p><strong>Question 5:</strong> Solve the differential equation [( \\frac{dy}{dx} = 2x - 5 )].</p> <p><strong>Answer 5:</strong> The general solution is [( y = x^2 - 5x + C )], where [( C )] is the constant of integration.</p> </body>`;
  // return <Latex>{latex}</Latex>;
  return <div>{renderContent(test1)}</div>
};

export default LatexTest;
