import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { showWarning } from "../constants/toast";
import { TestAI } from "../constants/testAILoading";
import useTranslation from "../hooks/Translation";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function NewResearch(props: any) {
  const navigate = useNavigate();
  const translate = useTranslation();
  const [selectedFile, setSelectedFile] = React.useState<any>(null);
  const handlePdfUpload = async (event: any) => {
    console.log(event.target.files[0]);
    const file = event.target.files[0];
    console.log(file?.name?.split("."));

    if (
      !(file?.name?.split(".")?.[file?.name?.split(".")?.length - 1] == "pdf")
    ) {
      showWarning(translate.errors.onlyPDFFormat || "Please upload pdf file");
      return;
    }
    setSelectedFile(event.target.files[0]);
    // navigate('/test-ai',{state:{
    //   selectedFile:file,
    //   type:TestAI.RESEARCH_REFERENCE,
    //   prevPage:"/research",
    // }});
    navigate("/pdfPreview", {
      state: {
        type: TestAI.RESEARCH_REFERENCE,
        prevPage: "/research",
        nextPage: "/reserch-edit2/",
        selectedFile: file,
        isReference: true,
      },
    });
  };
  return (
    <>
      <React.Fragment>
        <BootstrapDialog
          className="modl"
          onClose={props.handleClose}
          aria-labelledby="customized-dialog-title"
          open={props.open}
        >
          <IconButton
            aria-label="close"
            onClick={props.handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <p>{translate.research.startWithReferencesHeader}</p>
            <ul className="tst_lst">
              <li>
                <figure>
                  <img
                    // style={{border:"4px solid white",padding:"8px"}}
                    src="/static/images/bars.png"
                    alt=""
                  ></img>
                </figure>
                <Button className="btnn btn_primary upld_btn">
                  {translate.research.startWithReferences}
                  <input
                    type="file"
                    accept="application/pdf"
                    onChange={(e) => {
                      handlePdfUpload(e);
                      e.target.value = "";
                    }}
                  />
                </Button>
              </li>
              <li>
                <figure>
                  <img src="/static/images/box.png" alt=""></img>
                </figure>
                <Button
                  className="btnn btn_primary"
                  onClick={() => {
                    navigate("/topic-research");
                  }}
                >
                  {translate.research.startWithoutReferences}
                </Button>
              </li>
            </ul>
          </DialogContent>
          <DialogActions></DialogActions>
        </BootstrapDialog>
      </React.Fragment>
    </>
  );
}
export default NewResearch;
