//@ts-ignore
const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{1FC00}-\u{1FFFD}\u{200D}\u{FE0F}]/u;
const specialCharRegex = /[^a-zA-Z0-9 ]{2,}/;
export const isString = (val: any) => {
  if (val.includes(".")) {
    return true;
  }
  if (val.length === 1 && val === " ") {
    return false;
  }
  if (
    val[val.length - 1] === " " &&
    val[val.length - 1] !== val[val.length - 2]
  ) {
    return true;
  }
  if (
    val[val.length - 1]?.trim()?.toLowerCase() !==
    val[val.length - 1]?.trim()?.toUpperCase() ||
    val === ""
  ) {
    return true;
  }
  return false;
};
// export const isValidInputNative = (value: string) => {
//   if (value === '') {
//     return true;
//   }
//   if (value.trim() === '') {
//     return false; // Reject if the value is empty or only consists of whitespace
//   }
//   if (value.includes('  ') || value.includes('..')) {
//     return false; // Reject if there are consecutive spaces or decimals
//   }
//   if (emojiRegex.test(value.replace(' ', ''))) {
//     return false; // Reject if the value contains an emoji
//   }
//   if (specialCharRegex.test(value.replace(' ', ''))) {
//     return false; // Reject if the value contains consecutive special characters
//   }
//   return true; // Accept the input if it meets all the conditions
// };

export const isValidInputNative = (value: string) => {
  // Reject if the value is empty or only consists of whitespace
  // if (value.trim() === '') {
  //   return false;
  // }

  // Reject if there are consecutive spaces
  if (value.includes('  ')) {
    return false;
  }
  const stringData = '?:[!@#$%^&*(),.?;+-\'~/\\":{}|<>]';
  // Reject consecutive special characters by checking for patterns
  // const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/g;
  // if (specialCharRegex.test(value)) {
  // Check for consecutive special characters
  for (let key of stringData) {
    if (value.includes(`${key}${key}`))
      return false;
  }
  for (let key of stringData) {
    if (value.includes(`${key} ${key}`))
      return false;
  }

  // Regex for emojis (excluding whitespace for proper testing)
  //@ts-ignore
  // const emojiRegex = /\p{Emoji}/u;
  // if (emojiRegex.test(value.replace(/ /g, ''))) {
  //   return false; // Reject if the value contains an emoji
  // }

  return true; // Accept the input if it meets all the conditions
};



export const isFloat = (val: any) => {
  if (val[val.length - 1] === " " || val === "." || val === "0") {
    return false;
  }
  if (val.includes(".")) {
    val = val.replace(".", "");
    // eslint-disable-next-line no-restricted-globals
    if ((!val.includes(".") && !isNaN(val?.trim())) || val === "") {
      return true;
    }
    return false;
  }
  // eslint-disable-next-line no-restricted-globals
  if (!isNaN(val?.trim()) || val === "") {
    return true;
  }
  return false;
};
export const isNumber = (val: any) => {
  if (val[val.length - 1] === " ") {
    return false;
  }
  if (val.includes(".")) {
    return false;
  }
  // eslint-disable-next-line no-restricted-globals
  if (!isNaN(val?.trim()) || val === "") {
    return true;
  }
  return false;
};

export const isValidInput = (value: string) => {
  if (value === "") {
    return true;
  }
  if (value.trim() === "") {
    return false; // Reject if the value is empty or only consists of whitespace
  }

  if (value.includes("  ") || value.includes("..")) {
    return false; // Reject if there are consecutive spaces or decimals
  }

  return true; // Accept the input if it meets all the conditions
};
export const isArabicText = (str: string) => {
  const arabicRegex =
    /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDCF\uFDF0-\uFDFF\uFE70-\uFEFF]/g;
  const matches = str?.match(arabicRegex);
  return matches && matches.length > 0;
};