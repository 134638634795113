/* eslint-disable @typescript-eslint/no-unused-vars */
import Layout from "../../layout/index";
import "react-tabs/style/react-tabs.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, TextField } from "@mui/material";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import React, { useState } from "react";
import Header from "../../layout/header/Header";
import useAuth from "../../hooks/useAuth";
import "./checkbox.css";
import { TestAI } from "../../constants/testAILoading";
import { showError } from "../../constants/toast";
import { isString } from "../../utils/validations";
import useTranslation from "../../hooks/Translation";
function SpecifyReference() {
  const translate = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log(state);
  const [selectedReference, setSelectedReference] = useState(1);
  const referenceEnum: any = translate?.getLanguage() == "en" ? {
    1: "None",
    2: "MLA",
    3: "APA",
    4: "IEEE",
    5: "Chicago",
    6: "Harvard",
    7: "Vancouver",
  } : {
    1: "لا شيء",
    2: "MLA",
    3: "APA",
    4: "IEEE",
    5: "شيكاغو",
    6: "هارفارد",
    7: "فانكوفر"
  }
    ;

  const [researchDescription, setResearchDescription] = useState("");
  const [isCitation, setIsCitation] = useState(false);
  console.log(isCitation);

  return (
    <div className="sdbr_rltv">
      <Layout.SidePanel />
      <Header />
      <div className="sde_br_mn slct">
        <div className="sde_mn_cnt tpc_rsrch rfnce">
          <h6>{translate.research.specifyReferences}</h6>

          <Tabs>
            <TabList>
              <Tab style={{ cursor: "context-menu" }}>
                {translate.research.createNewResearch}
              </Tab>
              {/* <Tab>My Researches</Tab> */}
            </TabList>

            <TabPanel>
              <div className="sde_mn_cnt slct_mn articl rsrh_pge ">
                <ul className="slct_tn text_center">
                  <li
                    className={selectedReference == 1 ? "active" : ""}
                    onClick={() => setSelectedReference(1)}
                    style={{
                      cursor:
                        selectedReference == 1 ? "context-menu" : "pointer",
                    }}
                  >
                    {referenceEnum[1]}
                  </li>
                  <li
                    className={selectedReference == 2 ? "active" : ""}
                    onClick={() => setSelectedReference(2)}
                    style={{
                      cursor:
                        selectedReference == 2 ? "context-menu" : "pointer",
                    }}
                  >
                    {referenceEnum[2]}
                  </li>
                  <li
                    className={selectedReference == 3 ? "active" : ""}
                    onClick={() => setSelectedReference(3)}
                    style={{
                      cursor:
                        selectedReference == 3 ? "context-menu" : "pointer",
                    }}
                  >
                    {referenceEnum[3]}
                  </li>
                  <li
                    className={selectedReference == 4 ? "active" : ""}
                    onClick={() => setSelectedReference(4)}
                    style={{
                      cursor:
                        selectedReference == 4 ? "context-menu" : "pointer",
                    }}
                  >
                    {referenceEnum[4]}
                  </li>
                  <li
                    className={selectedReference == 5 ? "active" : ""}
                    onClick={() => setSelectedReference(5)}
                    style={{
                      cursor:
                        selectedReference == 5 ? "context-menu" : "pointer",
                    }}
                  >
                    {referenceEnum[5]}
                  </li>
                  <li
                    className={selectedReference == 6 ? "active" : ""}
                    onClick={() => setSelectedReference(6)}
                    style={{
                      cursor:
                        selectedReference == 6 ? "context-menu" : "pointer",
                    }}
                  >
                    {referenceEnum[6]}
                  </li>
                  <li
                    className={selectedReference == 7 ? "active" : ""}
                    onClick={() => setSelectedReference(7)}
                    style={{
                      cursor:
                        selectedReference == 7 ? "context-menu" : "pointer",
                    }}
                  >
                    {referenceEnum[7]}
                  </li>
                </ul>
                {/* <p className='text_center'><small>Approx. 54000 - 90000 <span className='d_block'>symbols / 90000 - 15000 words</span></small></p> */}
                <TextField
                  className="control_group"
                  hiddenLabel
                  placeholder={
                    translate.global.enterDescription || "Enter description"
                  }
                  fullWidth
                  value={researchDescription}
                  onChange={(e) => {
                    if (e.target.value == " ") return;
                    if (!isString(e.target.value)) {
                      return;
                    }
                    if (e.target.value.length <= 500)
                      setResearchDescription(e.target.value);
                    else
                      showError(
                        translate.errors.descriptionCanNotExceed500Characters ||
                        "Description can't exceed 500 characters"
                      );
                  }}
                  // onChange={(e:React.ChangeEvent<HTMLInputElement>)=>}
                  variant="outlined"
                  multiline
                  rows={5}
                />
              </div>
              <div className="rght_btn  togl_mn">
                <div className="togl">
                  <p>
                    <small>
                      {translate.research.reduceCitations || "Reduce Citations"}
                    </small>
                  </p>

                  <div className="container0001">
                    <input
                      type="checkbox"
                      className="checkbox0001"
                      checked={isCitation}
                      onClick={() => setIsCitation(!isCitation)}
                      id="checkbox0001"
                    />
                    <label
                      className="switch0001"
                      // onClick={()=>setIsCitation(!isCitation)}
                      htmlFor="checkbox0001"
                    >
                      <span className="slider0001 "></span>
                    </label>
                  </div>
                  {/* <FormControlLabel
                    control={<IOSSwitch sx={{ m: 1 }} />}
                    label=""
                    checked={isCitation} 
                    onClick={()=>setIsCitation(!isCitation)} 
                  /> */}
                </div>
                <Button
                  type="button"
                  className="btnn btn_primary h_46"
                  onClick={() => {
                    navigate("/reserch-edit2/", {
                      state: {
                        ...state,
                        reference: referenceEnum[selectedReference],
                        isCitation: isCitation,
                        description: researchDescription,
                        type: TestAI.RESEARCH,
                        isReference: false,
                      },
                      replace: true,
                    });
                    //   navigate("/test-ai",{state:{...state,
                    //   reference:referenceEnum[selectedReference],
                    //   isCitation:isCitation,
                    //   description:researchDescription,
                    //   type:TestAI.RESEARCH
                    // },replace:true})
                  }}
                >
                  {translate.global.next || "Next"}
                </Button>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default SpecifyReference;
