import {
  ContactUsUrl,
  END_POINTS,
  contactSupport,
  purposeUrl,
} from "../constants/url";
import { CommonBody } from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  status: number;
  message: string;
};

export const cmsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getTerms: builder.query<CommonResponseType & { data: any }, {}>({
      query: () => ({
        url: END_POINTS.termsConditions,
        method: "GET",
      }),
    }),
    getFaq: builder.query<CommonResponseType & { data: any }, {}>({
      query: () => ({
        url: END_POINTS.faq,
        method: "GET",
      }),
    }),
    getContact: builder.query<CommonResponseType & { data: any }, {}>({
      query: () => ({
        url: ContactUsUrl,
        method: "GET",
      }),
    }),
    postContactSupport: builder.mutation<
      CommonResponseType & { data: any },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.sendQuery,
        method: "POST",
        body,
      }),
    }),
    getTestimonials: builder.query<CommonResponseType & { data: any }, {}>({
      query: () => ({
        url: END_POINTS.testimonial,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyGetTermsQuery,
  useLazyGetFaqQuery,
  useLazyGetContactQuery,
  usePostContactSupportMutation,
  useLazyGetTestimonialsQuery,
} = cmsApi;
