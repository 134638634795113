/* eslint-disable @typescript-eslint/no-unused-vars */
import Layout from "../../layout/index";
import "react-tabs/style/react-tabs.css";
import { Button, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import useAuth from "../../hooks/useAuth";
import React, { useState } from "react";
import Header from "../../layout/header/Header";
import { showError } from "../../constants/toast";
import { isValidInputNative } from "../../utils/validations";
import useTranslation from "../../hooks/Translation";

function TopicResearch() {
  const navigate = useNavigate();
  const translate = useTranslation();
  const [researchTopic, setResearchTopic] = useState("");
  const [sidebar, setSidebar] = useState(true);
  return (
    <div className="sdbr_rltv">
      {sidebar ? <Layout.SidePanel /> : null}
      <Header setSidebar={setSidebar} />
      <div className="sde_br_mn">
        {/* <div className="sde_hdr">
          <div className="sde_hdr_lt">
            <figure>
              <img src="/static/images/research.png" alt=""></img>
            </figure>
            <div className="">
              <h3>Research</h3>
              <p> Lorem Ipsum dolor et amet</p>
            </div>
          </div>
          <div className="sde_hdr_rt">
            <figure>
              <img src="/static/images/notification.png" alt=""></img>
            </figure>
            <h3>Hi, Ahmed</h3>
            <figure>
              <img src="/static/images/ahmed.png" alt=""></img>
            </figure>
          </div>
        </div> */}
        <div className="sde_mn_cnt tpc_rsrch">
          <h5>{translate.research.topicResearch || "Topic of Research"}</h5>
          <Tabs>
            <TabList>
              <Tab style={{ cursor: "context-menu" }}>
                {translate.research.createNewResearch ||
                  "Create a new Research"}
              </Tab>
              {/* <Tab>My Researches</Tab> */}
            </TabList>

            <TabPanel>
              <div className="crt_psn hd_6 mt_40 crt_artcl">
                <div className="atcl_cntr">
                  <TextField
                    className="control_group"
                    hiddenLabel
                    placeholder="Enter research topic"
                    fullWidth
                    value={researchTopic}
                    onChange={(e) => {
                      if (e.target.value == " ") return;
                      if (!isValidInputNative(e.target.value)) {
                        return;
                      }
                      if (e.target.value.length <= 500)
                        setResearchTopic(e.target.value);
                      else
                        showError(
                          translate.errors.topicNameNotExceed500Characters ||
                            "Topic name can't exceed 500 characters"
                        );
                    }}
                    // onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setResearchTopic(e.target.value)}
                    variant="outlined"
                    multiline
                    rows={10}
                  />
                  {/* <div className="artcl_btn">
                    <Button type="button" className="btnn btn_primary">
                      Effect of mass communication
                    </Button>
                    <Button type="button" className="btnn btn_primary">
                      Effect of mass communication
                    </Button>
                    <Button type="button" className="btnn btn_primary">
                      Effect of mass communication of social media
                    </Button>
                  </div> */}
                  <div className="rght_btn">
                    <Button
                      type="button"
                      className="btnn btn_primary"
                      onClick={() => {
                        if (researchTopic?.trim() == "") {
                          showError(
                            translate.errors.enterTopicResearch ||
                              "Please enter topic of research"
                          );
                          return;
                        }
                        navigate("/research-page", {
                          state: researchTopic,
                          replace: true,
                        });
                      }}
                    >
                      {translate.global.next || "Next"}
                    </Button>
                  </div>
                </div>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default TopicResearch;
