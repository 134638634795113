import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import {
  useLazyGetAbilitiesQuery,
  useLazyGetAchivementsQuery,
} from "../services/testingModule";
import { showError } from "../constants/toast";
import useTranslation from "../hooks/Translation";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function Achievements(props: any) {
  const navigate = useNavigate();
  const translate = useTranslation();
  const [getAchievments] = useLazyGetAchivementsQuery();
  const fetchResponse = async (type: number) => {
    try {
      const response = await getAchievments({
        id: props.id,
        type: type,
      }).unwrap();
      if (response?.status === 200) {
        console.log(response, "responseeeeee");
        if (type === 1) navigate("/quiz/", { state: response });
        else navigate("/flashcard-quiz/", { state: response });

        props.handleClose();
      }
    } catch (error: any) {
      showError(error?.data?.message || error?.data?.message || "hi--3");
    }
  };

  return (
    <>
      <React.Fragment>
        <BootstrapDialog
          className="modl"
          onClose={props.handleClose}
          aria-labelledby="customized-dialog-title"
          open={props.open}
        >
          <IconButton
            aria-label="close"
            onClick={props.handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <p>{translate.testing.achievements.selectTypeOfAchievement}</p>
            <ul className="tst_lst">
              <li>
                <figure>
                  <img src="/static/images/bars.png" alt=""></img>
                </figure>
                <Button
                  className="btnn btn_primary"
                  onClick={() => fetchResponse(1)}
                >
                  {translate.testing.questionsWithOptions}
                </Button>
              </li>
              <li>
                <figure>
                  <img src="/static/images/box.png" alt=""></img>
                </figure>
                <Button
                  className="btnn btn_primary"
                  onClick={() => fetchResponse(2)}
                >
                  {translate.testing.flashcards}
                </Button>
              </li>
            </ul>
          </DialogContent>
          <DialogActions></DialogActions>
        </BootstrapDialog>
      </React.Fragment>
    </>
  );
}
export default Achievements;
