import React from "react";
import AiWork from "../../components/aiwork";
import { useNavigate } from "react-router-dom";
import useTranslation from "../../hooks/Translation";

function Congratulation() {
  const translate = useTranslation();
  const navigate = useNavigate();

  return (
    <div>
      <section className="bnr_sec congrs sign_in">
        <div className="conta_iner">
          <div className="bnr_mn">
            <AiWork />
            <div className="pymt_optn_rt py_mdl ">
              <figure onClick={() => navigate("/testing")}>
                <img src="/static/images/check2.png" alt=""></img>
              </figure>
              <div className="py_mdl_cnt hd_3 text_center">
                <h3>
                  {translate.subscriptions.congratulations || "Congratulations"}
                </h3>
                <p>
                  {translate.subscriptions.paymentCompleted ||
                    "Your payment is successfully completed"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Congratulation;
