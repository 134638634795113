import React from "react";
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import PaymentCards from "../../components/paymentCards";
import useTranslation from "../../hooks/Translation";

const SubscriptionPlanSection2 = () => {
  const [open, setOpen] = React.useState(false);
  const translate = useTranslation();
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 680,
    bgcolor: "#252F2E",
    borderRadius: "30px",
    p: 6,
  };

  return (
    <>
      <section>
        <div className="conta_iner u_spc">
          <div className="pymt_optn_flx">
            <PaymentCards />
            <div className="pymt_rt">
              <div className="jn_usr">
                <figure>
                  <img src="/static/images/user.png" alt=""></img>
                </figure>
                <p>
                  {translate.about.join} 35467 {translate.subscriptions.usersOn}
                  {translate.global.whizzoUpperCase}
                </p>
              </div>
              <div className="tbs_inr text_center hd_1 ">
                <figure>
                  <img src="/static/images/quartely.png" alt=""></img>
                </figure>
                <h3>{translate.subscriptions.quarterly}</h3>
                <h4>$90</h4>
                <ul className="tbs_inr_lst">
                  <li>{translate.subscriptions.quarterlyLine1}</li>
                  <li>{translate.subscriptions.quarterlyLine2}</li>
                  <li>{translate.subscriptions.quarterlyLine3}</li>
                  <li>{translate.subscriptions.quarterlyLine4}</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="ptm_dtl">
            <div className="ptm_cnt hd_2_1">
              <h2>{translate.subscriptions.paymentDetails}</h2>
              <ul className="ptm_flx hd_5">
                <li>
                  <h3>{translate.subscriptions.totalAmount}</h3>
                  <h4>$29.99</h4>
                </li>
                <li>
                  <h3>{translate.subscriptions.tax}</h3>
                  <h4>$10.00</h4>
                </li>
                <li>
                  <h3>{translate.subscriptions.grandTotal}</h3>
                  <h4 className="totl">$30.99</h4>
                </li>
              </ul>
            </div>
          </div>
          <div className="py_nw">
            <Button
              type="button"
              className="btn btn_primary btnn lrg"
              onClick={handleOpen}
            >
              {translate.subscriptions.payNow}
            </Button>
          </div>
        </div>
      </section>

      {/* modal */}
      <div>
        {/* <Button onClick={handleOpen}>Open modal</Button> */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="cong_mdl">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className="py_mdl"
            >
              <figure>
                <img src="/static/images/check2.png" alt=""></img>
              </figure>
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <div className="py_mdl_cnt hd_3 text_center">
                <h3>{translate.subscriptions.congratulations}</h3>
                <p>{translate.subscriptions.paymentCompleted}</p>
              </div>
            </Typography>
          </Box>
        </Modal>
      </div>
      {/* modal */}
    </>
  );
};
export default SubscriptionPlanSection2;
