import React from "react";
import Layout from "../../layout";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PresentationSlider4 from "./presentationSlider5";
import Download from "../../components/download";

function Presentationdown() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState<any>(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <main>
        <section className="prev_ed prev_down">
          <div className="prev_ed_lt">
            <div className="pd_lt">
              <h3>1/20</h3>
            </div>
            <div className="sde_br_mn">
              <div className="sde_hdr">
                <div
                  onClick={() => {
                    navigate("/presentation-preview");
                    console.log("kkkkkkkkkkkkk");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <ArrowBackIosNewIcon />
                </div>
              </div>
            </div>

            <div className="but_gp">
              <Button>Edit</Button>
              <Button>Save</Button>
            </div>
            <div>
              <PresentationSlider4 />
            </div>
            <div className="but_gp">
              <Button>Regenerate</Button>
            </div>
          </div>
          <div className="prev_ed_rt">
            <Button className="pos_abs" onClick={() => setOpen(true)}>
              Download
            </Button>
            <ul>
              <li>
                <figure>
                  <img src="/static/images/AI1.jpg" alt="image" />
                </figure>
              </li>
              <li>
                <figure>
                  <img src="/static/images/AI2.jpg" alt="image" />
                </figure>
              </li>
              <li>
                <figure>
                  <img src="/static/images/AI3.jpg" alt="image" />
                </figure>
              </li>
              <li>
                <figure>
                  <img src="/static/images/AI1.jpg" alt="image" />
                </figure>
              </li>
              <li>
                <figure>
                  <img src="/static/images/AI2.jpg" alt="image" />
                </figure>
              </li>
              <li>
                <figure>
                  <img src="/static/images/AI3.jpg" alt="image" />
                </figure>
              </li>
            </ul>
          </div>
          <Download open={open} setOpen={setOpen} handleClose={handleClose} />
        </section>
      </main>
    </>
  );
}

export default Presentationdown;
