/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from "react";
import Layout from "../../layout/index";
import "react-tabs/style/react-tabs.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Drag from "../../components/drag";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import Header from "../../layout/header/Header";
import { showError, showToast } from "../../constants/toast";
import {
  useDeleteFileMutation,
  useEditFileMutation,
  useGetFileSummarizationMutation,
} from "../../services/fileSummarisation";
import { CommonBody } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { FileSummarization } from "../../types/fileSummarization";
import Loader from "../../constants/Loader";
import "./file.css";
import Pagination from "../../components/pagination";
import { TestAI } from "../../constants/testAILoading";
import { MoreVert } from "@mui/icons-material";
import { FolderListComponent } from "../../components/folderList";
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import { DeleteModal } from "../../components/deleteModal";
import { RenameModal } from "../../components/renameModal";
import useTranslation from "../../hooks/Translation";

function FileSummarizationPage() {
  const translate = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState<any>(false);
  const user = useAuth();
  const [open1, setOpen1] = useState(false);
  const [renameValue, setRenameValue] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [sidebar, setSidebar] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [fileSummarization, setFileSummarization] = useState<
    FileSummarization[]
  >([]);

  const totalPages = Math.ceil(totalCount / 20);
  const pageRef = useRef(page);
  const hasMore = page <= totalPages;
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  const [getFileSummarization] = useGetFileSummarizationMutation();
  const [deleteData] = useDeleteFileMutation();
  const [editData] = useEditFileMutation();
  const [selectedId, setSelectedId] = useState(0);
  const toggleDelete = (id: number) => {
    setOpenDelete(true);
    setSelectedId(id);
  };
  const handleDelete = async (e: any) => {
    e.stopPropagation();
    try {
      const response = await deleteData({ id: selectedId }).unwrap();
      if (response.status == 200) {
        showToast(
          translate.fileSummarization.fileDeletedSuccessfully ||
            "File Deleted Successfully"
        );
        setOpenDelete(false);
      }
    } catch (error: any) {
      console.log(error);
      showError(
        error?.data?.message ||
          translate.errors.somethingWentWrong ||
          "Something went wrong"
      );
    } finally {
      await getFileSummarizationList(page);
    }
  };
  const toggleEdit = (id: number, text: any) => {
    setOpenEdit(true);
    setRenameValue(text);
    setSelectedId(id);
  };
  const handleEdit = async (e: any) => {
    e.stopPropagation();
    try {
      const response = await editData({
        id: selectedId,
        body: { file_name: renameValue },
      }).unwrap();
      if (response.status == 200) {
        showToast(
          translate.toastMessages.fileNameUpdatedSuccessfully ||
            "File name updated Successfully"
        );
        setOpenEdit(false);
        setRenameValue("");
      }
    } catch (error: any) {
      console.log(error);
      showError(
        error?.data?.message ||
          translate.errors.somethingWentWrong ||
          "Something went wrong"
      );
    } finally {
      await getFileSummarizationList(page);
    }
  };
  const getFileSummarizationList = async (page: number) => {
    if (page == 1) setIsLoading2(true);
    else setIsLoading(true);
    try {
      const body = {
        start: page,
        length: 20,
        search: "",
      };
      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await getFileSummarization(encryptedBody).unwrap();
      if (response?.status === 200) {
        setFileSummarization(response?.data?.response_object);
        setTotalCount(response?.data?.total_records);
      } else {
        setFileSummarization([]);
      }
    } catch (error: any) {
      showError(
        error?.data?.message || translate.errors.somethingWentWrong || ""
      );
    }
    if (page == 1) setIsLoading2(false);
    else setIsLoading(false);
  };

  useEffect(() => {
    getFileSummarizationList(page);
  }, [page]);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (
  //       window.innerHeight + document.documentElement.scrollTop >=
  //       document.documentElement.offsetHeight - 2
  //     ) {
  //       if (hasMore && !isLoading) {
  //         setPage((prevPage) => prevPage + 1);
  //       }
  //     }
  //   };

  //   window.addEventListener('scroll', handleScroll);
  //   return () => window.removeEventListener('scroll', handleScroll);
  // }, [hasMore, isLoading]);

  return (
    <div className="sdbr_rltv">
      <Loader isLoad={isLoading2} />
      <Layout.SidePanel />
      <Header setSidebar={setSidebar} />
      <div className="sde_br_mn">
        <div className="sde_mn_cnt">
          <button className="btnn btn_border" onClick={() => setOpen(true)}>
            <i className="fa-regular fa-plus"></i>
            {translate.fileSummarization.createNewFile || "Create a new File"}
          </button>
          <Tabs>
            <TabList>
              <Tab style={{ cursor: "context-menu" }}>
                {translate.fileTypeConversion.myPastFiles || "My Past Files"}
              </Tab>
            </TabList>

            <TabPanel>
              <ul className="tst_lst">
                {fileSummarization.length ? (
                  fileSummarization.map(
                    (data: FileSummarization, index: number) => (
                      <FolderListComponent
                        dataName={data?.file_name}
                        dataCreatedAt={data?.created_at}
                        index={index}
                        key={index}
                        nextPage={`/edit-file/${data?.id}`}
                        handleDelete={(e: any) => {
                          e.stopPropagation();
                          toggleDelete(data?.id);
                        }}
                        handleEdit={(e: any) => {
                          e.stopPropagation();
                          toggleEdit(data?.id, data?.file_name || "");
                        }}
                        isEdit={true}
                      />
                    )
                  )
                ) : (
                  <div
                    style={{
                      height: "85px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      marginTop: "15%",
                    }}
                  >
                    {" "}
                    <h3 style={{ fontSize: "xxx-large", color: "darkgrey" }}>
                      {translate.global.noRecordsFound || "No Records Found"}
                    </h3>
                  </div>
                )}
                {/* {isLoading &&  <Box  sx={{outline:"none",width:"100%",textAlign:"center",paddingInline:"20%"}}>
         
         <svg className="loaderSVG" viewBox="25 25 50 50">
 <circle className="loaderCircle" r="20" cy="50" cx="50"></circle>
</svg>


         </Box>
} */}
              </ul>
            </TabPanel>
          </Tabs>
        </div>
      </div>
      <Drag
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        nextPage={"/edit-file"}
        type={TestAI.FILE_SUMMARIZATION}
      />
      <Pagination
        module={fileSummarization}
        onPageChange={onPageChange}
        page={page}
        totalPages={totalPages}
      />
      <DeleteModal
        handleDelete={handleDelete}
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        textData={
          translate.fileSummarization.fileSummarizationLowerCase ||
          "file summarization"
        }
      />
      <RenameModal
        handleEdit={handleEdit}
        openEdit={openEdit}
        renameValue={renameValue}
        setOpenEdit={setOpenEdit}
        setRenameValue={setRenameValue}
        textData={
          translate.fileSummarization.fileSummarizationLowerCase ||
          "file summarization"
        }
      />
    </div>
  );
}

export default FileSummarizationPage;
