import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import { Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import "react-tabs/style/react-tabs.css";
import {
  usePostResendOtpMutation,
  usePostVerifyOtpMutation,
} from "../../services/auth";
import { useDispatch } from "react-redux";
import { setToStorage } from "../../constants/storage";
import { STORAGE_KEYS } from "../../constants/storageKeys";
import { showError, showToast } from "../../constants/toast";
import { setCredentials } from "../../reducers/authSlice";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import useTranslation from "../../hooks/Translation";

const Verify = () => {
  const [otp, setOtp] = useState("");
  const { state } = useLocation();
  console.log(state);
  const navigate = useNavigate();
  const [countDown, setCountDown] = useState<number>(30);
  const [verifyOtp] = usePostVerifyOtpMutation();
  const [resendOtp] = usePostResendOtpMutation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const translate = useTranslation();
  const handleSubmit = async () => {
    if (otp?.length < 4) {
      showError(translate.auth.enterValidOTP || "Please enter a valid otp");
      return;
    }
    setIsLoading(true);
    const body = {
      email: state?.email,
      otp: otp,
      // role: 2,
    };
    console.log(body, "body for verify otp");

    try {
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const res = await verifyOtp(encryptedBody).unwrap();
      if (res?.status === 200) {
        showToast(translate.auth.profileVerified || "Profile verified");
        if (state?.mode === "forgot") {
          navigate("/reset", { state: state?.email, replace: true });
        } else {
          dispatch(
            setCredentials({
              user: res?.data || null,
              token: res?.data?.token || null,
            })
          );
          setToStorage(STORAGE_KEYS.user, JSON.stringify(res?.data));
          setToStorage(STORAGE_KEYS.token, JSON.stringify(res?.data?.token));
          navigate("/profile");
        }
        console.log(res?.data?.token, "res?.token");
      } else {
        showError(translate.auth.invalidOtp || "Invalid Otp");
        setOtp("");
      }
      setIsLoading(false);
    } catch (res: any) {
      showError(res?.data?.message);
      setIsLoading(false);
      setOtp("");
      console.log(res, "error in otp");
    }
  };

  const handleResendOtp = async () => {
    setIsLoading(true);
    const body = {
      email: state?.email,
    };
    try {
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const res = await resendOtp(encryptedBody).unwrap();
      if (res?.status === 200) {
        showToast(
          translate.auth.otpResentSuccessfully || "Otp Resent successfully"
        );
      }
      setIsLoading(false);
    } catch (res: any) {
      showError(res?.message || translate.errors.somethingWentWrong);
      setIsLoading(false);
      setOtp("");
    }
  };

  useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => {
        setCountDown(countDown - 1);
      }, 1000);
    } else {
      setCountDown(0);
    }
  }, [countDown]);

  return (
    <div>
      <section className="bnr_sec c_white sign_in">
        <div className="conta_iner">
          <div className="bnr_mn">
            <div className="bnr_lt">
              <figure className="logo">
                <img
                  src="/static/images/logo.svg"
                  alt=""
                  onClick={() => navigate("/")}
                ></img>
              </figure>
              <h1>
                {translate.home.let}
                <span className="ai_bdr">
                  {translate.home.ai}
                  <span></span>
                </span>{" "}
                <span className="d_block"> {translate.home.doYourWork}</span>
              </h1>
              <p>
                {translate.home.homeMessageLine1}
                <span className="d_block">
                  {translate.home.homeMessageLine2}
                </span>{" "}
                {translate.home.homeMessageLine3}
              </p>
            </div>
            <div className="logn_rt hd_3 vrfy">
              <h3>{translate.auth.verifyAccount || "Verify Account"}</h3>
              <p>
                {" "}
                <small>
                  {" "}
                  <span className="d_block">
                    {" "}
                    {translate.auth.sentSMSCodeEmail ||
                      "We have sent you an SMS with a code to email"}{" "}
                  </span>
                  <span className="verify_email"> {state?.email} </span>
                </small>
              </p>
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderInput={(props) => <input {...props} />}
                inputType="tel"
              />
              <br />

              <div className="tmr">
                <p>
                  {" "}
                  {countDown === 0 ? (
                    ""
                  ) : (
                    <p style={{ cursor: "pointer" }}>
                      <span
                        style={{
                          margin: 0,
                          color: "#D63853",
                          paddingLeft: 3,
                          cursor: "pointer",
                        }}
                      >
                        00 : {countDown < 10 ? `0${countDown}` : countDown}
                      </span>
                    </p>
                  )}
                </p>
                <p>
                  {translate.auth.codeNotReceived || "Didn’t get the code?"}
                  <span
                    className="txt_clr d_block"
                    onClick={() => {
                      handleResendOtp();
                      setCountDown(30);
                    }}
                  >
                    {countDown === 0 ? translate.auth.resend || "Resend" : ""}
                  </span>
                </p>
              </div>
              <Button
                type="button"
                className="btn btn_primay btnn lrg"
                onClick={handleSubmit}
              >
                {translate.global.next || " Next"}
              </Button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Verify;
