import React, { useState } from "react";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import * as Yup from "yup";
import { useFormik } from "formik";
import { usePostContactSupportMutation } from "../../services/cms";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { showError, showToast } from "../../constants/toast";
import useTranslation from "../../hooks/Translation";

const ContactSection2 = () => {
  const [contactUs] = usePostContactSupportMutation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const translate = useTranslation();

  const formik = useFormik({
    initialValues: {
      email: "",
      fullname: "",
      query: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required(translate.validations.requiredEmail || "Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          translate.validations.enterValidEmail || "Please enter a valid email"
        )
        .max(
          80,
          translate.validations.emailLessThan80 ||
          "Email must be 80 characters or fewer"
        ),
      fullname: Yup.string()
        .required(translate.validations.requiredName || "Name is required")
        .max(
          50,
          translate.validations.nameLessThan50 ||
          "Name must be 50 characters or fewer"
        ),

      query: Yup.string()
        .max(
          200,
          translate.validations.messageLessThan200 ||
          "Message must be 200 characters or fewer"
        )
        .required(
          translate.validations.requiredMessage || "Message is required"
        ),
    }),

    onSubmit: async (values, { resetForm }) => {
      formik.setSubmitting(true);

      let body = {
        fullname: formik.values.fullname,
        query: formik.values.query,
        email: formik.values.email,
      };
      setIsLoading(true);
      console.log("body for contact us", body);
      try {
        let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response = await contactUs(encryptedBody).unwrap();
        setIsLoading(false);
        if (response?.status === 200) {
          showToast("Response Submitted");
        }
        resetForm();
      } catch (error: any) {
        setIsLoading(false);
        showError(error?.data?.message || error?.data?.message || "");
      }
    },
  });
  return (
    <>
      <section className="nxt_stp u_spc cunt_us ">
        <div className="conta_iner">
          <div className="nxt_stp cunt_flx">
            <div className="nxt_lt hd_2_1">
              <h2>{translate.contact.whatWillBeNextStep}</h2>
              <p className="mt_40">
                {translate.contact.whatWillBeNextStepHeading}
              </p>
              <ul className="nxt_lst mt_40">
                <li>
                  <span>1</span>
                  <div className="nxt_stp_cnt">
                    <h3>{translate.contact.step1}</h3>
                    <p>{translate.contact.step1Description}</p>
                  </div>
                </li>
                <li>
                  <span>2</span>
                  <div className="nxt_stp_cnt">
                    <h3>{translate.contact.step2}</h3>
                    <p>{translate.contact.step2Description}</p>
                  </div>
                </li>
                <li>
                  <span>3</span>
                  <div className="nxt_stp_cnt">
                    <h3>{translate.contact.step3}</h3>
                    <p>{translate.contact.step3Description}</p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="cunt_rt">
              <p>
                <small>{translate.contact.feelFreeToContactUs}</small>
              </p>
              <form className="frmn mt_40" onSubmit={formik.handleSubmit}>
                <div className="frmn_mn">
                  <TextField
                    className="control_group"
                    hiddenLabel
                    placeholder={translate.global.name || "Name"}
                    fullWidth
                    variant="outlined"
                    inputProps={{ maxLength: 50 }}
                    name="fullname"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.fullname}
                    helperText={
                      formik.touched.fullname && formik.errors.fullname
                    }
                    error={
                      formik.touched.fullname && Boolean(formik.errors.fullname)
                    }
                  />
                </div>
                <div className="frmn_mn">
                  <TextField
                    className="control_group"
                    hiddenLabel
                    placeholder={translate.global.email || "Email"}
                    fullWidth
                    variant="outlined"
                    inputProps={{ maxLength: 50 }}
                    name="email"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    helperText={formik.touched.email && formik.errors.email}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                  />
                </div>
                <div className="frmn_mn">
                  <TextField
                    className="control_group"
                    hiddenLabel
                    placeholder={translate.global.message || "Message"}
                    fullWidth
                    variant="outlined"
                    inputProps={{ maxLength: 30 }}
                    name="query"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.query}
                    helperText={formik.touched.query && formik.errors.query}
                    error={formik.touched.query && Boolean(formik.errors.query)}
                  />
                </div>
                <Button type="submit" className="smbt_btn">
                  {translate.global.submit}
                </Button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default ContactSection2;
