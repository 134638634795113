import Layout from "../../layout";
import Features from "../../features";

import "react-tabs/style/react-tabs.css";

const Contactpage = () => {
  return (
    <div className="">
      <Layout.Header />
      <Features.ContactSection1 />
      <Features.ContactSection2 />
      <Layout.Footer />
    </div>
  );
};
export default Contactpage;