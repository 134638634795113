import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import useTranslation from "../../hooks/Translation";

const SubscriptionSection3 = () => {
  const translate = useTranslation();
  return (
    <>
      <section className="fctur_sec text_white ub_spc">
        <div className="conta_iner">
          <div className="text_center hd_2_1 ">
            <h2>{translate.subscriptions.featureComparison}</h2>
          </div>
          <TableContainer component={Paper} className="mt_40 subscp_tble">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{translate.subscriptions.basis}</TableCell>
                  <TableCell align="center" className="text_center">
                    {translate.subscriptions.monthly}
                  </TableCell>
                  <TableCell align="center" className="text_center">
                    {translate.subscriptions.quarterly}

                    {/* &nbsp;(g) */}
                  </TableCell>
                  <TableCell align="center" className="text_center">
                    {translate.subscriptions.yearly}

                    {/* &nbsp;(g) */}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <tr>
                  <th>{translate.subscriptions.learnerExperience}</th>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>{translate.subscriptions.learnerExperience}</td>
                  <td>
                    <img src="/static/images/table_tick.png" alt=""></img>
                  </td>
                  <td>
                    <img src="/static/images/table_tick.png" alt=""></img>
                  </td>
                  <td>
                    <img src="/static/images/table_tick.png" alt=""></img>
                  </td>
                </tr>
                <tr>
                  <td>{translate.subscriptions.learnerExperience}</td>
                  <td>
                    <img src="/static/images/table_tick.png" alt=""></img>
                  </td>
                  <td>
                    <img src="/static/images/table_tick.png" alt=""></img>
                  </td>
                  <td>
                    <img src="/static/images/table_tick.png" alt=""></img>
                  </td>
                </tr>
                <tr>
                  <td>{translate.subscriptions.learnerExperience}</td>
                  <td>
                    <img src="/static/images/table_tick.png" alt=""></img>
                  </td>
                  <td>
                    <img src="/static/images/table_tick.png" alt=""></img>
                  </td>
                  <td>
                    <img src="/static/images/table_tick.png" alt=""></img>
                  </td>
                </tr>
                <tr>
                  <td>{translate.subscriptions.learnerExperience}</td>
                  <td></td>
                  <td>
                    <img src="/static/images/table_tick.png" alt=""></img>
                  </td>
                  <td>
                    <img src="/static/images/table_tick.png" alt=""></img>
                  </td>
                </tr>
                <tr>
                  <td>{translate.subscriptions.learnerExperience}</td>
                  <td></td>
                  <td>
                    <img src="/static/images/table_tick.png" alt=""></img>
                  </td>
                  <td>
                    <img src="/static/images/table_tick.png" alt=""></img>
                  </td>
                </tr>
                <tr>
                  <td>{translate.subscriptions.learnerExperience}</td>
                  <td></td>
                  <td>
                    <img src="/static/images/table_tick.png" alt=""></img>
                  </td>
                  <td>
                    <img src="/static/images/table_tick.png" alt=""></img>
                  </td>
                </tr>
                <tr>
                  <td>{translate.subscriptions.learnerExperience}</td>
                  <td></td>
                  <td></td>
                  <td>
                    <img src="/static/images/table_tick.png" alt=""></img>
                  </td>
                </tr>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </section>
    </>
  );
};
export default SubscriptionSection3;
