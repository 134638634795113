/* eslint-disable @typescript-eslint/no-unused-vars */
import Layout from "../../layout/index";
import "react-tabs/style/react-tabs.css";
import { Button } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import Header from "../../layout/header/Header";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import useAuth from "../../hooks/useAuth";
import { EditText2 } from "../../components/EditText2";
import {
  useDetailedResearchMutation,
  useLazyGetResearchByIdQuery,
  useLazyRegenerateResearchAnswerQuery,
  useSaveResearchMutation,
} from "../../services/research";
import { Research } from "../../types/research";
import { showError, showToast } from "../../constants/toast";
import Loader from "../../constants/Loader";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import EditText from "../../components/EditText";
import { getFromStorage } from "../../constants/storage";
import { STORAGE_KEYS } from "../../constants/storageKeys";
import { fileToBase64 } from "../../utils/fileToB64";
import TestAi from "../testAi";
import useTranslation from "../../hooks/Translation";
import { API_URL } from "../../constants/url";
let isBack = false;
function EditResearch2() {
  const navigate = useNavigate();
  const editor: any = useRef(null);

  const { id } = useParams();
  const [data, setData] = useState("");
  const { state } = useLocation();
  console.log("state :", state);
  const [getDataById] = useLazyGetResearchByIdQuery();
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const translate = useTranslation();
  console.log('translate :', translate.getLanguage());
  const [researchData, setResearchData] = useState<Research>({
    created_at: "",
    id: 0,
    media: "",
    result: "",
    topic: "",
    updated_at: "",
    file_name: "",
  });
  // const [temp,setTemp]=useState("");
  // console.log(temp);

  // useEffect(() => {
  //   //  console.log(("INSIDE HERE"));

  //   if (!data.includes("</ol>")) {
  //     //      console.log("DATA IS SET");

  //     setData(`<ol style="list-style-type: decimal; margin-left: 20px;"></ol>`);
  //   } else if (data.trim() == "") {
  //     setData(`<ol style="list-style-type: decimal; margin-left: 20px;"></ol>`);
  //   } else {
  //     setData(data);
  //   }
  // }, [data]);

  // function convertToHtmlList(input: string) {
  //   // Split input by line breaks and filter out empty lines
  //   const lines = input.replaceAll("  ", "").split('\n').filter(line => line.trim() !== '');

  //   // Initialize variables
  //   let html = '<ol style="list-style-type: decimal; margin-left: 20px;">\n';
  //   let sublistOpen = false;

  //   lines.forEach(line => {
  //     // Trim leading and trailing spaces
  //     line = line.trim();

  //     // Check if the line is a main list item or a sublist item
  //     if (/^\d+\.\s/.test(line)) {
  //       // Close previous sublist if it's open
  //       if (sublistOpen) {
  //         html += '        </ul></li>\n';
  //         sublistOpen = false;
  //       }
  //       // Add main list item
  //       html += `    <li>${line.slice(3).trim()}<ul>\n`; // added <ul> here
  //       sublistOpen = true;
  //     } else if (/^\w+\.\s/.test(line)) {
  //       // Add sublist item
  //       html += `            <li>${line.slice(3).trim()}</li>\n`;
  //     }
  //   });

  //   // Close any open sublist and main list
  //   if (sublistOpen) {
  //     html += '        </ul></li>\n';
  //   }
  //   html += '</ol>';

  //   return html;
  // }
  const bottomRef = React.useRef<HTMLDivElement>(null);

  function convertToHtmlList(input: string) {
    // Split input by line breaks and filter out empty lines
    const lines = input
      .replaceAll("  ", "")
      .split("\n")
      .filter((line) => line.trim() !== "");

    // Initialize variables
    let html = '<ol style="list-style-type: decimal; margin-left: 20px;">\n';
    let sublistOpen = false;

    lines.forEach((line) => {
      // Trim leading and trailing spaces
      line = line.trim();

      // Check if the line is a main list item or a sublist item
      if (/^\d+\.\s/.test(line)) {
        // Close previous sublist if it's open
        if (sublistOpen) {
          html += "        </ul></li>\n";
          sublistOpen = false;
        }
        // Add main list item
        html += `    <li>${line.slice(3).trim()}\n`;
      } else if (/^\w+\.\s/.test(line)) {
        // Open sublist if not already open
        if (!sublistOpen) {
          html +=
            '        <ul style="list-style-type: lower-roman; margin-left: 20px;">\n';
          sublistOpen = true;
        }
        // Add sublist item
        html += `            <li>${line.slice(3).trim()}</li>\n`;
      }
    });

    // Close any open sublist and main list
    if (sublistOpen) {
      html += "        </ul></li>\n";
    }
    html += "</ol>";

    return html;
  }

  // function convertHtmlListToString(html: string): string {
  //   const parser = new DOMParser();
  //   const doc = parser.parseFromString(html, "text/html");
  //   const ol :any= doc.querySelector("ol");
  //   const lis = ol.querySelectorAll("li");
  //   const romanNumbers: { [key: number]: string } = { 1: 'i', 2: 'ii', 3: 'iii', 4: 'iv', 5: 'v', 6: 'vi', 7: 'vii', 8: 'viii', 9: 'ix' };
  //   const result: string[] = [];

  //   lis.forEach((li: any, index: number) => {
  //     const text = li.textContent.trim();
  //     const ul = li.querySelector("ul");
  //     let subResult: string[] = [];

  //     if (ul) {
  //       const subLis = ul.querySelectorAll("li");
  //       subLis.forEach((subLi: any, subIndex: number) => {
  //         subResult.push(`${romanNumbers[subIndex + 1]}. ${subLi.textContent.trim()}`);
  //       });
  //     }

  //     result.push(`${index + 1}. ${text.replace(subResult.join("\n"), "")}`);
  //     if (subResult.length > 0) {
  //       result.push(subResult.join("\n"));
  //     }
  //   });

  //   return result.join("\n\n");
  // }
  const socketRef: any = useRef();
  const [ws, setWs] = useState<any>(null);
  const [disableButton, setDisableButton] = useState(false);
  const generateNewResearch = () => {
    // const ws=new WebSocket('https://d164-122-160-196-233.ngrok-free.app/file-summarization/');
    const ws = new WebSocket(
      state?.isReference
        ? `wss:${API_URL.replaceAll("https://", "")}research-topics-based-on-reference/`
        : `wss:${API_URL.replaceAll("https://", "")}research-topics/`
    );
    socketRef.current = ws;
    ws.onmessage = (event) => {
      console.log("event.data :", event.data);
      const data2 = JSON.parse(event.data);
      if (data2.signal == -1) {
        ws.close();
        setIsLoading(false);
        showError(translate.errors.pleaseTryAgain || "Please try again");
        navigate(`/reserch-edit2/${id}`, { replace: true });
        return;
      }
      if (data2.signal == 0) {
        ws.close();
        setDisableButton(false);
        setIsLoading(false);
        showToast(
          translate.toastMessages.generationSuccessfully ||
          "Generation successfully"
        );
        // return;
        if (!id)
          if (!isBack) {
            if (data2?.record_id)
              navigate(`/reserch-edit2/${data2?.record_id}`, {
                state: {
                  summaryData: data,
                },
                replace: true,
              });
          }
        //   // window.location.replace(`/edit-file/${data2?.record_id}`)
      }
      if (data2.signal == 401) {
        ws.close();
        setIsLoading(false);
        showError(translate.errors.pleaseLogin || "Please login");
        navigate("/");
        return;
      }
      if (data2.signal == 400) {
        ws.close();
        setIsLoading(false);
        showError(data2.message);
        navigate(`/research`, { replace: true });
        // return;
      }
      if (data2?.signal == 1) {
        setIsLoading(false);
        setDisableButton(true);
        setData(data2.data);
      }
      const teset = document.getElementById("mohit");
      if (teset) {
        setTimeout(() => {
          teset.scrollIntoView({ behavior: "smooth", block: "center" });
        }, 0);
      }

      console.log(event);
    };
    ws.onopen = async () => {
      // setIsBlink(true);
      const PAYLOAD: Record<string, any> = {
        token: JSON?.parse(getFromStorage(STORAGE_KEYS.token) as string),
        // html_text: state?.data ? state?.data : "",
        // record_id: id,
        language: translate.getLanguage() == "en" ? "english" : "arabic",
        reduce_citation: Boolean(state?.isCitation),
        description: state?.description ? state?.description : "",
        topic: state?.researchTopic,
        page: state?.page,
        tone: state?.researchTone,
        reference:
          state?.reference?.toLowerCase() == "none" ? "" : state?.reference,
        binary_data: state?.selectedFile
          ? await fileToBase64(state?.selectedFile)
          : "",
        file_name: state?.selectedFile ? state?.fileName : state?.researchTopic,
      };
      if (id) {
        PAYLOAD["record_id"] = id;
      }
      console.log("PAYLOAD :", PAYLOAD);
      ws?.send(JSON.stringify(PAYLOAD));
      console.log("Connected to websocket successfully");
    };
    ws.onerror = (error) => {
      // setIsBlink(false);
      ws.close();
      setIsLoading(false);
      console.log("There is some error", error);
    };
    ws.onclose = () => {
      // setIsBlink(false);
      console.log("Socket is closed");
      setIsLoading(false);
    };
    setWs(ws);
  };
  // useEffect(() => {
  //   if (state) {
  //     generateNewResearch();
  //   }
  // }, []);
  useEffect(() => {
    if (id) {
      if (state?.summaryData) {
        setData(state?.summaryData);
        // setContent(state?.summary);
        //@ts-ignore
        editor.current.value = state?.summaryData;
        // setContent(convertToHTML(state?.summaryData));
      } else {
        getDataByIdList();
      }
    } else {
      generateNewResearch();
    }
  }, [id]);
  useEffect(() => {
    isBack = false;
    return () => {
      isBack = true;
      console.log("Cleaning up: Closing WebSocket");
      const PAYLOAD = {
        signal: -1
      }

      if (socketRef?.current) {
        socketRef?.current.close(); // Close WebSocket on cleanup
      }

      ws?.send(JSON.stringify(PAYLOAD));
      if (ws) {
        ws.close();
      }
    };
  }, []);
  const romanNumerals: { [key: number]: string } = {
    1: "i",
    2: "ii",
    3: "iii",
    4: "iv",
    5: "v",
    6: "vi",
    7: "vii",
    8: "viii",
    9: "ix",
    10: "x",
    11: "xi",
    12: "xii",
    13: "xiii",
    14: "xiv",
    15: "xv",
    16: "xvi",
    17: "xvii",
    18: "xviii",
    19: "xix",
    20: "xx",
    21: "xxi",
    22: "xxii",
    23: "xxiii",
    24: "xxiv",
    25: "xxv",
    26: "xxvi",
    27: "xxvii",
    28: "xxviii",
    29: "xxix",
    30: "xxx",
    31: "xxxi",
    32: "xxxii",
    33: "xxxiii",
    34: "xxxiv",
    35: "xxxv",
    36: "xxxvi",
    37: "xxxvii",
    38: "xxxviii",
    39: "xxxix",
    40: "xl",
    41: "xli",
    42: "xlii",
    43: "xliii",
    44: "xliv",
    45: "xlv",
    46: "xlvi",
    47: "xlvii",
    48: "xlviii",
    49: "xlix",
    50: "l",
    51: "li",
    52: "lii",
    53: "liii",
    54: "liv",
    55: "lv",
    56: "lvi",
    57: "lvii",
    58: "lviii",
    59: "lix",
    60: "lx",
    61: "lxi",
    62: "lxii",
    63: "lxiii",
    64: "lxiv",
    65: "lxv",
    66: "lxvi",
    67: "lxvii",
    68: "lxviii",
    69: "lxix",
    70: "lxx",
    71: "lxxi",
    72: "lxxii",
    73: "lxxiii",
    74: "lxxiv",
    75: "lxxv",
    76: "lxxvi",
    77: "lxxvii",
    78: "lxxviii",
    79: "lxxix",
    80: "lxxx",
    81: "lxxxi",
    82: "lxxxii",
    83: "lxxxiii",
    84: "lxxxiv",
    85: "lxxxv",
    86: "lxxxvi",
    87: "lxxxvii",
    88: "lxxxviii",
    89: "lxxxix",
    90: "xc",
    91: "xci",
    92: "xcii",
    93: "xciii",
    94: "xciv",
    95: "xcv",
    96: "xcvi",
    97: "xcvii",
    98: "xcviii",
    99: "xcix",
    100: "c",
  };
  // function convertHtmlListToString(input: string): string {
  //     // Remove all HTML tags except for li
  //     let cleanedInput = input.replace(/<(?!\/?(li|ol|ul))[^>]+>/g, '');

  //     // Split into lines and remove empty ones
  //     let lines = cleanedInput.split('\n').filter(line => line.trim() !== '');

  //     let output = '';
  //     let mainItemCounter = 1;
  //     let subItemCounter = 1;
  //     let inSubList = false;

  //     for (let line of lines) {
  //       line = line.trim();

  //       if (line.startsWith('<ol')) {
  //         // Start of main list, do nothing
  //       } else if (line.startsWith('<ul')) {
  //         // Start of sublist
  //         inSubList = true;
  //         subItemCounter = 1;
  //       } else if (line.startsWith('</ul>')) {
  //         // End of sublist
  //         inSubList = false;
  //       } else if (line.startsWith('<li>')) {
  //         line = line.replace(/<\/?li>/g, '').trim();
  //         if (inSubList) {
  //           output += `${romanNumerals[subItemCounter]}. ${line}\n`;
  //           subItemCounter = subItemCounter + 1;
  //         } else {
  //           output += `${mainItemCounter}. ${line}\n`;
  //           mainItemCounter++;
  //         }
  //       }
  //     }

  //     return output.trim();
  //   }
  // function convertHtmlListToString(input: string): string {
  //   // Remove all HTML tags except for li
  //   let cleanedInput = input.replace(/<(?!\/?(li|ol|ul))[^>]+>/g, '');

  //   // Split into lines and remove empty ones
  //   let lines = cleanedInput.split('\n').filter(line => line.trim() !== '');
  ////   console.log("mooooo",lines);

  //   let output = '';
  //   let mainItemCounter = 1;
  //   let subItemCounter = 1;
  //   let inSubList = false;

  //   for (let line of lines) {
  //     line = line.trim();

  //     if (line.startsWith('<ol')) {
  //       // Start of main ordered list
  //       inSubList = false; // Ensure sublist flag is reset
  //       mainItemCounter = 1; // Reset main list counter
  //     } else if (line.trim().startsWith('<ul')) {
  //       // Start of unordered sublist
  //       inSubList = true;
  //       subItemCounter = 1; // Reset sublist counter
  //     } else if (line.trim().startsWith('</ol>')) {
  //       // End of main ordered list
  //       inSubList = false; // Ensure sublist flag is reset
  //     } else if (line.trim().startsWith('</ul>')) {
  //       // End of unordered sublist
  //       inSubList = false;
  //     } else if (line.includes('<li>')) {
  //       // Handle lines containing <li> tags
  //       let items = line.split('<li>').slice(1); // Slice to skip initial empty split

  //       for (let item of items) {
  //         let endIndex = item.indexOf('</li>');
  //         if (endIndex !== -1) {
  //           item = item.substring(0, endIndex).trim();

  //           if (inSubList) {
  //             output += `  ${convertToRoman(subItemCounter)}. ${item}\n`;
  //             subItemCounter++;
  //           } else {
  //             output += `${mainItemCounter}. ${item}\n`;
  //             mainItemCounter++;
  //           }
  //         }
  //       }
  //     }
  //   }

  //   return output.trim();
  // }

  function convertHtmlListToString(input: string) {
    // Remove all HTML tags except for li
    let cleanedInput = input.replace(/<(?!\/?(li|ol|ul))[^>]+>/g, "");
    //  // console.log("mooooo",cleanedInput);
    cleanedInput = cleanedInput.replaceAll("<li>", "==<li>");
    cleanedInput = cleanedInput.replaceAll("<ol>", "==<ol>");
    cleanedInput = cleanedInput.replaceAll("</ol>", "==</ol>");
    cleanedInput = cleanedInput.replaceAll("</ul></li>", "==</ul></li>");
    cleanedInput = cleanedInput.replaceAll("<ul ", "==<ul ");
    cleanedInput = cleanedInput.replaceAll("\n", "");
    let lines2 = cleanedInput;
    // Split into lines and remove empty ones
    let lines = lines2.split("==").filter((line) => line.trim() !== "");
    //  // console.log("mooooo",lines2)
    //  // console.log(lines2.split("==").filter(line => line.trim() !== ''))
    let output = "";
    let mainItemCounter = 1;
    let subItemCounter = 1;
    let inMainList = false;
    let inSubList = false;

    for (let line of lines) {
      if (line.startsWith("<ol")) {
        inMainList = true;
        inSubList = false;
      }
      if (line.trim().startsWith("<ul")) {
        inSubList = true;
        inMainList = false;
      }
      if (line.trim().startsWith("</ul></li>")) {
        inSubList = false;
        inMainList = true;
      }
      if (inMainList) {
        if (line.trim().startsWith("<li>")) {
          output +=
            mainItemCounter +
            ". " +
            line.trim().slice("<li>".length, line.length) +
            "\n";
          mainItemCounter += 1;
          subItemCounter = 1;
        }
      }

      if (inSubList) {
        if (line.trim().startsWith("<li>. ")) {
          const temp = line.slice(0, line.length - ",</li>".length + 1);
          output +=
            romanNumerals[subItemCounter] +
            ". " +
            temp.trim().slice("<li>. ".length, temp.length) +
            "\n";
          subItemCounter += 1;
        } else if (line.trim().startsWith("<li>")) {
          const temp = line.slice(0, line.length - ",</li>".length + 1);
          output +=
            romanNumerals[subItemCounter] +
            ". " +
            temp.trim().slice("<li>".length, temp.length) +
            "\n";
          subItemCounter += 1;
        }
      }
    }
    return output
      .trim()
      .replaceAll(";", "")
      .replaceAll("&nbsp", "")
      .replaceAll("<ul>", "")
      .replaceAll("</ul>", "")
      .replaceAll("<ol>", "")
      .replaceAll("</ol>", "")
      .replaceAll("<li>", "")
      .replaceAll("</li>", "")
      .replaceAll("</li", "");
  }

  const getDataByIdList = async () => {
    setIsLoading(true);
    try {
      const response = await getDataById({ id }).unwrap();
      if (response.status == 200) {
        setResearchData(response?.data);
        // setData(response?.data?.result.replaceAll("\n","<br>"));
        setData(response?.data?.result);
        // setData(convertToHtmlList(response?.data?.result));
        //@ts-ignore
        editor.current.value = response?.data?.result;
        // setTemp(convertToHtmlList(response?.data?.result));
        //      console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };
  // useEffect(() => {
  //   getDataByIdList();
  // }, []);

  const addOlData = () => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = data;

    const lastOl = tempDiv.querySelector("ol");
    if (lastOl) {
      const newLi = document.createElement("li");
      newLi.innerHTML = "&nbsp;";
      lastOl.appendChild(newLi);
      setData(tempDiv.innerHTML.replaceAll("<br>", ""));
    } else {
      setData("<ol><li>&nbsp;</li></ol>");
    }
    bottomRef.current?.scrollIntoView();
  };
  // function addOlData() {
  //   let str=data;

  //   let n = str.lastIndexOf("</ol>");
  ////   // console.log(n);
  ////   console.log("first ",n + "</ol>".length );
  ////   console.log("second ",str.length );

  // // if (n >= 0 && n + "</ol>".length >= str.length) {
  // if (n >= 0 &&  n<= str.length) {
  //   str = str.substring(0, n) + `<li></li></ol>`;
  //   // str = str.substring(0, n) + `<li>&nbsp<ul style='list-style-type: lower-roman; margin-left: 20px;'></ul></li></ol>`;

  //   setData(str)
  // }
  // }
  // function addLiData() {

  //   if(data==`<ol style="list-style-type: decimal; margin-left: 20px;">
  // </ol>`)
  //     {
  //       setData(`<ol style="list-style-type: decimal; margin-left: 20px;"><li><ul style="list-style-type: lower-roman; margin-left: 20px;"></ul></li></ol>`);
  //     }
  //     let str=data;
  //     let n = str.lastIndexOf("</ul>");
  ////     // console.log(n);

  //   if (n >= 0 && n <= str.length) {
  //   // if (n >= 0 && n + "</ul><li></ol>".length >= str.length) {
  //     str = str.substring(0, n) + `<li>&nbsp</li></ul></li></ol>
  //     `;
  //   setData(str)
  // }
  // }

  const addLiData = () => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = data;

    const lastOl = tempDiv.querySelector("ol");
    if (lastOl) {
      let lastLi = lastOl.lastElementChild;

      if (lastLi) {
        let lastUl = lastLi.querySelector("ul");

        if (lastUl) {
          // If there's a <ul> inside the last <li>, add a new <li> in it
          const newLi = document.createElement("li");
          newLi.innerHTML = "&nbsp;";
          lastUl.appendChild(newLi);
        } else {
          // If no <ul> inside the last <li>, create a new <ul> with an <li>
          const newUl = document.createElement("ul");
          newUl.style.listStyleType = "lower-roman";
          newUl.style.marginLeft = "20px";
          const newLi = document.createElement("li");
          newLi.innerHTML = "&nbsp;";
          newUl.appendChild(newLi);
          lastLi.appendChild(newUl);
        }
      } else {
        // If no <li> inside <ol>, add a new <li> with a <ul>
        const newLi = document.createElement("li");
        const newUl = document.createElement("ul");
        newUl.style.listStyleType = "lower-roman";
        newUl.style.marginLeft = "20px";
        const newSubLi = document.createElement("li");
        newSubLi.innerHTML = "&nbsp;";
        newUl.appendChild(newSubLi);
        newLi.appendChild(newUl);
        lastOl.appendChild(newLi);
      }

      setData(tempDiv.innerHTML.replaceAll("<br>", ""));
      bottomRef.current?.scrollIntoView();
    }
  };
  //console.log(data);
  const [regenerate] = useLazyRegenerateResearchAnswerQuery();
  const regenerateData = async () => {
    setIsLoading(true);
    try {
      const response = await regenerate({
        id: id as string,
        page: "1",
      }).unwrap();
      if (response?.status == 200) {
        // setData(response?.data?.result.replaceAll("\n","<br>"));
        // setData(convertToHtmlList(response?.data));
        setData(response?.data);
        showToast(
          translate.toastMessages.regenerationSuccessful ||
          "Regeneration successful"
        );
      }
    } catch (error: any) {
      showError(
        translate.errors.regenerationUnsuccessful ||
        "Regeneration unsuccessful ,Please try again"
      );
    }
    setIsLoading(false);
  };
  const [generateDetail] = useDetailedResearchMutation();
  const generateDetailedData = async () => {
    setIsLoading(true);
    try {
      const body = {
        html_text: data,
      };
      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await generateDetail({
        body: encryptedBody,
        id: id as string,
      }).unwrap();
      if (response?.status == 200) {
        showToast(
          translate.toastMessages.generationSuccessfully ||
          "Generation successful"
        );
        navigate("/reserch-edit/" + id, {
          state: {
            data: response?.data,
            id: id,
            prevPage: `/reserch-edit/${id}`,
          },
        });
      }
    } catch (error: any) {
      showError(
        translate.errors.GenerationUnsuccessful ||
        "Generation unsuccessful ,Please try again"
      );
    }
    setIsLoading(false);
  };

  const deleteLastLine = () => {
    if (
      data.trim().replaceAll(" ", "") ==
      `<ol style="list-style-type: decimal; margin-left: 20px;"><li><ul style="list-style-type: lower-alpha; margin-left: 20px;"></ul></li></ol>`
        .trim()
        .replaceAll(" ", "")
    ) {
      return;
    }
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = data;

    const removeLastNonEmptyChild = (element: any) => {
      if (!element.hasChildNodes()) {
        return false;
      }

      for (let i = element.childNodes.length - 1; i >= 0; i--) {
        const child = element.childNodes[i];

        if (
          child.nodeType === Node.ELEMENT_NODE &&
          child.innerHTML.trim() !== ""
        ) {
          if (removeLastNonEmptyChild(child)) {
            return true;
          } else {
            element.removeChild(child);
            return true;
          }
        } else if (
          child.nodeType === Node.TEXT_NODE &&
          child.nodeValue.trim() !== ""
        ) {
          element.removeChild(child);
          return true;
        } else if (
          child.nodeType === Node.ELEMENT_NODE &&
          child.tagName === "LI"
        ) {
          element.removeChild(child);
          return true;
        }
      }
      return false;
    };

    if (removeLastNonEmptyChild(tempDiv)) {
      setData(tempDiv.innerHTML);
    }
  };
  //console.log(isEdit);
  const [saveResearch] = useSaveResearchMutation();

  // function htmlToFormattedString(html:string) {
  //   let formattedString = '';
  //   let olCounter = 1; // Counter for <ol> items
  //   let ulCounter = 1; // Counter for <ul> items

  //   // Remove line breaks and trim the HTML string
  //   html = html.replace(/\r?\n|\r/g, '').trim();

  //   // Split the HTML by <li> tags to process each list item
  //   const liItems = html.split('<li>').slice(1); // slice(1) to skip initial empty split

  //   for (let i = 0; i < liItems.length; i++) {
  //     let listItem = liItems[i].replace('</li>', '').trim();

  //     // Determine the list type and format accordingly
  //     if (listItem.startsWith('<ol')) {
  //       formattedString += `${olCounter}. `;
  //       olCounter++;
  //     } else if (listItem.startsWith('<ul')) {
  //       formattedString += `  ${convertToRoman(ulCounter)}. `;
  //       ulCounter++;
  //     }

  //     // Clean up and append the list item content
  //     listItem = listItem.replace(/<[^>]+>/g, '').trim(); // Remove HTML tags
  //     formattedString += `${listItem}\n`;
  //   }

  //   return formattedString.trim();
  // }

  // // Function to convert number to Roman numeral (simplified)
  // function convertToRoman(num:number) {
  //   const roman = ['i', 'ii', 'iii', 'iv', 'v', 'vi', 'vii', 'viii', 'ix', 'x'];
  //   return roman[num - 1] || num;
  // }

  // const saveResearchData = async () => {
  //   try {
  //     const body = {
  //       text: convertHtmlListToString(data),
  //     };

  //     //console.log("MOOOOOOOOO1",data);
  //     //console.log("MOOOOOOOOO2",convertHtmlListToString(data));
  //     //// console.log("MOOOOOOOOO3",htmlToFormattedString(data));

  //     const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
  //     //// console.log("MOHTI",convertHtmlListToString(data));
  //     // return;

  //     const response = await saveResearch({
  //       id: id as string,
  //       body: encryptedBody,
  //     }).unwrap();
  //     if (response?.status == 200) {
  //       showToast("Research updated successfully");
  //     }
  //   } catch (error: any) {
  //     showError("Some error while updating research");
  //     //    console.log(error);
  //   }
  // };
  return data || id ? (
    <div className="sdbr_rltv">
      <Layout.SidePanel />
      <Header />
      <Loader isLoad={isLoading} />
      {/* <div className="sde_hdr">
        <div className="sde_hdr_lt">
          <button className="back_btn" onClick={() => navigate("/reserch-edit/"+id)}>
            <ArrowBackIosNewIcon />
          </button>
          <figure>
            <img src="/static/images/articles.png" alt=""></img>
          </figure>
          <div className="">
            <h3>Research</h3>
            <p> Lorem Ipsum dolor et amet</p>
          </div>
        </div>
        <div className="sde_hdr_rt">
          <figure>
            <img src="/static/images/notification.png" alt=""></img>
          </figure>
          <h3>Hi, Ahmed</h3>
          <figure>
            <img src="/static/images/ahmed.png" alt=""></img>
          </figure>
        </div>
      </div> */}

      <div className="sde_br_mn">
        <div className="sde_mn_cnt tpc_rsrch">
          {!isEdit && (
            <Button
              className="btn btn_primary h_46"
              onClick={() => {
                if (disableButton) return;
                setIsEdit(true);
              }}
            >
              {translate.global.edit || "Edit"}
            </Button>
          )}
          <div
            style={{
              color: "black",
              padding: "0px",
              fontWeight: "lighter",
              paddingBottom: "100px",
              background: "transparent",
            }}
            className="edt_artcl"
            dir="auto"
          >
            <>
              <EditText2
                content={data}
                setContent={setData}
                disable={isEdit ? false : true}
                editor={editor}
              // setData={setTemp}
              />
              {/* <EditText value={data} setValue={setData}/> */}
            </>
            <div ref={bottomRef}></div>
          </div>
        </div>
      </div>
      <div className="rght_btn edt_art_btn fixed_btm_right">
        {isEdit && (
          <ul className="edt_art_lst">
            <li
              className="active"
              style={{ cursor: "pointer" }}
              onClick={() => {
                // regenerateData()
                setIsLoading(true);
                generateNewResearch();
              }}
            >
              <figure>
                <img src="/static/images/genrte.png" alt="img"></img>
              </figure>
              <p>{translate.global.regenerate || "Regenerate"}</p>
            </li>

            <li
              // onClick={()=>setAddLi(addLi+1)}
              // onClick={()=>setData((prevData:any)=>{
              //   return addLiElement(prevData,"TEST")
              // })}
              style={{ cursor: "pointer" }}
              onClick={() => {
                {
                  if (disableButton) return;
                  addLiData();
                }
              }}
            >
              <figure>
                <img src="/static/images/singlet.png" alt="img"></img>
              </figure>
              <p>{translate.research.addPoint || "Add Point"}</p>
            </li>
            <li
              // onClick={()=>setAddOl(addOl+1)}
              // onClick={()=>setData((prevData:any)=>{
              //   return addOlElement(prevData)
              // })}
              style={{ cursor: "pointer" }}
              //   onClick={()=>setData((prevData:any)=>{
              //   return addOlElement(prevData,"TEST")
              // })}
              onClick={() => {
                if (disableButton) return;
                addOlData();
              }}
            >
              <figure>
                <img src="/static/images/text.png" alt="img"></img>
              </figure>
              <p> {translate.research.addSection || "Add Section"}</p>
            </li>
            {/* <li>
                <figure>
                  <img src="/static/images/text.png" alt="img"></img>
                </figure>
                <p> Pronoun</p>
              </li> */}
            <li
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (disableButton) return;
                deleteLastLine();
              }}
            >
              <figure>
                <img src="/static/images/delete.png" alt="img"></img>
              </figure>
              <p> {translate.global.delete || "Delete"}</p>
            </li>
          </ul>
        )}
        {disableButton ? null : (
          <div className="ldr_btn">
            {disableButton && <figure style={{ width: "48px" }}>
              <img
                src="/static/images/test-load.gif"
              />
            </figure>}
            <Button
              type="button"
              className="btnn btn_primary"
              style={{
                height: "20px",
                fontSize: "15px",
                // cursor: disableButton ? "default" : "pointer",
              }}
              disabled={disableButton}
              // onClick={() => navigate("/reserch-edit/"+id)}
              onClick={() => {
                if (disableButton) return;
                if (isEdit) {
                  // saveResearchData();
                  console.log(editor.current.value);
                  if (
                    editor.current.value == "<p><br></p>" ||
                    editor.current.value == "" ||
                    editor.current.value == "<p></p>"
                  ) {
                    showError("Please add some data");
                    return;
                  }
                  if (editor?.current) showToast("Research updated successfully");
                  setIsEdit(false);
                } else {
                  navigate("/reserch-edit/" + id, {
                    state: {
                      prevPage: `/reserch-edit/${id}`,
                      data: data,
                    },
                  });
                  // generateDetailedData()
                }
              }}
            >
              {isEdit
                ? translate.global.save || "Save"
                : translate.global.generate || "Generate"}
            </Button>
          </div>
        )}
      </div>
    </div>
  ) : (
    <TestAi />
  );
}

export default EditResearch2;
