import React from 'react'
import { useNavigate } from 'react-router-dom'
import useTranslation from '../hooks/Translation';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { MoreVert } from '@mui/icons-material';

const PresentationFolder = ({ data, index, handleDelete, handleEdit }: { data: any, index: number, handleDelete: any, handleEdit: any }) => {
    const navigate = useNavigate();
    const isEdit = true;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open0 = Boolean(anchorEl);
    const translate = useTranslation();
    const handleClick0 = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose0 = (event: any) => {
        event.stopPropagation();

        setAnchorEl(null);
    };
    return (
        <li
            style={{ cursor: "pointer" }}
            onClick={() =>
                navigate(`/presentation-edit/${data?.id}`)
            }
        >
            <Tooltip title={data?.file_name ? data?.file_name : translate.presentation.presentation || "Presentation"}>
                <h3>
                    {data?.file_name
                        ? data?.file_name.length > 10
                            ? data?.file_name?.slice(0, 10) + "..."
                            : data?.file_name
                        : translate.presentation.presentation}
                </h3>
            </Tooltip>
            <p className="mt_40">
                {data?.created_at?.slice(0, 10)}
            </p>
            <IconButton
                aria-label="more"
                id="demo-positioned-button"
                aria-controls={open0 ? "demo-positioned-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open0 ? "true" : undefined}
                onClick={handleClick0}
                style={{
                    position: "absolute",
                    top: "25px",
                    right: 0,
                }}
            >
                <MoreVert htmlColor="white" />
            </IconButton>
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open0}
                onClose={handleClose0}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                {isEdit ? (
                    <MenuItem
                        onClick={(e) => {
                            setAnchorEl(null);
                            handleEdit(e);
                        }}
                    >
                        {translate.global.edit}
                    </MenuItem>
                ) : null}
                <MenuItem
                    onClick={(e) => {
                        setAnchorEl(null);
                        handleDelete(e);
                    }}
                >
                    {translate.global.delete}
                </MenuItem>
            </Menu>
        </li >
    )
}

export default PresentationFolder