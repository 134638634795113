/* eslint-disable @typescript-eslint/no-unused-vars */
import Layout from "../../layout/index";
import "react-tabs/style/react-tabs.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import useAuth from "../../hooks/useAuth";
import React, { useEffect, useState } from "react";
import Header from "../../layout/header/Header";
import { showError } from "../../constants/toast";
import { isValidInputNative } from "../../utils/validations";
import {
  getFromStorage,
  removeFromStorage,
  setToStorage,
} from "../../constants/storage";
import { STORAGE_KEYS } from "../../constants/storageKeys";
import useTranslation from "../../hooks/Translation";

function Creatpresentations() {
  const navigate = useNavigate();
  const goProfile = () => {
    navigate("/setting");
  };
  const translate = useTranslation();
  const [presentationTopic, setPresentationTopic] = useState("");
  const [sidebar, setSidebar] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [displayHeaderSidebar, setDisplayHeaderSidebar] = useState(true);
  useEffect(() => {
    if (searchParams?.get("app") || getFromStorage(STORAGE_KEYS.application)) {
      setToStorage(STORAGE_KEYS?.application, "1");
      setDisplayHeaderSidebar(false);
    }
    // else
    // {
    //   removeFromStorage(STORAGE_KEYS?.application);
    // }
  }, []);
  return (
    <div className="sdbr_rltv crtprst">
      {displayHeaderSidebar && sidebar ? <Layout.SidePanel /> : null}
      {displayHeaderSidebar && <Header setSidebar={setSidebar} />}

      <div className="sde_br_mn">
        <div className="crt_psnt hd_6 presCreate">
          {!displayHeaderSidebar && (
            <div
              onClick={() => {
                //@ts-ignore
                window["ReactNativeWebView"] &&
                  //@ts-ignore
                  window["ReactNativeWebView"].postMessage("back");
                console.log("kkkkkkkkkkkkk");
              }}
              style={{ cursor: "pointer", marginLeft: 0 }}
            >
              <ArrowBackIosNewIcon />
            </div>
          )}
          <h3>
            {translate.presentation.enterPresentationPrompt ||
              "Enter Prompt for AI to generate presentation"}
          </h3>
          <TextField
            className="control_group"
            hiddenLabel
            placeholder={
              translate.presentation.enterPresentationTopic ||
              "Enter presentation topic"
            }
            fullWidth
            value={presentationTopic}
            onChange={(e) => {
              if (e.target.value == " ") return;
              if (!isValidInputNative(e.target.value)) {
                return;
              }
              if (e.target.value.length <= 500)
                setPresentationTopic(e.target.value);
              else
                showError(
                  translate.errors
                    .presentationTopicNameCantExceed500Characters ||
                    "Presentation topic name can't exceed 500 characters",
                  Boolean(searchParams.get("app")) ||
                    Boolean(getFromStorage(STORAGE_KEYS.application))
                );
            }}
            // onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setPresentationTopic(e.target.value)}
            variant="outlined"
            multiline
            rows={10}
          />
          <div className="rght_btn">
            <Button
              className="btnn"
              onClick={() => {
                if (presentationTopic?.trim() == "") {
                  showError(
                    translate.errors.enterTopicPresentation ||
                      "Please enter topic of presentation",
                    Boolean(searchParams.get("app")) ||
                      Boolean(getFromStorage(STORAGE_KEYS.application))
                  );
                  return;
                }
                navigate("/presentation-preview", {
                  replace: true,
                  state: { topic: presentationTopic },
                });
              }}
            >
              {translate.global.next || "Next"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Creatpresentations;
