import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import { Button, IconButton, InputAdornment } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import AiWork from "../../components/aiwork";
import { showError, showToast } from "../../constants/toast";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import * as Yup from "yup";
import { useFormik } from "formik";
import { unstable_HistoryRouter } from "react-router-dom";

import "react-tabs/style/react-tabs.css";
import { usePostResetPasswordMutation } from "../../services/auth";
import { setCredentials } from "../../reducers/authSlice";
import { useAppDispatch } from "../../hooks/store";
import Loader from "../../constants/Loader";
import useTranslation from "../../hooks/Translation";

const Reset = () => {
  const translate = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { state } = useLocation();
  const [resetPass] = usePostResetPasswordMutation();
  const [showPassword1, setShowPassword1] = useState(false);
  const handleClickShowPassword1 = () => {
    setShowPassword1(!showPassword1);
  };
  const handleMouseDownPassword1 = (event: any) => {
    event.preventDefault();
  };

  const [showPassword2, setShowPassword2] = useState(false);

  const handleClickShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };
  const handleMouseDownPassword2 = (event: any) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .label(translate.auth.newPassword || "New Password")
        .required(
          translate.validations.requirePassword || "Password is required"
        )
        .min(
          5,
          translate.validations.passwordAtleast5CharacterLong ||
            "Password must be at least 5 characters long"
        ),
      // .matches(
      //   /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.*[0-9])[^\s]{8,}$/,
      //   "Password must be at least 8 characters long and include 1 uppercase, 1 lowercase, and 1 special character."
      // ),

      confirmPassword: Yup.string()
        .oneOf(
          [Yup.ref("password")],
          translate.validations.passwordMustMatch || "Passwords must match"
        )
        .required(
          translate.validations.requiredConfirmPassword ||
            "Confirm password is required"
        ),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      setIsLoading(true);
      let body = {
        email: state,
        new_password: formik.values.password,
        // role: 2,
      };
      console.log(body, "body for reset");
      try {
        let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response = await resetPass(encryptedBody).unwrap();
        if (response?.status === 200) {
          showToast(
            translate.toastMessages.passwordResetSuccessfully ||
              "Password reset successfully"
          );
          navigate("/login");
          return;
        }
        setIsLoading(false);
      } catch (error: any) {
        setIsLoading(false);
        showError(
          error?.data?.message || translate.errors.somethingWentWrong || ""
        );
      }
    },
  });

  return (
    <div>
      <Loader isLoad={isLoading} />
      <section className="bnr_sec c_white sign_in">
        <div className="conta_iner">
          <div className="bnr_mn">
            <AiWork />
            <div className="logn_rt hd_3">
              <h3>{translate.auth.changePassword}</h3>
              <form className="frmn " onSubmit={formik.handleSubmit}>
                <div className="frmn_mn">
                  <label>{translate.auth.newPassword || "New Password"}</label>
                  <TextField
                    className="control_group"
                    hiddenLabel
                    fullWidth
                    placeholder={translate.auth.enterNewPassword}
                    variant="outlined"
                    name="password"
                    type={showPassword1 ? "text" : "password"}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment className="eye_btn" position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleClickShowPassword1()}
                            onMouseDown={(e) => handleMouseDownPassword1(e)}
                            edge="end"
                          >
                            {showPassword1 ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <i className="fa-regular fa-eye"></i>
                </div>
                <div className="frmn_mn">
                  <label>{translate.auth.confirmPassword}</label>
                  <TextField
                    className="control_group"
                    hiddenLabel
                    fullWidth
                    variant="outlined"
                    name="confirmPassword"
                    placeholder={translate.auth.reEnterPassword}
                    type={showPassword2 ? "text" : "password"}
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.confirmPassword &&
                      Boolean(formik.errors.confirmPassword)
                    }
                    helperText={
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment className="eye_btn" position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleClickShowPassword2()}
                            onMouseDown={(e) => handleMouseDownPassword2(e)}
                            edge="end"
                          >
                            {showPassword2 ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <i className="fa-regular fa-eye"></i>
                </div>
                <Button type="submit" className="btn btn_primay btnn lrg">
                  {translate.global.update || " Update"}
                </Button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Reset;
