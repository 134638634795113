import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-tabs/style/react-tabs.css";
import useTranslation from "../../hooks/Translation";
import { useLazyGetTestimonialsQuery } from "../../services/cms";
import { showError } from "../../constants/toast";
import { Testimonial } from "../../types/General";
import { Rating } from "@mui/material";

const AboutSection4 = () => {
  const translate = useTranslation();
  const [testimonial, setTestimonial] = useState<Testimonial[]>([]);
  const [getData] = useLazyGetTestimonialsQuery();
  const getDataById = async () => {
    try {
      const response = await getData({}).unwrap();
      if (response.status == 200) {
        setTestimonial(response?.data);
      }
    } catch (error: any) {
      showError(error?.data?.message || translate);
    }
  };
  useEffect(() => {
    getDataById();
  }, []);
  const settings = {
    loop: false,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 2000,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <>
      <section className="membr_sec hd_2_1">
        <div className="conta_iner">
          <h2 className="text_center">{translate.about.ourMembers}</h2>
          <Slider {...settings} className="mbr_sldr">
            {testimonial.map((data: Testimonial) => (
              <li>
                <figure
                  style={{
                    borderRadius: "50%",
                    width: "250px",
                    background: "grey",
                  }}
                >
                  <img
                    src={
                      data?.profile_picture?.media_url ||
                      "/static/images/mbr_sldr1.png"
                    }
                    style={{ borderRadius: "50%", objectFit: "cover" }}
                    alt=""
                  ></img>
                </figure>
                <div
                  className="mbr_cnt hd_6"
                  style={{ width: "350px", minHeight: "120px" }}
                >
                  <h3 style={{ display: "flex", alignItems: "center" }}>
                    {translate?.getLanguage() == "en"
                      ? `${data?.first_name} ${data?.last_name}`
                      : `${data?.first_name_ar} ${data?.last_name_ar}`}
                    <Rating
                      value={Number(data?.rating)}
                      readOnly={true}
                      max={5}
                    />
                  </h3>
                  <p style={{ fontSize: "12px" }}>
                    {translate?.getLanguage() == "en"
                      ? data?.message
                      : data?.message_ar}
                  </p>
                </div>
              </li>
            ))}
            {/* <li>
              <figure>
                <img src="/static/images/mbr_sldr2.png" alt=""></img>
              </figure>
              <div className="mbr_cnt hd_6">
                <h3>Abdel Nour</h3>
                <p>Lorem ipsum</p>
              </div>
            </li>
            <li>
              <figure>
                <img src="/static/images/mbr_sldr3.png" alt=""></img>
              </figure>
              <div className="mbr_cnt hd_6">
                <h3>Abdel Nour</h3>
                <p>Lorem ipsum</p>
              </div>
            </li>
            <li>
              <figure>
                <img src="/static/images/mbr_sldr4.png" alt=""></img>
              </figure>
              <div className="mbr_cnt hd_6">
                <h3>Abdel Nour</h3>
                <p>Lorem ipsum</p>
              </div>
            </li>
            <li>
              <figure>
                <img src="/static/images/mbr_sldr5.png" alt=""></img>
              </figure>
              <div className="mbr_cnt hd_6">
                <h3>Abdel Nour</h3>
                <p>Lorem ipsum</p>
              </div>
            </li> */}
          </Slider>
          {/* <ul className="mbr_sldr">
                    <li>
                        <figure>
                            <img src="/static/images/mbr_sldr1.png" alt=""></img>
                        </figure>
                        <div className="mbr_cnt hd_6">
                            <h3>Abdel Nour</h3>
                            <p>Lorem ipsum</p>
                        </div>
                    </li>
                </ul> */}
        </div>
      </section>
    </>
  );
};
export default AboutSection4;
