/* eslint-disable @typescript-eslint/no-unused-vars */
import Layout from "../../layout/index";
import "react-tabs/style/react-tabs.css";
import { Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import useAuth from "../../hooks/useAuth";
import React, { useState } from "react";
import Header from "../../layout/header/Header";
import useTranslation from "../../hooks/Translation";

function SelectTons() {
  const translate = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [selectedData, setSelectedData] = useState(1);
  const dataObject: any = translate?.getLanguage() == "en" ? {
    1: "Friendly",
    2: "Realistic",
    3: "Optimistic",
    4: "Professional",
    5: "Sarcastic",
    6: "Happy",
    7: "Enthusiastic",
    8: "Professional",
  } : {
    1: "ودود",
    2: "واقعي",
    3: "متفائل",
    4: "مهني",
    5: "ساخر",
    6: "سعيد",
    7: "متحمس",
    8: "مهني"
  }
    ;
  return (
    <div className="sdbr_rltv">
      <Layout.SidePanel />
      <Header />
      {/* <div className='sde_hdr'>
        <div className='sde_hdr_lt'>
          <button
                className="back_btn"
                onClick={() => navigate("/editartical")}
              >
                <ArrowBackIosNewIcon />
                </button>
                <figure>
                    <img src='/static/images/articles.png' alt=''></img>
                </figure>
                <div className=''>
                    <h3>Articales</h3>
                    <p> Lorem Ipsum dolor et amet</p>
                </div>
            </div>
            <div className='sde_hdr_rt'>
                <figure>
                <img src='/static/images/notification.png' alt=''></img>
                </figure>
                <h3>Hi, Ahmed</h3>
                <figure>
                <img src='/static/images/ahmed.png' alt=''></img>
                </figure>
            </div>
        </div> */}
      <div className="sde_br_mn slct">
        <h6>{translate.article.selectToneVoice || "Select Tone of voice"}</h6>
        <div className="sde_mn_cnt slct_mn tn_lst">
          <ul className="slct_tn text_center">
            <li
              className={selectedData == 1 ? "active" : ""}
              onClick={() => setSelectedData(1)}
              style={{ cursor: selectedData == 1 ? "context-menu" : "pointer" }}
            >
              {dataObject[1]}
            </li>
            <li
              className={selectedData == 2 ? "active" : ""}
              onClick={() => setSelectedData(2)}
              style={{ cursor: selectedData == 2 ? "context-menu" : "pointer" }}
            >
              {dataObject[2]}
            </li>
            <li
              className={selectedData == 3 ? "active" : ""}
              onClick={() => setSelectedData(3)}
              style={{ cursor: selectedData == 3 ? "context-menu" : "pointer" }}
            >
              {dataObject[3]}
            </li>
            <li
              className={selectedData == 4 ? "active" : ""}
              onClick={() => setSelectedData(4)}
              style={{ cursor: selectedData == 4 ? "context-menu" : "pointer" }}
            >
              {dataObject[4]}
            </li>
            <li
              className={selectedData == 5 ? "active" : ""}
              onClick={() => setSelectedData(5)}
              style={{ cursor: selectedData == 5 ? "context-menu" : "pointer" }}
            >
              {dataObject[5]}
            </li>
            <li
              className={selectedData == 6 ? "active" : ""}
              onClick={() => setSelectedData(6)}
              style={{ cursor: selectedData == 6 ? "context-menu" : "pointer" }}
            >
              {dataObject[6]}
            </li>
            <li
              className={selectedData == 7 ? "active" : ""}
              onClick={() => setSelectedData(7)}
              style={{ cursor: selectedData == 7 ? "context-menu" : "pointer" }}
            >
              {dataObject[7]}
            </li>
            <li
              className={selectedData == 8 ? "active" : ""}
              onClick={() => setSelectedData(8)}
              style={{ cursor: selectedData == 8 ? "context-menu" : "pointer" }}
            >
              {dataObject[8]}
            </li>
          </ul>
          <div className="rght_btn">
            <Button
              type="button"
              className="btnn btn_primary"
              onClick={() =>
                navigate("/pronouns", {
                  state: { ...state, selectedTone: dataObject[selectedData] },
                  replace: true,
                })
              }
            >
              {translate.global.next || "Next"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelectTons;
