import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import {
  useLazyDeleteAccountQuery,
  useLazyLogoutQuery,
} from "../services/auth";
import { showError, showToast } from "../constants/toast";
import { removeFromStorage } from "../constants/storage";
import { STORAGE_KEYS } from "../constants/storageKeys";
import { useAppDispatch } from "../hooks/store";
import { resetAuth } from "../reducers/authSlice";
import useTranslation from "../hooks/Translation";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

type props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleClose: () => void;
  text: string;
};

function Logout({ open, setOpen, handleClose, text }: props) {
  const translate = useTranslation();
  const navigate = useNavigate();
  const [logOutApi] = useLazyLogoutQuery();
  const dispatch = useAppDispatch();
  const [deleteAccount] = useLazyDeleteAccountQuery();

  const doLogout = async () => {
    try {
      const res = await logOutApi({}).unwrap();
      if (res?.status === 200) {
        navigate("/");
        removeFromStorage(STORAGE_KEYS.token);
        dispatch(resetAuth());
        showToast(
          translate.toastMessages.logoutSuccessful || "Logged out successfully"
        );
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };

  const doDeleteAccount = async () => {
    try {
      const res = await deleteAccount({}).unwrap();
      if (res?.status === 200) {
        showToast(
          translate.toastMessages.accountDeletedSuccessfully ||
          "Account deleted successfully"
        );
        navigate("/");
      }
    } catch (error: any) {
      showError(error?.data?.message || error?.data?.message || "Something went wrong");
    }
  };

  return (
    <>
      <React.Fragment>
        <BootstrapDialog
          className="modl log_mdl"
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          {text === translate.settings.logOut ? (
            <DialogContent dividers>
              <p>
                {translate.auth.logoutConfirmation ||
                  "Are You Sure You want to Log Out ?"}
              </p>
              <div className="lgut_btn">
                <button
                  className="btn btn_border"
                  onClick={() => setOpen(false)}
                >
                  {translate.global.no}
                </button>
                <Button className="btnn btn_primary" onClick={doLogout}>
                  {translate.global.yes}
                </Button>
              </div>
            </DialogContent>
          ) : (
            <DialogContent dividers>
              <p>
                {translate.auth.deleteAccountConfirmation ||
                  "Are You Sure You want to delete your account ?"}
              </p>
              <div className="lgut_btn">
                <button
                  className="btn btn_border"
                  onClick={() => setOpen(false)}
                >
                  {translate.global.no}
                </button>
                <Button className="btnn btn_primary" onClick={doDeleteAccount}>
                  {translate.global.yes}
                </Button>
              </div>
            </DialogContent>
          )}

          {/* <DialogActions>
          </DialogActions> */}
        </BootstrapDialog>
      </React.Fragment>
    </>
  );
}
export default Logout;
