import { getFromStorage } from "../constants/storage";
import { STORAGE_KEYS } from "../constants/storageKeys";
import { showError, showToast } from "../constants/toast";


export const UploadDocument = async (url: string, formData: FormData) => {
    const getToken = getFromStorage(STORAGE_KEYS.token) as string;
    const token = JSON.parse(getToken);

    let headers = {
        // 'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };

    try {
        const res = await fetch(url, {
            method: 'POST',
            headers,
            body: formData,
        });
        const response = await res.json();
        // if (response?.status !== 200) {
        //     showError(response?.message || 'Something went wrong');
        // }
        return response;
    } catch (error: any) {
        // showError(
        //     error?.data?.message || error?.data?.detail || 'Something went wrong',
        // );
        console.log(error, '>>>>>>>>>');
        return error;
    }
};