import {
  Dialog,
  DialogContent,
  Card,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import { showError } from "../constants/toast";
import useTranslation from "../hooks/Translation";

export const RenameModal = ({
  openEdit,
  setOpenEdit,
  renameValue,
  setRenameValue,
  handleEdit,
  textData,
}: {
  openEdit: any;
  setOpenEdit: any;
  renameValue: any;
  setRenameValue: any;
  handleEdit: any;
  textData: any;
}) => {
  const translate = useTranslation();
  return (
    <Dialog
      className="rename_dialog"
      open={openEdit}
      onClose={() => {
        setOpenEdit(false);
      }}
    >
      <DialogContent
        style={{
          padding: 0,
          width: "100%",
          maxWidth: "400px",
          minHeight: "200px",
        }}
      >
        <Card
          style={{
            color: "black",
            background: "#373737",
            width: "100%",
            maxWidth: "400px",
            minHeight: "200px",
            padding: "20px",
          }}
        >
          {" "}
          <Typography style={{ marginBottom: "5px", marginLeft: "10px" }}>
            {`${translate.global.rename} ${textData}`}
          </Typography>
          <div className="control_group">
            <span dir="auto">
              <TextField
                className="mohitRename"
                hiddenLabel
                dir="auto"
                value={renameValue}
                fullWidth
                placeholder="Enter name"
                inputProps={{
                  maxLength: 250,
                }}
                onChange={(e) => setRenameValue(e.target.value)}
                style={{ marginBottom: "20px" }}
              />
            </span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <Button
              onClick={(e) => {
                e.stopPropagation();

                if (!renameValue.trim().length) {
                  showError(`${textData} ${translate.global.nameIsRequired}`);
                  return;
                }
                handleEdit(e);
              }}
            >
              {translate.global.rename}
            </Button>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                setOpenEdit(false);
                setRenameValue("");
              }}
            >
              {translate.global.cancel}
            </Button>
          </div>
        </Card>
      </DialogContent>
    </Dialog>
  );
};
