
import { API_URL, END_POINTS } from "../constants/url";
import { UploadDocument } from "../utils/documentsUpload";
import emptySplitApi from "../utils/rtk";
type FileSummarizationResponse=any;
type CommonResponseType = {
    status: number;
    message: string;
};

type GetAllFileSummarizationsResponse = {
    // FileSummarization: FileSummarizationResponse[];
    response_object: FileSummarizationResponse[];
    total_records: number;
  };


// type encryptedBody = {
//     image: string;
//     email: string;
//     fullName: string;
//     countryCode: string;
//     phone: string;
//     address:string;
//   };
type encryptedBody = {
  hash: string;
  sek: string;
} | null

type getDataBody={
  length:number,
  start:number,
  search:string
}
type ChangeStatusBody={
  is_active:boolean
}
export const FileSummarizationApi=emptySplitApi.injectEndpoints({
endpoints:(builder)=>({

generateAiExplanation:builder.mutation<
CommonResponseType & {data:any},
   encryptedBody>({
      query:(body)=>{
          let url = END_POINTS.generateAIExplanation;
          return{
            url:url,
            method:'POST',
            body
          }
        }
}),
identifyVocab:builder.mutation<
CommonResponseType & {data:any},
   encryptedBody>({
      query:(body)=>{
          let url = END_POINTS.identifyVocab;
          return{
            url:url,
            method:'POST',
            body
          }
        }
}),
changeLanguage:builder.mutation<
CommonResponseType & {data:any},
   encryptedBody>({
      query:(body)=>{
          let url = END_POINTS.changeLanguage;
          return{
            url:url,
            method:'POST',
            body
          }
        }
}),

})
})

export const{
    useGenerateAiExplanationMutation,
    useChangeLanguageMutation,
    useIdentifyVocabMutation,
}=FileSummarizationApi;
// export const generateFileSummarization=async(file:any)=>{
// let url = API_URL+END_POINTS.generateFileSummarizationAnswer;
// const formData=new FormData();
// formData.append('file_link',file);
// formData.append('type',"1");
// try{
// const response= await UploadDocument(url,formData);
// return response;}
// catch(error)
// {
//   console.log(error)
// }
// }
