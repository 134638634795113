import React from "react";
import useTranslation from "../../hooks/Translation";

const AboutSection2 = () => {
  const translate = useTranslation();
  return (
    <>
      <section className="get_strt mt_40 text_center hd_2_1">
        <div className="conta_iner">
          <h2>{translate.about.howItStarted}</h2>
          <p className="mt_40">{translate.about.howItStartedLine1}</p>
          <p>{translate.about.howItStartedLine2}</p>
        </div>
      </section>
    </>
  );
};
export default AboutSection2;
