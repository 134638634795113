import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useMediaQuery } from "@mui/system";

const PresentationSlider5 = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [activeIndex, setActiveIndex] = useState(0);
  const [nav1, setNav1] = useState<any | null>(null);
  const [nav2, setNav2] = useState<any | null>(null);
  const sliderRef1 = useRef<any | null>(null);
  const sliderRef2 = useRef<any | null>(null);
  useEffect(() => {
    setNav1(sliderRef1.current);
    setNav2(sliderRef2.current);
  }, []);
  const handleAfterChange = (index: number) => {
    setActiveIndex(index);
    nav2.slickGoTo(index);
  };
  const data = [
    {
      id: 1,
      slideImage: "/static/images/ai.jpg",
    },
    {
      id: 2,
      slideImage: "/static/images/ai.jpg",
    },
    {
      id: 3,
      slideImage: "/static/images/ai.jpg",
    },
    {
      id: 4,
      slideImage: "/static/images/ai.jpg",
    },
  ];
  return (
    <section className="propSlider_sc">
      <div className="container">
        <div className="gap_p">
          <Slider
            className="slideGallery"
            infinite={data?.length > 1}
            slidesToShow={1}
            slidesToScroll={1}
            arrows={true}
            dots={false}
          >
            {data?.length
              ? [...data]?.map((item, index) => (
                  <div className="sliderflx" >
                    <p>
                      It is a long established fact that a reader will be
                      distracted by the readable content of a page when looking
                      at its layout. The point of using Lorem Ipsum is that it
                      has a more-or-less normal distribution of letters, as
                      opposed to using 'Content here,
                    </p>
                    <figure>
                      <img
                        src={item?.slideImage || "/static/images/ai.jpg"}
                        alt=""
                      />
                    </figure>
                  </div>
                ))
              : undefined}
          </Slider>
          {/* <Slider
            className="slideThumb"
            asNavFor={nav1}
            ref={(slider: any) => (sliderRef2.current = slider)}
            slidesToShow={4}
            swipeToSlide={data?.length > 1}
            centerPadding="0px"
            focusOnSelect={data?.length > 1}
            centerMode={true}
            infinite={data?.length > 1}
            slidesToScroll={1}
            arrows={false}
            // autoplay
          >
            {data?.length
              ? [...data, ...data]?.map((item, index) => (
                  <figure>
                    <img
                      src={item?.thumbImage || "/static/images/abut_sec.png"}
                      alt=""
                    />
                  </figure>
                ))
              : undefined}
          </Slider> */}
        </div>
      </div>
    </section>
  );
};
export default PresentationSlider5;
