import { useLocation, useNavigate, useParams } from "react-router-dom";
import Layout from "../../layout/index";
import { Box, Button, Modal, Typography } from "@mui/material";
import Download2 from "../../components/download2";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Header from "../../layout/header/Header";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import useAuth from "../../hooks/useAuth";
import HTMLReactParser from "html-react-parser";
import {
  useDownloadFileSummarizationMutation,
  useLazyGetFileSummarizationByIdQuery,
} from "../../services/fileSummarisation";
import { showError, showToast, showWarning } from "../../constants/toast";
import { CommonBody } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import AiWork from "../../components/aiwork";
import { Close, FamilyRestroomOutlined } from "@mui/icons-material";
import {
  useChangeLanguageMutation,
  useGenerateAiExplanationMutation,
  useIdentifyVocabMutation,
} from "../../services/utils";
import Loader from "../../constants/Loader";
import { handleDownload } from "../../utils/handleDownload";
import {
  useDownloadArticleMutation,
  useLazyGetArticleByIdQuery,
  useLazyRegenerateArticleAnswerQuery,
} from "../../services/article";
import JoditEditor from "jodit-react";
import { getFromStorage } from "../../constants/storage";
import { STORAGE_KEYS } from "../../constants/storageKeys";
import { fileToBase64 } from "../../utils/fileToB64";
import TestAi from "../testAi";
import useTranslation from "../../hooks/Translation";
import { API_URL } from "../../constants/url";

let isLanguageChanged = false;
let originalSummary = "";
let translatedSummary = "";
let highlightedSummary = "";
let htmlContent = "";
let summaryState = "";
let isBack = false;
const EditArticle = () => {
  const translate = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const user = useAuth();
  const { state } = useLocation();
  console.log(state);
  const [getDataById] = useLazyGetArticleByIdQuery();
  const [downloadPdfFile] = useDownloadFileSummarizationMutation();
  const [downloadPdfFile2] = useDownloadArticleMutation();
  const [aiExplain] = useGenerateAiExplanationMutation();
  const [changeLanguage] = useChangeLanguageMutation();
  const [identifyVocab] = useIdentifyVocabMutation();
  const [aiDetail, setAiDetail] = useState("");

  const [content, setContent] = useState("");
  const editorRef = useRef(null);

  const [open, setOpen] = React.useState<any>(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [openAI, setOpenAI] = React.useState<any>(false);

  const handleClickOpenAI = () => {
    setOpenAI(true);
  };
  const handleCloseAI = () => {
    setOpenAI(false);
  };
  const [sidebar, setSidebar] = useState(true);
  const [summary, setSummary] = useState("");
  // useEffect(() => {
  //   if (id) getDataByIdList();
  //   else setSummary(state?.data);
  // }, []);

  const getDataByIdList = async () => {
    setIsLoading(true);
    try {
      const response = await getDataById({ id }).unwrap();
      if (response.status == 200) {
        originalSummary = response?.data?.result;
        setSummary(response?.data?.result);
        // setContent(response?.data?.result);
        setContent(convertToHTML(response?.data?.result));
        htmlContent = convertToHTML(response?.data?.result);
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    // if (summary) setContent(summary);
    if (summary) setContent(convertToHTML(summary));
  }, [summary]);
  useEffect(() => {
    htmlContent = content;
  }, [content]);
  function convertToHTML(text: string) {
    const lines = text?.trim().split("\n");
    let html = "<ol>";
    let isSubList = false;
    let isParagraph = false;
    lines.forEach((line, index) => {
      const trimmedLine = line.trim();
      if (/^\d+\./.test(trimmedLine)) {
        if (isSubList) {
          html += "</ul>";
          isSubList = false;
        }
        if (isParagraph) {
          html += "</p>";
          isParagraph = false;
        }
        html += `<li><p>${trimmedLine.slice(3).trim()}</p>`;
      } else if (/^[a-z]+\./.test(trimmedLine)) {
        if (!isSubList) {
          html += "<ul>";
          isSubList = true;
        }
        if (isParagraph) {
          html += "</p>";
          isParagraph = false;
        }
        html += `<li><p>${trimmedLine.slice(2).trim()}</p>`;
      } else if (trimmedLine === "") {
        if (isParagraph) {
          html += "</p>";
          isParagraph = false;
        }
      } else {
        if (!isParagraph) {
          html += "<p>";
          isParagraph = true;
        }
        html += `${trimmedLine} `;
      }
      // Handle the end of the last item
      if (index === lines.length - 1) {
        if (isParagraph) {
          html += "</p>";
          isParagraph = false;
        }
        if (isSubList) {
          html += "</ul>";
          isSubList = false;
        }
      }
    });
    html += "</li></ol>";
    return html;
  }
  const generateHTMLcontent = (text: string) => {
    return text.replaceAll("\n", "<br>");
  };
  const [regenerate] = useLazyRegenerateArticleAnswerQuery();
  const regenerateArticle = async () => {
    setIsLoading(true);
    try {
      const response = await regenerate({
        id: id as string,
        page: "1",
      }).unwrap();
      if (response?.status == 200) {
        // setData(response?.data?.result.replaceAll("\n","<br>"));
        originalSummary = response?.data;
        setSummary(response?.data);
        // htmlContent=convertToHTML(response?.data)
        showToast(
          translate.toastMessages.regenerationSuccessful ||
            "Regeneration successful"
        );
      }
    } catch (error: any) {
      showError(
        translate.errors.regenerationUnsuccessful ||
          "Regeneration unsuccessful ,Please try again"
      );
    }
    setIsLoading(false);
  };
  const getAiDetail = async () => {
    if (!document.getSelection()?.toString()) {
      showError(
        translate.noteTaking.selectSomethingToGetDetail ||
          "Please select something to get detail"
      );
      return;
    }
    setIsLoading(true);
    try {
      const body = {
        text: document.getSelection()?.toString() || content || summary,
      };
      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await aiExplain(encryptedBody).unwrap();
      if (response.status == 200) {
        setAiDetail(response?.data);
        setOpenAI(true);
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };
  const getChangeLanguage = async (isPdf: boolean) => {
    setIsLoading(true);
    try {
      const body = {
        text:
          htmlContent ||
          summary.replaceAll(`style='background-color:rgb(185 154 36)'`, ""),
      };
      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await changeLanguage(encryptedBody).unwrap();
      if (response.status == 200) {
        if (isPdf) downloadPdfData(response?.data, 9);
        else setSummary(response?.data);
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };
  const getVocab = async (isPdf: boolean) => {
    setIsLoading(true);
    try {
      const body = {
        text_data: summary || content,
      };
      console.log("body :", body);
      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await identifyVocab(encryptedBody).unwrap();
      if (response.status == 200) {
        let temp = summary;
        response?.data?.map((element: any) => {
          temp = temp?.replaceAll(
            element + " ",
            `<span style='background-color:rgb(185 154 36)'>${element + " "}</span>`
          );
        });
        console.log(temp);
        if (isPdf) downloadPdfData(convertToHTML(temp), 3);
        else setSummary(temp);
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };
  const downloadPdfData = async (data: any, type: number) => {
    setIsLoading(true);
    try {
      let html = "";
      let tempHTML = htmlContent;
      if (type == 9) {
        html =
          '<html lang="ar"><meta charset="UTF-8"><style> body{font-family:"Arial", sans-serif;}</style></head><body style="background-color: white;" dir="auto">';
        html +=
          '<pre style="white-space: pre-wrap; font-family: Arial, sans-serif; background-color: #fff; border: 0px">';
        html += convertToHTML(data).replaceAll(
          "<span style='background-color:rgb(185 154 36)'>",
          "<span>"
        );
        // html+=generateHTMLcontent(data);
        html += "</pre>";
        html += "</body></html>";
      } else {
        html =
          '<html lang="ar"><meta charset="UTF-8"><body style="background-color: white;"  dir="auto">';
        html +=
          '<pre style="white-space: pre-wrap; font-family: Arial, sans-serif; background-color: #fff; border: 0px">';
        html += htmlContent.replaceAll(
          "<span style='background-color:rgb(185 154 36)'>",
          "<span>"
        );
        html += "</pre>";
        html += "</body></html>";
      }

      const body = {
        html_text: html,
        // type:type==9?2:type,
        // new: true,
      };
      console.log(body, "body for download ");

      if (html) {
        // setLoading(true);
        let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response = await downloadPdfFile2({
          body: encryptedBody,
          id: id,
        }).unwrap();
        // setLoading(false);
        if (response?.status === 200) {
          setOpen(false);
          handleDownload(
            response?.data,
            response?.data?.split("/")?.[response?.data?.split("/")?.length - 1]
          );

          // window.open(response?.data || '');
        }
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
    }
    setIsLoading(false);
  };
  const generateHTML = (parts: string[], wordsToHighlight: string[]) => {
    let html =
      '<html lang="ar"><meta charset="UTF-8"><body style="background-color: white;" dir="auto">';
    // let html=''
    html +=
      '<pre style="white-space: pre-wrap; font-family: Arial, sans-serif; background-color: #fff; border: 0px">';
    html += parts
      ?.map((part, index) => {
        const isHighlighted = wordsToHighlight
          .map((word) => word?.toLowerCase())
          .includes(part?.toLowerCase());

        const style = isHighlighted
          ? "font-size: 14px; color: black; background-color: yellow; letter-spacing: 1px;"
          : "font-size: 14px; color: black; letter-spacing: 1px;";

        return part?.length
          ? `<span style="${style}" key="${index}">${part}</span>`
          : "";
      })
      .join("");

    html += "</pre>";
    html += "</body></html>";
    return html;
  };
  const [placeholder, setPlaceholder] = useState("MOHIT");
  const config2 = useMemo(
    () => ({
      // autofocus: true,
      buttons: [
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "eraser",
        // "ul",
        // "ol",
        "font",
        "fontsize",
        "brush",
      ],
      readonly: false,
      // saveSelectionOnBlur: true,
      placeholder: "",
      // autofocus: true,
      toolbarAdaptive: false,
      statusbar: false,
      askBeforePasteHTML: false,

      tabIndex: -1,
      zIndex: 0,
      addNewLine: false,
      // triggerChangeEvent: true,
      theme: "snow",
      // toolbar: false,
      textIcons: false,
      // style: {
      //   fontFamily: 'Arial, sans-serif',
      //   fontSize: '14px',
      //   color: 'black'
      // },
      // editorClassName: "jodit-black",
      className: "jodit-black",
    }),
    [placeholder]
  );
  const downloadPdf = async (type: number) => {
    if (type == 1) {
      getChangeLanguage(true);
    } else if (type == 2) {
      downloadPdfData(originalSummary, 2);
    } else if (type == 3) {
      setIsLoading(true);
      let temp = "";
      try {
        const body = {
          text_data: summary,
        };
        const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response = await identifyVocab(encryptedBody).unwrap();
        if (response.status == 200) {
          const regex = new RegExp(`(${response?.data?.join("|")})`, "gi");
          temp = generateHTML(htmlContent?.split(regex), response?.data);
        }
      } catch (error) {
        console.log(error);
      }

      try {
        const body = {
          html_text: temp,
          type: 1,
          new: true,
        };
        console.log("MOHIT", temp);

        console.log(body, "body for download ");

        if (temp) {
          // setLoading(true);
          let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
          const response = await downloadPdfFile2({
            body: encryptedBody,
            id: id,
          }).unwrap();
          // setLoading(false);
          if (response?.status === 200) {
            setOpen(false);
            // window.open(response?.data || '');
            handleDownload(
              response?.data,
              response?.data?.split("/")?.[
                response?.data?.split("/")?.length - 1
              ]
            );
          }
        }
      } catch (error) {
        // setLoading(false);
        console.log(error);
      }
      setIsLoading(false);

      // getVocab(true);
    }
  };
  const onBlurEditor = (event: any) => {
    // event.preventDefault();
    // event.stopPropagation();
    //@ts-ignore
    editorRef.current.value = event;
    setContent(event);
  };

  const onEditorChange = (editorNewValue: any) => {
    console.log("onEditorChange", editorNewValue);
    //@ts-ignore
    editorRef.current.value = editorNewValue;
    setContent(editorNewValue);
  };

  // <JoditEditor
  //   ref={editor}
  //   name="content"
  //   value={editorContent}
  //   config={config}
  //   tabIndex={1} // tabIndex of textarea
  //   onBlur={onBlurEditor}
  //   onChange={onEditorChange}
  // />;
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: 587,
    bgcolor: "#353e3d",
    border: "1px solid #FFFFFF8A",
    boxShadow: 24,
    borderRadius: "10px",
    p: 3,
    maxHeight: "95vh",
    overflow: "auto",
  };
  const [messages, setMessages] = useState<any | String>("");
  const [ws, setWs] = useState<any>(null);
  const [isBlink, setIsBlink] = useState(false);
  const socketRef: any = useRef();
  const createNewArticle = () => {
    // const ws=new WebSocket('https://d164-122-160-196-233.ngrok-free.app/file-summarization/');
    const ws = new WebSocket(
      `wss:${API_URL.replaceAll("https://", "")}generate-article/`
    );
    socketRef.current = ws;
    ws.onmessage = (event) => {
      setIsLoading(false);

      console.log("event.data :", event.data);
      const data = JSON.parse(event.data);
      if (data.signal == -1) {
        ws.close();
        showError(translate.errors.pleaseTryAgain || "Please try again");
        navigate("/artical", { replace: true });
      }
      if (data.signal == 0) {
        ws.close();
        showToast(
          translate.toastMessages.generationSuccessfully ||
            "Generation successfully"
        );
        if (!isBack) {
          if (data?.record_id)
            navigate(`/edit-article/${data?.record_id}`, {
              state: {
                summaryData: summaryState,
              },
              replace: true,
            });
        }
        // window.location.replace(`/edit-file/${data?.record_id}`)
      }
      if (data.signal == 401 || data?.status == 401) {
        ws.close();
        if (id) setIsLoading(false);

        showError(translate.errors.pleaseLogin || "Please login");
        navigate("/");
        // return;
      }
      if (data.signal == 400 || data?.status == 400) {
        ws.close();
        if (id) setIsLoading(false);
        showError(data?.data || data?.message);
        navigate("/artical", { replace: true });
        // return;
      } else {
        setMessages(data.data);
        setSummary(data.data);
        summaryState = data.data;
      }
      const teset = document.getElementById("mohit");
      if (teset) {
        setTimeout(() => {
          teset.scrollIntoView({ behavior: "smooth", block: "center" });
        }, 0);
      }

      console.log(event);
    };
    ws.onopen = async () => {
      setIsBlink(true);
      if (id) setIsLoading(true);
      window.scrollTo(0, 0);
      const PAYLOAD: Record<string, string> = {
        token: JSON?.parse(getFromStorage(STORAGE_KEYS.token) as string),
        topic: state?.articleTopic,
        words: state?.wordLimit,
        language: state?.selectedLanguage,
        tone: state?.selectedTone,
        pronouns: state?.pronoun,
        region: state?.region,
        file_name: state?.articleTopic,
        // picture: state?.isCitation,
      };
      if (id) PAYLOAD["record_id"] = id;
      console.log("PAYLOAD :", PAYLOAD);
      ws?.send(JSON.stringify(PAYLOAD));
      console.log("Connected to websocket successfully");
    };
    ws.onerror = (error) => {
      setIsBlink(false);
      setIsLoading(false);
      ws.close();
      console.log("There is some error", error);
    };
    ws.onclose = () => {
      setIsBlink(false);
      setIsLoading(false);

      // setSummary(summary + " ");
      console.log("Socket is closed");
    };
    setWs(ws);
  };
  useEffect(() => {
    if (id) {
      if (state?.summaryData) {
        setSummary(state?.summaryData);
        // setContent(state?.summary);
        //@ts-ignore
        editorRef.current.value = convertToHTML(state?.summaryData);
        setContent(convertToHTML(state?.summaryData));
      } else {
        getDataByIdList();
      }
    } else {
      createNewArticle();
    }
  }, [id]);
  // useEffect(() => {
  //   //@ts-ignore
  //   if (content) editorRef.current.value = content;
  // }, [content]);
  useEffect(() => {
    isBack = false;
    return () => {
      isBack = true;
      originalSummary = "";
      const PAYLOAD = {
        signal: -1,
      };

      if (socketRef?.current) {
        console.log("Cleaning up: Closing WebSocket");
        socketRef?.current.close(); // Close WebSocket on cleanup
      }

      ws?.send(JSON.stringify(PAYLOAD));
      if (ws) {
        ws.close();
      }
    };
  }, []);
  return (
    <>
      <Loader isLoad={isLoading} />
      {summary || id ? (
        <div className="sdbr_rltv">
          <Layout.SidePanel />
          <Header />

          <div className="sde_br_mn sde_br_mn_fixed">
            {/* <div className='sde_hdr'>
          <div className='sde_hdr_lt'>
          <button
                className="back_btn"
                onClick={() => navigate("/file-summarization")}
              >
                <ArrowBackIosNewIcon />
                </button>
              <figure>
                <img src='/static/images/summarization.png' alt=''></img>
              </figure>
              <div className=''>
                <h3>File Summarization</h3>
                <p> Lorem Ipsum dolor et amet</p>
              </div>
          </div>
          <div className='sde_hdr_rt'>
            <figure>
              <img src='/static/images/notification.png' alt=''></img>
            </figure>
            <h3>Hi, Ahmed</h3>
            <figure onClick={goProfile}>
              <img src='/static/images/ahmed.png' alt=''></img>
            </figure>
          </div>
      </div> */}
            <div className="sde_mn_cnt edt_fle">
              {/* <Button className='btnn btn-primary h_46'>
        Upload a new File
        </Button> */}
              <div className="edt_nte list_enable">
                {/* {summary?HTMLReactParser(generateHTMLcontent(summary)) :
    <p>Loading...
    </p>
      } */}
                <div className="edt_artcl" style={{ padding: 0 }} dir="auto">
                  <JoditEditor
                    ref={editorRef}
                    value={content}
                    onBlur={onBlurEditor}
                    // onChange={onEditorChange}
                    config={config2}
                  />
                </div>
                <div id="mohit" style={{ paddingTop: "50px" }}></div>
                <div
                  className="prfl_btn fixed_bottom"
                  // style={{
                  //   position: "relative",
                  //   paddingBottom: "30px",
                  //   paddingTop: "20px",
                  // }}
                >
                  <div className="prfl_btn_lft">
                    {/* <Button className="btnn h_46" onClick={()=>{
                if(!isLanguageChanged)
                  {
                    getChangeLanguage(false);
                    isLanguageChanged=true;
                  }
                  else
                  {
                    setSummary(originalSummary);
                    isLanguageChanged=false;

                  }
              }}>Change Language</Button> */}
                    <Button
                      disabled={!id}
                      className="btnn h_46"
                      onClick={() => {
                        // regenerateArticle();
                        createNewArticle();
                      }}
                    >
                      {translate.global.regenerate || "Regenerate"}
                    </Button>
                    <Button
                      disabled={!id}
                      className="btnn h_46"
                      onClick={() => {
                        if (!isLanguageChanged) getVocab(false);
                        else
                          showWarning(
                            "Please change language to identify important vocabulary"
                          );
                      }}
                    >
                      {translate.global.identifyingImportantVocabulary ||
                        "Identifying Important Vocabulary"}
                    </Button>

                    <Button
                      className="btnn h_46"
                      disabled={!id}
                      onClick={() => {
                        getAiDetail();
                      }}
                    >
                      {translate.global.aIExplanation || "AI Explanation"}
                    </Button>
                    {!id && (
                      <figure style={{ width: "48px" }}>
                        <img src="/static/images/test-load.gif" />
                      </figure>
                    )}
                  </div>
                  <Button
                    disabled={!id}
                    className="btnn h_46"
                    onClick={() => setOpen(true)}
                  >
                    {translate.global.download || "Download"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <TestAi />
      )}
      <Download2
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        downloadPdf={downloadPdf}
        label={translate.article.Article || "Article"}
      />
      <Modal
        open={openAI}
        onClose={handleCloseAI}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modall"
      >
        <Box
          sx={style}
          component={"div"}
          className="text_center how_to_modal"
          dir="auto"
        >
          <p className="close_icon" onClick={handleCloseAI}>
            <Close />
          </p>
          <figure>
            <img src="/static/images/bulb.svg" alt="icon"></img>
          </figure>
          <Typography variant="h6" component="h2">
            {aiDetail
              ? aiDetail?.slice(0, aiDetail?.indexOf("\n"))
              : "How to learn trignomentery table easily?"}
          </Typography>
          <Typography sx={{ mt: 2, textAlign: "start" }}>
            {aiDetail
              ? HTMLReactParser(
                  generateHTMLcontent(
                    aiDetail.slice(aiDetail?.indexOf("\n"), aiDetail?.length)
                  )
                )
              : `It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout. The point
            of using Lorem Ipsum is that it has a more-or-less normal
            distribution of letters, as opposed to using 'Content here, content
            here', making it look like readable English. Many desktop publishing
            packages and web page editors now use Lorem Ipsum as their default
            model text, and a search for 'lorem ipsum' will uncover many web
            sites still in their infancy. Various versions have evolved over the
            years, sometimes by accident, sometimes on purpose (injected humour
            and the like).`}
          </Typography>
        </Box>
      </Modal>
      {/* <Drag
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        nextPage={'/edit-file'}
      /> */}
    </>
  );
};
export default EditArticle;
