import Layout from "../../layout/index";
import "react-tabs/style/react-tabs.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Button, Tooltip } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Notification from "../../components/notification";
import React, { useEffect, useRef, useState } from "react";
import Header from "../../layout/header/Header";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import useAuth from "../../hooks/useAuth";
import { END_POINTS } from "../../constants/url";
import { showError, showWarning } from "../../constants/toast";
import { covertFile, useGetHistoryMutation } from "../../services/conversion";
import { useDropzone } from "react-dropzone";
import Pagination from "../../components/pagination";
import { CommonBody } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { Conversion } from "../../types/conversion";
import { handleDownload } from "../../utils/handleDownload";
import Loader from "../../constants/Loader";
import useTranslation from "../../hooks/Translation";

const FileType = () => {
  const navigate = useNavigate();
  const user = useAuth();
  const translate = useTranslation();
  // const [open1, setOpen1] = useState(false);
  // const [sidebar, setSidebar] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [typeConversion, setTypeConversion] = useState<Conversion[]>([]);
  const totalPages = Math.ceil(totalCount / 20);
  const pageRef = useRef(page);
  const hasMore = page <= totalPages;
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const { state } = useLocation();
  const [value, setValue] = useState<any>(1);
  useEffect(() => {
    if (state) {
      setSelectedFile(state?.selectedFile);
      setCategory(state?.category);
      navigate("/file-type", { replace: true, state: null });
    }
  }, []);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [category, setCategory] = React.useState("1");
  const handleChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
    setSelectedFile(null);
    setSelectedAccepted(acceptedFormat[parseInt(event.target.value)]);
  };
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [sidebar, setSidebar] = useState(true);
  console.log(category, "category");

  const handleFileChange = (event: any) => {
    const temp = event;
    if (!event.length) {
      showWarning(
        translate.errors.selectValidFormat || `Please select a valid format.`
      );
      return;
    }
    const file = event.length == 1 ? event[0] : event;
    const fileFeature = fileFeatures(parseInt(category));
    setSelectedFile(file);
  };
  console.log("FILE", selectedFile);

  console.log(selectedFile, "file selected");

  const selectedFormat: any = {
    1: "/static/images/format/pdf.png",
    2: "/static/images/format/doc.png",
    3: "/static/images/format/pdf.png",
    4: "/static/images/format/img.png",
    5: "/static/images/format/pdf.png",
    6: "/static/images/format/xlsx.png",
    7: "/static/images/format/pdf.png",
    8: "/static/images/format/ppt.png",
  };
  const acceptedFormat: any = {
    1: { "application/pdf": [".pdf"] },
    2: {
      "application/msword": ["doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
    },
    3: { "application/pdf": [".pdf"] },
    4: { "image/*": [".png", ".jpeg", ".jpg"] },
    5: { "application/pdf": [".pdf"] },
    6: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
        ".xls",
      ],
    },
    7: { "application/pdf": [".pdf"] },
    8: {
      "application/vnd.ms-powerpoint": [".ppt", ".pptx"],
      // "application/vnd.openxmlformats-officedocument.presentationml.presentation":['pptx']
    },
  };
  const [selectedAccepted, setSelectedAccepted] = useState(acceptedFormat[1]);
  const types = {
    pdf: "pdf",
    doc: "doc",
    docx: "docx",
    jpeg: "jpeg",
    jpg: "jpg",
    png: "png",
    xls: "xls",
    xlsx: "xlsx",
    ppt: "ppt",
    pptx: "pptx",
  };
  const [getHistoryDataAPI] = useGetHistoryMutation();
  const getHistoryData = async () => {
    setIsLoading(true);
    try {
      const body = {
        start: page,
        length: 20,
        search: "",
      };
      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await getHistoryDataAPI(encryptedBody).unwrap();
      if (response?.status === 200) {
        setTypeConversion(response?.data?.response_object);
        setTotalCount(response?.data?.total_records);
      } else {
        setTypeConversion([]);
      }
    } catch (error: any) {
      showError(
        error?.data?.message ||
          translate.errors.somethingWentWrong ||
          "Something went wrong"
      );
    }
    setIsLoading(false);
  };
  const dropFile = (e: any) => {
    console.log(e);
    handleFileChange(e);
  };
  const fileFeatures = (category: number) => {
    switch (category) {
      case 1:
        return {
          type: [types.pdf],
          url: END_POINTS.pdfToWord,
          mime: "PDF File",
          key: "pdf_file",
        };
      case 2:
        return {
          type: [types.doc, types.docx],
          url: END_POINTS.wordToPdf,
          mime: "DOC/DOCX File",
          key: "word_file",
        };
      case 3:
        return {
          type: [types.pdf],
          url: END_POINTS.pdfToImage,
          mime: "PDF File",
          key: "pdf_file",
        };
      case 4:
        return {
          type: [types.jpeg, types.jpg, types.png],
          url: END_POINTS.imageToPdf,
          mime: "Image File",
          key: "image",
        };
      case 5:
        return {
          type: [types.pdf],
          url: END_POINTS.pdfToExcel,
          mime: "PDF File",
          key: "pdf_file",
        };
      case 6:
        return {
          type: [types.xls, types.xlsx],
          url: END_POINTS.excelToPdf,
          mime: "XLSX/XLS File",
          key: "excel_file",
        };
      case 7:
        return {
          type: [types.pdf],
          url: END_POINTS.pdfToPpt,
          mime: "PDF File",
          key: "pdf_file",
        };
      case 8:
        return {
          type: [types.ppt, types.pptx],
          url: END_POINTS.pptToPdf,
          mime: "PPT File",
          key: "ppt_file",
        };
      default:
        return {
          type: [],
          url: "/",
          mime: "",
          key: "",
        };
    }
  };
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept: selectedAccepted,
    onDrop: dropFile,
    multiple: category == "4" ? true : false,
  });
  useEffect(() => {
    if (value) getHistoryData();
    console.log(value);
  }, [value, page]);
  return (
    <div className="sdbr_rltv">
      {sidebar ? <Layout.SidePanel /> : null}
      <Header setSidebar={setSidebar} />

      <div className="sde_br_mn ">
        <div className="sde_mn_cnt hd_6 fle_typ_inr">
          <h3>
            {" "}
            {translate.fileTypeConversion.uploadSelectTypeConversion ||
              "Upload and select the type of conversion"}
          </h3>

          <Tabs>
            <TabList>
              <Tab
                style={{ cursor: "context-menu" }}
                onClick={() => {
                  setValue(0);
                }}
              >
                {translate.fileTypeConversion.fileTypeConversion ||
                  "File Type Conversion"}
              </Tab>
              <Tab
                style={{ cursor: "context-menu" }}
                onClick={() => {
                  setValue(1);
                }}
              >
                {translate.fileTypeConversion.myPastFiles || "My Past Files"}
              </Tab>
            </TabList>

            <TabPanel className="  text_center fle_type_mn" value={0}>
              <section>
                <ul className="file_type file_type_single">
                  <li {...getRootProps()}>
                    <figure>
                      <img
                        width={"91px"}
                        height={"86px"}
                        src={
                          selectedFile
                            ? selectedFormat[parseInt(category)]
                            : "/static/images/image-upload.png"
                        }
                        alt=""
                      ></img>
                    </figure>
                    {!selectedFile ? (
                      <p className="drg">
                        {translate.fileTypeConversion.dragAndDropFile ||
                          "Drag and drop your file here"}
                        <span className="d_block">or</span>
                      </p>
                    ) : (
                      <p className="drg">
                        {selectedFile?.length > 1 ? (
                          "Total files : " + selectedFile.length
                        ) : selectedFile?.name?.length < 15 ? (
                          selectedFile?.name
                        ) : (
                          <Tooltip title={selectedFile?.name}>
                            <div>{selectedFile?.name.slice(0, 15) + "..."}</div>
                          </Tooltip>
                        )}
                        <span className="d_block">
                          {selectedFile?.length > 1
                            ? translate.fileTypeConversion.multipleImages ||
                              "multiple images"
                            : // selectedFile?.type
                              fileFeatures(parseInt(category)).mime}
                        </span>
                      </p>
                    )}
                    <Button
                      className="btnn btn_primary upld_btn"
                      onClick={() => {
                        if (category === "0") {
                          showWarning(
                            translate.fileTypeConversion
                              .pleaseSelectTypeConversion ||
                              "Please select a Type of conversion"
                          );
                          return;
                        }
                      }}
                    >
                      {translate.global.browseFile || "Browse a file"}
                      {category === "0" ? (
                        ""
                      ) : (
                        <input
                          type="file"
                          {...getInputProps()}
                          // accept={selectedAccepted}
                          // onChange={handleFileChange}
                        />
                      )}
                    </Button>
                  </li>

                  <li>
                    <figure>
                      <img src="/static/images/downlod.png" alt=""></img>
                    </figure>
                    <p className="drg">
                      {translate.fileTypeConversion.addFromCloud ||
                        "Add your file from cloud"}
                    </p>

                    <Button className="btnn btn_primary upld_btn">
                      {translate.fileTypeConversion.uploadFromCloud ||
                        "Upload from cloud"}
                      <input type="file" />
                    </Button>
                  </li>
                </ul>
              </section>

              <div className="frmn frmn_flx">
                <p>
                  {translate.fileTypeConversion.selectTypeOfConversion ||
                    "Select type of conversion"}
                </p>
                <FormControl fullWidth className="control_group">
                  <Select
                    labelId="category-label"
                    id="category-select"
                    value={category}
                    onChange={handleChange}
                  >
                    {/* <MenuItem value={0} disabled>
                      Select
                    </MenuItem> */}
                    <MenuItem value={"1"}>Pdf to Word</MenuItem>
                    <MenuItem value={"2"}>Word to Pdf</MenuItem>
                    <MenuItem value={"3"}>Pdf to Image</MenuItem>
                    <MenuItem value={"4"}>Image to Pdf</MenuItem>
                    <MenuItem value={"5"}>Pdf to Excel</MenuItem>
                    <MenuItem value={"6"}>Excel to Pdf</MenuItem>
                    <MenuItem value={"7"}>Pdf to Ppt</MenuItem>
                    <MenuItem value={"8"}>Ppt to Pdf</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="py_nw">
                <Button
                  className="btnn btn_primary"
                  onClick={() => {
                    if (!selectedFile) {
                      showError(
                        translate.fileTypeConversion.noFileSelected ||
                          "No file selected"
                      );
                      return;
                    }
                    if (
                      Array.isArray(selectedFile) &&
                      selectedFile?.length == 0
                    ) {
                      showError(
                        translate.fileTypeConversion.noFileSelected ||
                          "No file selected"
                      );
                      return;
                    }
                    navigate("/convert", {
                      state: {
                        selectedFile: selectedFile,
                        key: fileFeatures(parseInt(category))?.key,
                        url: fileFeatures(parseInt(category))?.url,
                        category: category,
                      },
                    });
                  }}
                >
                  {translate.fileTypeConversion.convert || "Convert"}
                </Button>
              </div>
            </TabPanel>
            <TabPanel className="  text_center" value={1}>
              <Loader isLoad={isLoading} />
              <ul className="tst_lst">
                {typeConversion.length ? (
                  typeConversion.map((data: Conversion, index: number) => (
                    <li
                      key={index}
                      onClick={() =>
                        handleDownload(
                          data?.converted_media?.media_url as string,
                          data?.converted_media?.media_name as string
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <Tooltip
                        title={
                          data?.converted_media?.media_name
                            ? data?.converted_media?.media_name
                            : translate.global.file || "Research"
                        }
                      >
                        <h3>
                          {data?.converted_media?.media_name
                            ? data?.converted_media?.media_name.length > 10
                              ? data?.converted_media?.media_name?.slice(
                                  0,
                                  10
                                ) + "..."
                              : data?.converted_media?.media_name
                            : translate.global.file || "Research"}
                        </h3>
                      </Tooltip>
                      {/* <h3>{data?.converted_media?.media_name ? (data?.converted_media?.media_name?.length>10
                    ?
                    <Tooltip title={data?.converted_media?.media_name}>
                      <>{data?.converted_media?.media_name.slice(0,10)+"..."}</>
                      </Tooltip>
                    :
                    data?.converted_media?.media_name):"-"}</h3> */}
                      <p className="mt_40">
                        {data?.sub_category ? data?.sub_category : "-"}
                      </p>
                    </li>
                  ))
                ) : (
                  <div
                    style={{
                      height: "85px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      marginTop: "15%",
                    }}
                  >
                    {" "}
                    <h3 style={{ fontSize: "xxx-large", color: "darkgrey" }}>
                      {translate.global.noRecordsFound || "No Records Found"}
                    </h3>
                  </div>
                )}
              </ul>

              <Pagination
                module={typeConversion}
                onPageChange={onPageChange}
                page={page}
                totalPages={totalPages}
              />
            </TabPanel>
          </Tabs>
        </div>
      </div>
      <Notification
        open={open1}
        setOpen={setOpen1}
        handleClose={handleClose1}
      />
    </div>
  );
};

export default FileType;
