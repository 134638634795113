import React from "react";
import Layout from "../../layout";
import Features from "../../features";


const Aboutpage = () => {
  return (
    <div>
      <Layout.Header /> 
      <Features.AboutSection1 />
      <Features.AboutSection2 />
      <Features.AboutSection3 />
      <Features.AboutSection4 />
      <Features.AboutSection5/>
      <Layout.Footer />
    </div>
  );
};
export default Aboutpage;