import React from "react";
import useTranslation from "../../hooks/Translation";

const AboutSection5 = () => {
  const translate = useTranslation();
  return (
    <>
      <section className="jn_usr u_spc">
        <div className="conta_iner">
          <div className="jn_usr_flx">
            <div className="jn_usr_lt hd_1_1">
              <h2>
                {translate.about.join}{" "}
                <span className="d_block">{translate.about.millions} </span>
                {translate.about.ofUsers}{" "}
              </h2>
              <p>{translate.about.joinMillionsUserLine} </p>
            </div>
            <div className="jn_usr_rt">
              <figure>
                <img src="/static/images/comma.png" alt=""></img>
              </figure>
              <p dir="auto">{translate.about.singleReviewLine1}</p>
              <p dir="auto">{translate.about.singleReviewLine2}</p>
              <div className="jn_usr_inr">
                <div className="jn_usr_lt hd_6">
                  <h3>{translate.about.singleReviewName}</h3>
                  <p>
                    {translate.about.singleReviewDetailLine1}{" "}
                    <span className="d_block">
                      {" "}
                      {translate.about.singleReviewDetailLine2}{" "}
                    </span>
                  </p>
                </div>
                <div className="jn_usr_ryt">
                  <figure>
                    <img src="/static/images/adam.png" alt=""></img>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default AboutSection5;
